import React, { Component } from 'react'
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import Select from 'react-select';
import { dbURLDylanCoversHeading, dbURLDylanCovers, customStyles3 } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import ReactPlayer from "react-player";
//import ReactGA from "react-ga";
//import YoutubeEmbedAll from "../misc/YoutubeEmbedAll";
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ReactMarkdown from 'react-markdown';

class SongsCoversDylan extends Component {

    state = {
        columnDefs: [
            {headerName: "Song", field: "title", resizable: true, width: 300, cellStyle: { 'font-size': '14px' }},
            {headerName: "Songwriter", field: "songwriter", filter: true, width: 300, cellStyle: { 'font-size': '14px' }},
            {headerName: "Location & Date", field: "location_date", filter: true, width: 500, cellStyle: { 'font-size': '14px' }}
        ],
        HeadingArray: [],
        SongsArray: [],
        selectedOptionCategory: null,
        selectedOptionYear: null,
        selectedOptionAlbum: null,
        GridOn: false,
        SearchString: '',
        SearchStringLyrics: '',
        loading1: true,
        loading2: true,
        selectedOptionRange: 'All'
      }

    getHeading(){
        axios.get(dbURLDylanCoversHeading)
            .then(response => {
            this.setState({
                HeadingArray: response.data,
                loading1: false
            });
          });
        } 

    getAllCovers(){
        axios.get(dbURLDylanCovers)
          .then(response => {
            this.setState({
              SongsArray: response.data,
              loading2: false
            });
          });
      } 
    
    componentDidMount() {
        this.getHeading()
        this.getAllCovers()
      }

    selectedOptionRange = (selectedRange) => {
        this.setState({ selectedOptionRange: selectedRange });
    } 
      
      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

      handleSearchChangeLyrics = this.handleSearchChangeLyrics.bind(this);

      handleSearchChangeLyrics(event) {
      this.setState({SearchStringLyrics: event.target.value});
      } 
  
      clearSearchFieldLyrics(){
      this.setState({
          SearchStringLyrics: '', 
          })
      }
    
    selectedOptionCategory = (selectedCategory) => {
        this.setState({ selectedOptionCategory: selectedCategory });
    }

    selectedOptionYear = (selectedYear) => {
        this.setState({ selectedOptionYear: selectedYear });
    } 

    selectedOptionAlbum = (selectedAlbum) => {
        this.setState({ selectedOptionAlbum: selectedAlbum });
    }


    clearFilterCategory(){
        this.setState({
            selectedOptionCategory: null, 
            })
        }
    
    clearFilterYear(){
        this.setState({
            selectedOptionYear: null, 
            })
        }
    
    clearFilterAlbum(){
        this.setState({
            selectedOptionAlbum: null, 
            })
        }

     onChangeGrid = () => {
        this.setState(initialState => ({
            GridOn: !initialState.GridOn,
        }));
     }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
      render() {

        if (this.state.loading || this.state.loading2) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }
        

        //ReactGA.pageview(window.location.pathname);

        //const { selectedOptionAlbum } = this.state;
        const { selectedOptionRange } = this.state;
        const { selectedOptionCategory } = this.state;
        const { selectedOptionYear } = this.state;
        const { SearchString } = this.state;
        const { SearchStringLyrics } = this.state;
        const HeadingArrayData = this.state.HeadingArray;
        const AllSongs = this.state.SongsArray;
        //console.table(AllSongs);
        let ListPic, ListPic2, ManyVersionsSameSong
        //NoAltVersion

        if (AllSongs.length < 1) {
            return <div>
                        <main className="my-1 py-1">
                            <div className="container">  
                                <div className="row pt-1">
                                    <div className="col">
                                        <h1>404 - Not Found!</h1>
                                        <Link to="/lists">
                                            Go to current Lists
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                </div> 
        }


        //Filter logic
        let rangeArray, filterArray, searchWords

        if (selectedOptionRange === '#') {
            rangeArray = AllSongs.filter(e => e.sort_letter === '#');  
            } 
        if (selectedOptionRange === 'A') {
            rangeArray = AllSongs.filter(e => e.sort_letter === 'A');  
            } 
        if (selectedOptionRange === 'B') {
            rangeArray = AllSongs.filter(e => e.sort_letter === 'B');  
            } 
        if (selectedOptionRange === 'C') {
            rangeArray = AllSongs.filter(e => e.sort_letter === 'C');  
            } 
        if (selectedOptionRange === 'All') {
            rangeArray = AllSongs;  
            } 
        if (!selectedOptionRange) {
            rangeArray = AllSongs;
        }
        

        function SearchSongs(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllSongs.length; i++) {

                if (AllSongs[i].searchtitle.includes(searchText))
                
                    {searchArray.push(AllSongs[i]);}
                    //console.table(filterArrayYear);
            } 
            //console.log(searchArray);
            return searchArray
        }

        function SearchSongsLyrics(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllSongs.length; i++) {

                if (AllSongs[i].searchlyrics.includes(searchText))
                
                    {searchArray.push(AllSongs[i]);}
                    //console.table(filterArrayYear);
            } 
            //her må eg legge inn HIGHLIGHT funksjon
            //bruk Mark.js 
            return searchArray
        }

        if (!selectedOptionYear && !selectedOptionCategory && SearchString) {
            filterArray = SearchSongs(SearchString.toLowerCase());
            }  
        
        if (!selectedOptionYear && !selectedOptionCategory && SearchStringLyrics) {
            
            searchWords = SearchStringLyrics.split(/\s/).filter(word => word)
            
            filterArray = SearchSongsLyrics(SearchStringLyrics.toLowerCase());
            } 

            

        if (selectedOptionYear && !selectedOptionCategory) {
            filterArray = rangeArray.filter(e => e.year === selectedOptionYear.value);
            } 
        if (selectedOptionCategory && !selectedOptionYear) {
                filterArray = rangeArray.filter(e => e.cover_cat === selectedOptionCategory.value);
            }
          

        if (selectedOptionYear && selectedOptionCategory) {
            filterArray = rangeArray.filter(e => e.year === selectedOptionYear.value 
                && e.cover_cat === selectedOptionCategory.value);
            }

        if (!selectedOptionYear && !selectedOptionCategory && !SearchString && !SearchStringLyrics) {
            filterArray = rangeArray;
            } 
        
        //get only category
        const OnlyCategory = filterArray.map(item => item.cover_cat);
        //unique album + count
        const uniqueCategory = OnlyCategory.reduce((b,c) => (
            (b[b.findIndex(d => d.cover_cat===c)] ||
            // eslint-disable-next-line 
            b[b.push({cover_cat: c, count: 0})-1]).count++,b), 
            []
        );
        const uniqueCategorySorted = uniqueCategory.sort(function(a, b){
            return b.count-a.count
        })
        //map Album with count to AlbumOptions
        let selectLabel
        const CategoryOptions = uniqueCategorySorted.map(value => { 
            //bedre labels
            if (value.cover_cat === 'on_record') {
                selectLabel = "on record"
            }
            if (value.cover_cat === 'concert') {
                selectLabel = "concert performance"
            }
            if (value.cover_cat === 'informal') {
                selectLabel = "informal performance"
            }
            if (value.cover_cat === 'basement') {
                selectLabel = "basement tapes"
            }
            if (value.cover_cat === 'studio') {
                selectLabel = "in the studio"
            }
            if (value.cover_cat === 'rehearsal') {
                selectLabel = "tour rehearsal"
            }
            return {value: value.cover_cat, label: selectLabel + ' (' + value.count + ')'}; 
        });
 
 
         const OnlyYear = filterArray.map(item => item.year);
         const OnlyYearSorted = OnlyYear.sort();
         const uniqueYear = OnlyYearSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.year===c)] ||
             // eslint-disable-next-line 
             b[b.push({year: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueYearSorted = uniqueYear.sort(function(a, b){
            return b.count-a.count
        })
         const YearOptions = uniqueYearSorted.map(value => { 
             //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
             return {value: value.year, label: value.year + ' (' + value.count + ')'}; 
            //}
         });

         //GENIAL måte å plukke ut verdier fra ARRAY, fra ØS

        //const {lyrics, performed_onstage, times_live, first_live, last_live } = AllSongs[0];

        const Heading = HeadingArrayData.map(value => {

            ListPic = value.picture_link
            ListPic2 = value.picture2

            return (
                <React.Fragment key={value.id}>
                    <div>
                        <h1>{value.name}</h1>

                        <div className="row">
                        
                        {/* kolonne 1 */}
                        <div className="col-sm-4 mb-3">
                            
                             <img src={ListPic}
                            alt="alt" className="img-fluid" /> 

                            {
                                (() => {
                                if (ListPic2) {
                                return  <div className='mt-2'><img src={ListPic2} alt="alt" className="img-fluid" /> </div>
                                }
                                return 
                                })()
                            }

                             <div>
                                <br />
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress - {AllSongs.length} out of 500+ included.</b></div> 
                                
                                <br />
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs"><b>{selectedOptionRange} - {rangeArray.length} selected</b></div>
                                </div> 
                            </div>
                            
                            
                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-5">
                            
                            <div className="DescriptionBig my-2 p-1">
                                <ReadMoreAndLess
                                        charLimit={350}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess>
                            </div>

                            {
                                (() => {
                                    if (value.comment1) {
                                    return  <div className="card bg-light py-2 px-2">
                                                <div className="quote mb-1">{value.comment1}</div> 
                                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                                            </div>
                                    }
                                    return 
                                })()
                            }

                            <p className="Description"><i>All lyrics are the property and copyright of their owners, provided for educational purposes only.</i></p>
                            
                            {
                                (() => {
                                    if (value.spotify) {
                                    return  <div>
                                                    <p><b>--> <a href={ value.spotify }>
                                                Spotify playlist</a></b></p>
                                            </div>
                                    }
                                    return 
                                })()
                            }
                            <div className='mt-3'>
                                <b>Check out</b>
                                <div className="Description">
                                    <ul>
                                        {
                                            (() => {
                                            if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                            if (value.link1) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            return 
                                            })()
                                        }

                                        <li><b><a href='/lists'>
                                            More Lists @ MusicThisDay.com
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            
                        
                        </div>

                        {/* kolonne 3 */}
                        <div className="col-sm-3 mb-1">
                            {(!this.state.SearchString && !this.state.SearchStringLyrics && !value.no_filters)  &&
                            <div className="row">   
                                <div className="col">
                                    <h4>Filters</h4>
                                </div>
                            </div>
                            }
                            {(!this.state.SearchString && !this.state.SearchStringLyrics && !value.no_year_released)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                        <Select
                                            className="select"
                                            value={selectedOptionYear}
                                            onChange={this.selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='Recorded year'
                                            styles={customStyles3}
                                        />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterYear()} >Reset</button>
                                </div>
                            </div>
                            }
                            {(!this.state.SearchString && !this.state.SearchStringLyrics)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                    <Select
                                        className="select"
                                        value={selectedOptionCategory}
                                        onChange={this.selectedOptionCategory}
                                        options={CategoryOptions}
                                        placeholder='Select Category'
                                        styles={customStyles3}
                                    />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterCategory()} >Reset</button>
                                </div>
                            </div>
                            }

                            {((!this.state.selectedOptionCategory) && (!this.state.selectedOptionYear) && (!ManyVersionsSameSong))  && 
                            <div>
                                <div className="row">   
                                        <div className="col">
                                        <h4>Search</h4>
                                    </div>
                                </div>
                            

                                {((!this.state.selectedOptionCategory) && (!this.state.selectedOptionYear) && !this.state.SearchStringLyrics)  && 
                                    <div className="row pb-2">  
                                            <div className="col-9">
                                            <input 
                                                className="form-control form-control-sm" 
                                                type="text" 
                                                value={SearchString} 
                                                onChange={this.handleSearchChange} 
                                                placeholder="Search all song titles" 
                                            />
                                            </div>
                                            <div className="col-3 pl-0">
                                                <button type="button" className="btn btn-primary btn-sm"  
                                                onClick={() => this.clearSearchField()} >Reset</button>
                                            </div>
                                    </div>
                                }

                                {((!this.state.selectedOptionCategory) && (!this.state.selectedOptionYear) && !this.state.SearchString)  && 
                                <div className="row pb-2">  
                                        <div className="col-9">

                                                <DebounceInput
                                                    className="form-control form-control-sm" 
                                                    type="text" 
                                                    minLength={3}
                                                    debounceTimeout={1000}
                                                    value={SearchStringLyrics}
                                                    onChange={this.handleSearchChangeLyrics}
                                                    placeholder="Search all lyrics" 
                                                />

                                        </div>
                                        <div className="col-3 pl-0">
                                            <button type="button" className="btn btn-primary btn-sm"  
                                            onClick={() => this.clearSearchFieldLyrics()} >Reset</button>
                                        </div>
                                </div>
                                }
                            </div>
                            }
                           
                            <div className="row align-items-start no-gutters">
                                    
                                <div className="col pt-2">

                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox"
                                            checked={this.state.GridOn}
                                            onChange={this.onChangeGrid}
                                            className="form-check-input"
                                        />
                                        Change to Grid
                                        </label>
                                    </div>
                                </div>

                            </div>
                 
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className='col-sm-10 pb-2' align="Left">
                            <div className='mb-2'>Select letter to filter:</div>
                            {this.state.selectedOptionRange === 'All' && 
                            <Button className="btn btn-primary btn-xs active focus" onClick={() => this.selectedOptionRange('All')}>All</Button>
                            }
                            {this.state.selectedOptionRange !== 'All' && 
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('All')}>All</Button>
                            }
                            &nbsp;&nbsp;-&nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('#')}>#</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('A')}>A</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('B')}>B</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('C')}>C</Button>
                            &nbsp;&nbsp;
                        </div>
                        
                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                        
                        
                    </div>
                </React.Fragment>
                )
            }
        )


        const coverItems = filterArray.map(value => {

            return (
                <React.Fragment key={value.id}>

                   
                    {/* Tittel + lokasjon */}
                    {
                        (() => {
                        if (value.sort) {
                            return <div>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='sortTitle pl-3 mb-2'>
                                            {value.sort}
                                        </div>
                                    </div>
                                </div>
                                {
                                    (() => {
                                    if (value.sort_letter !== '#') {
                                        return  <div className='row'>
                                                    <div className='col'>
                                                        <div className="bg-light p-2 m-2">
                                                            <b>Sources & inspiration:</b>
                                                            <div className="Description">
                                                                <ul>
                                                                    <li><a href="http://www.bjorner.com/still.htm">
                                                                    Olof’s “Still On The Road“</a></li>
                                                                    <li><a href="https://www.amazon.com/Songs-He-Didnt-Write-Influence/dp/1842404245">
                                                                    The Songs He Didn't Write - Bob Dylan Under the Influence (Derek Barker )</a></li>
                                                                    <li>The Songs He Didn't Write - Bob Dylan Under the Influence - A Supplement 2 (Derek Barker) -> Out of print</li>
                                                                    <li><a href="https://www.amazon.com/Bob-Dylan-Stolen-Moments-1941-1995/dp/0028646762/ref=sr_1_1?dchild=1&keywords=clinton+heylin+bob+dylan+a+life+in+stolen+moments&qid=1623526140&sr=8-1">
                                                                    Bob Dylan: A Life in Stolen Moments Day by Day 1941-1995 (Clinton Heylin)</a></li>
                                                                    <li>Wikipedia</li>
                                                                </ul>
                                                            </div> 
                                                        </div>
                                                    </div>
                                            </div>
                                    }
                                    return 
                                    })()
                                }
                                <div className="row videoTitle2">
                                    <div className="col-sm-12 pt-2"> 
                    
                                        {
                                            (() => {
                                            if (value.cover_cat === 'on_record') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">on record</button>
                                            }
                                            if (value.cover_cat === 'concert') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">concert performance</button>
                                            }
                                            if (value.cover_cat === 'informal') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">informal performance</button>
                                            }
                                            if (value.cover_cat === 'basement') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">basement tapes</button>
                                            }
                                            if (value.cover_cat === 'studio') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">in the studio</button>
                                            }
                                            if (value.cover_cat === 'rehearsal') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">tour rehearsal</button>
                                            }
                                            return 
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.album_title) {
                                                return  <div><h4>{value.title} ({value.songwriter})</h4>
                                                <h6>ALBUM: {value.album_title}</h6>
                                                </div>
                                            }
                                            return <div><h4>{value.title} ({value.songwriter})</h4>
                                            </div>
                                            })()
                                        }
                                        

                                        <div><b>{value.location_date}</b></div>
                                    </div>
                                </div>
                            </div>
                        }
                    
                        return <div className="row videoTitle2">
                                    <div className="col-sm-12 pt-2"> 
                    
                                        {
                                            (() => {
                                            if (value.cover_cat === 'on_record') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">on record</button>
                                            }
                                            if (value.cover_cat === 'concert') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">concert performance</button>
                                            }
                                            if (value.cover_cat === 'informal') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">informal performance</button>
                                            }
                                            if (value.cover_cat === 'basement') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">basement tapes</button>
                                            }
                                            if (value.cover_cat === 'studio') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">in the studio</button>
                                            }
                                            if (value.cover_cat === 'rehearsal') {
                                                return <button type="button" className="btn btn-info btn-xs my-1 float-right">tour rehearsal</button>
                                            }
                                            return 
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.album_title) {
                                                return  <div><h4>{value.title} ({value.songwriter})</h4>
                                                <h6>ALBUM: {value.album_title}</h6>
                                                </div>
                                            }
                                            return <div><h4>{value.title} ({value.songwriter})</h4>
                                            </div>
                                            })()
                                        }
                                        

                                        <div><b>{value.location_date}</b>
                                        
                                        </div>
                                    </div>
                                </div>
                        })()
                    }

                    <div className="row pt-3 pb-3">

                    {/* kolonne 1 */}
                    <div className="col-sm-3">
                        {
                            (() => {
                            if (value.picture) {
                                return <div><img src={value.picture} className="img-fluid" alt="">
                                    </img></div>
                            }
                            if (value.album_cover_link && !value.picture) {
                                return <div><img src={value.album_cover_link} className="img-fluid" alt="">
                                        </img>
                                        {
                                            (() => {
                                            if (value.album_rd) {
                                                return <div>
                                                            <div className='mt-2 px-2 Date'>Album released: {value.album_rd} </div>
                                                        </div>
                                            }
                                            return 
                                            })()
                                        }
                                    </div>
                            }
                            
                            return 
                            })()
                        }

                        {
                            (() => {
                                if (value.performed_when) {
                                return  <div className='m-3'><b>Performed onstage</b> <br />
                                            <div className='factText p-2'>
                                                <div><b>Years:</b> {value.performed_when}</div>
                                                <div><i>-> <b>{value.performed_times}</b> times</i></div>
                                                <div><b>First time:</b> {value.performed_first} <br /> 
                                                <b>Last time:</b> {value.performed_last}</div>
                                            </div>
                                        </div>
                                }
                                return 
                            })()
                        }
                        
                    </div>

                    {/* kolonne 2 */}
                    <div className="col-sm-6">

                        {
                            (() => {
                            if (value.description) {
                            return  <div className='Description mt-2'>
                                        <ReadMoreAndLess
                                                charLimit={400}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                </div>
                            
                            }
                            
                            return 
                            })()
                        }

                        {
                            (() => {
                            if (value.line_up) {
                                return <div>
                                            <div className='mt-2'><b>Line-up:</b></div>
                                            <div className="factText mb-2 px-2 py-1">{value.line_up}</div>
                                        </div>
                            }
                            return 
                            })()
                        }
                        
                        

                        <div className="row pt-2 Description">   
                            {
                                (() => {
                                    if (value.lyrics && !SearchStringLyrics) {
                                        return  <div className="bg-light ml-2 mr-2">
                                                    <div className="py-2 px-2">
                                                        <ReadMoreAndLess
                                                                charLimit={300}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.lyrics}
                                                        </ReadMoreAndLess>
                                                
                                                    </div>
                                                </div>
                                        }
                                        if (value.lyrics && SearchStringLyrics) {
                                        return  <div className="bg-light ml-2 mr-2">
                                                <div className="py-2 px-2">
                                    
                                                        <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={searchWords}
                                                            autoEscape={true}
                                                            textToHighlight={value.lyrics}
                                                        />
                                                </div>
                                            </div>

                                        }
                                    return
                                    }
                                )()
                            }

                            {
                                (() => {
                                if (value.quote2) {
                                    return <div className="card bg-light py-2 px-2 mt-3">
                                                <div className="quote mb-1">{value.quote2}</div> 
                                                <div className="blockquote-footer"><i>{value.quote2_source}</i></div>
                                            </div>
                                }
                                return 
                                })()
                            }   
                            
                        </div>  

                    </div>

                    {/* kolonne 3 */}
                    <div className="col-sm-3">

                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                            {
                                (() => {
                                if (value.youtube) {
                                    return <div className='mb-2 mt-1'><div className="border d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                    //className="react-player"
                                                    light='true'
                                                    controls
                                                    volume={0.9}
                                                    width="280px"
                                                    height="200px"
                                                    /> 
                                            </div>
                                        </div>
                                }
                                return <div><div className='text-center my-3'><h5>
                                    <i>Not available on YouTube</i></h5></div>
                                </div>
                                })()
                            }
                            
                            {
                                (() => {
                                if (value.performances_description) {
                                    return <div className="card bg-light mb-2 py-2 px-2">
                                                <div className="quote2 mb-1">{value.performances_description}</div> 
                                            </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.quote) {
                                    return <div className="card bg-light p-2">
                                                <div className="quote2 mb-3">{value.quote}</div> 
                                                <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                                            </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.youtube2) {
                                        return <div><div className="Header2 mt-2" align="center"><b>Alt version / Live version</b></div>
                                            <div className='mb-2 mt-1'><div className="border d-flex justify-content-center flex-nowrap">  
                                                            <ReactPlayer
                                                            url={ value.youtube2 }
                                                            //className="react-player"
                                                            light='true'
                                                            controls
                                                            volume={0.9}
                                                            width="280px"
                                                            height="200px"
                                                            //width="350"
                                                            //height="650"
                                                            /> 
                                                    </div>
                                                </div>
                                                
                                                <div className="card bg-light mb-2 py-2 px-2">
                                                        <div className="quote2 mb-1">{value.youtube2_description}</div> 
                                                </div>
                                                            
                                            </div>
                                            
                                    }
                                    return
                                })()
                            }
                            
                    </div>

                    </div>

                    {
                        (() => {
                        if (value.more_info) {
                            return <div className="row p-2 mt-2 mb-3">
                                        <div className='col'>
                                            <h4><i>Other performances of</i> - {value.title}</h4>
                                        <div className="factTextNoWrap rounded p-2">
                                            <ReactMarkdown>
                                                {value.more_info}                 
                                            </ReactMarkdown>
                                        </div>
                                        </div>
                                    </div>
                        }
                        return 
                        })()
                    }

                    {
                        (() => {
                        if (value.songs_not_included) {
                            return <div className="row p-1 mt-3 mb-3">
                                        <div className='col'>
                                            <h4><i>Other songs worth mentioning</i> - {value.sort_letter}</h4>
                                        <div className="factTextNoWrap rounded p-1">
                                            <ReactMarkdown>
                                                {value.songs_not_included}                 
                                            </ReactMarkdown>
                                        </div>
                                        </div>
                                    </div>
                        }
                        return 
                        })()
                    }       
                                
                    
                    

                    
                    

                    


                </React.Fragment>          
            )

        })

    
    
        return (
            <div>
                <main className="my-1 py-1">
                    <div className="container">
                          
                        { Heading }
    
                        {
                            (() => {
                            if (this.state.loading) {
                                return <div><br /><h5><i>Loading...</i></h5></div>
                            }
                            return <div> 
                                {!this.state.GridOn && 
                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                    {coverItems}
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            })()
                        }
                        
                        {this.state.GridOn && 
                            <div className="row pr-1 pl-2 pt-3">
                                <div
                                    id="myGrid"
                                    style={{ height: '850px', width: '1200px' }}
                                    className="ag-theme-balham" 
                                    >
                                
                                    <AgGridReact
                                    
                                    onGridReady={ params => this.gridApi = params.api }
                                        rowSelection="multiple"
                                        pagination={true}
                                        columnDefs={this.state.columnDefs}
                                        rowData={filterArray}
                                        >
                                        
                                    </AgGridReact>
                                    
                                </div>
                            </div>
                        }
    
                        
                        <div className='row'>
                            <div className='col border-top'>
                                <div className='float-right m-2'>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                    <b>Scroll to top</b></button>
                                </div>
                            </div>
                        </div>

                        <div className="bg-light p-2 m-2">
                            <b>Sources & inspiration:</b>
                            <div className="Description">
                                <ul>
                                    <li><a href="http://www.bjorner.com/still.htm">
                                    Olof’s “Still On The Road“</a></li>
                                    <li><a href="https://www.amazon.com/Songs-He-Didnt-Write-Influence/dp/1842404245">
                                    The Songs He Didn't Write - Bob Dylan Under the Influence (Derek Barker )</a></li>
                                    <li>The Songs He Didn't Write - Bob Dylan Under the Influence - A Supplement 2 (Derek Barker) -> Out of print</li>
                                    <li><a href="https://www.amazon.com/Bob-Dylan-Stolen-Moments-1941-1995/dp/0028646762/ref=sr_1_1?dchild=1&keywords=clinton+heylin+bob+dylan+a+life+in+stolen+moments&qid=1623526140&sr=8-1">
                                    Bob Dylan: A Life in Stolen Moments Day by Day 1941-1995 (Clinton Heylin)</a></li>
                                    <li>Wikipedia</li>
                                </ul>
                            </div> 
                        </div>

                  
                    </div>
                </main>
            </div>

        )
    }
}

export default SongsCoversDylan