import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import { dbURLBestSongs, dbURLBestSongsLive } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
//import ReactGA from "react-ga";
import YoutubeEmbed from "../misc/YoutubeEmbed";
import { Link } from 'react-router-dom';
import SEO from '../../SEO';
import withRouter from '../withRouter';

class TheBestSongs extends Component {

    state = {
        SongAllData: [],
        SongLiveData: [],
        loading1: true,
        loading2: true
      }

    getAllSongItems(){
        axios.get(dbURLBestSongs + this.props.params.slug)
          .then(response => {
            this.setState({
              SongAllData: response.data,
              loading1: false
            });
          });
      } 
    
    getLiveSongItems(){
        axios.get(dbURLBestSongsLive + this.props.params.slug)
          .then(response => {
            this.setState({
              SongLiveData: response.data,
              loading2: false
            });
          });
      } 
    
    componentDidMount() {
        this.getAllSongItems()
        this.getLiveSongItems()
      }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {
        if (this.state.loading1 || this.state.loading2) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }

        const AllSongItems = this.state.SongAllData;
        const AllLiveVersions = this.state.SongLiveData;
        let AltVersions

        const {artist, title, selected_pic} = AllSongItems[0];
        let name = artist + " - " + title
        

        const songItems = AllSongItems.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row">

                        <div className="col-sm-7 mb-3">
                            
                            <img src={value.selected_pic} className="img-fluid" alt=""></img>

                        </div>
                        <div className="col-sm-5 mb-3">

                            <div className='BestSong text-center p-1 mb-3 rounded'><b>{value.artist} - {value.title}</b>
                            </div>
                            
                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.main_comment}</div> 
                                <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                            </div>

                        </div>

                    </div>

                    <div className="row pt-3 pb-3 border-bottom border-top border-info">
                        
                        {/* kolonne 1 */}
                        <div className="col-sm-2 text-center">
                            
                            <div align="center">
                                {
                                    (() => {
                                    if (value.picture) {
                                    return  <img src={value.picture} alt="" height="130" width="130"></img>
                                    }
                                    return <img src={value.album_picture} alt="" height="130" width="130"></img>
                                    })()
                                }
                                <br />
                                {
                                    (() => {
                                    if (value.youtube) {
                                        return  <div><br /><b>Listen on YouTube</b>
                                                <div className="border">
                                                    <YoutubeEmbed embedId={value.youtubeID} />
                                                </div>
                                            </div>
                                    }
                                    return <p></p>;
                                    })()
                                }
    
                            </div>

                        </div>

                        {/* kolonne 2 */}
                        <div className="col-sm-5 pb-3 border-right">

                            <div className="row">
                                <div className="col py-2 border-right Description">

                                    {
                                    (() => {
                                    if (value.description) {
                                    return  <div>
                                                <div className='factText p-1'>
                                                    <ReadMoreAndLess
                                                            charLimit={600}
                                                            readMoreText={" Read more ▼"}
                                                            readLessText={" Read less ▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                        >
                                                        {value.description}
                                                    </ReadMoreAndLess>
                                                </div>
                                                <p className="Description"> 
                                                    <br /> <em> -> <a href={value.wiki}>more info..</a></em>
                                                    </p>
                                            </div>
                                    
                                    }
                                    if (!value.description && value.comment) {
                                        return <div><div className="card bg-light py-2 px-2">
                                                <p className="quote3">{value.comment}</p> 
                                            
                                                {
                                                    (() => {
                                                            if (value.date_txt) {
                                                                return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                    })()

                                                }
                        
                                            </div>
                                        </div>
                                    }
                                    return <p>No description..yet.</p>
                                    })()
                                    }
                                </div>

                            </div>

                            <div className="row pt-3">
                                <div className="px-2"><b>Lyrics</b> <br /></div>
                                <div className="bg-light ml-2 mr-2">
                                    <div className="py-2 px-2 Description">
                                        <ReadMoreAndLess
                                                charLimit={400}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.lyrics}
                                        </ReadMoreAndLess>
                                
                                    </div>
                                </div>

                            </div>   

                            

                        </div>
                        
                        {/* kolonne 3 */}
                        <div className="col-sm-5">

                                <div className="row">
                                
                
                                    <div className="col-sm-7">
                                            <div>
                                                
                                            <b>Songwriter(s)</b> <br />
                                            <div className="factText px-1">{value.songwriter}</div>
                                            {
                                                (() => {
                                                if (value.main_composer) {
                                                    return <div>
                                                            <b>Main writer</b> <br />
                                                            <div className="factText p-2">{value.main_composer}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.original_performer) {
                                                    return <div>
                                                            <b>Original performer</b> <br />
                                                            <div className="factText">{value.original_performer}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                                <b>Recorded </b><br />
                                                {
                                                    (() => {
                                                    if (value.recorded) {
                                                    return <div className="Date px-1"><i>{value.recorded}</i></div>
                                                    }
                                                    return <div className="Date px-1"><i>{value.rd_text}</i></div>
                                                    })()
                                                }

                                                {
                                                    (() => {
                                                    if (value.studio_txt) {
                                                    return <div>
                                                                <b>Location</b> <br />
                                                                <div className="factText px-1">{value.studio_txt}</div>
                                                                
                                                            </div>
                                                    } 
                                                    return 
                                                    })()
                                                }

                                                {
                                                    (() => {
                                                    if (value.producer) {
                                                    return <div>
                                                                <b>Producer</b> <br />
                                                                <div className="factText px-1">{value.producer}</div>
                                                                
                                                            </div>
                                                    } 
                                                    return 
                                                    })()
                                                }
                                            
                                                {
                                                    (() => {
                                                    if (value.engineer) {
                                                    return <div><b>Engineer</b> <br />
                                                    <div className="factText px-1">{value.engineer}</div>
                                                    
                                                    </div>
                                                
                                                    } 
                                                    return <p></p>
                                                    })()
                                                }
                                                
                                            </div>

                                    </div>

                                    <div className="col-sm-5">
                                            
                                            
                                            {
                                                    (() => {
                                                    if (value.single_released) {
                                                    return <div>
                                                                <b>Single released</b> <br />
                                                                <div className="Date px-1">{value.single_released}</div>
                                                            </div>
                                                    } else if (value.sr_text) {
                                                    return <div>
                                                                <b>Single released</b> <br />
                                                                <div className="Date px-1">{value.sr_text}</div>
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                            }

                                            {
                                                (() => {
                                                if (value.album) {
                                                return <div><b>Album</b> <br />
                                                <div className="factText px-1">{value.album}</div>
                                                <div className="Date px-1"><i>{value.album_rd||value.ar_text}</i></div>
                                                <p></p>
                                                </div>
                                            
                                                } 
                                                return <p></p>
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.times_live) {
                                                return <div><b>Performed onstage</b> <br />
                                                <div className="factText px-1">{value.performed_onstage}</div>
                                                <div className="factText px-1"><i>-> <b>{value.times_live}</b> times</i></div>
                                                <div className="factText px-1"><b>First time:</b> {value.first_live} <br /> 
                                                <b>Last time:</b> {value.last_live}</div>
                                                <br />
                                                </div>
                                                } 
                                                if (value.performed_onstage && !value.times_live) {
                                                    return <div><b>Performed onstage</b> <br />
                                                    <div className="factText px-1">{value.performed_onstage}</div>
                                                    <br />
                                                    </div>
                                                } 

                                                return <p></p>
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.outtake) {
                                                    return <button type="button" className="btn btn-info btn-xs">Outtake</button>
                                                }
                                                if (value.live_version && value.cover_version) {
                                                    return <div><button type="button" className="btn btn-info btn-xs">Live</button>&nbsp;
                                                    <button type="button" className="btn btn-info btn-xs">Cover</button>
                                                    </div>
                                                }
                                                if (value.cover_version) {
                                                    return <button type="button" className="btn btn-info btn-xs">Cover</button>
                                                }
                                                if (value.alt_version) {
                                                    return <button type="button" className="btn btn-info btn-xs">Alt. version</button>
                                                }
                                                return <p></p>;
                                                })()
                                                }
                                    
                                    </div>

                                </div>

                                <div className="row pt-3">
                                    <div className="col-12">
                                        {
                                            (() => {
                                            if (value.line_up) {
                                            return  <div><b>Probable line-up</b> <br />
                                                        <div className="factText px-1">
                                                            {value.line_up}
                                                        </div>
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                    </div>
                                </div>
                            
                            </div>
                                
                    </div>

                    <div className="row pt-3 pb-3 border-bottom border-top border-info">

                        <div className="col-sm-6">

                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.comment_2}</div> 
                                <div className="blockquote-footer"><i>{value.people_txt2} ({value.date_2})</i></div>
                            </div>

                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="quote mb-3">{value.comment1}</div> 
                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                            </div>

                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="quote mb-3">{value.comment3}</div> 
                                <div className="blockquote-footer"><i>{value.source3}</i></div>
                            </div>

                            {
                                (() => {
                                if (value.comment5) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment5}</div> 
                                                <div className="blockquote-footer"><i>{value.source5}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.comment_3}</div> 
                                <div className="blockquote-footer"><i>{value.people_txt3} ({value.date_3})</i></div>
                            </div>

                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="quote mb-3">{value.comment2}</div> 
                                <div className="blockquote-footer"><i>{value.source2}</i></div>
                            </div>

                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="quote mb-3">{value.comment4}</div> 
                                <div className="blockquote-footer"><i>{value.source4}</i></div>
                            </div>

                            {
                                (() => {
                                if (value.comment6) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment6}</div> 
                                                <div className="blockquote-footer"><i>{value.source6}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>


                </React.Fragment>          
            )

        }) 

        const AltVersionItems = AllSongItems.map(value => {

            if (value.video1_alt) {AltVersions = true};

            return (

                <React.Fragment key={value.id}>

                    <div className="row pt-3 pb-3">

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.video1_alt && value.video3_alt) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video1_alt} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc1_alt}
                                            </div>
                                            
                                        </div>
                                        <div className="card bg-light mt-3 py-2 px-2">

                                        <div className="mx-4">  
                                                <YoutubeEmbed embedId={value.video3_alt} />
                                        </div>

                                    <div className='DescriptionBig2 pl-4'>
                                        {value.desc3_alt}
                                    </div>
                                    
                                </div></div>
                                }
                                if (value.video1_alt && !value.video3_alt) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video1_alt} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc1_alt}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                        {
                                (() => {
                                if (value.video2_alt && value.video4_alt) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video2_alt} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc2_alt}
                                            </div>
                                            
                                        </div>

                                        <div className="card bg-light mt-3 py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video4_alt} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc4_alt}
                                            </div>
                                            
                                        </div>


                                    </div>
                                }
                                if (value.video2_alt && !value.video4_alt) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video2_alt} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc2_alt}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>

                </React.Fragment>
            )
        })
        
        const LiveItems = AllLiveVersions.map(value => {
        
            return (

                <React.Fragment key={value.id}>

                    <div className="row videoTitle2">
                        <div className="col-sm-12"> 
                            <div>&nbsp;<b>{value.venue} - {value.city}, {value.country} - {value.gig_date_clean}</b>
                                {
                                    (() => {
                                    if (value.last) {
                                        return <button type="button" className="btn btn-info btn-xs my-1" style={{float: 'right'}}>Last performance</button>
                                        
                                    }
                                    if (value.first) {
                                        return <button type="button" className="btn btn-info btn-xs my-1" style={{float: 'right'}}>Live debut</button>
                                    }
                                    return 
                                    })()
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3 pb-3">
                        <div className="col-sm-3">
                            {
                                (() => {
                                if (value.picture_link && !value.pic_size) {
                                    return <div><img src={value.picture_link} alt="" height="250" width="250" 
                                            className="rounded mx-auto d-block"></img><br /></div>
                                }
                                if (value.picture_link && value.pic_size) {
                                    return <div><img src={value.picture_link} alt="" height="335" width="250" 
                                            className="rounded mx-auto d-block"></img><br /></div>
                                }
                                return 
                                })()
                            }

                            <p><b>{value.tour}</b></p>
                            
                            

                        </div>

                        <div className="col-sm-4">

                            {
                                (() => {
                                if (value.selected_lyrics) {
                                    return <div className="bg-light mx-1">
                                                <div className="py-1 px-1 Description">
                                                    <ReadMoreAndLess
                                                            charLimit={700}
                                                            readMoreText={" Read more ▼"}
                                                            readLessText={" Read less ▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                        >
                                                        {value.selected_lyrics}
                                                    </ReadMoreAndLess>
                                            
                                                </div>
                                            </div>
                                }
                                if (value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <div className="quote mb-3">{value.comment}</div> 
                                                <div className="blockquote-footer"><i>{value.source}</i></div>
                                            </div>
                                }
                                return 
                                })()
                            }
                            
                            
                                
                                
                                <div className='mt-2'><b>Line-up:</b></div>
                                <div className="factText mb-2 px-2 py-1">{value.musicians}</div>

                             

                        </div>

                        <div className="col-sm-5">

                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                                <div className="border my-2">  
                                        {/* <ReactPlayer
                                            //url={ value.youtube + '?t=' + value.youtube1 }
                                            url={ value.video }
                                            //className="react-player"
                                            //light='true'
                                            controls
                                            volume={0.9}
                                            width="350px"
                                            height="300px"
                                            //width="350"
                                            //height="650"
                                        /> */}
                                        <YoutubeEmbed embedId={value.video} />
                                </div>
                                        
                        </div>

                    </div>

                </React.Fragment>
            
            )

        })

        const CoverItems = AllSongItems.map(value => {

            return (

                <React.Fragment key={value.id}>

                    <div className="row pt-3 pb-3">

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.video1 && value.video3) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video1} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc1}
                                            </div>
                                            
                                        </div>
                                        <div className="card bg-light mt-3 py-2 px-2">

                                        <div className="mx-4">  
                                                <YoutubeEmbed embedId={value.video3} />
                                        </div>

                                    <div className='DescriptionBig2 pl-4'>
                                        {value.desc3}
                                    </div>
                                    
                                </div></div>
                                }
                                if (value.video1 && !value.video3) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video1} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc1}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.video2 && value.video4) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video2} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc2}
                                            </div>
                                            
                                        </div>

                                        <div className="card bg-light mt-3 py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video4} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc4}
                                            </div>
                                            
                                        </div>


                                    </div>
                                }
                                if (value.video2 && !value.video4) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video2} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.desc2}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>

                </React.Fragment>
            )
        })
    
        return (

            <div>
                {
                    (() => {
                    if (this.state.loading) {
                        return <div>
                                <main className="my-4 py-4"><div className="container"> 
                                <div className="row pt-5"><div className="col">
                                <h4>Loading...</h4></div></div></div></main>;
                            </div>
                    }
                    if (songItems.length < 1) {
                        return <div>
                                    <main className="my-1 py-1">
                                        <div className="container">  
                                            <div className="row pt-1">
                                                <div className="col">
                                                    <h1>404 - Not Found!</h1>
                                                    <Link to="/lists">
                                                        Go to current Posts
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                            </div>
                    }
                    return <main className="my-1 py-1">
                            <div className="container">
                            <SEO
                                title={name}
                                description={'Facts, quotes, lyrics, studio versions, live versions & cover versions'}
                                name={name}
                                picture_link={selected_pic}
                                url={'https://www.musicthisday.com/songs/'+ this.props.slug}
                                canonical={'https://www.musicthisday.com/songs/'+ this.props.slug}
                                type='article' />
            
                                <div className="row bestSongsTitle mb-1">
                                    <div className="col-sm-12 mb-2"> 
                                        <div className="text-center text-light bg-secondary rounded"><b> THE BEST SONGS</b></div>
                                    </div>
                                </div>
            
                                <div className="row">
                                    <div className="col">
                                            {songItems}
                                    </div>
                                </div>
    
                                {
                                    (() => {
                                    if (AltVersions) {
                                    return  <div>
                                                <div className='row mt-3'>
                                                    <div className='col'>
                                                        <div className='BestSong text-center p-1 mb-3 rounded'><b>Alt Studio versions</b>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div className="row">
                                                    <div className="col">
                                                            {AltVersionItems}
                                                    </div>
                                                </div>
                                    </div>
                                    }
                                    return 
                                    })()
                                }

                                {
                                    (() => {
                                    if (LiveItems.length > 0) {
                                    return  <div>
                                                <div className='row mt-3'>
                                                    <div className='col'>
                                                        <div className='BestSong text-center p-1 mb-3 rounded'><b>{LiveItems.length} - Selected live versions</b>
                                                        </div>
                                                    </div>
                                                </div>
                    
                                                <div className="row">
                                                    <div className="col">
                                                            {LiveItems}
                                                    </div>
                                                </div>
                                    </div>
                                    }
                                    return 
                                    })()
                                }
    
                                
    
                                <div className='row mt-3'>
                                    <div className='col'>
                                        <div className='BestSong text-center p-1 mb-3 rounded'><b>Selected cover versions</b>
                                        </div>
                                    </div>
                                </div>
    
                                <div className="row">
                                    <div className="col">
                                            {CoverItems}
                                    </div>
                                </div>
            
    
                                <p align="Right"><br />
                                    <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                    <b>Scroll to top</b></button>
                                </p>
                  
                            </div>
                        </main>
                    })()
                }  
            </div>

  

        )
    }
}

export default withRouter(TheBestSongs);