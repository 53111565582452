import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import { dbURLGreatConcerts } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
//import ReactGA from "react-ga";
import YoutubeEmbed from "../misc/YoutubeEmbed";
import { Link } from 'react-router-dom';
import withRouter from '../withRouter';

class GreatConcert extends Component {
    state = {
        ConcertAllData: [],
        loading: true
      }

    getAllConcertData(){
        axios.get(dbURLGreatConcerts + this.props.params.slug)
          .then(response => {
            this.setState({
                ConcertAllData: response.data,
                loading: false
            });
          });
      }
    
    componentDidMount() {
        this.getAllConcertData()
      }

    scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    render() {

        const AllConcertDetails = this.state.ConcertAllData;

        const ConcertDetails = AllConcertDetails.map(value => {


            return (
                <React.Fragment key={value.id}>

                    <div className="row">

                        <div className="col-sm-5 mb-3 text-center">
                            
                            <img src={value.big_picture} className="img-fluid" alt=""></img>

                        </div>
                        <div className="col-sm-7 mb-3">
                            <div className='BestSong text-center p-1 mb-3 rounded'>
                                <div>&nbsp;<b>{value.artist} - {value.venue} - {value.city}, {value.country} 
                                - {value.gig_date}&nbsp;&nbsp;&nbsp;</b></div> 
                            </div>
                            
                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.comment}</div> 
                                <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                            </div>
                            {
                                (() => {
                                if (value.comment_2) {
                                    return <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote3 mb-3">{value.comment_2}</div>
                                                <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }
                            

                        </div>

                    </div>

                    <div className="row pt-3 pb-2 border-bottom border-top border-info">

                        {/* kolonne 1 */}
                        <div className="col-sm-7 pb-2 border-right">

                            <div className="row">
                                <div className="col py-2 border-right Description">

                                    <div>
                                        <div className='factText p-1'>
                                            <ReadMoreAndLess
                                                    charLimit={1800}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description}
                                            </ReadMoreAndLess>
                                        </div>
                                        {/* <p className="Description"> 
                                            <br /> <em> -> <a href={value.wikipedia_link}>more info..</a></em>
                                            </p> */}
                                    </div>
                                    
                                </div>

                            </div>

                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-5">

                                <div className="row">
                                
                                    <div className="col">
                                        <div>
                                            <b>Setlist</b> <br />
                                            <div className="bg-light mx-2 py-1 px-2 Description">
                                                <ReadMoreAndLess
                                                    charLimit={700}
                                                    readMoreText={" more ▼"}
                                                    readLessText={" less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                        >
                                                        {value.setlist}
                                                </ReadMoreAndLess>
                                            </div>
                                            {
                                                (() => {
                                                if (value.musicians) {
                                                return <div><b>Musicians</b> <br />
                                                <div className="factText p-1 mb-2">{value.musicians}</div>
                                                </div>
                                            
                                                } 
                                                return
                                                })()
                                            }
 
                                        </div>

                                    </div>

                                </div>
                            
                            </div>
                                
                    </div>

                    {/* COMMENTS */}
                    <div className="row pt-3 pb-3 border-bottom border-top border-info">

                        <div className="col-sm-6">
                            
                            {
                                (() => {
                                if (value.comment3) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment3}</div> 
                                                <div className="blockquote-footer"><i>{value.source3}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment5) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment5}</div> 
                                                <div className="blockquote-footer"><i>{value.source5}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.comment4) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment4}</div> 
                                                <div className="blockquote-footer"><i>{value.source4}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment6) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment6}</div> 
                                                <div className="blockquote-footer"><i>{value.source6}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>

                    {/* Selected songs */}
                        <div className='row mt-3'>
                            <div className='col'>
                                <div className='BestSong text-center p-1 mb-1 rounded'><b>Selected songs</b>
                                </div>
                            </div>
                        </div>
                                        <div className="row pt-3 pb-3 border-bottom border-info">

                                            <div className="col-sm-6">

                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video1} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.title1}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video3} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.title3}</b></h5>
                                                    </div>
                                                </div>

                                                

                                            </div>

                                            <div className="col-sm-6">
                                                
                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video2} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.title2}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video4} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.title4}</b></h5>
                                                    </div>
                                                </div>
                                            

                                            </div>

                    </div>

                </React.Fragment>
            )


        })

        return (
            <div>
                {
                    (() => {
                    if (this.state.loading) {
                        return <div>
                                <main className="my-4 py-4"><div className="container"> 
                                <div className="row pt-5"><div className="col">
                                <h4>Loading...</h4></div></div></div></main>;
                            </div>
                    }
                    if (ConcertDetails.length < 1) {
                        return <div>
                                    <main className="my-1 py-1">
                                        <div className="container">  
                                            <div className="row pt-1">
                                                <div className="col">
                                                    <h1>404 - Not Found!</h1>
                                                    <Link to="/lists">
                                                        Go to current Posts
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                            </div>
                    }
                    return <main className="my-1 py-1">
                                <div className="container">
                
                                    <div className="row bestSongsTitle mb-1">
                                        <div className="col-sm-12 mb-2"> 
                                            <div className="text-center text-light bg-secondary rounded"><b>Great Concert</b></div>
                                        </div>
                                    </div>
                
                                    <div className="row">
                                        <div className="col">
                                                {ConcertDetails}
                                        </div>
                                    </div>
                        
                                    <p align="Right"><br />
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                        <b>Scroll to top</b></button>
                                    </p>
                                    
                            
                                </div>
                            </main>
                    })()
                }

                

            

            </div>

        )

    }

}
export default withRouter(GreatConcert)