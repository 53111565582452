import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURLConcert } from '../Constants';
import '../lists.scss';
import ReactPlayer from "react-player";
//import ReactMarkdown from 'react-markdown';
import SEO from '../../SEO';
import {useParams, Link} from 'react-router-dom';

const Concert = (props) => {

    const [concertData, setConcertData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const params = useParams();

    useEffect(() => {
        const fetchConcertData = () => {
          axios.get(dbURLConcert + params.slug)
          .then(response => {
            setLoading(false)
            setConcertData(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        fetchConcertData()
      }, [params.slug])
  
    if (error) return `Error: ${error.message}`;

    if (loading) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }
    if (concertData.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/posts">
                                        Go to current Post
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, page } = concertData[0];


    const concertItems = concertData.map(value => {

        return (
            <React.Fragment key={value.id}>

                <div className="row videoOfTheDayTitle mb-2">
                    <div className="col-sm-12"> 
                        <div className="text-center"><b>Concert: </b>{value.name}</div>
                    </div>
                </div>

                <div className="row">

                    {/* Kolonne 1 */}
                    <div className="col-sm-4">

                        <img src={value.picture_link} alt="concertPicture" className="img-fluid rounded mx-auto d-block mb-2" />

                        <div>
                            <div className="text-center mb-2"><b>{value.tour}</b></div>
                        </div>

                        <b>Line-up:</b><br />
                        <div className="factText p-1">{value.musicians}</div>

                        {value.description &&
                            <div className="Description bg-light my-3 mx-2 p-2">
                                {value.description}                     
                            </div>
                        }

                        {value.comment_2 &&
                            <div className="card bg-light py-2 px-2">
                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                {value.source_txt_details_2 &&
                                    <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                }
                                {!value.source_txt_details_2 &&
                                    <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>
                                }
                            </div>
                        }
                        
                    </div>

                    {/* Kolonne 2 */}
                    <div className="col-sm-4">

                        {
                            (() => {
                                if (value.info) {
                                return  <div className="Description bg-light my-3 mx-2 p-2">
                                            {value.info}                     
                                        </div>
                                }
                                return 
                            })()
                        }

                        {
                            (() => {
                                if (value.comment1) {
                                return  <div className="card bg-light px-2 mb-2">
                                            <div className="quote2 px-1 pt-2 mb-3">{value.comment1}</div> 
                                            <div className="blockquote-footer"><i>{value.source1}</i></div>
                                        </div>
                                }
                                return 
                            })()
                        }

                        <div className="Description bg-light mx-2 p-2">
                            <b>Setlist:</b><br />
                            {value.setlist}                     
                        </div>  

                        <b>Date </b><br />
                        <div className="Date p-1">{value.gig_date}</div>
                        <b>Venue </b><br />
                        <div className="Date p-1">{value.venue}</div>
                        <b>Location </b><br />
                        <div className="Date p-1">{value.city} - {value.country}</div>

                        

                        <div className="row mt-4 ml-3">
                            <div className="col-sm-12">
                                    <b>Check out:</b>
                                    {
                                        (() => {
                                        if (value.link1) {
                                        return  <li><b><a href={value.url1}>
                                                    {value.link1}
                                                    </a></b>
                                                </li> 
                                            }   
                                            return 
                                        })()
                                    }
                                    {
                                        (() => {
                                        if (value.link2) {
                                        return  <li><b><a href={value.url2}>
                                                    {value.link2}
                                                    </a></b>
                                                </li> 
                                            }   
                                            return 
                                        })()
                                    }
                                    {
                                        (() => {
                                        if (value.link3) {
                                        return  <li><b><a href={value.url3}>
                                                    {value.link3}
                                                    </a></b>
                                                </li> 
                                            }   
                                            return 
                                        })()
                                    }
                                    
                                    <li><b><a href='/bob-dylan-any-day'>
                                        Bob Dylan - Any Day
                                        </a></b>
                                    </li> 
                                    <li><b><a href='lists/concerts/top-100-bob-dylan-concerts'>
                                         Top 100 Bob Dylan Concerts
                                        </a></b>
                                    </li> 

                            </div>
                        </div> 

                    </div>
                    
                    {/* Kolonne 3 */}
                    <div className="col-sm-4">

                        {
                            (() => {
                                if (value.youtube) {
                                return  <div>
                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                    light='true'
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                /><br />
                                            </div>  
                                        </div>
                                }
                                return <div><i>Nothing found on YouTube.</i></div>
                            })()
                        }

                        {
                            (() => {
                                if (value.youtube_2) {
                                return  <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube_2 }
                                                    light='true'
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                /><br />
                                            </div>  
                                        </div>
                                }
                                return
                            })()
                        }

                        {
                            (() => {
                                if (value.youtube_3) {
                                return  <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_3}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube_3 }
                                                    light='true'
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                /><br />
                                            </div>  
                                        </div>
                                }
                                return
                            })()
                        }

                        {
                            (() => {
                                if (value.youtube_4) {
                                return  <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_4}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube_4 }
                                                    light='true'
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                /><br />
                                            </div>  
                                        </div>
                                }
                                return
                            })()
                        }

                        {
                            (() => {
                                if (value.youtube_5) {
                                return  <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_5}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube_5 }
                                                    light='true'
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                /><br />
                                            </div>  
                                        </div>
                                }
                                return 
                            })()
                        }

                        {
                            (() => {
                                if (value.youtube_6) {
                                return  <div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_6}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube_6 }
                                                    light='true'
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                /><br />
                                            </div>  
                                        </div>
                                }
                                return 
                            })()
                        }

                        {value.comment &&
                            <div className="card bg-light py-2 px-2">
                                <div className="quote3 mb-3">{value.comment}</div> 
                                {value.source_txt_details &&
                                    <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                }
                                {!value.source_txt_details &&
                                    <div className="blockquote-footer"><i>{value.source_txt}</i></div>
                                }
                            </div>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {concertData.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />
                            
                    <div className="row">
                        <div className="col-sm-12">
                                {concertItems}
                        </div>
                    </div>

                </div>
            )}
        </main>
    )


}

export default Concert