import React, { Component } from 'react'
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
//import Select from 'react-select';
import { dbURL } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
//import Modal from "react-bootstrap/Modal";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
//import ReactGA from "react-ga";

//import Select from 'react-select';

class ConcertsTours extends Component {

    state = {
        columnDefs: [
            {headerName: "Date", field: "gig_date", resizable: true, width: 100, cellStyle: { 'font-size': '14px' }},
            {headerName: "Venue", field: "venue", sortable: true, width: 160, cellStyle: { 'font-size': '14px' }},
            {headerName: "City", field: "city", filter: true, sortable: true, width: 150, cellStyle: { 'font-size': '14px' }},
            {headerName: "Country", field: "country", filter: true, sortable: true, cellStyle: { 'font-size': '14px' }}
          ],
        TourData: [],
        ConcertsData: [],
        GridOn: false,
        SearchString: '',
        loading: true
      }

    getTourItem(){
        axios.get(dbURL + this.props.api2)
          .then(response => {
            this.setState({
                TourData: response.data
            });
          });
      } 

    getConcertItems(){
        axios.get(dbURL + this.props.api)
          .then(response => {
            this.setState({
                ConcertsData: response.data,
              loading: false
            });
          });
      } 
    
    componentDidMount() {
        this.getTourItem()
        this.getConcertItems()
      }

      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
        this.setState({SearchString: event.target.value});
        } 
    
      clearSearchField(){
        this.setState({
          SearchString: '', 
            })
        }

      onChangeGrid = () => {
        this.setState(initialState => ({
            GridOn: !initialState.GridOn,
        }));
     }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    render() {

        const { SearchString } = this.state;
        const TourItem = this.state.TourData;
        const ConcertItemsAll = this.state.ConcertsData;
        let filterArray, searchWords

        function SearchSetlists(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < ConcertItemsAll.length; i++) {
                //console.table(AllItems);
                if (ConcertItemsAll[i].search_setlist.includes(searchText))
                
                    {searchArray.push(ConcertItemsAll[i]);}
                    
            } 
            //console.log(searchArray);
            return searchArray
        }

        if (SearchString) {
            searchWords = SearchString.split(/\s/).filter(word => word)

            filterArray = SearchSetlists(SearchString.toLowerCase());
            } 
        if (!SearchString) {
            filterArray = ConcertItemsAll;
        }
        
        
    
        const SelectedTourHeader = TourItem.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row mb-3">
                        {/* SJEKKE PÅ MOBIL */}

                        <div className="col-sm-4">
                            <img src={value.picture}
                            alt="alt" className="img-fluid rounded mx-auto d-block" />
                        </div>

                        <div className="col-sm-8">
                            <h3>{value.title}</h3>
                            
                            <div className="Description">{value.description}</div>
                             <br />

                             <div className="row">
                                 <div className="col-sm-6">
                                    <b>Links</b>
                                    <div className="Description">
                                    
                                        <ul>
                                            <li><b><a href='https://www.boblinks.com'>
                                                    Bob Links
                                                </a></b>
                                            </li>
                                            <li><b><a href='http://www.bjorner.com/still.htm'>
                                                    Olof's Still on the Road
                                                </a></b>
                                            </li>
                                            <li><b><a href='https://en.wikipedia.org'>
                                                    Wikipedia
                                                </a></b>
                                            </li>--
                                            <li><b><a href='/lists/concerts/top-100-bob-dylan-concerts'>
                                                Top 100 Bob Dylan Concerts
                                                </a></b>
                                            </li>
                                            <li><b><a href='/dylanconcerts2019'>
                                                    Recommended Bob Dylan Concerts - 2019
                                                </a></b>
                                            </li> 
                                            <li><b><a href='/dylanconcerts2018'>
                                                    Recommended Bob Dylan Concerts - 2018
                                                </a></b>
                                            </li> 
                                            <li><b><a href='/dylanconcerts2017'>
                                                    Recommended Bob Dylan Concerts - 2017
                                                </a></b>
                                            </li> 
                                        </ul>
                                
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div>
                                        <div className="row">   
                                            <div className="col mb-2 pb-2 bg-light">
                                                <div><b>Start date </b><br />
                                                    <div className="Date pl-1" > {value.start_date_txt}</div>
                                                </div>
                                                <div><b>End date </b><br />
                                                    <div className="Date pl-1">{value.end_date_txt}</div>
                                                </div>
                                                <div><b>Number of shows</b><br />
                                                    <div className="Date pl-1">{value.no_shows}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">   
                                                <div className="col">
                                                <h5>Search</h5>
                                            </div>
                                        </div>
                                
                                        <div className="row pb-2">  
                                                <div className="col-8">
                                                <DebounceInput
                                                        //type="number"
                                                        minLength={3}
                                                        debounceTimeout={500}
                                                        value={SearchString}
                                                        onChange={this.handleSearchChange}
                                                        placeholder="Search all setlists" 
                                                    />
                                                </div>
                                                <div className="col-4 pl-0">
                                                    <button type="button" className="btn btn-primary"  
                                                    onClick={() => this.clearSearchField()} >Reset</button>
                                                </div>
                                        </div>
                                        {this.state.SearchString && 
                                                    <div className="Description">    
                                                    <b>Hits: {filterArray.length}</b>
                                                    </div>
                                                }
                                    </div>
                                        

                                
                                </div>
                            
                            </div>

                                


                        </div>

                    </div>

                </React.Fragment>
            )
            }
        )

        const ConcertItems = filterArray.map(value => {
            return (
                <React.Fragment key={value.id}>

                    <div className="row videoTitle">
                        <div className="col-sm-12"> 

                            {
                                (() => {
                                if (value.info) {
                                    return <div>&nbsp;<b>{value.venue} - {value.city}, {value.country} - {value.info} - {value.gig_date||value.date_txt}&nbsp;&nbsp;&nbsp;</b></div>
                                }
                                return <div>&nbsp;<b>{value.venue} - {value.city}, {value.country} - {value.gig_date||value.date_txt}&nbsp;&nbsp;&nbsp;</b></div>
                                })()
                            }

                        </div>
                    </div>

                    <div className="row pt-3 pb-2">

                        {/* Kolonne 1 */}
                        <div className="col-sm-3">
                            {
                                (() => {
                                if (value.pic_size && value.picture_link) {
                                    return <p align="center"><img src={value.picture_link} alt="" height="240" width="200"></img></p>
                                    }
                                if (value.picture_link && !value.pic_size) {
                                    return <div><img src={value.picture_link} alt="" height="200" width="200" 
                                            className="rounded mx-auto d-block"></img><br /></div>
                                }
                                return 
                                })()
                            }
                            <p align="center"><b>{value.tour}</b></p>
                            
                            <div className="Header4 p-2">
                                <b>Line-up:</b><br />
                                    <div className="factText">
                                        {value.musicians}
                                    
                                    </div>
                                
                            </div>

                        </div>

                        {/* Kolonne 2 */}
                        <div className="col-sm-5 py-1 border-right Description">
                            {
                                (() => {
                                if (SearchString) {
                                return  <div className="bg-light ml-2 mr-2 py-1 px-2">
                                            
                                
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={searchWords}
                                                        autoEscape={true}
                                                        textToHighlight={value.setlist}
                                                    />
                                            
                                        </div>
                                     }
                                     return <div className="bg-light ml-2 mr-2 py-1 px-2">
                                            <b>Setlist:</b><br />
                                            
                                            {value.setlist}
                                            
                                        </div>
                                    }
                                )()
                            }

                                

                                {
                                    (() => {
                                    if (value.description) {
                                    return  <div className="pt-3">
                                                <ReadMoreAndLess
                                                        charLimit={400}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.description}
                                                </ReadMoreAndLess>
                                                
                                            </div>
                                    
                                    }
                                    if (!value.description && value.comment) {
                                        return  <div className="card bg-light pt-3 px-2 mt-4">
                                                    <p className="quote3">{value.comment}</p> 
                                                    
                                                    {
                                                        (() => {
                                                            if (value.source_txt_details) {
                                                                return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                        })()

                                                    }

                                                </div>
                                    }


                                    return 
                                    })()
                                }
                            
                            
                        </div>

                        {/* Kolonne 3 */}
                        <div className="col-sm-4">

                                    {
                                        (() => {
                                            if (value.youtube && value.song_title_2 && value.song_title_3 && value.youtube_4) {
                                                return <div>
                                                            
                                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                            <div className="border my-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                                
                                                                    <ReactPlayer
                                                                        url={ value.youtube||value.vimeo }
                                                                        //className="react-player"
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        width="100%"
                                                                        height="100%"
                                                                        //width="300"
                                                                        //height="300"
                                                                    />
                                                            </div>
                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_2 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
    
                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_3}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_3 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>

                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_4}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_4 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
                                            
                                                        </div>
                                            }
                                        if (value.youtube && value.song_title_2 && value.song_title_3) {
                                            return <div>
                                                        
                                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                        <div className="border my-2">
                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                            
                                                                <ReactPlayer
                                                                    url={ value.youtube||value.vimeo }
                                                                    //className="react-player"
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="100%"
                                                                    height="100%"
                                                                    //width="300"
                                                                    //height="300"
                                                                />
                                                        </div>
                                                        <div className="border mb-2">
                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                                            
                                                            <ReactPlayer
                                                                url={ value.youtube_2 }
                                                                light='true'
                                                                //light
                                                                controls
                                                                width="300"
                                                                height="300"
                                                            />
                                                        </div>

                                                        <div className="border mb-2">
                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_3}</b></div>
                                                            
                                                            <ReactPlayer
                                                                url={ value.youtube_3 }
                                                                light='true'
                                                                //light
                                                                controls
                                                                width="300"
                                                                height="300"
                                                            />
                                                        </div>
                                        
                                                    </div>
                                        }
                                        if (value.youtube && value.song_title_2) {
                                            return <div>
                                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                        <div className="border my-2">
                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                            
                                                                <ReactPlayer
                                                                    url={ value.youtube||value.vimeo }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
                                                            <div className="border mb-2">
                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                                        
                                                            <ReactPlayer
                                                                url={ value.youtube_2 }
                                                                light='true'
                                                                //light
                                                                controls
                                                                width="300"
                                                                height="300"
                                                            />
                                                            </div>
                                                        
                                                    </div>
                                        }
                                        if (value.youtube) {
                                            return <div>
                                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                        <div className="border my-2">
                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                            
                                                                <ReactPlayer
                                                                    url={ value.youtube||value.vimeo }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                        </div>
                                                            
                                        
                                                    </div>
                                        }

                                        return <div><i>Nothing found on YouTube, yet....</i></div>
                                        })()
                                    }

                                    <div className="row pt-3">
                                        <div className="col-12">
                                            {
                                                (() => {
                                                if (value.comment && value.comment_2 && value.description) {
                                                    return <div>
                                                            <div className="card bg-light py-2 px-2">
                                                                <p className="quote3">{value.comment}</p> 
                                                                
                                                                {
                                                                    (() => {
                                                                        if (value.source_txt_details) {
                                                                            return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                        }
                                                                        return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                                    })()

                                                                }

                                                            </div><br />
                                                    
                                                            <div className="card bg-light py-2 px-2">
                                                                <p className="quote3">{value.comment_2}</p> 
                                                                
                                                                {
                                                                    (() => {
                                                                        if (value.source_txt_details_2) {
                                                                            return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                        }
                                                                        return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                                    })()

                                                                }

                                                            </div>
                                                        </div>
                                                                        
                                                }


                                                if (value.comment && value.description) {
                                                    return <div className="card bg-light py-2 px-2">
                                                                <p className="quote3">{value.comment}</p> 
                                                                
                                                                {
                                                                    (() => {
                                                                        if (value.source_txt_details) {
                                                                            return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                        }
                                                                        return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                                    })()

                                                                }

                                                            </div>
                                                                        
                                                }
                                                return 
                                                })()
                                            }
                                        </div>
                                    </div> 


                        </div>

                    </div>

                </React.Fragment>
            )
        })
        

        return (

            <main className="my-1 py-1">
                <div className="container">
                            
                    {SelectedTourHeader}

                    <div className="row">
                        <div className="col pb-2 mr-4" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {
                        (() => {
                        if (this.state.loading) {
                            return <div><br /><h5><i>Loading concerts...</i></h5></div>
                        }
                        return <div> 
                            {!this.state.GridOn && 
                                <div className="row">
                                    <div className="col">
                                            {ConcertItems}
                                    </div>
                                </div>
                            }
                            </div>
                        })()
                    }
        
        

                    {this.state.GridOn && 
                        <div className="row pr-1 pl-2 pt-3">
                            <div
                                id="myGrid"
                                style={{ height: '1150px', width: '1200px' }}
                                className="ag-theme-balham" 
                                >
                            
                                <AgGridReact
                                
                                onGridReady={ params => this.gridApi = params.api }
                                    rowSelection="multiple"
                                    pagination={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={ConcertItems}
                                    >
                                    
                                </AgGridReact>
                                
                            </div>
                        </div>
                    }
                    <div className="mr-3">
                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>
                    </div>
              
                </div>
            </main>

        )




    }

}

export default ConcertsTours