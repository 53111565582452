import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDom from "react-dom/client";
//import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

ReactGA.initialize('G-VMCYZEGBX5');

const container = document.getElementById("root");
const root = ReactDom.createRoot(container);
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
); 

/* ReactDOM.render(
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
        , document.getElementById('root')
        ); */

//serviceWorker.unregister();

/* const container = document.getElementById("root");
const root = ReactDom.createRoot(container);
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

