import React, { useState } from "react";
import './lists.scss';
import dayjs from "dayjs";
import SEO from "../SEO";
import AnyDayNews from "./today/AnyDayNews";
import AnyDayAlbums from "./today/AnyDayAlbums";
import AnyDayPeople from "./today/AnyDayPeople";
import AnyDaySongs from "./today/AnyDaySongs";
import AnyDayConcerts from "./today/AnyDayConcerts";
import { animateScroll as scroll } from "react-scroll";

const MusicAnyDay = () => {

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const today = dayjs().format('YYYY-MM-DD');
    const todayFormated = dayjs(today).format('MM-DD');
    const todayMonth = dayjs(today).format('MM');
    const todayDay = dayjs(today).format('DD');

    const Days31 = ['01', '03', '05', '07', '08', '10', '12'];

    const [monthSelected, setMonthSelected] = useState(todayMonth);
    const [dateSelected, setdateSelected] = useState(todayFormated);

    const setMonthSelectedEvent = (event) => {
        setMonthSelected(event.target.value)
        document.getElementById("daySelector").value = "DEFAULT";
    }

    const setDaySelectedEvent = (event) => {
        setdateSelected(monthSelected + '-' + event.target.value)
    }

    const MonthString = dateSelected.slice(0, 2);
    const DayString = dateSelected.slice(-2);
    let Month
    if (MonthString === '01') {
        Month = 'January'
    }
    if (MonthString === '02') {
        Month = 'February'
    }
    if (MonthString === '03') {
        Month = 'March'
    }
    if (MonthString === '04') {
        Month = 'April'
    }
    if (MonthString === '05') {
        Month = 'May'
    }
    if (MonthString === '06') {
        Month = 'June'
    }
    if (MonthString === '07') {
        Month = 'July'
    }
    if (MonthString === '08') {
        Month = 'August'
    }
    if (MonthString === '09') {
        Month = 'September'
    }
    if (MonthString === '10') {
        Month = 'October'
    }
    if (MonthString === '11') {
        Month = 'November'
    }
    if (MonthString === '12') {
        Month = 'December'
    }

    const formatedDay = Month + ' ' + DayString

    const Heading = <div>
                        {/* HEADER row */}
                        <div className="row mb-2 pb-3 border-bottom">
                                
                                {/* kolonne 1 */}
                                <div className="col-sm-8 ">  
                                    <div className="AnyDayTitle mb-2 bg-info text-center rounded">&nbsp;<b>{formatedDay} in Music History</b></div> 
                                    <div className="my-2">
                                        Please check out our <b>Facebook page</b> for daily updates:
                                        -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                                    </div>          
                                </div>
                                
                                {/* kolonne 2 */}
                                <div className="col select">
                                    <b>Select month:</b><br />
                                            <select defaultValue={todayMonth} onChange={setMonthSelectedEvent}>
                                                <option value="{todayMonth}" disabled>Choose a Month ...</option>
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>  
                                </div>

                                {/* kolonne 3 */}
                                <div className="col select">
                                    <b>Select day:</b><br />
                                            <select id="daySelector" defaultValue={todayDay} onChange={setDaySelectedEvent}>
                                                <option value="DEFAULT" disabled>Choose a day...</option>
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                {(monthSelected !== '02') && <option value="30">30</option>}
                                                {Days31.some(el => monthSelected.includes(el)) && <option value="31">31</option>}
                                            </select>
                                    
                                </div> 
                                
                        </div>
                        
                        
                    </div>

  return (
     <main className="my-1 py-1">
        <div className="container">
        <SEO
          title='This Day in Music History'
          description='People, albums, songs and concerts relating any day in music history'
          name='Music This Day.'
          type='article' />

            <AnyDayNews />

            {Heading}

            <div className="row">

                {/* PEOPLE */}
              <div className="col-sm-4">
                    <AnyDayPeople
                        dateSelected = {dateSelected}
                        />
              </div>

                {/* ALBUMS */}
              <div className="col-sm-4">
                <AnyDayAlbums 
                    dateSelected = {dateSelected}
                    />
              </div>

                {/* SONGS */}
              <div className="col-sm-4">
                <AnyDaySongs
                    dateSelected = {dateSelected}
                    />
              </div>
            
            </div>

                {/* CONCERTS */}
            <div className="row mt-4">
                <div className="col-sm-12"> 
                    <div className="TodayTitle20 mb-2 rounded">&nbsp;<b>Concerts - {formatedDay}</b></div>
                    <AnyDayConcerts
                        dateSelected = {dateSelected}
                        />
                </div>
            </div>

            <div align="Right"><br />
                <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                    <b>Scroll to top</b>
                </button>
            </div>
        </div>
    </main> 
    
  )
}

export default MusicAnyDay
