import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL } from './Constants';
import './lists.scss';
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../SEO";
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import { AgGridReact } from 'ag-grid-react';
import { SearchLists } from './misc/utils';

const DylanListsPosts = () => {

    const [listData, setlistData] = useState([])
    const [postData, setpostData] = useState([])
    const [loadingLists, setLoadingLists] = useState(true)
    const [loadingPosts, setLoadingPosts] = useState(true)
    const [errorLists, setErrorLists] = useState(null)
    const [errorPosts, setErrorPosts] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [optionRange, setOptionRange] = useState('All')
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
        {
            field: 'page',
            headerName: 'List (click on name to open)',
            cellRenderer: function(params) {
                let pageData = params.data.page;
                let nameData = params.data.name;
                let newLink = `<a href= "https://www.musicthisday.com/${pageData}">${nameData}</a>`;
                return newLink;
            },
            sortable: true, width: 500, cellStyle: { 'font-size': '14px' }
            //flex: 1,
        },
        {headerName: "Category", field: "category", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }},
        {headerName: "Source", field: "source", sortable: true, width: 250, cellStyle: { 'font-size': '14px' }},
        {headerName: "Year", field: "year", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }}
      ])
   

    let listAndPostArray, dylanlistAndPostArray, sortedlistAndPostArray, filterArray

    useEffect(() => {
        const fetchListsData = () => {
          axios.get(dbURL + 'lists')
          .then(response => {
            setLoadingLists(false)
            setlistData(response.data)
          }).catch(errorLists => {
            setErrorLists(errorLists);
          });
        }

        const fetchPostsData = () => {
            axios.get(dbURL + 'posts')
            .then(response => {
                setLoadingPosts(false)
                setpostData(response.data)
            }).catch(errorPosts => {
                setErrorPosts(errorPosts);
            });
        }

        fetchListsData()
        fetchPostsData()
      }, [])
  
    if (errorLists) return `Error List: ${errorLists.message}`;
    if (errorPosts) return `Error Post: ${errorPosts.message}`;

    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (loadingLists || loadingPosts) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if ((listData.length > 0) && (postData.length > 0)) {

        listAndPostArray = [...listData, ...postData];

        sortedlistAndPostArray = listAndPostArray.sort((a, b) => {

            if (a.sort === b.sort) {
                return b.id - a.id;
             }
             return a.sort - b.sort;
        });
 
    }

    dylanlistAndPostArray = sortedlistAndPostArray.filter(e => e.artist === 'Bob Dylan');

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        setOptionRange('All')
        }
    
    /* function SearchListsPostsName(searchText) { 
        let searchArray = [];
        let searchPostsLists;
        
        for (let i = 0; i < dylanlistAndPostArray.length; i++) {

            //ta høyde for at transcript_full kan være NULL

            searchPostsLists = dylanlistAndPostArray[i].name.toLowerCase();

            if (searchPostsLists.includes(searchText))
            
                {searchArray.push(dylanlistAndPostArray[i]);}
        } 
        return searchArray
    } */

    if (searchString) {
        filterArray = SearchLists(dylanlistAndPostArray, 'name', searchString.toLowerCase());
        } 

    if (optionRange === 'Albums' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Albums');  
        } 
    if (optionRange === 'Songs' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Songs');  
        } 
    if (optionRange === 'Concerts' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Concerts');  
        } 
    if (optionRange === 'Interviews' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Interviews');  
        } 
    if (optionRange === 'Video' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Video');  
        } 
    if (optionRange === 'Bootleg' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Bootleg');  
        } 
    if (optionRange === 'Sessions' && !searchString) {
        filterArray = dylanlistAndPostArray.filter(e => e.category === 'Sessions');  
        } 
    if (optionRange === 'All' && !searchString) {
        filterArray = dylanlistAndPostArray;  
        } 

    


    const BobDylanItems = filterArray.map(value => {

        let page = '/' + value.page;

        return (
            <React.Fragment key={value.page}>

            <div className="row pt-3 pb-3 border-bottom border-top border-info">
                
                <div className="col-sm-3 pb-3 text-center">
                    
                    <div align="center">
                        {/* <img src={value.picture_link} alt="" height="175" width="175" /> */}
                        <img src={value.picture_link} alt="" className="img-fluid"></img>
                    </div>

                </div>

                <div className="col-sm-6 pb-3 border-right lyrics">
                    
                    <p className='Header'><b><Link to={page}>{value.name}</Link></b></p>

                    {/* <p className='Header'><b>{value.name}</b></p>
                    <Link to="/50dylan">Bob Dylan's 50 greatest songs – ranked! (2020)</Link> */}

                        <ReadMoreAndLess
                                charLimit={500}
                                readMoreText={" Read more ▼"}
                                readLessText={" Read less ▲"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                            >
                            {value.description}
                        </ReadMoreAndLess>

                </div>
              
                
                    <div className="col-sm-3 pb-4">

                        <div className="row">
                        
        
                            <div className="col pr-0 mr-2">
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Category: </b></div>
                                    <div className="col-6 ml-2">{value.category}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Source: </b></div>
                                    <div className="col-6 ml-2">{value.source}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Year: </b></div>
                                    <div className="col-6 ml-2">{value.year}</div>
                                </div>
                                {
                                    (() => {
                                    if (value.publish_date) {
                                    return <div>
                                         <div className="row">
                                            <div className="col-4 mr-2"><b>Published: </b></div>
                                            <div className="col-6 ml-2">{value.publish_date}</div>
                                        </div>
                                     </div>
                                    }
                                    return
                                    })()
                                }

                                   
                                    <br /><br />
                                    {
                                        (() => {
                                        if (value.in_progress) {
                                        return  <b><span className="p-1 mb-2 bg-info text-white">In progress</span></b>
                                        }
                                        return <p></p>
                                        })()
                                    }

                            </div>

                        </div>   
                    
                    </div>
                
                    

            </div>

            </React.Fragment>          
        )

    })
   

    return (
        <main className="my-1 py-1">
            {listData.length > 0 && (
                <div className="container">

                 <SEO
                    title={'Bob Dylan posts & lists'}
                    description={'All kinds of Bob Dylan posts & lists; Albums, songs, concert, sessions, etc'}
                    name={'Bob Dylan posts & lists'}
                    url={'https://www.musicthisday.com/bobdylan'}
                    picture_link={'https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/3481186e-cdd2-4e80-13b8-9d4913aa7000/public'}
                    canonical={'https://www.musicthisday.com/bobdylan'}
                    type='article' />
                
                <h1>Bob Dylan</h1>
                
                <div className="row">
                        <div className="col-sm-3">
                            <img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/3481186e-cdd2-4e80-13b8-9d4913aa7000/public' 
                            alt="Rs100dylan" className="img-fluid rounded mx-auto d-block" />
                            <br />
                            <b>Count: {filterArray.length}</b>
                        </div>

                        <div className="col-sm-5">
                                <p>
                                In this app I will publish some of my favorite Bob Dylan lists, with filtering options & different view styles.<br />
                                <br />
                                Any suggestions? Drop me an email: egil@alldylan.com
                                    </p>
                                    
                                    <b>--> <a href='bob-dylan-any-day'> Bob Dylan - Any Day </a></b>
                                    <br /><br /> <br /> 
                                
                                <p><strong>click on buttons to filter on category.</strong></p>
                                <p><strong>click on title to visit list / post.</strong></p>
                                
                        </div>
                        
                        <div className="col-sm-4 mb-4 border-start border-info">
                
                                <div className="row">   
                                        <div className="col">
                                        <h4>Search Bob Dylan lists / posts</h4>
                                    </div>
                                </div>
                            
                            <div className="row mb-1">  
                                <div className="col-sm-6 mt-1">
                                    <div className="searchMedium">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={500}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all posts & lists" 
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-1">
                                    <button type="button" className="btn btn-primary"   
                                        onClick={() => clearSearchField()} > Reset </button>
                                </div>

                            </div>

                            <div className="form-check mt-5">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    checked={AgGrid}
                                    onChange={checkHandler}
                                    className="form-check-input"
                                />
                                <label htmlFor="checkbox">Change to Grid </label>
                            </div>

                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-sm-4" align="Left">
                            {optionRange === '' && 
                            <button type="button" className="btn btn-info btn-xs active focus mt-1" onClick={() => setOptionRange('All')}>All categories</button>
                            }
                            {optionRange !== '' && 
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('All')}>All categories</button>
                            }
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Albums')}>Albums</button>
                            {/* handleOptionRange('Albums') */}
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Songs')}>Songs</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Concerts')}>Concerts</button>
                            &nbsp;&nbsp;
                        </div>
                        <div className="col-sm-6" align="Left">
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Video')}>Videos</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Bootleg')}>Bootlegs</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Sessions')}>Rec. Sessions</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Interviews')}>Interviews</button>
                            &nbsp;&nbsp;
                            
                        </div>


                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm mt-1" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {!AgGrid &&    
                    <div className="row">
                        <div className="col-sm-12">
                            {BobDylanItems} 
                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={filterArray}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>

                </div>
                
            )}
        </main>
    )


}

export default DylanListsPosts