import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayHistoryDylan } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import { Link } from "react-scroll";

const AnyDayHistoryDylan = (props) => {
  const [history, setHistory] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchHistory = () => {
        axios.get(dbURLAnyDayHistoryDylan + props.dateSelected)
        .then(response => {
          setIsLoading(false)
          setHistory(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchHistory()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {history.length > 0 && (
        <div>
            
            <div className="row">    
                <div className="col my-3 mx-3 py-2 px-3 bg-light border rounded-xl">
                    <div className="row">
                        <div className="col text-center">
                        <h3>Summary</h3>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col">
                            {history.map(value => (
                                <div key={value.id}>
                                    
                                    <div className="row pt-1 pb-1 mb-1">

                                            {/* kolonne 1 */}
                                            <div className="col-sm-1 text-center">
                                                    {
                                                        (() => {
                                                        if (value.cat === "Album") {
                                                            return <button type="button" className="btn btn-primary btn-xxs mb-2">
                                                                <Link  to="albums" spy={true} smooth={true}>
                                                                <b><u>Albums</u></b>
                                                                </Link>
                                                            </button>
                                                            }
                                                        return 
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                        if (value.cat === "Song") {
                                                            return <button type="button" className="btn btn-primary btn-xxs mb-2">
                                                                <Link  to="songs" spy={true} smooth={true}>
                                                                <b><u>Songs</u></b>
                                                                </Link>
                                                            </button>
                                                            }
                                                        return 
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                        if (value.cat === "RecSession") {
                                                            return <button type="button" className="btn btn-primary btn-xxs mb-2">
                                                                <Link  to="sessions" spy={true} smooth={true}>
                                                                <b><u>Studio</u></b>
                                                                </Link>
                                                            </button>
                                                            }
                                                        return 
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                        if (value.cat === "Concert") {
                                                            return <button type="button" className="btn btn-primary btn-xxs mb-2">
                                                                <Link  to="concerts" spy={true} smooth={true}>
                                                                <u><b>Concerts</b></u>
                                                                </Link>
                                                            </button>
                                                            }
                                                        return 
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                        if (value.cat === "People") {
                                                            return <button type="button" className="btn btn-primary btn-xxs mb-2">
                                                                <Link  to="people" spy={true} smooth={true}>
                                                                <u><b>People</b></u>
                                                                </Link>
                                                            </button>
                                                            }
                                                        return 
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                        if (value.cat === "History") {
                                                            return <button type="button" className="btn btn-primary btn-xxs mb-2">
                                                                <Link  to="history" spy={true} smooth={true}>
                                                                <u><b>History</b></u>
                                                                </Link>
                                                            </button>
                                                            }
                                                        return 
                                                        })()
                                                    }

                                            </div>

                                            {/* kolonne 2 */}
                                            <div className="col-sm-1 text-center">
                                                    <div className="Song2"><b>{value.date_year}</b></div>
                                            </div>

                                            {/* kolonne 2 */}
                                            <div className="col-sm-10 Description">
                                                {
                                                    (() => {
                                                    if (value.cat === "Concert") {
                                                        return <div>
                                                                    <ReadMoreAndLess
                                                                            charLimit={600}
                                                                            readMoreText={" Read more ▼"}
                                                                            readLessText={" Read less ▲"}
                                                                            readMoreClassName="read-more-less--more"
                                                                            readLessClassName="read-more-less--less"
                                                                        >
                                                                        {value.description}
                                                                    </ReadMoreAndLess>
                                                                    
                                                                </div>

                                                        }
                                                    return <div>
                                                                <ReadMoreAndLess
                                                                        charLimit={600}
                                                                        readMoreText={" Read more ▼"}
                                                                        readLessText={" Read less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.description}
                                                                </ReadMoreAndLess>
                                                            </div>

                                                    })()
                                                }
                                                
                                                {
                                                    (() => {
                                                    if (value.url) {
                                                        return <div>
                                                            <em> -> <a href={value.url} target="_blank" rel="noopener noreferrer">{value.link_text}</a></em>
                                                        </div>
                                                        }
                                                    return 
                                                    })()
                                                }


                                                

                                                        

                                            </div>

                                        </div>
                                
                                </div>
                            ))}
                            
                        </div>
                    </div>  
                </div>
            </div>
          
        </div>
      )}
    </div>
  )
}

export default AnyDayHistoryDylan
