import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayPeopleDylan } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";

const AnyDayPeopleDylan = (props) => {
  const [people, setPeople] = useState([])
  //const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchPeople = () => {
        axios.get(dbURLAnyDayPeopleDylan + props.dateSelected)
        .then(response => {
          //setIsLoading(false)
          setPeople(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchPeople()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {people.length > 0 && (
        <div id="people">
            <div className="row TodayTitle mt-4">
                <div className="col"> 
                    <div>&nbsp;<b>People</b>
                    </div>
                </div>
            </div>
          {people.map(value => (
            <div key={value.id}>
                <div className="row pt-1 pb-1">
                        
                        {/* kolonne 1 */}
                        <div className="col-sm-2 text-center">
                            <p className='Song2'><b>{value.person}</b></p>
                            <div align="center">
                                {
                                    (() => {
                                    if (value.pic_size) {
                                        return <img src={value.picture_link} alt="" height="185" width="150"></img>
                                        }
                                    return <img src={value.picture_link} alt="" height="130" width="130"></img>
                                    })()
                                }
                                
                            </div>

                        </div>

                        {/* kolonne 2 */}
                        <div className="col-sm-7 pb-3 border-right Description">

                            <div className="row">
                                <div className="col py-2 border-right Description">

                                    {
                                        (() => {
                                        if (value.mgray && value.dylan_desc && value.pd_comment) {
                                            return  <div>
                                                    {value.dylan_desc}
                                                    <div className="card bg-light py-2 px-2 my-3">
                                                        <p className="quote3">{value.mgray}
                                                        
                                                        </p> 
                                                        <div className="blockquote-footer"><i>Michael Gray (The Bob Dylan Encyclopedia)</i></div>
                                                    </div>
                                                    <div>
                                                        <div className="card bg-light py-2 px-2">
                                                            <p className="quote3">{value.pd_comment}</p> 
                                                            {
                                                                (() => {
                                                                        if (value.pd_source_details) {
                                                                            return <div className="blockquote-footer"><i>{value.pd_source} ({value.pd_source_details})</i></div>
                                                                        }
                                                                        return <div className="blockquote-footer"><i>{value.pd_source}</i></div>

                                                                })()

                                                            }
                                                        
                                                        </div>
                                                    </div>
                                            </div>
                                            }
                                            if (value.mgray && !value.dylan_desc && value.pd_comment) {
                                                return  <div>
                                                        
                                                        <div className="card bg-light py-2 px-2 my-1">
                                                            <p className="quote3">{value.mgray}
                                                            
                                                            </p> 
                                                            <div className="blockquote-footer"><i>Michael Gray (The Bob Dylan Encyclopedia)</i></div>
                                                        </div>
                                                        <div>
                                                            <div className="card bg-light py-2 px-2">
                                                                <p className="quote3">{value.pd_comment}</p> 
                                                                {
                                                                    (() => {
                                                                            if (value.pd_source_details) {
                                                                                return <div className="blockquote-footer"><i>{value.pd_source} ({value.pd_source_details})</i></div>
                                                                            }
                                                                            return <div className="blockquote-footer"><i>{value.pd_source}</i></div>
    
                                                                    })()
    
                                                                }
                                                            
                                                            </div>
                                                        </div>
                                                </div>
                                                }
                                        if (value.mgray && value.dylan_desc && !value.pd_comment) {
                                        return  <div>
                                                {value.dylan_desc}
                                                <div className="card bg-light py-2 px-2 mt-3">
                                                    <p className="quote3">{value.mgray}
                                                    
                                                    </p> 
                                                    <div className="blockquote-footer"><i>Michael Gray (The Bob Dylan Encyclopedia)</i></div>
                                                
                                                </div>
                                        </div>
                                        }
                                        if (!value.mgray && value.dylan_desc && value.pd_comment) {
                                            return  <div>
                                                    {value.dylan_desc}
                                                    <div>
                                                        <div className="card bg-light py-2 px-2 mt-3">
                                                            <p className="quote3">{value.pd_comment}</p> 
                                                            {
                                                                (() => {
                                                                        if (value.pd_source_details) {
                                                                            return <div className="blockquote-footer"><i>{value.pd_source} ({value.pd_source_details})</i></div>
                                                                        }
                                                                        return <div className="blockquote-footer"><i>{value.pd_source}</i></div>

                                                                })()

                                                            }
                                                        
                                                        </div>
                                                    </div>
                                            </div>
                                            }
                                        if (value.mgray && !value.dylan_desc) {
                                            return  <div>
                                                    <div className="card bg-light py-2 px-2">
                                                        <p className="quote3">{value.mgray}
                                                        
                                                        </p> 
                                                        <div className="blockquote-footer"><i>Michael Gray (The Bob Dylan Encyclopedia)</i></div>
                                                    
                                                    </div>
                                            </div>
                                        }
                                        if (value.pd_comment) {
                                            return  <div>
                                                    <div className="card bg-light py-2 px-2">
                                                        <p className="quote3">{value.pd_comment}</p> 
                                                        {
                                                            (() => {
                                                                    if (value.pd_source_details) {
                                                                        return <div className="blockquote-footer"><i>{value.pd_source} ({value.pd_source_details})</i></div>
                                                                    }
                                                                    return <div className="blockquote-footer"><i>{value.pd_source}</i></div>

                                                            })()

                                                        }
                                                    
                                                    </div>
                                            </div>
                                        }
                                        if (value.dylan_desc && !value.pd_comment && !value.mgray) {


                                        return <div>
                                                <ReadMoreAndLess
                                                        charLimit={1000}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.dylan_desc}
                                                </ReadMoreAndLess>
                                                
                                            </div>
                                        }

                                        return <p></p>

                                        })()
                                    }

                                </div>

                            </div>

                        </div>
                        
                        {/* kolonne 3 */}
                        <div className="col-sm-3">

                                <div className="row">
                                    <div className="col-12">
                                        <b>Born </b><br />

                                        {
                                            (() => {
                                                if (value.born) {
                                                    return   <div>
                                                        <div className="Date">{value.born} - {value.city}
                                                        , {value.region}, {value.country}</div> 
                                                    </div>
                                                }
                                                return <div className="Date">{value.born_place}</div>
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.dead||value.dead_place) {
                                                if (value.dead && value.dead_place) {
                                                    return <div>
                                                        <b>Dead </b><br />
                                                        <div className="Date">{value.dead} - {value.dead_place}
                                                        </div> 
                                                    </div>
                                                }
                                                if (value.dead) {
                                                    return <div>
                                                            <b>Dead </b><br />
                                                            <div className="Date">{value.dead}
                                                            </div> 
                                                        </div>
                                                }
                                                return <div>
                                                        <b>Dead </b><br />
                                                        <div className="Date">{value.dead_place}
                                                        </div> 
                                                    </div>
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                            if (value.main_genre && value.sub_genres) {
                                            return  <div>
                                                <b>Genres </b><br />
                                                <div className="Date">{value.main_genre}, {value.sub_genres}
                                                </div> 
                                            </div>
                                            }
                                            if (value.main_genre && !value.sub_genres) {
                                                return  <div>
                                                    <b>Genres </b><br />
                                                    <div className="Date">{value.main_genre}
                                                    </div> 
                                                </div>
                                                }
                                        
                                            return 

                                            })()
                                        }
                                        {
                                            (() => {
                                                if (value.instrument) {
                                                    return   <div>
                                                    <b>Instruments </b><br />
                                                    <div className="Date">{value.instrument}
                                                    </div> 
                                                </div>
                                                }
                                                return 
                                            })()
                                        }
                                        <br />
                                        <em> -> <a href={value.wikipedia_link}>more...</a></em>

                                        </div>

                                    

                                </div>   
                               
                            
                            </div>
                                
                    </div>
                
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AnyDayPeopleDylan
