import React, { Component } from 'react'

class YoutubeEmbedAll extends Component {

  render () {

    const Youtube = 'https://www.youtube.com/embed/' + this.props.embedId.substring(this.props.embedId.length - 11)

    return (
      <div>
        <div className="video-responsive">
          <iframe
            loading="lazy"
            width="853"
            height="480"
            src={Youtube}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>

      </div>


    )

  }

}

export default YoutubeEmbedAll
