import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import { dbURL, customStyles4 } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import Select from 'react-select';
import ReactMarkdown from 'react-markdown';
import SEO from '../../SEO';
import { Link } from 'react-router-dom';


class GreatestConcertsByDecade extends Component {

    state = {
        selectedOptionYear: null,
        selectedOptionArtist: null,
        ConcertsHeadingData: [],
        ConcertData: [],
        loading: true,
        loading2: true
      }

    getConcertsHeadingItem(){
        axios.get(dbURL + this.props.HeaderAPI)
          .then(response => {
            this.setState({
                ConcertsHeadingData: response.data,
                loading: false
            });
          });
      } 

    getConcertItems(){
        axios.get(dbURL + this.props.ConcertsAPI)
          .then(response => {
            this.setState({
                ConcertData: response.data,
                loading2: false
            });
          });
      } 
    
    componentDidMount() {
        this.getConcertsHeadingItem()
        this.getConcertItems()
      }

    selectedOptionYear = (selectedYear) => {
        this.setState({ selectedOptionYear: selectedYear });
    } 

    clearFilterYear(){
        this.setState({
            selectedOptionYear: null, 
            })
        }
    
    selectedOptionArtist = (selectedArtist) => {
        this.setState({ selectedOptionArtist: selectedArtist });
    } 

    clearFilterArtist(){
        this.setState({
            selectedOptionArtist: null, 
            })
        }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    render() {

        if (this.state.loading || this.state.loading2) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }

        const { selectedOptionYear } = this.state;
        const { selectedOptionArtist } = this.state;
        const ConcertsHeading = this.state.ConcertsHeadingData;
        const ConcertItemsAll = this.state.ConcertData;
        let filterArray

        if (ConcertItemsAll.length < 1) {
            return <div>
                        <main className="my-1 py-1">
                            <div className="container">  
                                <div className="row pt-1">
                                    <div className="col">
                                        <h1>404 - Not Found!</h1>
                                        <Link to="/lists">
                                            Go to current Lists
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                </div> 
        }

        const {name, description, picture_link, page} = ConcertsHeading[0];

        
        if (selectedOptionYear && !selectedOptionArtist) {
            filterArray = ConcertItemsAll.filter(e => e.year === selectedOptionYear.value);
            } 
        if (!selectedOptionYear && selectedOptionArtist) {
            filterArray = ConcertItemsAll.filter(e => e.artist === selectedOptionArtist.value);
            } 
        if (!selectedOptionYear && !selectedOptionArtist) {
            filterArray = ConcertItemsAll;
            } 
        
        //get only year
        const OnlyYear = filterArray.map(item => item.year);
        const OnlyYearSorted = OnlyYear.sort();
        const uniqueYear = OnlyYearSorted.reduce((b,c) => (
            (b[b.findIndex(d => d.year===c)] ||
            // eslint-disable-next-line 
            b[b.push({year: c, count: 0})-1]).count++,b), 
            []
        );
        const uniqueYearSorted = uniqueYear.sort(function(a, b){
            return b.count-a.count
        })
        const YearOptions = uniqueYearSorted.map(value => { 
            //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
            return {value: value.year, label: value.year + ' (' + value.count + ')'}; 
            //}
        });

        //get only artists
        const OnlyArtist = filterArray.map(item => item.artist);
        //unique artist + count
        const uniqueArtist = OnlyArtist.reduce((b,c) => (
            (b[b.findIndex(d => d.artist===c)] ||
            // eslint-disable-next-line 
            b[b.push({artist: c, count: 0})-1]).count++,b), 
            []
        );
        const uniqueArtistSorted = uniqueArtist.sort(function(a, b){
           return b.count-a.count
       })
        //map Artist with count to ArtistOptions
        const ArtistOptions = uniqueArtistSorted.map(value => { 
            return {value: value.artist, label: value.artist + ' (' + value.count + ')'}; 
        });


        const SelectedConcertsHeader = ConcertsHeading.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row mb-3">
                        {/* SJEKKE PÅ MOBIL */}
                        
                        <div className="col-sm-4">
                            <img src={value.picture_link}
                            alt="alt" className="img-fluid" />

                             {/* <div className="btn btn-secondary btn-xs mt-3 mb-2 ml-5">
                                Number of concerts: {ConcertItemsAll.length}</div>  */}
                        </div>

                        <div className="col-sm-8">
                            <div className="row mb-2">
                                <div className="col">
                                    <h3>{value.name}</h3>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-sm-7">
                                    <div className='bg-light Description'>
                                        <ReactMarkdown>
                                            {value.description}                       
                                        </ReactMarkdown>
                                    </div>
                                    
                                    
                                    {
                                        (() => {
                                        if (value.comment1) {
                                            return <div className="card bg-light py-2 px-2 my-2">
                                                        <p className="quote3">{value.comment1}
                                                        
                                                        </p> 
                                                        <div className="blockquote-footer"><i>{value.source1}</i></div>
                                                    
                                                    </div>
                                        }
                                        return <p></p>;
                                        })()
                                    }
                                    <b>Also check out:</b>
                                    <div className="Description">

                                        <ul>
                                            {
                                                (() => {
                                                if (value.link1) {
                                                    return <div><li><b><a href={value.url1}>
                                                                    {value.link1}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link2) {
                                                    return <div><li><b><a href={value.url2}>
                                                                    {value.link2}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link3) {
                                                    return <div><li><b><a href={value.url3}>
                                                                    {value.link3}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link4) {
                                                    return <div><li><b><a href={value.url4}>
                                                                    {value.link4}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link5) {
                                                    return <div><li><b><a href={value.url5}>
                                                                    {value.link5}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }

                                            
                                           
                                        </ul>
                                
                                    </div>
                                

                                </div>

                                <div className="col-sm-5">

                                    <div>
                                        
                                        <div className="row">   
                                                <div className="col">
                                                <h5>Filter</h5>
                                            </div>
                                        </div>
                                
                                        <div className="row pb-2">  
                                            <div className="col-7">
                                                    <Select
                                                        value={selectedOptionYear}
                                                        onChange={this.selectedOptionYear}
                                                        options={YearOptions}
                                                        placeholder='Select Year'
                                                        styles={customStyles4}
                                                    />
                                            </div>
                                            <div className="col-5"> 
                                                    <button type="button" className="btn btn-primary btn-sm"  onClick={() => this.clearFilterYear()} >Reset</button>
                                            </div>
                                        </div>
                                        <div className="row pb-2">  
                                            <div className="col-7">
                                                <Select
                                                    value={selectedOptionArtist}
                                                    onChange={this.selectedOptionArtist}
                                                    options={ArtistOptions}
                                                    placeholder='Select Artist'
                                                    styles={customStyles4}
                                                />
                                            </div>
                                            <div className="col-5">
                                                <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterArtist()} >Reset</button>
                                            </div>
                                        </div>
                                        
                                    </div>


                                </div>
                                
                            </div>


                        </div>              

                    </div>

                </React.Fragment>
            )
            }
        )

        const ConcertItems = filterArray.map(value => {
            
            return (

                <React.Fragment key={value.id}>

                    <div className="row ConcertTitleLight2 mt-3">
                        <div className="col-sm-12"> 

                            {
                                (() => {
                                if (value.info) {
                                return <div>&nbsp;<b>{value.artist} - {value.venue} - {value.city}, {value.country} - {value.gig_date} -> {value.info}&nbsp;&nbsp;&nbsp;</b></div>  
                            
                                } 
                                return <div>&nbsp;<b>{value.artist} - {value.venue} - {value.city}, {value.country} - {value.gig_date}&nbsp;&nbsp;&nbsp;</b></div>  
                                })()
                            }
    
                            
  
                        </div>
                    
                    </div>

                    <div className="row py-1 mb-3">

                        {/* 1 kolonne */}
                        <div className="col-sm-3">
                            
                            {
                                (() => {
                                    if (value.picture_link && value.pic_size) {
                                        return <div className="text-center mb-1"><img src={value.picture_link} alt="" height="290" width="220"></img></div>
                                        }
                                    if (value.picture_link && !value.pic_size) {
                                        return <div className="text-center mb-1"><img src={value.picture_link} alt="" height="220" width="220"></img></div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.album_cover) {
                                        return <div className="text-center">
                                            <img src={value.album_cover} alt="" height="220" width="220"></img>
                                            <div><b>Album</b> <br />
                                            <div className="Song2 mb-1" align="center"><b>{value.title}</b></div>
                                                <div className="Date"><i>{value.album_release}</i></div>
                                                <p></p>
                                                </div>
                                        
                                        </div>
                                        }
                                    return 
                                })()
                            }
                        

                            {
                                (() => {
                                if (value.musicians) {
                                return <div><b>Musicians</b> <br />
                                <div className="factText p-1 mb-2">{value.musicians}</div>
                                </div>
                            
                                } 
                                return
                                })()
                            }

                            {
                                (() => {
                                if (value.spotify) {
                                    return <div className="mb-2"><b>Spotify:&nbsp;&nbsp; </b><a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                    </div>
                                    
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment3) {
                                    return <div className="card bg-light py-2 px-2">
                                                <div className="quote3 mb-3">{value.comment3}</div>         
                                                <div className="blockquote-footer"><i>{value.source3}</i></div>
                                            </div>
                                }
                                return
                                })()
                            }

                        </div>

                        {/* 2 kolonne */}
                        <div className="col-sm-5">

                            <div className="bg-light mx-2 py-1 px-2 Description">
                                <b>Setlist:</b><br />
                                <ReadMoreAndLess
                                    charLimit={700}
                                    readMoreText={" more ▼"}
                                    readLessText={" less ▲"}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"
                                        >
                                        {value.setlist}
                                </ReadMoreAndLess>
                            </div>

                            {
                                (() => {
                                if (value.description) {
                                    return  <div className="Header4 mt-2 p-2 Description">
                                                
                                                    <div className="factText">
                                                    <ReadMoreAndLess
                                                        charLimit={700}
                                                        readMoreText={" more ▼"}
                                                        readLessText={" less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                            >
                                                            {value.description}
                                                    </ReadMoreAndLess>
                                                    </div>
                                            </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment_2) {
                                    return <div className="card bg-light py-2 px-2 mt-3">
                                                <div className="quote3 mb-3">{value.comment_2}</div>
                                                
                                                {
                                                    (() => {
                                                        if (value.source_txt_details_2) {
                                                            return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                        }
                                                        return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                    })()

                                                }
                                                
                                            
                                            </div>
                                }
                                return 
                                })()
                            }

                            

                            

                        </div>
                        
                        {/* 3 kolonne */}
                        <div className="col-sm-4">

                            {
                                (() => {
                                if (value.youtube && value.youtube_2 && value.youtube_3 && value.youtube_4) {
                                    return <div>
                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                        <div className="border my-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title}</b></div>
                                                 <ReactPlayer
                                                    url={ value.youtube }
                                                    //className="react-player"
                                                    light='true'
                                                    //light
                                                    controls
                                                    volume={0.9}
                                                    width="100%"
                                                    height="100%"
                                                    //width="300"
                                                    //height="300"
                                                /> 
                                                {/* <YoutubeEmbed embedId={value.youtube.substring(value.youtube.length - 11)} /> */}
                                                
                                        </div>
                                        <div className="border mb-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                            
                                            <ReactPlayer
                                                url={ value.youtube_2 }
                                                light='true'
                                                //light
                                                controls
                                                volume={0.9}
                                                width="300"
                                                height="300"
                                            />
                                        </div>

                                        <div className="border mb-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_3}</b></div>
                                            
                                            <ReactPlayer
                                                url={ value.youtube_3 }
                                                light='true'
                                                //light
                                                controls
                                                volume={0.9}
                                                width="300"
                                                height="300"
                                            />
                                        </div>

                                        <div className="border mb-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_4}</b></div>
                                            
                                            <ReactPlayer
                                                url={ value.youtube_4 }
                                                light='true'
                                                //light
                                                controls
                                                volume={0.9}
                                                width="300"
                                                height="300"
                                            />
                                        </div>

                                    </div>
                                }
                                
                                if (value.youtube && value.youtube_2 && value.youtube_3) {
                                    return <div>
                                
                                    <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                    <div className="border my-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title}</b></div>
                                        
                                            <ReactPlayer
                                                url={ value.youtube }
                                                //className="react-player"
                                                light='true'
                                                //light
                                                controls
                                                volume={0.9}
                                                width="100%"
                                                height="100%"
                                                //width="300"
                                                //height="300"
                                            />
                                    </div>
                                    <div className="border mb-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                        
                                        <ReactPlayer
                                            url={ value.youtube_2 }
                                            light='true'
                                            //light
                                            controls
                                            volume={0.9}
                                            width="300"
                                            height="300"
                                        />
                                    </div>

                                    <div className="border mb-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_3}</b></div>
                                        
                                        <ReactPlayer
                                            url={ value.youtube_3 }
                                            light='true'
                                            //light
                                            controls
                                            volume={0.9}
                                            width="300"
                                            height="300"
                                        />
                                    </div>
                    
                                </div>
                                }
                                if (value.youtube && value.youtube_2) {
                                    return <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                <div className="border my-2">
                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title}</b></div>
                                                    
                                                        <ReactPlayer
                                                            url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            volume={0.9}
                                                            width="300"
                                                            height="300"
                                                        />
                                                    </div>
                                                    <div className="border mb-2">
                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                                
                                                    <ReactPlayer
                                                        url={ value.youtube_2 }
                                                        light='true'
                                                        //light
                                                        controls
                                                        volume={0.9}
                                                        width="300"
                                                        height="300"
                                                    />
                                                </div>
                                                
                                            </div>
                                }
                                if (value.youtube) {
                                    return <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                                                <div className="border my-2">

                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title}</b></div>
                                                    
                                                        <ReactPlayer
                                                            url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            volume={0.9}
                                                            width="300"
                                                            height="300"
                                                        /><br />
                                                    

                                                </div>
                                                    
                                
                                            </div>
                                }

                                return
                                })()
                            }


                            

                            {
                                (() => {
                                if (value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <div className="quote3 mb-3">{value.comment}</div> 
                                                
                                                {
                                                    (() => {
                                                        if (value.source_txt_details) {
                                                            return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                        }
                                                        return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                    })()

                                                }
                                                
                                             
                                            
                                            </div>
                                }
                                return
                                })()
                            }

                            {
                                (() => {
                                if (value.comment4) {
                                    return <div className="card bg-light mt-2 py-2 px-2">
                                                <div className="quote3 mb-3">{value.comment4}</div>         
                                                <div className="blockquote-footer"><i>{value.source4}</i></div>
                                            </div>
                                }
                                return
                                })()
                            }

                        </div>


                    </div>


                </React.Fragment>
            )

        })


        return (

            <main className="my-1 py-1">
                <div className="container">

                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={picture_link}
                    url={'https://www.musicthisday.com/'+page}
                    type='article' />
                            
                    {SelectedConcertsHeader}

                    <div className="row">
                        
                        <div className="col pb-2 mr-4" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {
                        (() => {
                        if (this.state.loading) {
                            return <div><br /><h5><i>Loading session...</i></h5></div>
                        }
                        return <div> 
                            
                                <div className="row">
                                    <div className="col">
                                            {ConcertItems}
                                    </div>
                                </div>
                            
                            </div>
                        })()
                    }

                    <div className="mr-3">
                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>
                    </div>

                    { /* Load Comments now */ }
                   {/*  <HyvorTalk.Embed 
                        websiteId={6167}
                    /> */}
              
                </div>


            </main>
            

        )
    }
}

export default GreatestConcertsByDecade