import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURLists, dbDylanTimelineHistory, dbDylanTimelinePeople, dbDylanTimelineInterviews, 
    dbURListsRefs, dbMusicTimelineConcerts, dbDylanTimelineRecsessions, dbDylanTimelineSongs, dbDylanTimelineAlbums } from '../Constants';
import '../lists.scss';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
//import Select from 'react-select';
import { Link } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import SEO from "../../SEO";
import {useParams} from 'react-router-dom';
import dayjs from "dayjs";
import { SearchLists } from "../misc/utils";
import ReactMarkdown from 'react-markdown';

const BobDylanTimelineYear = (props) => {
    const [list, setList] = useState([]);
    const [historyItems, setHistory] = useState([]);
    const [peopleItems, setPeople] = useState([]);
    const [interviewItems, setInterviews] = useState([]);
    const [concertItems, setConcerts] = useState([]);
    const [sessionItems, setSessions] = useState([]);
    const [songItems, setSongs] = useState([]);
    const [albumItems, setAlbums] = useState([]);

    const [references, setReferences] = useState([])
    const [optionCategory, setoptionCategory] = useState('All')
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(true);
    const [loading6, setLoading6] = useState(true);
    const [loading7, setLoading7] = useState(true);

    const [errorList, setErrorList] = useState(null);
    const [errorReferences, setErrorReferences] = useState(null);
    const [errorHistory, setErrorHistory] = useState(null);
    const [errorPeople, setErrorPeople] = useState(null);
    const [errorInterviews, setErrorInterviews] = useState(null);
    const [errorConcerts, setErrorConcerts] = useState(null);
    const [errorSessions, setErrorSessions] = useState(null);
    const [errorSongs, setErrorSongs] = useState(null);
    const [errorAlbums, setErrorAlbums] = useState(null);

    const [searchString, setSearchString] = useState('')

    const params = useParams();

    let timelineArray, sortedTimelineArray, categoryArray, searchWords;
    
    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setList(response.data)
                setLoading(false)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefs + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        const fetchHistory = () => {
            axios.get(dbDylanTimelineHistory + params.slug)
            .then(response => {
              setHistory(response.data)
              setLoading1(false)
            }).catch(errorHistory => {
              setErrorHistory(errorHistory);
            });
          }
        
        const fetchPeople = () => {
            axios.get(dbDylanTimelinePeople + params.slug)
            .then(response => {
              setPeople(response.data)
              setLoading2(false)
            }).catch(errorPeople => {
              setErrorPeople(errorPeople);
            });
          }

        const fetchInterviews = () => {
            axios.get(dbDylanTimelineInterviews + params.slug)
            .then(response => {
              setInterviews(response.data)
              setLoading3(false)
            }).catch(errorInterviews => {
              setErrorInterviews(errorInterviews);
            });
          }

        const fetchConcerts = () => {
            axios.get(dbMusicTimelineConcerts + params.slug)
            .then(response => {
              setConcerts(response.data)
              setLoading4(false)
            }).catch(errorConcerts => {
              setErrorConcerts(errorConcerts);
            });
          }

        const fetchSessions = () => {
            axios.get(dbDylanTimelineRecsessions + params.slug)
            .then(response => {
              setSessions(response.data)
              setLoading5(false)
            }).catch(errorSessions => {
              setErrorSessions(errorSessions);
            });
          }

        const fetchSongs = () => {
            axios.get(dbDylanTimelineSongs + params.slug)
            .then(response => {
              setSongs(response.data)
              setLoading6(false)
            }).catch(errorSongs => {
              setErrorSongs(errorSongs);
            });
          }
        
        const fetchAlbums = () => {
            axios.get(dbDylanTimelineAlbums + params.slug)
            .then(response => {
              setAlbums(response.data)
              setLoading7(false)
            }).catch(errorAlbums => {
              setErrorAlbums(errorAlbums);
            });
          }

        fetchList()
        fetchReferences()
        fetchHistory()
        fetchPeople()
        fetchInterviews()
        fetchConcerts()
        fetchSessions()
        fetchSongs()
        fetchAlbums()

      }, [params.slug])

      //console.log(dbURLists + params.slug);
    if (errorList) return `Error List: ${errorList.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;
    if (errorHistory) return `Error history: ${errorHistory.message}`;
    if (errorPeople) return `Error people: ${errorPeople.message}`;
    if (errorInterviews) return `Error interviews: ${errorInterviews.message}`;
    if (errorConcerts) return `Error Concerts: ${errorConcerts.message}`;
    if (errorSessions) return `Error Sessions: ${errorSessions.message}`;
    if (errorSongs) return `Error Songs: ${errorSongs.message}`;
    if (errorAlbums) return `Error Albums: ${errorAlbums.message}`;


    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };


    if (loading || loading1 || loading2 || loading3 || loading4 || loading5 || loading6 || loading7) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const year = params.slug;

    //fjerne duplikater i albums, songs, people
    
    //const albumsUnique = [...new Map(albumItems.map((item) => [item["album_id"], item])).values()];
    //const songsUnique = [...new Map(songItems.map((item) => [item["song_id"], item])).values()];
    //const peopleUnique = [...new Map(peopleItems.map((item) => [item["people_id"], item])).values()];

    //Randon number funskjon
    let random = () => {
        let randomnumber = Math.floor((Math.random() * 100000) + 1);
        return randomnumber;
      };
    
     /*  getTimelineDylanPeople.forEach((obj) => {
        obj.randomnumber = randomnumber()
      }); */
    

    const sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //fikse SORT_DATE hvor person er DØD i YEAR (params_slug) 
    peopleItems.forEach((obj) => {   

        const YearSelectedSort = dayjs(obj.sort_date).format('YYYY');

        if (YearSelectedSort !== year)
            obj.sort_date = obj.dead_date
      }); 

    //fjerne konserter av andre artister enn Bob Dylan
    const DylanConcerts = concertItems.filter(e => e.artist === 'Bob Dylan'); 

    
    // konstruere timeline array

   /*  if ((historyItems.length > 0) && (peopleItems.length > 0) && (interviewItems.length > 0) 
         && (DylanConcerts.length > 0) && (sessionItems.length > 0) && (songItems.length > 0)) {

        
        } */

        timelineArray = [...historyItems, ...peopleItems, ...interviewItems, ...DylanConcerts,
            ...sessionItems, ...songItems, ...albumItems];

        console.table(timelineArray);

        if (timelineArray.length > 0) {
            sortedTimelineArray = timelineArray.sort(function(a,b){
                return new Date(a.sort_date) - new Date(b.sort_date);
              });

            //stappe inn radomnumber property i hvert object
                sortedTimelineArray.forEach((obj) => {
                    obj.randomnumber = random();
                });
            }
 
    //Søk skal ikke søke i entrys med NULL i searchdescription
    let ItemsWithSearchDescription = sortedTimelineArray.filter(e => e.searchdescription !== null);

    


    const {name, description, pic_link, page } = list[0];

    if (optionCategory === 'history') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'history');  
        } 
    if (optionCategory === 'albums') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'albums'); 
        }   
    if (optionCategory === 'songs') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'songs'); 
        } 
    if (optionCategory === 'people') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'people'); 
        }
    if (optionCategory === 'concerts') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'concerts'); 
        } 
    if (optionCategory === 'recsessions') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'recsessions'); 
        } 
    if (optionCategory === 'interviews') {
        categoryArray = sortedTimelineArray.filter(e => e.subject === 'interviews'); 
        } 
    if (optionCategory === 'All') {
        categoryArray = sortedTimelineArray;  
        }


    //Search
    if (searchString) {
        searchWords = searchString.split(/\s/).filter(word => word)
        categoryArray = SearchLists(ItemsWithSearchDescription, 'searchdescription', searchString.toLowerCase());
        } 
        

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    //console.log(list);
    //console.log(name);
    //console.table('denne: ' + sortedTimelineArray);

    //HEADING
    const TimelineHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                
                <div className="row my-3 gx-5">

                    {/* kolonne 1 */}
                    <div className="col-sm-3">

                        <div className='mt-3'><img src={value.picture_link} alt="alt" className="img-fluid" /> </div>
                        <div className="mt-2 quote2"></div> 
                        
                    </div>
                    {/* kolonne 2 */}
                    <div className="col-sm-5">
                        <h1>{value.name}</h1>

                        <div>
                            <div className="p-1 rounded"><i>Published: {value.publish_date}</i></div>
                        </div>
                            
                            <div className="factTextNoWrap rounded m-2 p-2">
                                <ReactMarkdown>
                                    {value.description}                       
                                </ReactMarkdown>
                            </div>

                            {
                                (() => {
                                if (value.comment1) {
                                    return <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="quote2">{value.comment1}</div>
                                                    <div className="blockquote-footer mt-1"><i>{value.source1}</i></div>
                                            </div>
                                }
                                return 
                                })()
                            }  

                            {
                                (() => {
                                if (value.spotify) {
                                return  <div className="postIntro my-3 ps-3"><b>--> <a href={ value.spotify }>
                                Spotify playlist</a></b></div>
                                }
                                return 
                                })()
                            }

                            <div className="d-flex p-2">
                                {((window.location.hostname === "localhost") || (value.in_progress)) &&
                                    <div>
                                        <div className="text-center">
                                            <div className="btn btn-light btn-sm mt-2"><b>In progress</b></div>
                                        </div>
                                        {categoryArray &&
                                        <div className="text-center">
                                            <div className="btn btn-info btn-xs">total items count: {categoryArray.length}</div>
                                        </div> 
                                        }
                                    </div>
                                    } 
                            </div>
                            

                               
                    </div>
                    {/* kolonne 3 */}
                    <div className="col-sm-4">
                        <div className="row">   
                                <div className="col">
                                <h4>Search</h4>
                            </div>
                        </div>
                        
                        <div className="row pb-2 mb-4">  
                                <div className="col-9">
                                    <div className="search">    
                                            <DebounceInput
                                                //type="number"
                                                minLength={4}
                                                debounceTimeout={1000}
                                                value={searchString}
                                                onChange={handleSearchChange}
                                                placeholder="Search description" 
                                            />
                                        </div>
                                    </div>
                                <div className="col-3 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()} >Reset</button>
                                </div>
                        </div>

                        <b>Check out</b>
                                <div className="Description">
                                    <ul>
                                        {
                                            (() => {
                                            if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                            if (value.link1) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            return 
                                            })()
                                        }


                                        <li><b><a href='/lists'>
                                            More Lists @ MusicThisDay.com
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>

                                
                    </div>
                </div>

                <div className="row my-3">
                    
                        <div className='col-sm-10 mb-2'>
                            <div className='me-4'><strong>Select category to filter:</strong></div>
                            {optionCategory === 'All' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1"  onClick={() => setoptionCategory('All')}>All</button>
                            }
                            {optionCategory !== 'All' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('All')}>All</button>
                            }

                            {optionCategory === 'albums' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setoptionCategory('albums')}>Albums</button>
                            }

                            {(optionCategory !== 'albums') && albumItems.length > 0 && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('albums')}>Albums</button>
                            }

                            {optionCategory === 'songs' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setoptionCategory('songs')}>Songs</button>
                            }

                            {optionCategory !== 'songs' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('songs')}>Songs</button>
                            }

                            {optionCategory === 'people' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setoptionCategory('people')}>People</button>
                            }

                            {optionCategory !== 'people' && peopleItems.length > 0 && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('people')}>People</button>
                            }

                            {optionCategory === 'concerts' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setoptionCategory('concerts')}>Concerts</button>
                            }
                          
                            {optionCategory !== 'concerts' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('concerts')}>Concerts</button>
                            }

                            {optionCategory === 'history' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setoptionCategory('history')}>History</button>
                            }

                            {optionCategory !== 'history' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('history')}>History</button>
                            }

                            {optionCategory === 'interviews' && 
                            <button type="button" className="btn btn-success btn-sm mt-1 me-1" onClick={() => setoptionCategory('interviews')}>Interviews</button>
                            }

                            {optionCategory !== 'interviews' && 
                            <button type="button" className="btn btn-info btn-xs mt-1 me-1" onClick={() => setoptionCategory('interviews')}>Interviews</button>
                            }

                            {optionCategory === 'recsessions' && 
                            <button type="button" className="btn btn-success btn-sm mt-1" onClick={() => setoptionCategory('recsessions')}>Recording sessions</button>
                            }

                            {optionCategory !== 'recsessions' && 
                            <button type="button" className="btn btn-info btn-xs mt-1" onClick={() => setoptionCategory('recsessions')}>Recording sessions</button>
                            }
                        </div>
                     

                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>

                        </div>
                </div>
                   
            </React.Fragment>
            )
    })

    //TIMELINE
    
    const TimeLine = categoryArray.map((value, index) => {

    let DescCharLimit, TranscriptLimit, SetlistCharLimit, RecSheetLimit

        if (value.charlimitdescription) {
            DescCharLimit = value.charlimitdescription;
        }
        else {
            DescCharLimit = 500;
        }

        if (value.transcript_limit) {
            TranscriptLimit = value.transcript_limit
        } else {
            TranscriptLimit = 350;
        }

        if (value.charlimitsetlist) {
            SetlistCharLimit = value.charlimitsetlist;
        } else {
            SetlistCharLimit = 330;
        } 

        if (value.session_sheet) {
            RecSheetLimit = value.session_sheet
        } else {
            RecSheetLimit = 330;
        }

        return (
            <React.Fragment key={value.randomnumber}>

                {/* ATTENTION -> TODO: legge inn simple bracket if then logikk */}

                {
                    (() => {
                    // History item
                    if (value.subject === 'history' && value.comment) {
                        return <div>

                                    {!value.picture &&
                                    <div className="row justify-content-center">
                                        <div className="col-sm-4 align-self-center py-2 border-10 rounded">
                                            {
                                                (() => {
                                                if (value.date_formated) {
                                                return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_formated}</b>
                                                        </div>
                                                }
                                                return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_txt}</b>
                                                        </div>
                                                })()
                                            }
                                            {!value.description &&
                                            <div>
                                                <div className="TimeLineItemTitle ms-2 text-center">{ value.title_history }</div>
                                            </div>
                                            }
                                            {value.description &&
                                            <div>
                                                <div className="TimeLineItemTitle ms-2">{ value.title_history }</div>
                                            </div>
                                            }
                                            

                                            <div className="Font14 p-2">

                                                    {
                                                        (() => {
                                                            if (!searchString && value.description) {
                                                                return  <div>
                                                                            <ReadMoreAndLess
                                                                                    charLimit={DescCharLimit}
                                                                                    readMoreText={" more ▼"}
                                                                                    readLessText={" less ▲"}
                                                                                    readMoreClassName="read-more-less--more"
                                                                                    readLessClassName="read-more-less--less"
                                                                                >
                                                                                {value.description}
                                                                            </ReadMoreAndLess>
                                                                        </div>
                                                                }
                                                        
                                                            if (searchString && value.description) {
                                                                    return <div>
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={value.description}
                                                                        />
                                                                    </div>
                                                                } 
                                                            return
                                                            }
                                                        )()
                                                    }

                                                {
                                                    (() => {
                                                    if (value.more_info) {
                                                        return <div align="Right">-> <a href={value.more_info_url}>{value.more_info}</a></div>
                                                    }
                                                    return 
                                                    })()
                                                } 
                                            </div>
                                                
                                            <div className="card bg-light mt-2 mx-2 pt-2 px-2">
                                                <div className="quote mb-3">{value.comment}</div>
                                                <figcaption className="blockquote-footer">
                                                    <cite title="Source Title">{value.comment_source}</cite>
                                                </figcaption>
                                            </div>
                
                                        </div>
                                    </div>
                                    }

                                    {value.picture &&
                                    <div className="row justify-content-center">
                                        {/* kolonne 1 */}
                                            <div className="col-sm-2 py-2 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                                {
                                                    (() => {
                                                    if (value.date_formated) {
                                                    return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_formated}</b>
                                                            </div>
                                                    }
                                                    return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_txt}</b>
                                                            </div>
                                                    })()
                                                }

                                                {
                                                    (() => {
                                                    if (value.picture) {
                                                        return <div align="center">
                                                        <div className='mt-2'><img src={value.picture} 
                                                        alt="alt" className="img-fluid rounded" /> </div>
                                                        </div>
                                                    }
                                                    return 
                                                    })()
                                                } 
                
                                            </div>
                                        
                                        {/* kolonne 2 */}
                                            <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">
                                                {!value.description &&
                                                <div>
                                                    <div className="TimeLineItemTitle ms-2 text-center">{ value.title_history }</div>
                                                </div>
                                                }
                                                {value.description &&
                                                <div>
                                                    <div className="TimeLineItemTitle ms-2">{ value.title_history }</div>
                                                </div>
                                                }

                                                    <div className="Font14 p-2">

                                                        {
                                                            (() => {
                                                                if (!searchString && value.description) {
                                                                    return  <div>
                                                                                <ReadMoreAndLess
                                                                                        charLimit={DescCharLimit}
                                                                                        readMoreText={" more ▼"}
                                                                                        readLessText={" less ▲"}
                                                                                        readMoreClassName="read-more-less--more"
                                                                                        readLessClassName="read-more-less--less"
                                                                                    >
                                                                                    {value.description}
                                                                                </ReadMoreAndLess>
                                                                            </div>
                                                                    }
                                                            
                                                                if (searchString && value.description) {
                                                                        return <div>
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={searchWords}
                                                                                autoEscape={true}
                                                                                textToHighlight={value.description}
                                                                            />
                                                                        </div>
                                                                    } 
                                                                return
                                                                }
                                                            )()
                                                        }

                                                            {
                                                                (() => {
                                                                if (value.more_info) {
                                                                    return <div align="Right">-> <a href={value.more_info_url}>{value.more_info}</a></div>
                                                                }
                                                                return 
                                                                })()
                                                            } 
                                                    </div>
                                                
                                                    <div className="card bg-light mt-2 mx-2 pt-2 px-2">
                                                        <div className="quote mb-3">{value.comment}</div>
                                                        <figcaption className="blockquote-footer">
                                                            <cite title="Source Title">{value.comment_source}</cite>
                                                        </figcaption>
                                                    </div>
                
                                            </div>
                                    </div>
                                    }
                                        
                                </div>           
                    }
                    if (value.subject === 'history' && !value.comment) {
                        return <div>

                                {!value.picture &&  
                                     <div className="row justify-content-center">
                                        <div className="col-sm-4 align-self-center py-2 border-10 rounded">
                                            {
                                                (() => {
                                                if (value.date_formated) {
                                                return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_formated}</b>
                                                        </div>
                                                }
                                                return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_txt}</b>
                                                        </div>
                                                })()
                                            }
                                             {!value.description &&
                                                <div>
                                                    <div className="TimeLineItemTitle ms-2 text-center">{ value.title_history }</div>
                                                </div>
                                                }
                                                {value.description &&
                                                <div>
                                                    <div className="TimeLineItemTitle ms-2">{ value.title_history }</div>
                                                </div>
                                                }
                                            <div className="Font14_wrap p-2">

                                                        {
                                                            (() => {
                                                                if (!searchString && value.description) {
                                                                    return  <div>
                                                                                <ReadMoreAndLess
                                                                                        charLimit={DescCharLimit}
                                                                                        readMoreText={" more ▼"}
                                                                                        readLessText={" less ▲"}
                                                                                        readMoreClassName="read-more-less--more"
                                                                                        readLessClassName="read-more-less--less"
                                                                                    >
                                                                                    {value.description}
                                                                                </ReadMoreAndLess>
                                                                            </div>
                                                                    }
                                                            
                                                                if (searchString && value.description) {
                                                                        return <div>
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={searchWords}
                                                                                autoEscape={true}
                                                                                textToHighlight={value.description}
                                                                            />
                                                                        </div>
                                                                    } 
                                                                return
                                                                }
                                                            )()
                                                        }


                                                {
                                                    (() => {
                                                    if (value.more_info) {
                                                        return <div align="Right">-> <a href={value.more_info_url}>{value.more_info}</a></div>
                                                    }
                                                    return 
                                                    })()
                                                } 
                                            </div>
                                        </div>

                                    </div>
                                } 
                                {value.picture &&  
                                    <div className="row justify-content-center">
                                            {/* kolonne 1 */}
                                            <div className="col-sm-2 py-2 border-left-10 border-top-10 border-right-10 border-bottom-10 rounded">
                                            {
                                                (() => {
                                                if (value.date_formated) {
                                                return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_formated}</b>
                                                        </div>
                                                }
                                                return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_txt}</b>
                                                        </div>
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.picture) {
                                                    return <div align="center">
                                                    <div className='my-2'><img src={value.picture} 
                                                    alt="alt" className="img-fluid rounded" /> </div>
                                                    </div>
                                                }
                                                return 
                                                })()
                                            }  


                
                                            </div>
                            
                                            {/* kolonne 2 */}
                                            <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">
                                                {!value.description &&
                                                <div>
                                                    <div className="TimeLineItemTitle ms-2 text-center">oo{ value.title_history }</div>
                                                </div>
                                                }
                                                {value.description &&
                                                <div>
                                                    <div className="TimeLineItemTitle ms-2">{ value.title_history }</div>
                                                </div>
                                                }
                                                <div className="Font14_wrap p-2">

                                                    {
                                                        (() => {
                                                            if (!searchString && value.description) {
                                                                return  <div>
                                                                            <ReadMoreAndLess
                                                                                    charLimit={DescCharLimit}
                                                                                    readMoreText={" more ▼"}
                                                                                    readLessText={" less ▲"}
                                                                                    readMoreClassName="read-more-less--more"
                                                                                    readLessClassName="read-more-less--less"
                                                                                >
                                                                                {value.description}
                                                                            </ReadMoreAndLess>
                                                                        </div>
                                                                }
                                                        
                                                            if (searchString && value.description) {
                                                                    return <div>
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={value.description}
                                                                        />
                                                                    </div>
                                                                } 
                                                            return
                                                            }
                                                        )()
                                                    }


                                                    {
                                                        (() => {
                                                        if (value.more_info) {
                                                            return <div align="Right">-> <a href={value.more_info_url}>{value.more_info}</a></div>
                                                        }
                                                        return 
                                                        })()
                                                    } 
                                                </div>
                                            </div>

                                    </div>
                                }  
                        
                            
                        </div>
                    }
                    if (value.subject === 'people') {
                        return <div className="row justify-content-center">
                                    {/* kolonne 1 */}
                                    <div className="col-sm-2 pb-2 pt-3 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                        {
                                            (() => {
                                            if (value.year_born === year) {
                                                return <div className="alert-sm alert-primary text-center mb-1 
                                                            rounded Crown" align="center"><b>{value.born}</b>
                                                        </div>
                                            }
                                            return <div className="alert-sm alert-primary text-center mb-1 
                                                    rounded Dead" align="center"><b>{value.dead}</b>
                                                </div>

                                            })()
                                        }

                                        <div className='my-2' align="center"><img src={value.picture_link} 
                                                alt="alt" className="img-fluid rounded" /> </div> 

                                        <div className="alert-sm alert-primary text-center mb-1 rounded Song"><b>{value.artist}</b></div>
                                    </div>
                                    {/* kolonne 2 */}
                                    <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">

                                        {(value.dylan_desc && value.mgray)  &&
                                            <div>
                                                <div className="Description p-2 clearfix">
                                                    <button type="button" className="btn btn-info btn-xs col-md-2 float-md-end mb-2 ms-md-2"><strong>People</strong></button>
                                                    {
                                                        (() => {
                                                            if (!searchString) {
                                                                return  <div>
                                                                            <ReadMoreAndLess
                                                                                    charLimit={DescCharLimit}
                                                                                    readMoreText={" more ▼"}
                                                                                    readLessText={" less ▲"}
                                                                                    readMoreClassName="read-more-less--more"
                                                                                    readLessClassName="read-more-less--less"
                                                                                >
                                                                                {value.dylan_desc}
                                                                            </ReadMoreAndLess>
                                                                        </div>
                                                                }
                                                        
                                                            return  <div>
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={value.dylan_desc}
                                                                        />
                                                                    </div>
                                                            }
                                                        )()
                                                    }

                                                    {value.mgray &&
                                                    <div className="card bg-light p-2 mt-3">
                                                        <div className="quote3 mb-4">{value.mgray}</div> 
                                                        <div className="blockquote-footer"><i>Michael Gray (The Bob Dylan Encyclopedia)</i></div>
                                                    </div>
                                                    }
                                                    
                                                    
                                                </div>

                                                <div className="row mt-1">
                                                    
                                                    <div className="col p-2 Font14">

                                                        {
                                                            (() => {
                                                            if (value.year_born === year) {
                                                                return <div>
                                                                        <b>Born </b><br />
                                                                        {
                                                                            (() => {
                                                                                if (value.born && value.city && !value.born_place) {
                                                                                return   <div>
                                                                                        <div className="DateInline p-1 rounded">{value.born} - {value.city}
                                                                                        , {value.region}, {value.country}</div> 
                                                                                    </div>
                                                                                } 
                                                                                if (value.born && !value.city && !value.born_place) {
                                                                                    return   <div>
                                                                                        <div className="DateInline p-1 rounded">{value.born}</div> 
                                                                                    </div>
                                                                                }
                                                                                return <div className="DateInline p-1 rounded">{value.born} - {value.born_place}</div>
                                                                            })()
                                                                        }

                                                                    </div>
                                                            }
                                                            return <div>
                                                                    {
                                                                        (() => {
                                                                        if (value.dead||value.dead_place) {
                                                                            if (value.dead && value.dead_place) {
                                                                                return <div>
                                                                                    <b>Dead </b><br />
                                                                                    <div className="DateInline p-1 rounded">{value.dead} - {value.dead_place}
                                                                                    </div> 
                                                                                </div>
                                                                            }
                                                                            if (value.dead) {
                                                                                return <div>
                                                                                        <b>Dead </b><br />
                                                                                        <div className="DateInline p-1 rounded">{value.dead}
                                                                                        </div> 
                                                                                    </div>
                                                                            }
                                                                            return <div>
                                                                                    <b>Dead </b><br />
                                                                                    <div className="DateInline p-1 rounded">{value.dead_place}
                                                                                    </div> 
                                                                                </div>
                                                                            }
                                                                        })()
                                                                    }

                                                                </div>

                                                            })()
                                                        }

                                                        {
                                                            (() => {
                                                                if (value.sub_genres && value.main_genre) {
                                                                return   <div className="mt-1">
                                                                        <b>Genres </b><br />
                                                                        <div>
                                                                            <div className="DateInline p-1 rounded">{value.main_genre}, {value.sub_genres}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                if (value.main_genre || !value.sub_genres) {
                                                                    return <div className="mt-1">
                                                                        <b>Genre </b><br />
                                                                        <div>
                                                                            <div className="DateInline p-1 rounded">{value.main_genre}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                return 
                                                            })()
                                                        }
                                                    </div>

                                                </div>
                                            
                                            </div>
                                        }

                                        {(value.dylan_desc && !value.mgray)  &&
                                            <div>
                                                <div className="Description p-2 border-bottom clearfix">
                                                    <button type="button" className="btn btn-info btn-xs col-md-2 float-md-end mb-2 ms-md-2"><strong>People</strong></button>
                                                    {
                                                        (() => {
                                                            if (!searchString) {
                                                                return  <div>
                                                                            <ReadMoreAndLess
                                                                                    charLimit={DescCharLimit}
                                                                                    readMoreText={" more ▼"}
                                                                                    readLessText={" less ▲"}
                                                                                    readMoreClassName="read-more-less--more"
                                                                                    readLessClassName="read-more-less--less"
                                                                                >
                                                                                {value.dylan_desc}
                                                                            </ReadMoreAndLess>
                                                                        </div>
                                                                }
                                                        
                                                            return  <div>
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={value.dylan_desc}
                                                                        />
                                                                    </div>
                                                            }
                                                        )()
                                                    }
                                                    
                                                </div>

                                                <div className="row mt-2">
                                                    
                                                    <div className="col p-2 Font14">

                                                        {
                                                            (() => {
                                                            if (value.year_born === year) {
                                                                return <div>
                                                                        <b>Born </b><br />
                                                                        {
                                                                            (() => {
                                                                                if (value.born && value.city && !value.born_place) {
                                                                                return   <div>
                                                                                        <div className="DateInline p-1 rounded">{value.born} - {value.city}
                                                                                        , {value.region}, {value.country}</div> 
                                                                                    </div>
                                                                                } 
                                                                                if (value.born && !value.city && !value.born_place) {
                                                                                    return   <div>
                                                                                        <div className="DateInline p-1 rounded">{value.born}</div> 
                                                                                    </div>
                                                                                }
                                                                                return <div className="DateInline p-1 rounded">{value.born} - {value.born_place}</div>
                                                                            })()
                                                                        }

                                                                    </div>
                                                            }
                                                            return <div>
                                                                    {
                                                                        (() => {
                                                                        if (value.dead||value.dead_place) {
                                                                            if (value.dead && value.dead_place) {
                                                                                return <div>
                                                                                    <b>Dead </b><br />
                                                                                    <div className="DateInline p-1 rounded">{value.dead} - {value.dead_place}
                                                                                    </div> 
                                                                                </div>
                                                                            }
                                                                            if (value.dead) {
                                                                                return <div>
                                                                                        <b>Dead </b><br />
                                                                                        <div className="DateInline p-1 rounded">{value.dead}
                                                                                        </div> 
                                                                                    </div>
                                                                            }
                                                                            return <div>
                                                                                    <b>Dead </b><br />
                                                                                    <div className="DateInline p-1 rounded">{value.dead_place}
                                                                                    </div> 
                                                                                </div>
                                                                            }
                                                                        })()
                                                                    }

                                                                </div>

                                                            })()
                                                        }

                                                        {
                                                            (() => {
                                                                if (value.sub_genres && value.main_genre) {
                                                                return   <div className="mt-1">
                                                                        <b>Genres </b><br />
                                                                        <div>
                                                                            <div className="DateInline p-1 rounded">{value.main_genre}, {value.sub_genres}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                if (value.main_genre || !value.sub_genres) {
                                                                    return <div className="mt-1">
                                                                        <b>Genre </b><br />
                                                                        <div>
                                                                            <div className="DateInline p-1 rounded">{value.main_genre}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                return 
                                                            })()
                                                        }
                                                    </div>

                                                </div>
                                            
                                            </div>
                                        }

                                        {(!value.dylan_desc && value.mgray)  &&
                                            <div>
                                                <div className="Description p-2 clearfix">
                                                    <button type="button" className="btn btn-info btn-xs col-md-2 float-md-end mb-2 ms-md-2"><strong>People</strong></button>
                                                    

                                                    {value.mgray &&
                                                    <div className="card bg-light py-2 px-2">
                                                        <div className="quote3 mb-4">{value.mgray}</div> 
                                                        <div className="blockquote-footer"><i>Michael Gray (The Bob Dylan Encyclopedia)</i></div>
                                                    </div>
                                                    }
                                                    
                                                    
                                                </div>

                                                <div className="row mt-2">
                                                    
                                                    <div className="col p-2 Font14">

                                                        {
                                                            (() => {
                                                            if (value.year_born === year) {
                                                                return <div>
                                                                        <b>Born </b><br />
                                                                        {
                                                                            (() => {
                                                                                if (value.born && value.city && !value.born_place) {
                                                                                return   <div>
                                                                                        <div className="DateInline p-1 rounded">{value.born} - {value.city}
                                                                                        , {value.region}, {value.country}</div> 
                                                                                    </div>
                                                                                } 
                                                                                if (value.born && !value.city && !value.born_place) {
                                                                                    return   <div>
                                                                                        <div className="DateInline p-1 rounded">{value.born}</div> 
                                                                                    </div>
                                                                                }
                                                                                return <div className="DateInline p-1 rounded">{value.born} - {value.born_place}</div>
                                                                            })()
                                                                        }

                                                                    </div>
                                                            }
                                                            return <div>
                                                                    {
                                                                        (() => {
                                                                        if (value.dead||value.dead_place) {
                                                                            if (value.dead && value.dead_place) {
                                                                                return <div>
                                                                                    <b>Dead </b><br />
                                                                                    <div className="DateInline p-1 rounded">{value.dead} - {value.dead_place}
                                                                                    </div> 
                                                                                </div>
                                                                            }
                                                                            if (value.dead) {
                                                                                return <div>
                                                                                        <b>Dead </b><br />
                                                                                        <div className="DateInline p-1 rounded">{value.dead}
                                                                                        </div> 
                                                                                    </div>
                                                                            }
                                                                            return <div>
                                                                                    <b>Dead </b><br />
                                                                                    <div className="DateInline p-1 rounded">{value.dead_place}
                                                                                    </div> 
                                                                                </div>
                                                                            }
                                                                        })()
                                                                    }

                                                                </div>

                                                            })()
                                                        }

                                                        {
                                                            (() => {
                                                                if (value.sub_genres && value.main_genre) {
                                                                return   <div className="mt-1">
                                                                        <b>Genres </b><br />
                                                                        <div>
                                                                            <div className="DateInline p-1 rounded">{value.main_genre}, {value.sub_genres}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                if (value.main_genre || !value.sub_genres) {
                                                                    return <div className="mt-1">
                                                                        <b>Genre </b><br />
                                                                        <div>
                                                                            <div className="DateInline p-1 rounded">{value.main_genre}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                return 
                                                            })()
                                                        }
                                                    </div>

                                                </div>
                                            
                                            </div>
                                        }

                                        
                                        
                                            
                                        
                                    </div>
                                </div>
                    }
                    if (value.subject === 'interviews') {
                        return <div className="row justify-content-center">
                                    {/* kolonne 1 */}
                                    <div className="col-sm-2 pb-2 pt-3 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                        
                                        {value.date_interview &&
                                            <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_interview}</b>
                                            </div>
                                        }
                                        {!value.date_formated &&
                                            <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_txt}</b>
                                            </div>
                                        }

                                        {value.picture &&
                                            <div align="center">
                                            <div className='mt-2'><img src={value.picture} 
                                            alt="alt" className="img-fluid rounded" /> </div>
                                            </div>
                                        }
                                        {value.interviewed_by && !value.not_interview &&
                                            <div className='mt-3'><b>Interviewer</b> <br />
                                                <div className='factText p-1'>
                                                    <div>{value.interviewed_by}</div>
                                                </div>
                                            </div>
                                        }
                                        {value.interviewed_by && value.not_interview &&
                                            <div className='mt-3'><b>Author</b> <br />
                                                <div className='factText p-1'>
                                                    <div>{value.interviewed_by}</div>
                                                </div>
                                            </div>
                                        }

                                                 
                                    </div>
                                    {/* kolonne 2 */}
                                    <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">
                                        {!value.description &&
                                            <div>
                                                {!value.not_interview &&
                                                <button type="button" className="btn btn-info btn-xs col-md-3 float-md-end mb-2 ms-md-2"><strong>Interview</strong></button>
                                                }
                                                <div className="TimeLineItemTitle ms-2 text-center">{ value.title }</div>
                                            </div>
                                        }
                                        {value.description &&
                                            <div>
                                                <div className="TimeLineItemTitle ms-2">{ value.title }</div>
                                            </div>
                                        }

                                        <div className="Font14 p-2">
                                        
                                            {!searchString && value.description &&
                                                <div>
                                                    <button type="button" className="btn btn-info btn-xs col-md-3 float-md-end mb-2 ms-md-2"><strong>Interview</strong></button>
                                                    <ReadMoreAndLess
                                                            charLimit={DescCharLimit}
                                                            readMoreText={" more ▼"}
                                                            readLessText={" less ▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                        >
                                                        {value.description}
                                                    </ReadMoreAndLess>
                                                </div>
                                            }
                                            {searchString && value.description &&
                                                <div>
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={searchWords}
                                                        autoEscape={true}
                                                        textToHighlight={value.description}
                                                    />
                                                </div>
                                            }

                                            {value.transcript &&
                                                <div className="bg-light mx-1 mt-3">
                                                    <div className="py-2 px-2 factText">
                                                        <ReadMoreAndLess
                                                                charLimit={TranscriptLimit}
                                                                readMoreText={" more ▼"}
                                                                readLessText={" less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.transcript}
                                                        </ReadMoreAndLess>
                                                    </div>
                                                </div>
                                            }
                                                       
                                            {value.intro_quote && !value.no_quote &&
                                                <div className="card bg-light mt-2 mx-2 pt-2 px-2">
                                                <div className="quote2 mb-3">{value.intro_quote}</div>
                                                <figcaption className="blockquote-footer">
                                                    <cite title="Source Title">{value.intro_quote_source}</cite>
                                                </figcaption>
                                                </div>
                                            }
                                                            
                                        </div>

                                    </div>
                                
                        </div>
                    }
                    if (value.subject === 'concerts') {
                        return <div className="row justify-content-center">
                            {/* kolonne 1 */}
                            <div className="col-sm-2 pb-2 pt-3 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                
                                <div className="alert-sm alert-primary text-center mb-1 
                                rounded Song" align="center"><b>{value.gig_date}</b>
                                </div>

                                <div className='my-2' align="center"><img src={value.picture_link} alt="alt" 
                                className="img-fluid rounded" /> 
                                </div>

                                <div align="center"><b>{value.venue}</b></div>
                                
                                <div align="center"><b>{value.city}, {value.country}</b></div>
                            </div>

                            {/* kolonne 2 */}
                            <div className="col-sm-3 py-2 border-top-10 border-bottom-10 border-right-10 rounded position-relative">

                                <div className="Description">
                                <button type="button" className="btn btn-info btn-xs col-md-3 float-md-end mb-2 ms-md-3"><strong>Concert</strong></button>
                                    <div>
                                    {
                                        (() => {
                                            if (!searchString) {
                                                return  <div>
                                                            <ReadMoreAndLess
                                                                    charLimit={SetlistCharLimit}
                                                                    readMoreText={" more ▼"}
                                                                    readLessText={" less ▲"}
                                                                    readMoreClassName="read-more-less--more"
                                                                    readLessClassName="read-more-less--less"
                                                                >
                                                                {value.setlist}
                                                            </ReadMoreAndLess>
                                                        </div>
                                                }
                                        
                                            return  <div>
                                                        <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={searchWords}
                                                            autoEscape={true}
                                                            textToHighlight={value.setlist}
                                                        />
                                                    </div>
                                            }
                                        )()
                                    }

                                    {

                                    }

                                    {value.comment && value.source_txt_details &&
                                    <div className="mt-3">
                                        <div className="card bg-light py-2 px-2">
                                            <div className="quote3 mb-4">{value.comment}</div> 
                                            <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                        </div>
                                    </div>
                                    }
                                    {value.comment && !value.source_txt_details &&
                                    <div className="mt-3">
                                        <div className="card bg-light py-2 px-2">
                                            <div className="quote3 mb-4">{value.comment}</div> 
                                            <div className="blockquote-footer"><i>{value.source_txt}</i></div>
                                        </div>
                                    </div>
                                    }
                                    {!value.comment && value.description &&
                                        <div className="Description mt-3 border-top p-2">
                                            <ReadMoreAndLess
                                                    charLimit={275}
                                                    readMoreText={" more ▼"}
                                                    readLessText={" less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description}
                                            </ReadMoreAndLess>    
                                        </div>
                                    }

                                    </div>
                                </div>
                            </div>

                            {/* kolonne 3 */}
                            <div className="col-sm-2 py-2 border-top-10 border-bottom-10 border-right-10 rounded">

                                {
                                    (() => {
                                        if (value.youtube) {
                                            return <div>
                                                    <div className="border my-2">
                                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video1}</b></div>
                                                            <ReactPlayer
                                                                url={ value.youtube }
                                                                light='true'
                                                                //light
                                                                controls
                                                                volume={0.9}
                                                                width="100%"
                                                                height="100%"
                                                            />
                                                    </div>
                                                </div>
                                            }
                                    })()
                                }
                                {
                                    (() => {
                                        if (value.youtube_2) {
                                            return <div>
                                                    <div className="border my-2">
                                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video2}</b></div>
                                                            <ReactPlayer
                                                                url={ value.youtube_2 }
                                                                light='true'
                                                                //light
                                                                controls
                                                                volume={0.9}
                                                                width="100%"
                                                                height="100%"
                                                            />
                                                    </div>
                                                </div>
                                            }
                                    })()
                                }
                                {
                                    (() => {
                                        if (value.youtube_3) {
                                            return <div>
                                                    <div className="border my-2">
                                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video3}</b></div>
                                                            <ReactPlayer
                                                                url={ value.youtube_3 }
                                                                light='true'
                                                                //light
                                                                controls
                                                                volume={0.9}
                                                                width="100%"
                                                                height="100%"
                                                            />
                                                    </div>
                                                </div>
                                            }
                                    })()
                                }

                                {value.musicians &&
                                    <div className="mt-4"><b>Musicians</b> <br />
                                    <div className="factText p-1 mb-2">{value.musicians}</div>
                                    </div>
                                }
                            </div>

                        </div>

                    }
                    if (value.subject === 'recsessions') {
                        return <div className="row justify-content-center">
                                    {/* kolonne 1 */}
                                    <div className="col-sm-2 pb-2 pt-3 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                        
                                        {value.rec_date &&
                                            <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.rec_date}</b>
                                            </div>
                                        }
                                        {value.date_txt && !value.rec_date &&
                                            <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.date_txt}</b>
                                            </div>
                                        }

                                        {value.picture &&
                                            <div align="center">
                                            <div className='mt-2'><img src={value.picture} 
                                            alt="alt" className="img-fluid rounded" /> </div>
                                            </div>
                                        }

                                        {value.album_cover && !value.picture &&
                                            <div align="center">
                                            <div className='mt-2'><img src={value.album_cover} 
                                            alt="alt" className="img-fluid rounded" /> </div>
                                            </div>
                                        }
                                        

                                        <div className='mt-3' align="center">
                                            <div className='factText p-1'>
                                                <div>{value.studio} - {value.location}  </div>
                                            </div>
                                        </div>

                                        <div><b>Musicians</b> <br />
                                            <div className="factText p-1">{value.musicians}</div>
                                        </div>
                                                 
                                    </div>
                                    {/* kolonne 2 */}
                                    <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">
                                        
                                        <div>
                                            <div className="TimeLineItemTitle ms-2 mb-2 text-center">{ value.title }</div>
                                        </div>
                                        
                                        <div className="Description">
                                        <button type="button" className="btn btn-info btn-xs col-md-3 float-md-end mb-2 ms-md-3"><strong>Rec Session</strong></button>
                                    
                                        {
                                            (() => {
                                                if (!searchString) {
                                                    return  <div className="bg-light mx-1 mt-3">
                                                                <ReadMoreAndLess
                                                                        charLimit={RecSheetLimit}
                                                                        readMoreText={" more ▼"}
                                                                        readLessText={" less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.rec_sheet}
                                                                </ReadMoreAndLess>
                                                            </div>
                                                    }
                                            
                                                return  <div>
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={searchWords}
                                                                autoEscape={true}
                                                                textToHighlight={value.rec_sheet}
                                                            />
                                                        </div>
                                                }
                                            )()
                                        }

                                        {value.description &&
                                            <div className="my-3">
                                                    <ReadMoreAndLess
                                                            charLimit={500}
                                                            readMoreText={" more ▼"}
                                                            readLessText={" less ▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                        >
                                                        {value.description}
                                                    </ReadMoreAndLess>
                                            </div>
                                        }

                                        {value.album_title &&
                                            <div>
                                            <b>Album </b><br />
                                            <div className="DateInline p-1 rounded">{value.album_title}
                                            </div> 
                                        </div>
                                        }

                                        {value.video && value.youtube &&
                                            <div className="border mt-3 mb-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                            
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="300"
                                                    height="300"
                                                />
                                            </div>
                                        }

                                        {value.video && value.youtube2 &&
                                            <div className="border mt-3 mb-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube2 }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="300"
                                                    height="300"
                                                /> 
                                            </div>
                                        }       
                                        
                                    </div>

                                        
                                </div>
                                
                        </div>
                    }
                    if (value.subject === 'songs') {
                        return <div className="row justify-content-center">
                                    {/* kolonne 1 */}
                                    <div className="col-sm-2 pb-2 pt-3 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                        {value.recorded_date &&
                                            <div className="alert-sm alert-primary text-center mb-1 
                                            rounded Song" align="center"><b>{value.recorded_date}</b>
                                            </div>
                                        }
                                        {value.rd_text &&
                                            <div className="alert-sm alert-primary text-center mb-1 
                                            rounded Song" align="center"><b>{value.rd_text}</b>
                                            </div>
                                        }

                                        {
                                            (() => {
                                            if (value.picture) {
                                                return <div align="center">
                                                            <div className='my-2'><img src={value.picture} 
                                                        alt="alt" className="img-fluid rounded" /> </div>
                                                    </div>
                                            }
                                            return <div align="center">
                                                    <div className='my-2'><img src={value.album_picture} 
                                                    alt="alt" className="img-fluid rounded" /> </div>
                                                    </div>
                                            })()
                                        }  

                                        <div className="alert-sm alert-primary text-center mb-1 rounded Song"><b>{value.title}</b></div>
                                    </div>
                                    {/* kolonne 2 */}
                                    <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">
                                        {value.description &&
                                            <div>
                                                <button type="button" className="btn btn-info btn-xs col-md-2 float-md-end mb-1 ms-md-2"><strong>Song</strong></button>
                                                    <div className="Description p-2 mb-2 border-bottom">

                                                    {
                                                        (() => {
                                                            if (!searchString) {
                                                                return  <div>
                                                                            <ReadMoreAndLess
                                                                                    charLimit={DescCharLimit}
                                                                                    readMoreText={" more ▼"}
                                                                                    readLessText={" less ▲"}
                                                                                    readMoreClassName="read-more-less--more"
                                                                                    readLessClassName="read-more-less--less"
                                                                                >
                                                                                {value.description}
                                                                            </ReadMoreAndLess>
                                                                            <div align="Right">-> <a href={value.wiki}>wikipedia</a></div>
                                                                        </div>
                                                                }
                                                            
                                                            return  <div>
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={searchWords}
                                                                            autoEscape={true}
                                                                            textToHighlight={value.description}
                                                                        />
                                                                    </div>
                                                            }
                                                        )()
                                                    }

                                                    </div>

                                                    <div className="row mt-2">
                                                        <div className="col-10 p-2 align-bottom">
                                                            {
                                                                (() => {
                                                                if (value.single_released || value.sr_text)  {
                                                                    return <div>
                                                                                <div className="row mt-2">
                                                                                    <div className="col Font14">
                                                                                    {
                                                                                        (() => {
                                                                                        if (value.album && value.album !== '<Single-only>') {
                                                                                        return <div><b>Album</b> <br />
                                                                                        <div className="DateInline px-1 rounded">{value.album} ({value.album_rd||value.ar_text})</div>
                                                                                        </div>
                                                                                    
                                                                                        } 
                                                                                        return 
                                                                                        })()
                                                                                    }
                                                                                    {
                                                                                        (() => {
                                                                                        if (!value.album && value.album_txt) {
                                                                                        return <div><b>Album</b> <br />
                                                                                        <div className="factText px-1">{value.album_txt}</div>
                                                                                        </div>
                                                                                        } 
                                                                                        return
                                                                                        })()
                                                                                    }
                                                                                    {
                                                                                        (() => {
                                                                                        if (value.album === '<Single-only>') {
                                                                                        return <div><b>Single</b></div>
                                                                                        } 
                                                                                        return
                                                                                        })()
                                                                                    }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                }
                                                                return <div>
                                                                            <div className="row mt-2">
                                                                                <div className="col Font14">
                                                                                    <b>Album: </b><br />
                                                                                    <div className="DateInline px-1 rounded">{value.album} ({value.album_rd||value.ar_text})</div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                    </div>
                                                                })()
                                                            } 
                                                        </div>
                                                    
                                                        <div className="col-2 p-2">
                                                            <div align="right">&nbsp;&nbsp;&nbsp;
                                                                <a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=30,height=30" /></a>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <div className="Font13"><strong>YouTube</strong></div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    
                                            </div>
                                        }

                                        {!value.description &&
                                            <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            
                                                <div className="border d-flex justify-content-center flex-nowrap">  
                                                    <ReactPlayer
                                                        url={ value.youtube }
                                                        controls
                                                        light='true'
                                                        volume={0.9}
                                                        width="380px"
                                                        height="200px"
                                                        /> 
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-2 p-2 align-bottom">
                                                        {
                                                            (() => {
                                                            if (value.single_released || value.sr_text)  {
                                                                return <button type="button" className="btn btn-info btn-xs"><strong>Song</strong></button>
                                                            }
                                                            return <button type="button" className="btn btn-info btn-xs"><strong>Song</strong></button>
                                                            })()
                                                        } 
                                                    </div>
                                                    <div className="col-10 Font14">
                                                        <b>Album: </b><br />
                                                        <div className="DateInline px-1 rounded">{value.album} ({value.album_rd||value.ar_text})</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                    }
                    if (value.subject === 'albums') {
                        return <div className="row justify-content-center">
                                    {/* kolonne 1 */}
                                    <div className="col-sm-2 pb-2 pt-3 border-left-10 border-top-10 border-bottom-10 border-right-10 rounded">
                                        {
                                            (() => {
                                            if (value.album_released) {
                                            return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.album_released}</b>
                                                    </div>
                                            }
                                            return <div className="alert-sm alert-primary text-center mb-1 rounded Song" align="center"><b>{value.ar_text}</b>
                                                    </div>
                                            })()
                                        }

                                        <div align="center">
                                            <div className='my-2'><img src={value.picture} alt="alt" className="img-fluid rounded" /> </div>
                                        </div>

                                        <div className="alert-sm alert-primary text-center mb-1 rounded Song"><b>{value.title}</b></div>
                                        <div align="center"><b>{value.artist}</b></div>
                                    </div>
                                    
                                    {/* kolonne 2 */}
                                    <div className="col-sm-4 py-2 border-top-10 border-bottom-10 border-right-10 rounded">
                                        
                                        {
                                            (() => {
                                            if (value.description) {
                                                return <div>
                                                            <div className="Description p-2 mb-2 border-bottom">
                                                            <button type="button" className="btn btn-info btn-xs col-md-2 float-md-end mb-1 ms-md-2"><strong>Album</strong></button>

                                                            {
                                                                (() => {
                                                                    if (!searchString) {
                                                                        return  <div>
                                                                                    <ReadMoreAndLess
                                                                                            charLimit={DescCharLimit}
                                                                                            readMoreText={" more ▼"}
                                                                                            readLessText={" less ▲"}
                                                                                            readMoreClassName="read-more-less--more"
                                                                                            readLessClassName="read-more-less--less"
                                                                                        >
                                                                                        {value.description}
                                                                                    </ReadMoreAndLess>
                                                                                </div>
                                                                        }
                                                                
                                                                    return  <div>
                                                                                <Highlighter
                                                                                    highlightClassName="YourHighlightClass"
                                                                                    searchWords={searchWords}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={value.description}
                                                                                />
                                                                            </div>
                                                                    }
                                                                )()
                                                            }
                                                                
                                                                <div align="Right">-> <a href={value.wiki}>wikipedia</a></div>
                                                            </div>
                                                            
                                                        </div>
                                            }
                                            return 
                                            })()
                                        }

                                       
                                        <div>
                                            <div className="row mt-2">
                                                <div className="col-8 ps-2 align-bottom">
                                                    
                                                    <div className="row mt-2">
                                                        
                                                        <div className="col Font14">
                                                            <b>Genre: </b><br />
                                                            <div className="DateInline px-1 rounded">{value.genre}</div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-2 p-1" align="Right">
                                                        <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                        <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                                        &nbsp;&nbsp;
                                                        <div className="Font13"><strong>Spotify</strong></div>
                                                </div>
                                                <div className="col-2 p-1" align="Left">
                                                        <div>&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                        <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=30,height=30" /></a>
                                                        <div className="Font13"><strong>YouTube</strong></div>
                                                        </div>  
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                    return 
                    })()
                }

                {(categoryArray.length - 1) !== index &&
                    <div className="row">
                        <div className="col">
                            <div className="text-center timeline-break my-2">&#8595;</div>
                        </div>
                    </div>
                }
                
            </React.Fragment>
        )
    })
    

    //REFERENCES
    const listReferences = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>

                {
                    (() => {
                    if (value.book_title) {
                    return  <div className="row px-1 my-1">
                                <div className="col">
                                    <div>{value.author_txt} ({value.published_year}).
                                    <a href={value.more_info}> {value.book_title}</a>. {value.publisher}. (book) </div>
                                </div>
                            </div>
                    } if (value.person && !value.date_txt) {
                    return <div className="row px-1 my-1">
                            <div className="col">
                                <a href={value.url}>
                                    {value.title}
                                </a> ({value.person})
                            </div>
                        </div>
                    } if (!value.person && value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> ({value.date_txt})
                                    </div>
                                </div>
                    }
                    if (!value.person && !value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> 
                                    </div>
                                </div>
                    }
                    return <div className="row px-1 my-1">
                                <div className="col">
                                    {value.person} ({value.date_txt}). <a href={value.url}>
                                        {value.title}
                                    </a>
                                </div>
                            </div>
                    })()
                }

                
            </React.Fragment>
        )
    })


    //console.table(historyItems);
    //console.table(interviewItems);
    //console.table(sortedTimelineArray);
    //console.log(sortedTimelineArray.length);
    
    return (
        <main>
            {sortedTimelineArray.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {TimelineHeading}
                        </div>
                    </div>
                    
                    
                     {TimeLine} 
                    


                    {references.length > 0 &&
                    <div>
                            <div className="row mt-4">
                                <div className="col">
                                        <h4>References</h4>
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <b>
                                    <a href='https://en.wikipedia.org'>
                                        Wikipedia
                                    </a>
                                    </b>
                                </div>
                            </div>
                        
                            <div className="row mt-2">
                                <div className="col">
                                        { listReferences }
                                </div>
                            </div>
                    </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>    


                </div>
            )}
        </main>
    )
}

export default BobDylanTimelineYear