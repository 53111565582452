import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlQuotesMTD, dbURLists } from '../Constants';
import '../lists.scss';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
//import Select from 'react-select';
//import { Link } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import SEO from "../../SEO";
import {useParams} from 'react-router-dom';

const QuoteLists = (props) => {
    const [quotes, setQuotes] = useState([])
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [searchStringDescription, setSearchStringDescription] = useState('')

    const params = useParams();

    let filterQuotes, searchWords, searchWordsDescription
    
    useEffect(() => {
        const fetchQuotes = () => {
          axios.get(dbUrlQuotesMTD + params.slug)
          .then(response => {
            setLoading2(false)
            setQuotes(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }
        fetchList()
        fetchQuotes()
      }, [params.slug])
  
    if (error) return `Error: ${error.message|errorList.message}`;


    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
     const handleSearchChangeDescription = (e) => {
        setSearchStringDescription(e.target.value);
      };
    
    
    const clearSearchField = (e) => {
        setSearchString('')
        }
    
    const clearSearchFieldDescription = (e) => {
        setSearchStringDescription('')
        }
    
    function SearchQuotes(searchText) { 
        let searchArray = [];
        let searchQuote;
        
        for (let i = 0; i < quotes.length; i++) {

            searchQuote = quotes[i].quote.toLowerCase();

            if (searchQuote.includes(searchText))
            
            //if (quotes[i].quote.includes(searchText))
            
                {searchArray.push(quotes[i]);}
        } 
        return searchArray
    }

    function SearchQuotesDescription(searchText) { 
        let searchArray = [];
        let searchQuoteDescriction;
        
        for (let i = 0; i < quotes.length; i++) {

            searchQuoteDescriction = quotes[i].comment.toLowerCase();

            if (searchQuoteDescriction.includes(searchText))
            
            //if (quotes[i].quote.includes(searchText))
            
                {searchArray.push(quotes[i]);}
        } 
        return searchArray
    }


    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };


    if (searchString) {
        searchWords = searchString.split(/\s/).filter(word => word)
        filterQuotes = SearchQuotes(searchString.toLowerCase());
        } 
    if (searchStringDescription) {
        searchWordsDescription = searchStringDescription.split(/\s/).filter(word => word)
        filterQuotes = SearchQuotesDescription(searchStringDescription.toLowerCase());
    }
    if (!searchString && !searchStringDescription) {
        filterQuotes = quotes;
    }

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, pic_link, page, no_filters, single_artist} = list[0];

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>
            
                <div className="row mb-3 gx-5">
                    <div className="col-sm-4">

                        <div className='mt-2'><img src={value.picture_link} alt="alt" className="img-fluid" /> </div>
                        
                        {!no_filters &&
                            <div className="row">   
                                    <div className="col">
                                        <h4>Search</h4>
                                </div>
                            </div>
                        }
                        {(!searchStringDescription && !no_filters) &&
                        <div className="row mb-1">  
                            <div className="col-sm-5 mt-1">
                                <div className="search">    
                                    <DebounceInput
                                        //type="number"
                                        minLength={2}
                                        debounceTimeout={500}
                                        value={searchString}
                                        onChange={handleSearchChange}
                                        placeholder="Search all quotes" 
                                    />
                                </div>
                            </div>
                            <div className="col-sm-7 mt-1">
                                <button type="button" className="btn btn-info"  
                                    onClick={() => clearSearchField()} > Reset </button>
                            </div>
                        </div>
                        }
                        {(!searchString && !no_filters) &&
                        <div className="row">  
                            <div className="col-sm-5 mt-1">
                                <div className="search">    
                                    <DebounceInput
                                        //type="number"
                                        minLength={2}
                                        debounceTimeout={500}
                                        value={searchStringDescription}
                                        onChange={handleSearchChangeDescription}
                                        placeholder="Search all descriptions" 
                                    />
                                </div>
                            </div>
                            <div className="col-sm-7 mt-1">
                                <button type="button" className="btn btn-info"  
                                    onClick={() => clearSearchFieldDescription()} > Reset </button>
                            </div>
                        </div>
                        } 
                    </div>
                    <div className="col-sm-8 Description">
                            <div>
                                <h2 align='center'>{value.name}</h2>
                                <div className="SubTitle pl-3 py-1 mb-2">{value.sub_title}</div>
                            </div>
                            <div className="p-3 border bg-light">
                                {value.description} 
                            </div>
                            <div className="d-flex p-2">
                                {((window.location.hostname === "localhost") || (value.in_progress)) &&
                                    <div className="text-center">
                                        <div className="btn btn-light btn-sm mt-2"><b>In progress</b></div>
                                    <div className="text-center">
                                        <div className="btn btn-info btn-sm mt-2">total count: {quotes.length}</div>
                                    </div> 
                                    </div>
                                    } 
                            </div>
                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const QuoteItems = filterQuotes.map(value => {

        let dead, born
        if (value.dead_date||value.dd_text) {
            dead = " - ";
            born = "(";
        }
        else {
            born = "(Born: ";
        }

        return (

            <React.Fragment key={value.id}>
                <div className="row pt-3 pb-3 border-bottom border-top border-info">

                    {/* kolonne 1 */}
                    <div className="col-sm-7">
                     

                         <div className='m-2'>

                            <div className="card">

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        {
                                                (() => {
                                                if (value.quote_picture) {
                                                return  <div className='p-4'>
                                                            <img src={value.quote_picture} alt="" height={value.pic_height|'250'} width={value.pic_height|'250'}
                                                            className="rounded mx-auto d-block img-fluid"></img>
                                                        </div>
                                                }
                                                if (!value.quote_picture && value.picture_link && !value.album_cover_link) {
                                                    return  <div className='p-4'>
                                                            <img src={value.picture_link} alt="" height={value.pic_height|'250'} width={value.pic_height|'250'}
                                                            className="rounded mx-auto d-block img-fluid"></img>
                                                        </div>
                                                    }
                                                return <div className='p-4'>
                                                            <img src={value.album_cover_link} alt="" height={value.pic_height|'250'} width={value.pic_height|'250'}
                                                            className="rounded mx-auto d-block img-fluid"></img>
                                                        </div>
                                                })()
                                            }
                                    </div>
                                    <div className='col-sm-8'>
                                        <div className="card-body mt-4"> 

                                            {
                                                (() => {
                                                if (value.old_font && !searchString) 
                                                return <div className="QuoteOld">&nbsp;&nbsp;&nbsp;{value.quote}</div> 
                                                if (value.old_font && searchString)
                                                return <div className="QuoteOld">
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={searchWords}
                                                                autoEscape={true}
                                                                textToHighlight={value.quote}
                                                            />
                                                        </div> 
                                                if (!value.old_font && !searchString)
                                                return <div className="quote4">{value.quote}</div> 
                                                if (!value.old_font && searchString)
                                                return <div className="quote4">
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={searchWords}
                                                                autoEscape={true}
                                                                textToHighlight={value.quote}
                                                            />
                                                        </div> 
                                                })()
                                            }

                                            {!single_artist &&
                                            <div>
                                                <div className="card-title mt-3">{value.author}</div>

                                                {
                                                    (() => {
                                                    if (value.author && (value.birth_date || value.bd_text)) {
                                                    return <div className="SmallText">
                                                                {born}{value.bd_text||value.birth_date}
                                                                {dead}
                                                                {value.dd_text||value.dead_date})
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                                }
                                            </div>
                                            }

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="mt-2">
                                        {
                                            (() => {
                                            if (value.source_txt) {
                                            return <div className="CleanDate"> 
                                                        <b>&nbsp; Source: </b>{value.source_txt}
                                                        {
                                                            (() => {
                                                            if (value.interview_date||value.date_txt) {
                                                                return <span>&nbsp; ({value.interview_date||value.date_txt})
                                                                            </span>
                                                                }
                                                            if (value.year||value.year_txt) {
                                                            return <span>&nbsp; ({value.year||value.year_txt})
                                                                        </span>
                                                            }
                                                            return 
                                                            })()
                                                        }
                                                    </div>
                                            } if (value.song_title && value.album_title) {
                                                return <div className="CleanDate"> 
                                                        <b>&nbsp; Song: </b>{value.song_title}
                                                        {
                                                            (() => {
                                                            if (value.first_recorded||value.rd_date) {
                                                                return <span>&nbsp; ({value.first_recorded||value.rd_date})
                                                                            </span>
                                                                }
                                                            if (value.rd_text && !value.first_recorded) {
                                                                return<span>&nbsp; ({value.rd_text})
                                                                </span>
                                                            }
                                                            return <span>&nbsp; ({value.year||value.year_txt})
                                                            </span>
                                                            })()
                                                        }
                                                        <div><b>&nbsp; Album: </b>{value.album_title}</div>
                                                    </div>
                                            }
                                            return 
                                            })()
                                        }
                                
                            </div>
                        
                        </div>
                        

                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-5 p-2 Description">

                        <div className="row">
                            <div className="col py-2 Description">

                                {
                                    (() => {
                                    if (value.comment && !searchStringDescription) {
                                    return <div className='mx-3'>
                                                <ReadMoreAndLess
                                                    charLimit={800}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.comment}
                                                </ReadMoreAndLess> 
                                            </div>
                                    }
                                    if (value.comment && searchStringDescription) {
                                    return <div className='mx-3'>
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={searchWordsDescription}
                                                    autoEscape={true}
                                                    textToHighlight={value.comment}
                                                />
                                            </div>
                                    } if (!value.comment && value.comment_quote) {
                                        return <div className="card bg-light pt-2 px-2">
                                                <div className="quote13 mb-2">{value.comment_quote}</div>
                                                <div className="blockquote-footer pb-2"><i>{value.comment_quote_source}</i></div>
                                            </div>
                                    }
                                    return 
                                    })()
                                }

                                {
                                    (() => {
                                    if (value.q_more_info) {
                                    return <p className="Description"> 
                                                <br /> <em> -> <a href={value.q_more_info}>more info..</a></em>
                                            </p>
                                    }
                                    return 
                                    })()
                                }
                                

                            </div>
                        </div>

                    </div>

                </div>

            </React.Fragment>

        )

    })
    
    return (
        <main className="my-1 py-1">
            {quotes.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.musicthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {QuoteItems}
                        </div>
                    </div>

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default QuoteLists