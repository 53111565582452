import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDaySongsDylan } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";

const AnyDaySongsDylan = (props) => {
  const [songs, setSongs] = useState([])
  //const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchSongs = () => {
        axios.get(dbURLAnyDaySongsDylan + props.dateSelected)
        .then(response => {
          //setIsLoading(false)
          setSongs(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchSongs()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {songs.length > 0 && (
        <div id="songs">
            <div className="row TodayTitle mt-4">
                <div className="col-sm-12"> 
                    <div>&nbsp;<b>Songs</b>
                    </div>
                </div>
            </div>
          {songs.map(value => (
            <div key={value.id}>

                <div className="row pt-3 pb-3 border-bottom border-top border-info">
                
                    {/* 1 kolonne */}
                    <div className="col-sm-2 text-center">
                        
                        <div className='Song mb-1 px-1'><b>{value.title}</b></div> 
                                
                        <div align="center">
                            {
                                (() => {
                                if (value.picture) {
                                    return <div align="center"><img src={value.picture} 
                                            alt="" height="150" width="150" className="rounded mx-auto d-block">
                                            </img></div>
                                    }
                                if (value.album_picture) {
                                    return <div align="center"><img src={value.album_picture} 
                                        alt="" height="150" width="150" className="rounded mx-auto d-block">
                                        </img></div>
                                    }
                                return 
                                })()
                            }

                            <b>Songwriter</b> <br />
                            <div className="factText px-1 mb-1">{value.songwriter}</div>
                            
                            {
                                (() => {
                                if (value.youtube) {
                                    return <div>
                                            <div className='mt-3' align='center'><b>Click to play</b></div>
                                            <div className="mt-1 border">
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="280"
                                                            height="200"
                                                />
                                            </div>
                                            </div>
                                }
                                return 
                                })()
                            }
                            {
                                (() => {
                                if (value.cover_version) {
                                    return <div><button type="button" className="btn btn-info btn-xs mt-2">Cover version</button>
                                    <br /><br />
                                    </div>
                                }
                                return 
                                })()
                            }
                                            
                            {
                                (() => {
                                if (value.main_composer) {
                                    return <div>
                                            <b>Main writer</b> <br />
                                            <div className="factText">{value.main_composer}</div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.original_performer) {
                                    return <div>
                                            <b>Original performer</b> <br />
                                            <div className="factText">{value.original_performer}</div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.youtube_2) {
                                    return <div className="mt-3 border">
                                        <div><b>Live version</b>
                                            <ReactPlayer
                                                    url={ value.youtube_2 }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="250"
                                                            height="250"
                                                />
                                        </div>
                                        <div className='factText2 mt-4'>
                                            {value.live_version_comment}
                                        </div>
                                </div>
                                }
                                return 
                                })()
                            }
                            
                        </div>

                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-5 pb-1 border-right Description">

                        <div className="row">
                            <div className="col py-2 border-right Description">

                                {
                                (() => {
                                if (value.description) {
                                return  <div>
                                            <ReadMoreAndLess
                                                    charLimit={value.desc_selected_songs|500}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description}
                                            </ReadMoreAndLess>
                                            <div className="Description"> 
                                                <br /> <em> -> <a href={value.wiki}>more info..</a></em>
                                                </div>
                                    </div>
                                }
                                if (!value.description && value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <div className="quote3 mb-3">{value.comment}</div>
                                                    {
                                                        (() => {
                                                                if (value.date_txt) {
                                                                    return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                        })()

                                                    }
                                        </div>
                                    }
                                    return 
                                    })()
                                }
                            </div>

                        </div>

                        <div className="row pt-1">
                            
                            {
                                (() => {
                                    if (value.lyrics) {
                                        return  <div className="bg-light ml-2 mr-2">
                                                    <div className="py-2 px-2">
                                                        <ReadMoreAndLess
                                                                charLimit={value.lyrics_selected_songs|400}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.lyrics}
                                                        </ReadMoreAndLess>
                                                
                                                    </div>
                                                </div>
                                        }
                                    return <p>No lyrics..yet.</p>
                                    }
                                )()
                            }
                                                            
                                                            
                        </div>   

                                                    
                        <div className='row mt-2'>
                            <div className='col-12'>
                                {
                                    (() => {
                                    if (value.comment_2 && value.description) {
                                    return  <div className="card bg-light py-2 px-2 mt-2">
                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                
                                                {
                                                    (() => {
                                                            if (value.date_2) {
                                                                return <div className="blockquote-footer"><i>{value.people_txt2} ({value.date_2})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.people_txt2}</i></div>
                                                    })()
                                                }
                                        
                                            </div>
                                    } 
                                    return
                                    })()
                                }
                            </div>
                        </div>
                            
                    </div>               
                    
                        
                    {/* 3 kolonne */}
                    <div className="col-sm-5">

                        <div className="row">
                        
                            <div className="col-sm-7">
                                    <div>
                                        
                                        {
                                            (() => {
                                            if (value.recorded) {
                                            return <div><b>Recorded </b><br />
                                                <div className="Date">{value.recorded}</div>
                                                </div>
                                            }
                                            if (!value.recorded && value.rd_text) {
                                            return <div><b>Recorded </b><br />
                                                    <div className="Date">{value.rd_text}</div>
                                                    </div>
                                            }
                                            return
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.studio_txt) {
                                            return <div>
                                                        <b>Location</b> <br />
                                                        <div className="factText">{value.studio_txt}</div>
                                                        
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.producer) {
                                            return <div>
                                                        <b>Producer</b> <br />
                                                        <div className="factText">{value.producer}</div>
                                                        
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                    
                                        {
                                            (() => {
                                            if (value.engineer) {
                                            return <div><b>Engineer</b> <br />
                                            <div className="factText">{value.engineer}</div>
                                            
                                            </div>
                                        
                                            } 
                                            return
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.line_up && !value.engineer && !value.producer && !value.studio_txt && !value.recorded && !value.rd_text) {
                                            return <div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <b>Probable line-up</b> <br />
                                                            <div className="factText px-2">
                                                                <ReadMoreAndLess
                                                                    charLimit={value.line_up_songs}
                                                                    readMoreText={" Read more ▼"}
                                                                    readLessText={" Read less ▲"}
                                                                    readMoreClassName="read-more-less--more"
                                                                    readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.line_up}
                                                                </ReadMoreAndLess>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            } 
                                            return
                                            })()
                                        }

                                        
                                    </div>

                            </div>

                            <div className="col-sm-5">
                                    
                                    
                                    {
                                            (() => {
                                            if (value.single_released) {
                                            return <div>
                                                        <b>Single released</b> <br />
                                                        <div className="Date">{value.single_released}</div>
                                                    </div>
                                            } else if (value.sr_text) {
                                            return <div>
                                                        <b>Single released</b> <br />
                                                        <div className="Date">{value.sr_text}</div>
                                                    </div>
                                            }
                                            return 
                                            })()
                                    }

                                    {
                                        (() => {
                                        if (value.album) {
                                        return <div><b>Album</b> <br />
                                        <div className="factText">{value.album}</div>
                                        <div className="Date"><i>{value.album_rd||value.ar_text}</i></div>
                                        </div>
                                    
                                        } 
                                        return 
                                        })()
                                    }
                                    {
                                        (() => {
                                        if (!value.album && value.album_txt) {
                                        return <div><b>Album</b> <br />
                                        <div className="factText">{value.album_txt}</div>
                                        </div>
                                        } 
                                        return 
                                        })()
                                    }
                                    {
                                        (() => {
                                        if (value.label) {
                                        return <div><b>Label</b> <br />
                                        <div className="factText">{value.label}</div>
                                        </div>
                                    
                                        } 
                                        return 
                                        })()
                                    }

                                    {
                                        (() => {
                                        if (value.performed_onstage && value.times_live) {
                                        return <div><b>Performed onstage</b> <br />
                                        <div className="factText">{value.performed_onstage}</div>
                                        <div className="factText"><i>-> <b>{value.times_live}</b> times</i></div>
                                        <div className="factText"><b>First time:</b> {value.first_live} <br /> 
                                        <b>Last time:</b> {value.last_live}</div>
                                    
                                        </div>
                                        } 
                                        if (value.performed_onstage && !value.times_live) {
                                            return <div><b>Performed onstage</b> <br />
                                            <div className="factText">{value.performed_onstage}</div>
                                            
                                            </div>
                                        } 
                                        if (!value.performed_onstage && value.times_live) {
                                            return <div><b>Performed onstage</b> <br />
                                            <div className="factText"><i>-> <b>{value.times_live}</b> times</i></div>
                                            <div className="factText"><b>First time:</b> {value.first_live} <br /> 
                                            <b>Last time:</b> {value.last_live}</div>
                                            
                                            </div>
                                        } 

                                        return
                                        })()
                                    }

                                    {
                                        (() => {
                                        if (value.outtake && !value.live_version) {
                                            return <div className='mt-2'>
                                                    <button type="button" className="btn btn-info btn-xs">Outtake/Demo</button>
                                                    </div>
                                        }
                                        if (value.live_version && value.outtake) {
                                            return <div><button type="button" className="btn btn-info btn-xs mb-1">Live</button><br />
                                            <button type="button" className="btn btn-info btn-xs">Outtake</button>
                                            </div>
                                        }
                                        if (value.live_version && value.alt_version) {
                                            return <div><button type="button" className="btn btn-info btn-xs">Live</button><br />
                                            <button type="button" className="btn btn-info btn-xs">Alt. version</button>
                                            </div>
                                        }
                                        if (value.live_version && !value.outtake) {
                                            return <button type="button" className="btn btn-info btn-xs">Live</button>
                                        }
                                        if (value.alt_version) {
                                            return <button type="button" className="btn btn-info btn-xs">Alt. version</button>
                                        }
                                        return 
                                        })()
                                    }
                            
                            </div>

                        </div>
                        {
                            (() => {
                            if (value.line_up && (value.engineer || value.producer || value.studio_txt 
                                || value.recorded || value.rd_text)) {
                            return  <div>
                                        <div className="row pt-3">
                                            <div className="col-12">
                                                <b>Probable line-up</b> <br />
                                                <div className="factText px-2">
                                                    <ReadMoreAndLess
                                                        charLimit={value.line_up_songs|500}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                        >
                                                        {value.line_up}
                                                    </ReadMoreAndLess>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            } 
                            return
                            })()
                        }

                        <div className="row pt-3">   
                            <div className="col-12">

                                {
                                    (() => {
                                        if (value.comment && value.description) {
                                            return <div className="card bg-light p-2 mb-2">
                                                        <div className="quote3 mb-3">{value.comment}</div> 
                                                        
                                                        {
                                                            (() => {
                                                                    if (value.date_txt) {
                                                                        return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                    }
                                                                    return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                            })()

                                                        }

                                                    </div>
                                            }
                                
                                    })()
                                }
                                
                                {
                                    (() => {
                                    if (value.comment_2 && !value.description) {
                                    return  <div className="card bg-light py-2 px-2 mt-2">
                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                
                                                {
                                                    (() => {
                                                            if (value.date_2) {
                                                                return <div className="blockquote-footer"><i>{value.people_txt2} ({value.date_2})</i></div>
                                                            }
                                                            return <div className="blockquote-footer"><i>{value.people_txt2}</i></div>
                                                    })()
                                                }
                                        
                                            </div>
                                    } 
                                    return
                                    })()
                                }
                            
                                {
                                    (() => {
                                    if (value.comment_3) {
                                    return <div className='mt-3'>
                                                <div className="card bg-light p-2 my-2">
                                                    <div className="quote3 mb-3">{value.comment_3}</div> 
                                                    {
                                                        (() => {
                                                                if (value.date_3) {
                                                                    return <div className="blockquote-footer"><i>{value.people_txt3} ({value.date_3})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.people_txt3}</i></div>
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                    } 
                                    return 
                                    })()
                                }
                                
                            </div>
                        </div> 
                    
                    </div>
                                
                </div>
                  
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AnyDaySongsDylan
