import React, { useState } from "react";
import '../lists.scss';
import { Link } from "react-scroll";
import AnyDayAlbumsDylan from "./AnyDayAlbumsDylan";
import AnyDayConcertsDylan from "./AnyDayConcertsDylan";
import AnyDaySongsDylan from "./AnyDaySongsDylan";
import AnyDaySessionsDylan from "./AnyDaySessionsDylan";
import AnyDayPeopleDylan from "./AnyDayPeopleDylan";
import AnyDayHistoryDylan from "./AnyDayHistoryDylan";
import dayjs from "dayjs";
import { animateScroll as scroll } from "react-scroll";
import SEO from "../../SEO";


const DylanAnyDay = () => {

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const today = dayjs().format('YYYY-MM-DD');
    const todayFormated = dayjs(today).format('MM-DD');
    const todayMonth = dayjs(today).format('MM');
    //console.log('todayMonth: ' + todayMonth)
    const todayDay = dayjs(today).format('DD');

    const Days31 = ['01', '03', '05', '07', '08', '10', '12'];

    //const [daySelected, setDaySelected] = useState(todayDay);
    const [monthSelected, setMonthSelected] = useState(todayMonth);
    const [dateSelected, setdateSelected] = useState(todayFormated);

    const setMonthSelectedEvent = (event) => {
        setMonthSelected(event.target.value)
        //setdateSelected(event.target.value + '-' )
        //setdateSelected(event.target.value + '-' + daySelected)
        //document.getElementById("daySelector").focus();
        document.getElementById("daySelector").value = "DEFAULT";
    }

    const setDaySelectedEvent = (event) => {
        //setDaySelected(event.target.value)
        setdateSelected(monthSelected + '-' + event.target.value)
    }

    const MonthString = dateSelected.slice(0, 2);
    const DayString = dateSelected.slice(-2);
    let Month
    if (MonthString === '01') {
        Month = 'January'
    }
    if (MonthString === '02') {
        Month = 'February'
    }
    if (MonthString === '03') {
        Month = 'March'
    }
    if (MonthString === '04') {
        Month = 'April'
    }
    if (MonthString === '05') {
        Month = 'May'
    }
    if (MonthString === '06') {
        Month = 'June'
    }
    if (MonthString === '07') {
        Month = 'July'
    }
    if (MonthString === '08') {
        Month = 'August'
    }
    if (MonthString === '09') {
        Month = 'September'
    }
    if (MonthString === '10') {
        Month = 'October'
    }
    if (MonthString === '11') {
        Month = 'November'
    }
    if (MonthString === '12') {
        Month = 'December'
    }

    const FormatedDay = Month + ' ' + DayString

    const Heading = <div>
                        {/* HEADER row */}
                        <div className="row">
                                
                                {/* kolonne 1 */}
                                <div className="col-sm-3 mb-3">
                                    
                                        <img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/3481186e-cdd2-4e80-13b8-9d4913aa7000/public'
                                    alt="alt" className="img-fluid rounded mx-auto d-block" /> 
                                    
                                </div>
                                
                                {/* kolonne 2 */}
                                <div className="col-sm-6">
                                    
                                    <h3>Bob Dylan - Any Day</h3>
                                    <div>
                                        Albums, Songs, Concerts, People & historic events.
                                    </div>
                                    <div className="mt-4"><b>Most important references and sources</b></div>
                                    <div className="Description">
                                        <ul>
                                            <li><a href="https://en.wikipedia.org/wiki/Bob_Dylan">
                                                Bob Dylan at Wikipedia</a></li>
                                            <li><a href="http://www.bjorner.com/still.htm">
                                            Still On The Road - Olof Bjørner</a></li>
                                            <li><a href="https://www.amazon.com/Bob-Dylan-Encyclopedia-Revised-Updated/dp/0826429742/ref=sr_1_1?crid=3DZNERRF6FNNE&dchild=1&keywords=The+Bob+Dylan+Encyclopedia+-+Michael+Gray&qid=1635791598&qsid=140-0184500-0552065&sprefix=the+bob+dylan+encyclopedia+-+michael+gray%2Caps%2C161&sr=8-1&sres=0826429742%2C0826469337%2CB09DW86258%2CB002AFECOI%2CB01NCEELHW%2CB01LA746D8%2CB01N5CS28J%2CB01MF9DJCG%2CB01MV4I26D%2CB007QI2C28%2CB007RE9V0M%2CB01NALI5TP%2CB01N5QBUUT%2CB01MXVM8BC%2CB01MRDNCF1%2CB0084FH4FC&srpt=ABIS_BOOK">
                                            The Bob Dylan Encyclopedia - Michael Gray</a></li>
                                            <li><a href="https://www.amazon.com/Bob-Dylan-Stolen-Moments-1941-1995/dp/0028646762/ref=sr_1_1?crid=15J3PK4NJOEUR&dchild=1&keywords=bob+dylan+stolen+moments&qid=1635791643&qsid=140-0184500-0552065&sprefix=bob+dylan+stolen+moment%2Caps%2C180&sr=8-1&sres=0028646762%2C0947730060%2CB07CG6MJZM%2CB08VY6Q9N3%2CB01BYG6U8Y%2CB07Y2HJDCH%2CB001U9DF66%2CB06XGV9N18%2CB008YZ7QEG%2CB0049FDIR4%2CB001DQI6VU%2CB011D1DK9C%2CB0085NW30O%2CB09HWR2ZYF%2CB07YLSYRYF%2CB07D21QJTY">
                                            Bob Dylan: A Life in Stolen Moments Day by Day 1941-1995 - Clinton Heylin</a></li>
                                        </ul>

                                    </div>
                                    <div className="row my-2 p-2">
                                        <div className="col-sm-4">
                                        <b>Select month:</b><br />
                                            <select defaultValue={todayMonth} onChange={setMonthSelectedEvent}>
                                                <option value="{todayMonth}" disabled>Choose a Month ...</option>
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-8">
                                        <b>Select day:</b><br />
                                            <select id="daySelector" defaultValue={todayDay} onChange={setDaySelectedEvent}>
                                                <option value="DEFAULT" disabled>Choose a day...</option>
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                {(monthSelected !== '02') && <option value="30">30</option>}
                                                {Days31.some(el => monthSelected.includes(el)) && <option value="31">31</option>}
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                {/* kolonne 3 */}
                                <div className="col-sm-3 mb-1">
                                    
                                    <h3>Content</h3>

                                    <ul>
                                        <li>   
                                            <Link  to="albums" spy={true} smooth={true}>
                                            <b><u>Albums</u></b>
                                            </Link>
                                        </li>
                                        <li>   
                                            <Link  to="songs" spy={true} smooth={true}>
                                            <b><u>Songs</u></b>
                                            </Link>
                                        </li>
                                        <li>   
                                            <Link  to="sessions" spy={true} smooth={true}>
                                            <b><u>Recording sessions</u></b>
                                            </Link>
                                        </li>
                                        <li>   
                                            <Link  to="concerts" spy={true} smooth={true}>
                                            <b><u>Concerts</u></b>
                                            </Link>
                                        </li>
                                        <li>   
                                            <Link  to="people" spy={true} smooth={true}>
                                            <b><u>People</u></b>
                                            </Link>
                                        </li>
                                        <li>   
                                            <Link  to="history" spy={true} smooth={true}>
                                            <b><u>Historic events</u></b>
                                            </Link>
                                        </li>
                                    </ul>
                                </div> 
                                
                        </div>
                        
                        <div className="row videoOfTheDayTitle mb-1">
                            <div className="col-sm-12"> 
                                <div className="text-center"><b> {FormatedDay} </b></div>
                                
                                {/* <div className="text-center"><b>dateSelected: {dateSelected}</b></div> */}
                            </div>
                        </div>
                    </div>

  return (
     <main className="my-1 py-1">
        <div className="container">
            <SEO
                title={'Bob Dylan - Any Day'}
                description={'Select a day of the year (default is today), and info on Albums, Songs, Concerts, People & historic events.'}
                name={'Bob Dylan - Any Day'}
                picture_link={'https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/3481186e-cdd2-4e80-13b8-9d4913aa7000/public'}
                url={'https://www.musicthisday.com/dylananyday'}
                type='article' />
            {Heading}

            {/* Summary History */}
            <AnyDayHistoryDylan
                dateSelected = {dateSelected}
                />

            {/* ALBUMS */}
            <AnyDayAlbumsDylan 
                dateSelected = {dateSelected}
                />
            
            {/* SONGS */}
            <AnyDaySongsDylan
                dateSelected = {dateSelected}
                />
            
            {/* SESSIONS */}
            <AnyDaySessionsDylan
                dateSelected = {dateSelected}
                />

            {/* CONCERTS */}
            <AnyDayConcertsDylan 
                dateSelected = {dateSelected}
                />

            {/* PEOPLE */}
            <AnyDayPeopleDylan
                dateSelected = {dateSelected}
                />

            <div align="Right"><br />
                <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                    <b>Scroll to top</b>
                </button>
            </div>
        </div>
    </main> 
    
    
  )
}

export default DylanAnyDay
