import React, { useEffect, useState } from "react"
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import Select from 'react-select';
import { dbURLists, customStyles2, dbURLSongsLive } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import ReactPlayer from "react-player";
import { useParams, Link } from 'react-router-dom';
import SEO from '../../SEO';
import { SearchLists, SelectProperty, ImageWithFallback } from "../misc/utils";

const SongsLiveLists = () => {
    const [songs, setSongs] = useState([])
    const [list, setList] = useState([])
    const [loadingSongs, setLoadingSongs] = useState(true)
    const [loadingList, setLoadingList] = useState(true)
    const [errorSongs, setErrorSongs] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [optionYear, setOptionYear] = useState(null)
    const [optionAlbum, setOptionAlbum] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [searchStringLyrics, setSearchStringLyrics] = useState('')
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
        {headerName: "Song", field: "title", resizable: true, width: 300, cellStyle: { 'font-size': '14px' }},
        {headerName: "Date", field: "gig_date", filter: true, sortable: true, width: 165, cellStyle: { 'font-size': '14px' }},
        {headerName: "Venue", field: "venue", sortable: true, width: 260, cellStyle: { 'font-size': '14px' }},
        {headerName: "City", field: "city", sortable: true, width: 260, cellStyle: { 'font-size': '14px' }},
        {headerName: "Country", field: "country", sortable: true, width: 200, cellStyle: { 'font-size': '14px' }}
      ])
    
    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setLoadingList(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchSongs = () => {
            axios.get(dbURLSongsLive + params.slug)
            .then(response => {
                setLoadingSongs(false)
                setSongs(response.data)
            }).catch(errorSongs => {
                setErrorSongs(errorSongs);
            });
        }

        fetchList()
        fetchSongs()
      }, [params.slug])

    if (errorList) return `Error List: ${errorList.message}`;
    if (errorSongs) return `Error Songs: ${errorSongs.message}`;

    if (loadingList || loadingSongs) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const {name, description, picture_link, page, hide_grid_option, ranked_list} = list[0];

    //En del felles variable til songs visning
    const {lyrics, performed_onstage, times_live, first_live, last_live, no_alt_live_version} = songs[0];

    let ListPic, ListPic2, ManyVersionsSameSong, filterArray, searchWords, YearOptions, 
    songsWithLyrics, AlbumOptions, sortedSongs

    YearOptions = SelectProperty(songs, 'year')
    AlbumOptions = SelectProperty(songs, 'album')

    const selectedOptionYear = (selectedYear) => {
        setOptionYear(selectedYear);
    }
    const selectedOptionAlbum = (selectedAlbum) => {
        setOptionAlbum(selectedAlbum);
    }

    songsWithLyrics = songs.filter(e => e.lyrics !== null);

    //Rank i bruk / Sortering
    if (ranked_list) {
        sortedSongs = songs.sort((a, b) => {
            return a.rank - b.rank;
        });
    }
    else {
        sortedSongs = songs;
    }

    //AgGrid checkbox
    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }

    //SØK
    if (searchString) {
        filterArray = SearchLists(songs, 'searchtitle', searchString.toLowerCase());
        } 
    if (searchStringLyrics) {
        searchWords = searchStringLyrics.split(/\s/).filter(word => word)
        filterArray = SearchLists(songsWithLyrics, 'searchlyrics', searchStringLyrics.toLowerCase());
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }
    
    const handleSearchChangeLyrics = (e) => {
        setSearchStringLyrics(e.target.value);
        };

    const clearSearchFieldLyrics = (e) => {
        setSearchStringLyrics('')
        }

    //FILTERS
    if (optionYear) {
        filterArray = sortedSongs.filter(e => e.year === optionYear.value);
        }  
    if (optionAlbum) {
        filterArray = sortedSongs.filter(e => e.album === optionAlbum.value);
        } 
    if (!searchString && !searchStringLyrics && !optionYear) {
        filterArray = sortedSongs;
    }

    const listHeading = list.map(value => {

        ListPic = value.picture_link
        ListPic2 = value.picture2

        if (value.many_versions_same_song) {
            ManyVersionsSameSong = 1
        }

        return (
            <React.Fragment key={value.id}>
                <div>
                    <h2>{value.name}</h2>

                    <div className="row">
                    
                        {/* kolonne 1 */}
                        <div className="col-sm-3 mb-3">
                            
                            <img src={ListPic}
                            alt="alt" className="img-fluid" /> 

                            {
                                (() => {
                                if (ListPic2) {
                                return  <div className='mt-2'><img src={ListPic2} alt="alt" className="img-fluid" /> </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.slug === 'the-best-version-of-songs-on-the-never-ending-tour') {
                                return  <div>
                                            <br />
                                            <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                            
                                            <br />
                                            <div className="text-center">
                                                <div className="btn btn-info btn-xs">{songs.length} songs out of 176 included.</div>
                                            </div> 
                                        </div>
                                }
                                return 
                                })()
                            }

                            
                            {((window.location.hostname === "localhost") && (value.in_progress)) &&
                                <div>
                                    <br />
                                    <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                    
                                    <br />
                                    <div className="text-center">
                                        <div className="btn btn-info btn-xs">total count: {songs.length}</div>
                                    </div> 
                                </div>
                            }
                            
                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-5">
                            {
                                (() => {
                                    if (value.sub_title) {
                                    return  <div className="SubTitle px-3 py-1 mb-2">{value.sub_title}</div>
                                    }
                                    return 
                                })()
                            }
                            {
                                (() => {
                                    if (value.description) {
                                    return  <div className="DescriptionBackground mb-2 mt-1 mx-2 py-1 px-1">{value.description}
                                        </div>
                                    }
                                    return 
                                })()
                            }
                            {
                                (() => {
                                    if (value.comment1) {
                                    return  <div className="card bg-light p-2 mb-2">
                                                <div className="quote2 mb-3">{value.comment1}</div> 
                                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                                            </div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                    if (ManyVersionsSameSong) {
                                    return  <div className='mt-3'><b>Performed onstage</b> <br />
                                                <div className='factText p-2'>
                                                    <div><b>Years:</b> {performed_onstage}</div>
                                                    <div><i>-> <b>{times_live}</b> times</i></div>
                                                    <div><b>First time:</b> {first_live} <br /> 
                                                    <b>Last time:</b> {last_live}</div>
                                                </div>
                                            </div>
                                    }
                                    return 
                                })()
                            }
                            
                            {
                                (() => {
                                    if (value.spotify) {
                                    return  <div>
                                                    <p><b>--> <a href={ value.spotify }>
                                                Spotify playlist</a></b></p>
                                            </div>
                                    }
                                    return 
                                })()
                            }  
                            {value.url &&
                                <div>
                                    <b>Source</b>
                                    <div className="Description">
                                        <ul>
                                            <li>
                                                <b><a href={value.url}>
                                                    {value.name} ({value.source})
                                                </a></b>
                                            </li>  
                                        </ul>
                                    </div>
                                </div>
                            }
                            <div className="mt-4"><b>Check out:</b></div>
                            <div className="Description">
                                <ul>
                                    {value.link1 &&
                                    <li><b><a href={value.url1}>{value.link1}</a></b></li>
                                    }
                                    {value.link2 &&
                                    <li><b><a href={value.url2}>{value.link2}</a></b></li>
                                    }
                                    {value.link3 &&
                                    <li><b><a href={value.url3}>{value.link3}</a></b></li>
                                    }
                                    {value.link4 &&
                                    <li><b><a href={value.url4}>{value.link4}</a></b></li>
                                    }
                                    {value.link5 &&
                                    <li><b><a href={value.url5}>{value.link5}</a></b></li>
                                    }
                                    
                                    <li><b><a href='/lists'>More Lists @ MusicThisDay.com</a></b></li>
                                </ul>
                                        
                            </div>

                            <div className="Description mt-4"><i>All lyrics are the property and copyright of 
                                their owners, provided for educational purposes only.</i></div>
                        </div>

                        {/* kolonne 3 */}
                        <div className="col-sm-4 mb-1">
                            {(!searchString && !searchStringLyrics && !value.no_filters)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h4>Filters</h4>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && !value.no_year_released && !value.no_filters && !optionAlbum)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionYear}
                                            onChange={selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='Select year..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionYear(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                            {(!searchString && !searchStringLyrics && !value.no_album && !value.no_filters && !optionYear)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={optionAlbum}
                                            onChange={selectedOptionAlbum}
                                            options={AlbumOptions}
                                            placeholder='Select album..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => setOptionAlbum(null)} >Reset</button>
                                    </div>
                                </div>
                            }
                             
                            {(!optionYear && !optionAlbum && !ManyVersionsSameSong) &&
                                <div>
                                    <div className="row">   
                                            <div className="col">
                                            <h4>Search</h4>
                                        </div>
                                    </div>

                                    <div className="row pb-2">  
                                        <div className="col-9">
                                            <div className="search">    
                                                    <DebounceInput
                                                        minLength={3}
                                                        debounceTimeout={1000}
                                                        value={searchString}
                                                        onChange={handleSearchChange}
                                                        placeholder="Search all song titles" 
                                                    />
                                                </div>
                                            </div>
                                        <div className="col-3 pl-0">
                                            <button type="button" className="btn btn-primary"  
                                            onClick={() => clearSearchField()} >Reset</button>
                                        </div>
                                    </div>

                                    <div className="row pb-2">  
                                        <div className="col-9">
                                            <div className="search">    
                                                <DebounceInput
                                                    minLength={3}
                                                    debounceTimeout={1000}
                                                    value={searchStringLyrics}
                                                    onChange={handleSearchChangeLyrics}
                                                    placeholder="Search all lyrics" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3 pl-0">
                                            <button type="button" className="btn btn-primary"  
                                            onClick={() => clearSearchFieldLyrics()} >Reset</button>
                                        </div>
                                    </div>
                                </div>
                                }
                                {!hide_grid_option &&
                                    <div className="row align-items-start no-gutters">     
                                        <div className="col pt-2">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                <input type="checkbox"
                                                    checked={AgGrid}
                                                    onChange={checkHandler}
                                                    className="form-check-input"
                                                />
                                                Change to Grid
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {ManyVersionsSameSong &&
                                    <div className='my-3'><b>Song lyrics</b> <br />
                                        <div className="bg-light ml-2 mr-2">
                                            <div className="py-2 px-2 Description">
                                                <ReadMoreAndLess
                                                        charLimit={400}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {lyrics}
                                                </ReadMoreAndLess>
                                        
                                            </div>
                                        </div>
                                    </div>

                                }
                                {(searchString||searchStringLyrics) &&
                                    <div>
                                        <div className='mt-3'>
                                            <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                        </div> 
                                    </div>
                                }
                                {!ManyVersionsSameSong &&
                                <div className="mt-3 ml-3"><b>Total: </b> {filterArray.length}</div>
                                }
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                </div>
            </React.Fragment>
            )
        }
    )

    const songItems = filterArray.map(value => {

        return (
            <React.Fragment key={value.id}>

                <div className="row videoTitle2">
                    <div className="col-sm-12 pt-2"> 

                        {
                            (() => {
                            if (!value.many_versions_same_song && value.album) {
                                return  <div><h4>{value.title}</h4>
                                <h6>ALBUM: {value.album}</h6>
                                </div>
                            }
                            if (!value.many_versions_same_song) {
                            return <h4>{value.title}</h4>
                            }
                            return
                            })()
                        }

                        <div><b>{value.venue} - {value.city}, {value.country} - {value.gig_date}</b>
                            {
                                (() => {
                                if (value.last) {
                                    return <button type="button" className="btn btn-info btn-xs my-1" style={{float: 'right'}}>Last performance</button>
                                    
                                }
                                if (value.debut) {
                                    return <span className="badge larger-badge bg-success mb-2" style={{float: 'right'}}>Live debut</span>
                                }
                                return 
                                })()
                            }
                        </div>
                    </div>
                </div>

                <div className="row pt-3 pb-3">
                    
                    {/* kolonne 1 */}
                    <div className="col-sm-3">

                        <div>
                            <ImageWithFallback src={value.picture_link}  
                                alt="description" />
                            
                            {/* <img src={value.picture_link} 
                             alt="alt" className="rounded img-fluid" />  */}
                        </div>

                        <div className="Header2" align="center"><b>{value.tour}</b></div>

                        {
                            (() => {
                                if (!ManyVersionsSameSong && value.performed_onstage) {
                                return  <div className='mt-3'><b>Performed onstage</b> <br />
                                            <div className='factText p-2'>
                                                <div><b>Years:</b> {value.performed_onstage}</div>
                                                <div><i>-> <b>{value.times_live}</b> times</i></div>
                                                <div><b>First time:</b> {value.first_live} <br /> 
                                                <b>Last time:</b> {value.last_live}</div>
                                            </div>
                                        </div>
                                }
                                if (!ManyVersionsSameSong && !value.performed_onstage) {
                                return <div className='mt-3'><b>Performed onstage</b> <br />
                                            <div className='factText p-2'>
                                                <div><i>-> <b>{value.times_live}</b> times</i></div>
                                                <div><b>First time:</b> {value.first_live} <br /> 
                                                <b>Last time:</b> {value.last_live}</div>
                                            </div>
                                        </div>
                                }
                                return 
                            })()
                        }
                        
                    </div>
                    
                    {/* kolonne 2 */}
                    <div className="col-sm-4">

                        {
                            (() => {
                            if (value.line_up) {
                                return <div>
                                            <div className='mt-2'><b>Line-up:</b></div>
                                            <div className="factText mb-2 px-2 py-1">
                                                <ReadMoreAndLess
                                                        charLimit={500}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.line_up}
                                                </ReadMoreAndLess>
                                            </div>
                                        </div>
                            }
                            return <div>
                                        <div className='mt-2'><b>Line-up:</b></div>
                                        <div className="factText mb-2 px-2 py-1">
                                            <ReadMoreAndLess
                                                    charLimit={500}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.musicians}
                                            </ReadMoreAndLess>
                                        </div>
                                </div>
                            })()
                        }
                        
                        

                        <div className="row pt-2 Description">   
                            {
                                (() => {
                                    if (value.lyrics && !searchStringLyrics && !ManyVersionsSameSong) {
                                        return  <div className="bg-light ml-2 mr-2">
                                                    <div className="py-2 px-2">
                                                        <ReadMoreAndLess
                                                                charLimit={300}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.lyrics}
                                                        </ReadMoreAndLess>
                                                
                                                    </div>
                                                </div>
                                        }
                                        if (value.lyrics && searchStringLyrics) {
                                        return  <div className="bg-light ml-2 mr-2">
                                                <div className="py-2 px-2">
                                    
                                                        <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={searchWords}
                                                            autoEscape={true}
                                                            textToHighlight={value.lyrics}
                                                        />
                                                </div>
                                            </div>

                                    }
                                    return
                                    }
                                )()
                            }

                            
                            
                        </div> 

                        

                         

                    </div>

                    {/* kolonne 3 */}
                    <div className="col-sm-5">

                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                            {
                                (() => {
                                if (value.youtube1) {
                                    return <div className="border d-flex justify-content-center flex-nowrap">  
                                                 <ReactPlayer
                                                    url={ value.youtube + '?t=' + value.youtube1 }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                    /> 
                                            </div>
                                }
                                if (value.youtube2) {
                                    return <div className="border d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube_2 + '?t=' + value.youtube2 }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                />
                                        </div>
                                }
                                if (value.youtube3) {
                                    return <div className="border my-2 d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube_3 + '?t=' + value.youtube3 }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                /> 
                                        </div>
                                }
                                if (value.youtube4) {
                                    return <div className="border my-2 d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube_4 + '?t=' + value.youtube4 }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                /> 
                                        </div>
                                }
                                if (value.youtube5) {
                                    return <div className="border my-2 d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube_5 + '?t=' + value.youtube5 }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                /> 
                                        </div>
                                }
                                if (value.youtube6) {
                                    return <div className="border my-2 d-flex justify-content-center flex-nowrap">  
                                                <ReactPlayer
                                                    url={ value.youtube_6 + '?t=' + value.youtube6 }
                                                    controls
                                                    light='true'
                                                    volume={0.9}
                                                    width="380px"
                                                    height="300px"
                                                /> 
                                        </div>
                                }
                                return <div className='text-center my-3'><h4><i>Not available on YouTube</i></h4>
                                        {value.comment_audio_not_available}
                                </div>
                                })()
                            }

                            {
                                (() => {
                                if (value.comment_audio_not_available && (value.youtube1|value.youtube2|value.youtube3|value.youtube4) ) {
                                    return  <div className='Description my-3'>
                                                    {value.comment_audio_not_available}
                                            </div>
                                }
                                return 
                                })()
                            }


                            {
                                (() => {
                                    if (value.youtube_live_version && !ManyVersionsSameSong && !no_alt_live_version) {
                                        return <div className='mt-3'> 
                                                    <div className="Header2" align="center"><b>Alt version</b></div>
                                                    <div className="card bg-light py-2 px-2">

                                                        <div className="mx-4">  
                                                                {/* <YoutubeEmbedAll embedId={value.youtube_live_version} /> */}
                                                                <ReactPlayer
                                                                    url={ value.youtube_live_version }
                                                                    //className="react-player"
                                                                    light='true'
                                                                    controls
                                                                    volume={0.9}
                                                                    width="380px"
                                                                    height="300px"
                                                                    //width="350"
                                                                    //height="650"
                                                                /> 
                                                        </div>

                                                        <div className='DescriptionBig2 mt-1 pl-4'>
                                                            {value.live_version_comment}
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                    }
                                    return
                                })()
                            }

                            {
                                (() => {
                                if (value.comment) {
                                    return <div className="card bg-light mt-4 py-2 px-2">
                                                <div className="quote mb-1">{value.comment}</div> 
                                            </div>
                                }
                                return 
                                })()
                            }
                            
                                    
                    </div>

                </div>


            </React.Fragment>          
        )

    })

    return (
        <main className="my-1 py-1">
            {list.length > 0 && (
                <div className="container">
                
                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={picture_link}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />

                    <div className="row">
                        <div className="col">
                            {listHeading}
                        </div>
                    </div>

                    {!AgGrid && 
                    <div className="row">
                        <div className="col">

                             {
                                (() => {
                                if (songs.length > 0) {
                                return <div> { songItems } </div>
                                }
                                return <div>
                                        <h3>No songs found</h3>
                                    </div>
                                })()
                            } 
                           
                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={songs}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>

                </div>
            )}
        </main>
    )

}
export default SongsLiveLists