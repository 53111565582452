import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
//import Select from 'react-select';
import { dbURLPostHeading, duURLVideoAudioOther } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import YoutubeEmbedAll from "../misc/YoutubeEmbedAll";
//import ReactGA from "react-ga";
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import SEO from '../../SEO';
import withRouter from '../withRouter';

class Post extends Component {

    state = {
        PostHeadingArray: [],
        AllVideoAudioArray: [],
        loading1: true,
        loading2: true
      }

      getPostHeading(){
        axios.get(dbURLPostHeading + this.props.params.slug)
            .then(response => {
                this.setState({
                    PostHeadingArray: response.data,
                    loading1: false
                });
            });
        } 
    
        getAllVideoAudio(){
            axios.get(duURLVideoAudioOther + this.props.params.slug)
              .then(response => {
                this.setState({
                    AllVideoAudioArray: response.data,
                  loading2: false
                });
              });
          } 
        
        componentDidMount() {
            this.getPostHeading()
            this.getAllVideoAudio()
          }

        scrollToTop = () => {
            scroll.scrollToTop(); 
        };
    
        scrollToBottom = () => {
            scroll.scrollToBottom();
          };

    render() {

        if (this.state.loading1 || this.state.loading2) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }

        const PostHeading = this.state.PostHeadingArray;
        //console.table(PostHeading);
        const VideoItems = this.state.AllVideoAudioArray;
        //console.table(VideoItems);
        if (VideoItems.length < 1) {
            return <div>
                        <main className="my-1 py-1">
                            <div className="container">  
                                <div className="row pt-1">
                                    <div className="col">
                                        <h1>404 - Not Found!</h1>
                                        <Link to="/posts">
                                            Go to current Posts
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                </div> 
        }

        const Heading = PostHeading.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row bestSongsTitle mb-1">
                        <div className="col-sm-12 mb-2"> 
                            <div className="text-center text-light bg-secondary rounded"><b> {value.name} </b></div>
                        </div>
                    </div>
                    {
                        (() => {
                        if (value.intro) {
                            return <div>
                                    <div className="row postIntro mb-1">
                                        <div className="col-sm-12 mb-3"> 
                                            <div className="rounded"><b> {value.intro} </b></div>
                                        </div>
                                    </div>    
                                </div>
                        }
                        return 
                        })()
                    }
                    
                    <div className="row">

                        <div className="col-sm-6 mb-3">
                            
                            <img src={value.picture_link} className="img-fluid" alt=""></img>

                        </div>
                        <div className="col-sm-6">

                            <div className='factText p-2 mb-3'>
                                <ReactMarkdown>
                                    {value.description}                       
                                </ReactMarkdown>
                            </div>
                            
                            <div className="card bg-light py-2 px-2 mb-3">
                                <div className="quote mb-3">{value.comment1}</div> 
                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                            </div>

                            {
                                (() => {
                                if (value.comment2) {
                                    return <div>
                                                <div className="card bg-light py-2 px-2">
                                                    <div className="quote mb-3">{value.comment2}</div> 
                                                    <div className="blockquote-footer"><i>{value.source2}</i></div>
                                                </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>

                </React.Fragment>
            )

            }
        )

        const VideoAudio = VideoItems.map(value => {

            return (
                <React.Fragment key={value.id}>
                    
                    <div className="row border-bottom mt-3">

                        <div className="col-sm-6 mb-3">

                            {
                                (() => {
                                if (value.description) {
                                    return <div className='factTextNoWrap px-2 py-1 mb-3'>
                                    <ReactMarkdown>
                                        {value.description}                       
                                    </ReactMarkdown>
                                </div>        
                                }
                                return 
                                })()
                            }
                            {
                                (() => {
                                if (value.quote) {
                                    return <div>
                                                <div className="card bg-light py-2 px-2">
                                                    <div className="quote mb-3">
                                                        <ReadMoreAndLess
                                                                charLimit={700}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.quote}
                                                        </ReadMoreAndLess>
                                                    </div> 
                                                    {
                                                        (() => {
                                                        if (value.quote_source) {
                                                            return <div>
                                                                        <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                                                                </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                        </div>
                                }
                                return 
                                })()
                            }
                            
                            {
                                (() => {
                                if (value.quote2) {
                                    return <div>
                                                <div className="card bg-light mt-3 py-2 px-2">
                                                    <div className="quote mb-3">
                                                        <ReadMoreAndLess
                                                                charLimit={700}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.quote2}
                                                        </ReadMoreAndLess>
                                                    </div> 
                                                    {
                                                        (() => {
                                                        if (value.quote2_source) {
                                                            return <div>
                                                                        <div className="blockquote-footer"><i>{value.quote2_source}</i></div>
                                                                </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>
                        {/* kolonne 2 */}
                        <div className="col-sm-6 mb-3">

                            <div className="card bg-light py-2 px-2">
                                <div className='DescriptionBig2 pl-4 my-2'>
                                    <b>{value.title}</b>
                                </div>
                                {
                                    (() => {
                                    if (value.youtube && !value.youtube_start) {
                                        return <div>
                                                    <div className="mx-4">
                                                        <YoutubeEmbedAll embedId={value.youtube} />
                                                    </div>
                                                </div>
                                    }
                                    if (value.youtube && value.youtube_start) {
                                    return <div>
                                                <div className="mx-4">
                                                    <ReactPlayer
                                                        url={ value.youtube + '?t=' + value.youtube_start }
                                                        //className="react-player"
                                                        light='true'
                                                        controls
                                                        volume={0.9}
                                                        width="380px"
                                                        height="300px"
                                                        //width="350"
                                                        //height="650"
                                                        /> 
                                                </div>
                                            </div>
                                    }
                                    return <div className='mx-4'>
                                        <img src={value.picture} alt="alt" className="img-fluid" /> 
                                        </div>
                                    })()
                                }
                            </div>

                                {
                                    (() => {
                                    if (value.quote3) {
                                        return <div>
                                                <div className="card bg-light mt-3 py-2 px-2">
                                                    <div className="quote mb-3">
                                                        <ReadMoreAndLess
                                                                charLimit={700}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.quote3}
                                                        </ReadMoreAndLess>
                                                    </div> 
                                                    {
                                                        (() => {
                                                        if (value.quote3_source) {
                                                            return <div>
                                                                        <div className="blockquote-footer"><i>{value.quote3_source}</i></div>
                                                                </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                    }
                                    return 
                                    })()
                                }

{
                                    (() => {
                                    if (value.quote4) {
                                        return <div>
                                                <div className="card bg-light mt-3 py-2 px-2">
                                                    <div className="quote mb-3">
                                                        <ReadMoreAndLess
                                                                charLimit={700}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.quote4}
                                                        </ReadMoreAndLess>
                                                    </div> 
                                                    {
                                                        (() => {
                                                        if (value.quote4_source) {
                                                            return <div>
                                                                        <div className="blockquote-footer"><i>{value.quote4_source}</i></div>
                                                                </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                    }
                                    return 
                                    })()
                                }
                                
                            
                            

                        </div>

                    </div>

                </React.Fragment>
            )

            }
        )


        return (
            
            <main className="my-1 py-1">
                <div className="container">
                <SEO
                    title='Music articles'
                    description='Music articles - albums, songs, people and more..'
                    name='Music This Day.'
                    type='article' />

                    {Heading}

                    <div className='row mt-3'>
                        <div className='col'>
                            <div className='BestSong text-center p-1 rounded'><b>Audio/Video</b>
                            </div>
                        </div>
                    </div>

                    {VideoAudio}

                    <div className="mr-3">
                        <p align="Right"><br />
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                            <b>Scroll to top</b></button>
                        </p>
                    </div>

                </div>
            </main>
        )

    }

}
export default withRouter(Post);