import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDaySessionsDylan } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";

const AnyDaySessionsDylan = (props) => {
  const [sessions, setSessions] = useState([])
  //const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchSessions = () => {
        axios.get(dbURLAnyDaySessionsDylan + props.dateSelected)
        .then(response => {
          //setIsLoading(false)
          setSessions(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchSessions()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {sessions.length > 0 && (
        <div id="sessions">
            <div className="row TodayTitle mt-4">
                <div className="col-sm-12"> 
                    <div>&nbsp;<b>Recording Sessions</b>
                    </div>
                </div>
            </div>
          {sessions.map(value => (
            <div key={value.id}>
                
                <div className="row ConcertTitleLight2 mt-3">
                        <div className="col-sm-12"> 
                            {
                                (() => {
                                    if (value.mult_days) {
                                        return <div>&nbsp;{value.mult_days}, {value.sessions_year} - {value.studio} - {value.location}   
                                        </div>
                                        }
                                    return <div>&nbsp;{value.sessions_year} - {value.studio} - {value.location}   
                                    </div>
                                })()
                            }
                        </div>
                    
                    </div>

                    <div className="row py-1 mb-3">

                        {/* 1 kolonne */}
                        <div className="col-sm-3">
                            <div>
                                <div className="Song2" align="center"><b>{value.title}</b></div>
                            </div>
                            {
                                (() => {
                                    if (value.alb_picture) {
                                        return <div className="text-center"><img src={value.alb_picture} alt="" height="180" width="180"></img></div>
                                        }
                                    return 
                                })()
                            }

                            
                            {
                                (() => {
                                if (value.producer) {
                                return <div>
                                            <b>Producer</b> <br />
                                            <div className="factText p-1">{value.producer}</div>
                                            
                                        </div>
                                } 
                                return 
                                })()
                            }
                        
                            {
                                (() => {
                                if (value.engineer) {
                                return <div><b>Engineer</b> <br />
                                <div className="factText mb-2 p-1">{value.engineer}</div>
                                
                                </div>
                            
                                } 
                                return <p></p>
                                })()
                            }

                            {
                                (() => {
                                if (value.musicians) {
                                return <div><b>Musicians</b> <br />
                                <div className="factText p-1">{value.musicians}</div>
                                </div>
                            
                                } 
                                return
                                })()
                            }
                            
                           {/*  {
                                (() => {
                                if (value.musicians) {
                                    return  <div className="Header4 p-1">
                                                <b>Musicians:</b><br />
                                                    <div className="factText">
                                                        {value.musicians}
                                                    </div>
                                            </div>
                                }
                                return 
                                })()
                            } */}

                        </div>

                        {/* 2 kolonne */}
                        <div className="col-sm-5 Description">

                            <div className="bg-light mx-2 py-1 px-2">
                                <b>Recording sheet:</b><br />
                                <ReadMoreAndLess
                                    charLimit={800}
                                    readMoreText={" more ▼"}
                                    readLessText={" less ▲"}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"
                                        >
                                        {value.rec_sheet}
                                </ReadMoreAndLess>
                            </div>

                            {
                                (() => {
                                if (value.notes) {
                                    return  <div className="Header4 mt-2">
                                                    <div className="factText">

                                                        <ReadMoreAndLess
                                                                charLimit={600}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.notes}
                                                        </ReadMoreAndLess>
                                                    
                                                    </div>
                                            </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.releases) {
                                    return  <div className="Header4 mt-2">
                                                    <div className="factText">
                                                        {value.releases}
                                                    </div>
                                            </div>
                                }
                                return 
                                })()
                            }

                        </div>
                        
                        {/* 3 kolonne */}
                        <div className="col-sm-4">

                            {
                                (() => {
                                if (value.youtube && value.youtube2 && value.youtube3) {
                                    return <div>
                                
                                    <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                    <div className="border my-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                        
                                            <ReactPlayer
                                                url={ value.youtube }
                                                //className="react-player"
                                                light='true'
                                                //light
                                                controls
                                                volume={0.9}
                                                width="100%"
                                                height="100%"
                                                //width="300"
                                                //height="300"
                                            />
                                    </div>
                                    <div className="border mb-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                        
                                        <ReactPlayer
                                            url={ value.youtube2 }
                                            light='true'
                                            //light
                                            controls
                                            volume={0.9}
                                            width="300"
                                            height="300"
                                        />
                                    </div>

                                    <div className="border mb-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title3}</b></div>
                                        
                                        <ReactPlayer
                                            url={ value.youtube3 }
                                            light='true'
                                            //light
                                            controls
                                            volume={0.9}
                                            width="300"
                                            height="300"
                                        />
                                    </div>
                    
                                </div>
                                }
                                if (value.youtube && value.youtube2) {
                                    return <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                <div className="border my-2">
                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                                    
                                                        <ReactPlayer
                                                            url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            volume={0.9}
                                                            width="300"
                                                            height="300"
                                                        />
                                                    </div>
                                                    <div className="border mb-2">
                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                                
                                                    <ReactPlayer
                                                        url={ value.youtube2 }
                                                        light='true'
                                                        //light
                                                        controls
                                                        volume={0.9}
                                                        width="300"
                                                        height="300"
                                                    />
                                                </div>
                                                
                                            </div>
                                }
                                if (value.youtube) {
                                    return <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                                                <div className="border my-2">

                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                                    
                                                        <ReactPlayer
                                                            url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            volume={0.9}
                                                            width="300"
                                                            height="300"
                                                        /><br />
                                                    

                                                </div>
                                                    
                                
                                            </div>
                                }

                                return
                                })()
                            }


                            {
                                (() => {
                                if (value.description) {
                                return <div className="Description">
                                        <ReadMoreAndLess
                                                charLimit={500}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                    </div>
                                }
                                return 
                                })()
                            }
                            
                        


                        </div>


                    </div>
                
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AnyDaySessionsDylan
