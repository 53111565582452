import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbBootleg } from '../Constants';
import '../lists.scss';
import ReactPlayer from "react-player";
//import ReactMarkdown from 'react-markdown';
import SEO from '../../SEO';
import {useParams, Link} from 'react-router-dom';

const Bootleg = (props) => {

    const [bootlegData, setbootlegData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const params = useParams();

    useEffect(() => {
        const fetchBootlegData = () => {
          axios.get(dbBootleg + params.slug)
          .then(response => {
            setLoading(false)
            setbootlegData(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        fetchBootlegData()
      }, [params.slug])
  
    if (error) return `Error: ${error.message}`;

    if (loading) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }
    if (bootlegData.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/posts">
                                        Go to current Post
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, page } = bootlegData[0];


    const BootlegItems = bootlegData.map(value => {

        return (
            <React.Fragment key={value.id}>

                <div className="row videoOfTheDayTitle mb-1">
                    <div className="col-sm-12"> 
                        <div className="text-center"><b>BOOTLEG: </b>{value.name}</div>
                    </div>
                </div>

                <div className="row">

                    {/* Kolonne 1 */}
                    <div className="col-sm-7">

                        <div className="border player-wrapper">
                                <ReactPlayer
                                    url={ value.video }
                                    className="react-player"
                                    //light='true'
                                    //light
                                    controls
                                    width="100%"
                                    height="100%"
                                    //width="300"
                                    //height="300"
                                />   
                        </div>

                        {
                            (() => {
                            if (value.description) {
                            return  <div className="factText rounded m-2 p-1">
                                            {value.description}                                 
                                    </div>
                                }   
                                return 
                            })()

                        }

                        <div className="row mt-2">
                            <div className="col-sm-5">
                                <b>Category </b><br />
                                <div className="factText p-1">{value.category}</div>
                                <b>Tour / Event </b><br />
                                <div className="factText p-1">{value.tour_session_name}</div>
                            </div>

                            <div className="col-sm-7">
                                <b>Line-up:</b><br />
                                <div className="factText p-1">{value.musicians}</div>
                            </div>
                        </div>
                    </div>

                    {/* Kolonne 2 */}
                    <div className="col-sm-5">

                        <div className="Description bg-light mx-2 p-2">
                            <b>Songs:</b><br />
                            {value.songs}                     
                        </div>  
                        <div className="row mt-4 ml-3">
                            <div className="col-sm-12">
                                    <b>Check out:</b>
                                    {
                                        (() => {
                                        if (value.link1) {
                                        return  <li><b><a href={value.url1}>
                                                    {value.link1}
                                                    </a></b>
                                                </li> 
                                            }   
                                            return 
                                        })()
                                    }
                                    {
                                        (() => {
                                        if (value.link2) {
                                        return  <li><b><a href={value.url2}>
                                                    {value.link2}
                                                    </a></b>
                                                </li> 
                                            }   
                                            return 
                                        })()
                                    }
                                    {
                                        (() => {
                                        if (value.link3) {
                                        return  <li><b><a href={value.url3}>
                                                    {value.link3}
                                                    </a></b>
                                                </li> 
                                            }   
                                            return 
                                        })()
                                    }
                                    
                                    <li><b><a href='dylananyday'>
                                        Bob Dylan - Any Day
                                        </a></b>
                                    </li> 
                                    <li><b><a href='lists/concerts/top-100-bob-dylan-concerts'>
                                         Top 100 Bob Dylan Concerts
                                        </a></b>
                                    </li> 

                                    {
                                        (() => {
                                        if (value.youtube_channel_url) {
                                        return  <div className="mt-1"><b>Source:</b>
                                                <li><b><a href={value.youtube_channel_url}>
                                                    {value.youtube_channel}
                                                    </a></b>
                                                </li> 
                                                </div>
                                            }   
                                            return 
                                        })()
                                    }

                                    
                                    
                            </div>
                        </div> 

                    </div>

                </div>

            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {bootlegData.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    url={'https://www.musicthisday.com/'+page}
                    canonical={'https://www.musicthisday.com/'+page}
                    type='article' />
                            
                    <div className="row">
                        <div className="col-sm-12">
                                {BootlegItems}
                        </div>
                    </div>

                </div>
            )}
        </main>
    )


}

export default Bootleg