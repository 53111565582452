import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayConcertsDylan } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const AnyDayConcertsDylan = (props) => {
  const [concerts, setConcerts] = useState([])
  //const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchConcerts = () => {
        axios.get(dbURLAnyDayConcertsDylan + props.dateSelected)
        .then(response => {
          //setIsLoading(false)
          setConcerts(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchConcerts()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  //fjerne duplikater
  const concertsUnique  = concerts.filter(
    (obj, index) =>
      concerts.findIndex((item) => item.id === obj.id) === index
  );

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {concertsUnique.length > 0 && (
        <div id="concerts">
            <div className="row TodayTitle mt-4">
                    <div className="col-sm-12"> 
                    <div>&nbsp;<b>Concerts</b>
                    </div>
                </div>
            </div>
          {concertsUnique.map(value => (
            <div key={value.concert_id}>
                    {
                        (() => {
                        if (!value.setlist) {
                            return  <div className="row ConcertTitleLight">
                                        {
                                            (() => {
                                                if (value.info) {
                                                    return <div className="col-sm-12"> 
                                                    <div>&nbsp;{value.gig_year} - {value.venue} - {value.city}, {value.country} ({value.info})&nbsp;&nbsp;&nbsp;     
                                                    </div>
                                                </div>
                                                    }
                                                return <div className="col-sm-12"> 
                                                <div>&nbsp;{value.gig_year} - {value.venue} - {value.city}, {value.country}&nbsp;&nbsp;&nbsp;     
                                                </div>
                                            </div>
                                            })()
                                        }
                                        
                                    </div>
                        }
                        return <div>

                                    <div className="row ConcertTitleLight2 mt-3">
                                        {
                                            (() => {
                                                if (value.info) {
                                                    return <div className="col-sm-12"> 
                                                    <div>&nbsp;{value.gig_year} - {value.venue} - {value.city}, {value.country} ({value.info})&nbsp;&nbsp;&nbsp;     
                                                    </div>
                                                </div>
                                                    }
                                                return <div className="col-sm-12"> 
                                                <div>&nbsp;{value.gig_year} - {value.venue} - {value.city}, {value.country}&nbsp;&nbsp;&nbsp;     
                                                </div>
                                            </div>
                                            })()
                                        }
                                    </div>

                                    

                                    <div className="row py-1 mb-3">

                                        {/* 1 kolonne */}
                                        <div className="col-sm-3">

                                            {value.picture_link &&
                                            <div><img src={value.picture_link} 
                                            alt="alt" className="img-fluid rounded" /> </div>
                                            }

                                            <div>
                                                <p align="center"><b>{value.tour}</b></p>
                                            </div>

                                            {
                                                (() => {
                                                if (value.musicians) {
                                                    return  <div className="Header4 p-1">
                                                                <b>Line-up:</b><br />
                                                                    <div className="factText">
                                                                        {value.musicians}
                                                                    </div>
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            
                                            

                                            {
                                                (() => {
                                                if (value.spotify) {
                                                    return  <div><br />
                                                        <p align="center"><b>Spotify link</b></p>
                                                        <p className="aligncenter"><a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                    <img border="0" alt="Spotify" align="center" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=50,height=50" /></a></p>
                                                    </div>
                                                    
                                                }
                                                return 
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.list_name && value.list_name_songs_dylan) {
                                                    return <div className="card p-2 my-2 Description bg-light">
                                                            <div>
                                                                <b>Check out list:</b><br />
                                                                <Link style = {{'fontSize': '14px'}} 
                                                                className="font-weight-bold text-wrap-2" 
                                                                    to={value.page}>- {value.list_name}</Link><br />
                                                                <Link style = {{'fontSize': '14px'}} 
                                                                    className="font-weight-bold text-wrap-2" 
                                                                        to={value.page_songs_dylan}>- {value.list_name_songs_dylan}</Link>
                                                            </div>
                                                        </div>
                                                }
                                                if (value.list_name && !value.list_name_songs_dylan) {
                                                    return <div className="card p-2 my-2 Description bg-light">
                                                            <div>
                                                            <b>Check out list:</b><br />
                                                            <Link style = {{'fontSize': '14px'}} 
                                                                className="font-weight-bold text-wrap-2" 
                                                                    to={value.page}>- {value.list_name}</Link>
                                                            </div>
                                                        </div>
                                                }
                                                if (!value.list_name && value.list_name_songs_dylan) {
                                                    return <div className="card p-2 my-2 Description bg-light">
                                                            <div>
                                                            <b>Check out list:</b><br />
                                                            <Link style = {{'fontSize': '14px'}} 
                                                                className="font-weight-bold text-wrap-2" 
                                                                    to={value.page_songs_dylan}>- {value.list_name_songs_dylan}</Link>
                                                            </div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                                    
                                            } 

                                        </div>

                                        {/* 2 kolonne */}
                                        <div className="col-sm-5 Description">

                                            <div className="bg-light ml-2 mr-2 py-1 px-2">
                                                <b>Setlist:</b><br />
                                                <ReadMoreAndLess
                                                    charLimit={800}
                                                    readMoreText={" more ▼"}
                                                    readLessText={" less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                        >
                                                        {value.setlist}
                                                </ReadMoreAndLess><br />
                                            </div>

                                                {
                                                    (() => {
                                                    if (value.description && (value.description !== "CONCERT") && (value.description !== "NETSHOW")) {
                                                    return  <div className="py-3">
                                                                <ReadMoreAndLess
                                                                        charLimit={400}
                                                                        readMoreText={" Read more ▼"}
                                                                        readLessText={" Read less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.description}
                                                                </ReadMoreAndLess>
                                                                
                                                            </div>
                                                    
                                                    }
                                                    if (!value.description && value.comment) {
                                                        return  <div className="card bg-light pt-3 px-2 mt-4">
                                                                    <div className="quote3 mb-3">{value.comment}</div> 
                                                                    
                                                                    {
                                                                        (() => {
                                                                            if (value.source_txt_details) {
                                                                                return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                            }
                                                                            return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                                        })()

                                                                    }

                                                                </div>
                                                    }


                                                    return 
                                                    })()
                                                }
                                            
                                            
                                        </div>

                                        {/* 3 kolonne */}
                                        <div className="col-sm-4">

                                                    {
                                                        (() => {
                                                            if (value.youtube && value.youtube_2 && value.youtube_3 && value.youtube_4) {
                                                                return <div>
                                                            
                                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                                <div className="border my-2">
                                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                                    
                                                                        <ReactPlayer
                                                                            url={ value.youtube||value.vimeo }
                                                                            //className="react-player"
                                                                            light='true'
                                                                            //light
                                                                            controls
                                                                            volume={0.9}
                                                                            width="100%"
                                                                            height="100%"
                                                                            //width="300"
                                                                            //height="300"
                                                                        />
                                                                </div>
                                                                <div className="border mb-2">
                                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                                    
                                                                    <ReactPlayer
                                                                        url={ value.youtube_2 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        volume={0.9}
                                                                        width="300"
                                                                        height="300"
                                                                    />
                                                                </div>
        
                                                                <div className="border mb-2">
                                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_3}</b></div>
                                                                    
                                                                    <ReactPlayer
                                                                        url={ value.youtube_3 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        volume={0.9}
                                                                        width="300"
                                                                        height="300"
                                                                    />
                                                                </div>

                                                                <div className="border mb-2">
                                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_4}</b></div>
                                                                    
                                                                    <ReactPlayer
                                                                        url={ value.youtube_4 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        volume={0.9}
                                                                        width="300"
                                                                        height="300"
                                                                    />
                                                                </div>
                    
                                                            </div>
                                                            }
                                                        if (value.youtube && value.youtube_2 && value.youtube_3) {
                                                            return <div>
                                                        
                                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                            <div className="border my-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                                
                                                                    <ReactPlayer
                                                                        url={ value.youtube||value.vimeo }
                                                                        //className="react-player"
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        volume={0.9}
                                                                        width="100%"
                                                                        height="100%"
                                                                        //width="300"
                                                                        //height="300"
                                                                    />
                                                            </div>
                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_2 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    volume={0.9}
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
    
                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_3}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_3 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    volume={0.9}
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
                                            
                                                        </div>
                                                        }
                                                        if (value.youtube && value.youtube_2) {
                                                            return <div>
                                                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                                        <div className="border my-2">
                                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                                            
                                                                                <ReactPlayer
                                                                                    url={ value.youtube||value.vimeo }
                                                                                    light='true'
                                                                                    //light
                                                                                    controls
                                                                                    volume={0.9}
                                                                                    width="300"
                                                                                    height="300"
                                                                                />
                                                                            </div>
                                                                            <div className="border mb-2">
                                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                                        
                                                                            <ReactPlayer
                                                                                url={ value.youtube_2 }
                                                                                light='true'
                                                                                //light
                                                                                controls
                                                                                volume={0.9}
                                                                                width="300"
                                                                                height="300"
                                                                            />
                                                                        </div>
                                                                        
                                                                    </div>
                                                        }
                                                        if (value.youtube) {
                                                            return <div>
                                                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                                                                        <div className="border my-2">

                                                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                                            
                                                                                <ReactPlayer
                                                                                    url={ value.youtube||value.vimeo }
                                                                                    light='true'
                                                                                    //light
                                                                                    controls
                                                                                    volume={0.9}
                                                                                    width="300"
                                                                                    height="300"
                                                                                /><br />
                                                                            {
                                                                                (() => {
                                                                                    if (value.mult_songs1) {
                                                                                        return <div className="bg-light ml-2 mr-2 py-1 px-2 Description">{value.mult_songs1}</div>
                                                                                        }
                                                                                    return 
                                                                                })()
                                                                            }

                                                                        </div>
                                                                            
                                                        
                                                                    </div>
                                                        }

                                                        return
                                                        })()
                                                    }

                                                    <div className="row pt-3">
                                                        <div className="col-12">
                                                            {
                                                                (() => {
                                                                if (value.comment && value.comment_2 && value.description) {
                                                                    return <div>
                                                                            <div className="card bg-light py-2 px-2">
                                                                                <div className="quote3 mb-3">{value.comment}</div> 
                                                                                
                                                                                {
                                                                                    (() => {
                                                                                        if (value.source_txt_details) {
                                                                                            return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                                                    })()

                                                                                }

                                                                            </div><br />
                                                                    
                                                                            <div className="card bg-light py-2 px-2">
                                                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                                                
                                                                                {
                                                                                    (() => {
                                                                                        if (value.source_txt_details_2) {
                                                                                            return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                                                    })()

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                                        
                                                                }


                                                                if (value.comment_2 && !value.comment) {
                                                                    return <div className="card bg-light py-2 px-2">
                                                                                <div className="quote3 mb-3">{value.comment_2}</div> 
                                                                                
                                                                                {
                                                                                    (() => {
                                                                                        if (value.source_txt_details_2) {
                                                                                            return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                                                    })()

                                                                                }

                                                                            </div>
                                                                                        
                                                                }
                                                                if (value.comment_2 && value.description) {
                                                                    return <div className="card bg-light py-2 px-2">
                                                                                <div className="quote3 mb-3">{value.comment}</div> 
                                                                                
                                                                                {
                                                                                    (() => {
                                                                                        if (value.source_txt_details) {
                                                                                            return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                                                    })()

                                                                                }

                                                                            </div>
                                                                                        
                                                                }
                                                                return 
                                                                })()
                                                            }
                                                        </div>
                                                    </div> 


                                        </div>
                    

                                    </div>



                        </div>
                        })()
                    }
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AnyDayConcertsDylan
