import React, { Component } from 'react'
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Container, Button } from 'reactstrap';
import Select from 'react-select';
import { dbURL } from '../Constants';

class ConcertLog extends Component {

    state = {
        columnDefs: [
          {headerName: "Date", field: "date", resizable: true, width: 120, cellStyle: {fontWeight: 'bold', 'background-color': 'ivory', 'font-size': '14px'}},
          {headerName: "Artist/Band", field: "artist", filter: true, width: 200, cellStyle: { 'font-size': '14px' }},
          {headerName: "Venue", field: "venue", sortable: true, width: 250, cellStyle: { 'font-size': '14px' }},
          {headerName: "City", field: "city", filter: true, sortable: true, width: 150, cellStyle: { 'font-size': '14px' }},
          {headerName: "Country", field: "country", sortable: true, width: 150, cellStyle: { 'font-size': '14px' }}
        ],
        rowAllData: [],
        selectedOptionArtist: null,
        selectedOptionYear: null
      }

    getAlbumItems(){
        axios.get(dbURL + 'concertlog')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 
    
    componentDidMount() {
        this.getAlbumItems()
      }

     selectedOptionYear = (selectedYear) => {
        this.setState({ selectedOptionYear: selectedYear });
    } 

    selectedOptionArtist = (selectedArtist) => {
        this.setState({ selectedOptionArtist: selectedArtist });
    }

    clearFilterYear(){
        this.setState({
            selectedOptionYear: null, 
        })
    }

    clearFilterArtist(){
        this.setState({
            selectedOptionArtist: null, 
        })
    }

    render() {

        const { selectedOptionYear } = this.state;
        const { selectedOptionArtist } = this.state;

        let filterArray

        if (selectedOptionYear && !selectedOptionArtist) {
        filterArray = this.state.rowAllData.filter(e => e.year === selectedOptionYear.value);
        } 
        if (!selectedOptionYear && selectedOptionArtist) {
        filterArray = this.state.rowAllData.filter(e => e.artist === selectedOptionArtist.value);
        } 
        if (selectedOptionArtist && selectedOptionYear) {
            filterArray = this.state.rowAllData.filter(e => e.artist === selectedOptionArtist.value 
                && e.year === selectedOptionYear.value)
        }
        if (!selectedOptionYear && !selectedOptionArtist) {
        filterArray = this.state.rowAllData
        } 

        

        //get only artists
        const OnlyArtists = filterArray.map(item => item.artist);

        //unique artist + count
        
        const uniqueArtists = OnlyArtists.reduce((b,c) => (
            (b[b.findIndex(d => d.artist===c)] ||
            // eslint-disable-next-line 
            b[b.push({artist: c, count: 0})-1]).count++,b), 
            []
        );
        const uniqueArtistsSorted = uniqueArtists.sort(function(a, b){
            return b.count-a.count
        })
        
        //map Artists with count to ArtistOptions
        const ArtistOptions = uniqueArtistsSorted.map(value => { 
            return {value: value.artist, label: value.artist + ' (' + value.count + ')'}; 
        });

        const OnlyYear = filterArray.map(item => item.year);
        const OnlyYearSorted = OnlyYear.sort();
        const uniqueYear = OnlyYearSorted.reduce((b,c) => (
            (b[b.findIndex(d => d.year===c)] ||
            // eslint-disable-next-line 
            b[b.push({year: c, count: 0})-1]).count++,b), 
            []
        );
        const uniqueYearSorted = uniqueYear.sort(function(a, b){
            return b.count-a.count
        })
        const YearOptions = uniqueYearSorted.map(value => { 
            return {value: value.year, label: value.year + ' (' + value.count + ')'}; 
        });


        
    
        return (

            <main className="my-5 py-5">
                <Container>
                    <div className="row">  
                        <div className="col-sm-2 mb-3"> 
                            <img src='https://premium.vgc.no/v2/images/582b4ce1-f8d7-4ede-8cac-9bdb9923125f?fit=crop&h=2898&w=1900&s=870f0c8c50c15840e7514aa7fbd4743a3d460cc8' 
                            alt="500singles" className="img-fluid rounded mx-auto d-block" />
                        </div>

                        <div className="col-sm-5">
                            <h3>My Concert log</h3>
                            
                                
                                <p>
                                    In progress... starting with the "big" guns.
                                </p>

                        </div>

                        <div className="col-sm-5">
                            <div className="row">   
                                <div className="col">
                                    <h4>Filters</h4>
                                </div>
                            </div>

                            <div className="row pb-2">  
                                <div className="col-7">
                                    <Select
                                        value={selectedOptionArtist}
                                        onChange={this.selectedOptionArtist}
                                        options={ArtistOptions}
                                        placeholder='Select Artist'
                                    />
                                </div>
                                <div className="col-5"> 
                                        <Button color="primary"  onClick={() => this.clearFilterArtist()} >Reset filter</Button>
                                </div>
                            </div>

                            <div className="row pb-2">  
                                <div className="col-7">
                                    <Select
                                        value={selectedOptionYear}
                                        onChange={this.selectedOptionYear}
                                        options={YearOptions}
                                        placeholder='Select Year'
                                    />
                                </div>
                                <div className="col-5"> 
                                        <Button color="primary"  onClick={() => this.clearFilterYear()} >Reset filter</Button>
                                </div>
                            </div>
             
                        </div>
                    </div>

        
        <div className="row pr-2 pl-2 pt-1">
                <div
                    id="concert_log"
                    style={{ height: '2000px', width: '1200px' }}
                    className="ag-theme-balham" 
                    >
                
                    <AgGridReact
                    
                    onGridReady={ params => this.gridApi = params.api }
                        rowSelection="multiple"
                        pagination={true}
                        columnDefs={this.state.columnDefs}
                        rowData={filterArray}
                        rowHeight="30"
                        >

                    </AgGridReact>
                    
                </div>
        </div>

        {/*< div className="row">
            <div className="col-sm-8">
                <p>col-lg-4</p>
            </div>
            <div className="col-sm-4">
                <p>col-lg-4</p>
            </div>
            <div className="col-sm-12">
                <p>col-lg-4 col-md-4 col-sm-12</p>
            </div>
        </div> */}
        
      </Container>
    </main>

        )
    }
}

export default ConcertLog