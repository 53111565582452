import React, { Component } from 'react';
import '../lists.scss';
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";



class DylanConcertslondon extends Component {

    

    render() {


        const Concerts = this.props.concertItems;

        const ConcertsReturn = Concerts.map(value => {

            

            return (
                <React.Fragment key={value.id}>

                    <div className="row pt-3 pb-3 border-bottom border-top border-info">
                        
                        <div className="col-sm-2 text-center">
                            
                            <div className="Song2 rounded mb-2" align="center">
                                <b>{value.gig_date}</b>
                            </div>

                            <div align="center">
                            {
                            (() => {
                            if (value.picture_link) {
                            return  <img src={value.picture_link} alt="" 
                            className="img-fluid rounded"></img>
                            }
                            return <b>No picture</b>
                            })()
                            }
                            </div>

                            

                            <div align="center">
                                
                                <p className="mt-2"><b>{ value.tour||'' }</b></p> 
                            
                            </div>

                            {
                                (() => {
                                if (value.spotify) {
                                    return <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Spotify" src="/images/spotify.png" width="28" height="28" /></a>
                                    
                                }
                                return <p></p>;
                                })()
                            }

                        </div>

                        <div className="col-sm-6 pb-3 border-right Description">
                            <h4>{value.venue}</h4>
                            
                            <div className="Header4 p-2 rounded">
                                    <b>Musicians:</b><br />
                                    
                                    <ReadMoreAndLess
                                        charLimit={225}
                                        readMoreText={" more ▼"}
                                        readLessText={" less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                            >
                                            { value.musicians||'' }
                                    </ReadMoreAndLess>
                                    
                            </div> 

                            

                            <div className="Description">
                                        <br /><b>Setlist:</b><br />
                                        <ReadMoreAndLess
                                            charLimit={200}
                                            readMoreText={" more ▼"}
                                            readLessText={" less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                                >
                                                { value.setlist||'' }
                                        </ReadMoreAndLess><br />
                            </div> 

                        </div>
                            
                        <div className="col-sm-4 pb-4">

                                {
                                    (() => {
                                    if (value.youtube||value.vimeo) {
                                        return <div><div className="Header2"><b>{value.title||value.alt_title||'No Audio/Video'}</b></div>
                                                        <ReactPlayer
                                                            url={ value.youtube||value.vimeo }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="300"
                                                            
                                                        />
                                                        
                                                </div>
                                    }
                                    
                                    return <div><b>No Video/audio</b>
                                                        
                                                </div>
                                    })()
                                }    
                        
                        </div>
                        
                            

                    </div>

                </React.Fragment>          
            )

        })

        return (
            <div>
                { ConcertsReturn }
            </div>
        )
    
    }

}

export default DylanConcertslondon