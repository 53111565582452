import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayAlbumsDylan } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";

const AnyDayAlbumsDylan = (props) => {
  const [albums, setAlbums] = useState([])
  //const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchAlbums = () => {
        axios.get(dbURLAnyDayAlbumsDylan + props.dateSelected)
        .then(response => {
          //setIsLoading(false)
          setAlbums(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchAlbums()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {albums.length > 0 && (
        <div id="albums">
            <div className="row TodayTitle mt-4">
                <div className="col-sm-12"> 
                    <div>&nbsp;<b>Albums</b>
                    </div>
                </div>
            </div>
          {albums.map(value => (
            <div key={value.id}>
                
                <div className="row py-3 border-bottom border-info">
                    {/* 1 kolonne */}
                    <div className="col-sm-3">

                        {
                            (() => {
                            if (value.rank) {
                            return  <div className='Song text-center mb-1'><b>{value.rank} - {value.title}</b></div>  
                            }
                            return <div className='Song text-center mb-1'><b>{value.title}</b></div> 
                            })()
                        }

                        <div align="center">
                        <img src={value.picture} alt="" height="250" width="250" 
                        className="rounded mx-auto d-block"></img>
                        </div>
                        
                        {
                            (() => {
                            if (value.spotify && value.youtube) {
                                return <div>
                                            <div className="row">
                                                <div className="col-6 pr-3" align="Right">
                                                    <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                        <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=40,height=40" /></a>
                                                        &nbsp;&nbsp;
                                                        <br />
                                                        <b>spotify</b>
                                                </div>
                                                <div className="col-6" align="Left">
                                                    <div>&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                        <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a>
                                                        <br />
                                                        <b>YouTube</b>
                                                        </div>  
                                                </div>
                                            </div>
                                        </div>
                            }
                            if (value.spotify && !value.youtube) {
                                return <div>
                                        <div className="row">
                                            <div className="col" align="center">
                                                <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                                        <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=40,height=40" /></a>
                                                        &nbsp;&nbsp;
                                                        <br />
                                                        <b>spotify</b>
                                            </div>
                                        </div>
                                </div>
                            } if (value.youtube && !value.spotify) {
                                return <div>
                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div align="center">&nbsp;&nbsp;&nbsp;<a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a>
                                                <br />
                                                <b>YouTube</b>
                                            </div>  
                                </div>
                            } return 
                            
                            })()
                        }

                        <div className="row">
                            <div className='col'>
                                {   
                                    (() => {
                                    if (value.producer) {
                                    return <div className='mt-2'>
                                            <b>Producer(s)</b> <br />
                                                <div className="factText p-1">{value.producer}</div>
                                            </div>
                                    } 
                                    return 
                                    })()
                                }
                            </div>
                        </div>
                        
                               
                            

                        {
                            (() => {
                            if (value.track_listing) {
                                return <div className="row pt-2">
                                            <div className="col-12">
                                                <b>Track listing</b><br />
                                                <div className="bg-light">
                                                    <div className="py-1 px-1 Description">
                                                        <ReadMoreAndLess
                                                                charLimit={500}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.track_listing}
                                                        </ReadMoreAndLess> 
                                                
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            } 
                            return 
                            })()
                        }

                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-5 pb-3 border-right">

                        <div className="Description"> 
                                <ReadMoreAndLess
                                        charLimit={value.desc_album_lists|1000}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess> 

                                    <br />
                                    <em> -> <a href={value.wiki}>more info..</a></em>
                        </div>

                        <div className="pt-3">
                            {
                                (() => {
                                    if (value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <p className="quote3">{value.comment}</p> 
                                                
                                                {
                                                    (() => {
                                                        if (value.source_txt_details) {
                                                            return <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                                                        }
                                                        return <div className="blockquote-footer"><i>{value.source_txt}</i></div>

                                                    })()

                                                }

                                            </div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.comment_more) {
                                    return <div className="card bg-light mt-2 py-2 px-2">
                                                <p className="quote3">{value.comment_more}</p> 
                                                <div className="blockquote-footer"><i>{value.source_more}</i></div>
                                            </div>
                                    }
                                    return 
                                })()
                            }

                            
                            

                        </div>

                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-4 pb-4">

                        <div className="row">

                            <div className="col pr-0 mr-2">
                                    <div>
                                        {
                                            (() => {
                                                if (value.rd_text) {
                                                return <div><b>Recorded </b><br />
                                                <div className="DateTxt p-1">{value.rd_text}</div>
                                                
                                                </div>
                                                }
                                                if (value.recorded) {
                                                    return <div><b>Recorded </b><br />
                                                        <div className="Date p-1">{value.recorded}</div>
                                                        
                                                        </div>
                                                }
                                            })()
                                        }

                                        {   
                                            (() => {
                                            if (value.studio_txt) {
                                            return <div>
                                                        <b>Location</b> <br />
                                                        <div className="factText p-1">{value.studio_txt}</div>
                                                        
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                        {   
                                            (() => {
                                            if (value.engineer) {
                                            return <div>
                                                        <b>Engineer</b> <br />
                                                        <div className="factText p-1">{value.engineer}</div>
                                                        
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                    </div>

                            </div>

                            <div className="col pl-1">

                                    {
                                        (() => {
                                        if (value.album_released) {
                                        return <div><b>Released</b> <br />
                                        <div className="Date p-1">
                                            {value.album_released}</div></div>
                                        } else if (value.ar_text) {
                                        return <div><b>Released</b> <br />
                                        <div className="Date p-1">
                                            {value.ar_text}</div></div>
                                        }
                                        })()
                                    }
                                    

                                    
                                    <div>
                                    <b>Label</b> <br />
                                    <div className="factText p-1">{value.label}</div>
                                    </div>
                                    {   
                                        (() => {
                                        if (value.genre) {
                                        return <div>
                                                <b>Genre</b> <br />
                                                <div className="factText p-1">{value.genre}</div>
                                            </div>
                                        } 
                                        return 
                                        })()
                                    }
            
                            </div>

                        </div>

                        <div className="row pt-3">
                            <div className="col-12">
                                {
                                    (() => {
                                    if (value.personnel) {
                                    return  <div><b>Line-up</b> <br />
                                                <div className="factText p-1">  
                                                <ReadMoreAndLess
                                                        charLimit={600}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.personnel}
                                                </ReadMoreAndLess>
                                                </div>
                                            </div>
                                    } 
                                    return <p></p>
                                    })()
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col pt-3 mb-3">
                                    {
                                            (() => {
                                            if (value.comment_2) {
                                            return <div className="card bg-light p-2">
                                                        <p className="quote3">{value.comment_2}</p> 
                                                        
                                                        {
                                                            (() => {
                                                                if (value.source_txt_details_2) {
                                                                    return <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.source_txt_2}</i></div>

                                                            })()

                                                        }

                                                    </div>
                                            }
                                            return 
                                        })()
                                    }

                            </div>

                        </div>

                        {
                            (() => {
                            if (value.video1) {
                                return <div>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <div className='videoTitle pl-2 mx-2' align='center'><strong>Selected tracks</strong></div>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-6 mb-2'>
                                                    <div className="card bg-light py-2">
                                                        <div className="mx-1">  
                                                            <ReactPlayer
                                                                url={ value.video1 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        width="280"
                                                                        height="200"
                                                            />
                                                        </div>
                                                        <div className='DescriptionBig2 pl-1'>
                                                            <strong>{value.desc1}</strong>
                                                        </div>
                                                    </div>
                                                    </div>
                                                <div className='col-sm-6 mb-2'>
                                                    {
                                                        (() => {
                                                        if (value.video2) {
                                                            return <div className="card bg-light py-2">
                                                                        <div className="mx-1">  
                                                                            <ReactPlayer
                                                                                url={ value.video2 }
                                                                                        light='true'
                                                                                        //light
                                                                                        controls
                                                                                        width="280"
                                                                                        height="200"
                                                                            />
                                                                        </div>
                                                                        <div className='DescriptionBig2 pl-1'>
                                                                            <strong>{value.desc2}</strong>
                                                                        </div>
                                                                    </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                    </div>
                            }
                            return 
                            })()
                        }
                        {
                            (() => {
                            if (value.video3) {
                                return <div>
                                            <div className="row mt-1">
                                                <div className='col-sm-6 mb-2'>
                                                    <div className="card bg-light py-2">
                                                        <div className="mx-1">  
                                                            <ReactPlayer
                                                                url={ value.video3 }
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        width="280"
                                                                        height="200"
                                                            />
                                                        </div>
                                                        <div className='DescriptionBig2 pl-1'>
                                                            <strong>{value.desc3}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2'>
                                                    {
                                                        (() => {
                                                        if (value.video4) {
                                                            return <div className="card bg-light py-2">
                                                                        <div className="mx-1">  
                                                                            <ReactPlayer
                                                                                url={ value.video4 }
                                                                                        light='true'
                                                                                        //light
                                                                                        controls
                                                                                        width="280"
                                                                                        height="200"
                                                                            />
                                                                        </div>
                                                                        <div className='DescriptionBig2 pl-1'>
                                                                            <strong>{value.desc4}</strong>
                                                                        </div>
                                                                    </div>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                            }
                            return 
                            })()
                        }

                    </div>
                </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AnyDayAlbumsDylan
