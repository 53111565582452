import React, { Component } from 'react';
import '../lists.scss';
import ReadMoreAndLess from "react-read-more-less";


class DylanLondonLocations extends Component {

     
    

    render() {

        const Locations = this.props.locationItems;

         
        

        const LocationsReturn = Locations.map(value => {

            let typeLabel
            
            if (value.dylan_type === 'Performance') {
                typeLabel = 'First performance'; 
            }   
            if (value.dylan_type === 'Lodging') {
                typeLabel = 'First visit'; 
            }  
            if (value.dylan_type === 'Pleasure') {
                typeLabel = 'Visited'; 
            }      

            return (

                

                <React.Fragment key={value.id}>
                    <div className="row rounded p-1 videoTitle">
                        <div className="col mb-1"> 
                            <b>{value.name}        </b>&nbsp;&nbsp;
                            <div className="btn btn-primary btn-xs">{value.type}</div>
                            
                        </div>
                       
                            
                        
                    </div>

                    <div className="row pt-3 pb-3">
                        <div className="col-sm-4">
                            <div>
                                <b><i>Address</i> </b>
                                
                            </div>
                            
                            
                           
                            <div className="factText rounded p-1">{value.address}</div>  <br />
                            <div>
                                <div className="py-2 px-2 Description">
                                    <ReadMoreAndLess
                                            charLimit={400}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                        >
                                        {value.description}
                                    </ReadMoreAndLess>
                                    
                                </div>
                                <p className="Description">
                                        <em> -> <a href={value.more_datails_url}>more details</a></em>
                                    </p>
                                    
                            </div>
                        </div>
                    

                        <div className="col-sm-4">
    
                            <h5>Bob Dylan</h5>
                            <div className="btn btn-primary btn-xs">{value.dylan_type}</div><br /><br />
                            <b>{typeLabel} </b><br />
                            <div className="Date p-1 rounded">{value.first_date||value.year}</div><br />
                            <b>Info </b><br />
                            <div className="p-1 rounded factText">
                                <ReadMoreAndLess
                                        charLimit={500}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.comment}
                                </ReadMoreAndLess>
                        
                            </div>
                                      

                        </div>

                        <div className="col-sm-4 align-self-center">


                                <div>
                                    <img src={value.pic_link} alt="" height="270" width="270" 
                                            className="rounded mx-auto d-block"></img>
                                </div>
                                      

                        </div>

                    </div>



                </React.Fragment>

            )
        })

        return (
            <div>
                { LocationsReturn }
            </div>
        )
    
    
    }

}

export default DylanLondonLocations