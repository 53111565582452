import React, { Component } from 'react'
import './lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Container, Button } from 'reactstrap';
import Select from 'react-select';
import { dbURL } from './Constants';
import { animateScroll as scroll } from "react-scroll";
//import ReadMoreAndLess from "react-read-more-less";
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';

//import Select from 'react-select';

class Posts extends Component {

    state = {
        columnDefs: [
            //{headerName: "List", field: "name", resizable: true, width: 400, cellStyle: { 'font-size': '14px' }},
            //{headerName: "link", field: "page", filter: true, sortable: true, width: 200, cellStyle: { 'font-size': '14px' }},
            {
                field: 'page',
                headerName: 'List (click on name to open)',
                cellRenderer: function(params) {
                    let pageData = params.data.page;
                    let nameData = params.data.name;
                    let newLink = `<a href= "https://www.musicthisday.com/${pageData}">${nameData}</a>`;
                    return newLink;
                },
                sort: 'asc', sortable: true, width: 500, cellStyle: { 'font-size': '14px' }
                //flex: 1,
            },
            {headerName: "Category", field: "category", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }},
            {headerName: "Year", field: "year", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }},
          ],
        rowAllData: [],
        PostsInProgress: [],
        selectedOptionArtist: null,
        GridOn: false,
        SearchString: '',
        selectedOptionRange: ''
      }

    getPostItems(){
        axios.get(dbURL + 'posts')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 

    getPostsInProgressItems(){
        axios.get(dbURL + 'postsinprogress')
          .then(response => {
            this.setState({
                PostsInProgress: response.data
            });
          });
      }
    
    componentDidMount() {
        this.getPostItems()
        this.getPostsInProgressItems()
      }

    selectedOptionRange = (selectedRange) => {
        this.setState({ selectedOptionRange: selectedRange });
    } 
    
      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

    selectedOptionArtist = (selectedArtist) => {
        this.setState({ selectedOptionArtist: selectedArtist });
    } 
    
    clearFilterArtist(){
        this.setState({
            selectedOptionArtist: null, 
            })
        } 

     onChangeGrid = () => {
        this.setState(initialState => ({
            GridOn: !initialState.GridOn,
        }));
     }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {

        const { selectedOptionArtist } = this.state;
        const { SearchString } = this.state;
        const { selectedOptionRange } = this.state;
        const PostsInProgress = this.state.PostsInProgress;

        //Filter logic
        const allPosts = this.state.rowAllData
        
        let filterArray, rangeArray
        

        if (selectedOptionRange === 'People') {
            rangeArray = allPosts.filter(e => e.category === 'People');  
            } 
        if (selectedOptionRange === 'Albums') {
            rangeArray = allPosts.filter(e => e.category === 'Albums');  
            } 
        if (selectedOptionRange === 'Songs') {
            rangeArray = allPosts.filter(e => e.category === 'Songs');  
            } 
        if (selectedOptionRange === 'Sessions') {
            rangeArray = allPosts.filter(e => e.category === 'Sessions');  
            } 
        if (selectedOptionRange === 'Concerts') {
            rangeArray = allPosts.filter(e => e.category === 'Concerts');  
            } 
        if (selectedOptionRange === 'Interviews') {
            rangeArray = allPosts.filter(e => e.category === 'Interviews');  
            } 
        if (selectedOptionRange === 'Bootleg') {
            rangeArray = allPosts.filter(e => e.category === 'Bootleg');  
            } 
        if (selectedOptionRange === 'Video') {
            rangeArray = allPosts.filter(e => e.category === 'Video');  
            } 
        if (selectedOptionRange === 'All') {
            rangeArray = allPosts;  
            } 
        if (!selectedOptionRange) {
            rangeArray = allPosts;
        }
         if (selectedOptionRange === 'unfinished') {
            rangeArray = PostsInProgress;
        }  


        function SearchLists(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < rangeArray.length; i++) {

                if (rangeArray[i].searchname.includes(searchText))
                
                    {searchArray.push(rangeArray[i]);}
                    
            } 
            //console.log(searchArray);
            return searchArray
        }


        if (!selectedOptionArtist && SearchString) {
            filterArray = SearchLists(SearchString.toLowerCase());
            }  

            if (selectedOptionArtist) {
                filterArray = rangeArray.filter(e => e.artist === selectedOptionArtist.value);
                } 
            
            
            if (!selectedOptionArtist && !SearchString) {
                filterArray = rangeArray;
                } 
 
 
         const OnlyArtist = filterArray.map(item => item.artist);
         const OnlyArtistSorted = OnlyArtist.sort();
         const uniqueArtist = OnlyArtistSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.artist===c)] ||
             // eslint-disable-next-line 
             b[b.push({artist: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueArtistSorted = uniqueArtist.sort(function(a, b){
            return b.count-a.count
        })
         const ArtistOptions = uniqueArtistSorted.map(value => { 
             return {value: value.artist, label: value.artist + ' (' + value.count + ')'}; 
            //}
         });
        

        const PostItems = filterArray.map(value => {

            let page = '/' + value.page;

            return (
                <React.Fragment key={value.id}>

                <div className="row pt-3 pb-3 border-bottom border-top border-info">
                    
                    <div className="col-sm-3 pb-3 text-center">
                        
                        <div align="center">
                        {
                        (() => {
                        if (value.picture) {
                          return <img src={value.picture} alt=""
                          className="rounded img-fluid" />
                          
                        }
                        return <img src={value.picture_link} alt="" className="rounded img-fluid"  />
                        })()
                        }
                        </div>

                    </div>

                    <div className="col-sm-6 pb-3 border-right lyrics">
                        
                        <p className='Header'><b><Link to={page}>{value.name}</Link></b></p>

                         <ReactMarkdown>
                            {value.description}                       
                        </ReactMarkdown>

                    </div>
                    
                    <div className="col-sm-3 pb-3">

                        <div className="row">
                            <div className="col-4 mr-2"><b>Category: </b></div>
                            <div className="col-6 ml-2">{value.category}</div>
                        </div>
                        
                        <div className="row">
                            <div className="col-4 mr-2"><b>Year: </b></div>
                            <div className="col-6 ml-2">{value.year}</div>
                        </div>

                        {
                            (() => {
                            if (value.publish_date) {
                            return <div>
                                    <div className="row">
                                    <div className="col-4 mr-2"><b>Published: </b></div>
                                    <div className="col-6 ml-2">{value.publish_date}</div>
                                </div>
                                </div>
                            }
                            return
                            })()
                        }

                        <br /><br />
                        {
                            (() => {
                            if (value.in_progress) {
                            return  <b><span className="p-1 mb-2 bg-info text-white">In progress</span></b>
                            }
                            return <p></p>
                            })()
                        }  
                    
                    </div>
                           

                </div>

                </React.Fragment>          
            )

        })
    
        return (

            <main className="my-1 py-2">
                <Container>
                
                   
                    <div className="row">
                        <div className="col-sm-3 mb-3">
                            <img src='https://borntolisten.com/wp-content/uploads/2020/10/Songs.jpg' 
                            alt="Rs100dylan" className="img-fluid rounded mx-auto d-block" />
                            <br />
                            <b>Count: {filterArray.length}</b>
                        </div>

                        <div className="col-sm-5">
                                <h3>Music Posts</h3>
                                <p>
                                Here are all posts published @ musicthisday.com
                                </p>
                                <p>
                                    For lists <a href='/lists'>Click here</a>
                                </p>
                                <p><strong>click on post name to visit post.</strong></p>
                                
                        </div>
                        
                        <div className="col-sm-4 mb-4">
                                {(!this.state.SearchString)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h5>Filters</h5>
                                    </div>
                                </div>
                                }

                                {(!this.state.SearchString)  &&
                                    <div className="row pb-2">  
                                        <div className="col-7">
                                                <Select
                                                    //styles={{control: customControlStyles}}
                                                    value={selectedOptionArtist}
                                                    onChange={this.selectedOptionArtist}
                                                    options={ArtistOptions}
                                                    placeholder='Select artist'

                                                />
                                        </div>
                                            <div className="col-5"> 
                                                <button type="button" className="btn btn-primary small"  onClick={() => this.clearFilterArtist()} >Reset filter</button>
                                        </div>
                                    </div>
                                }

                                
                                {!this.state.selectedOptionArtist  && 
                                <div className="row">   
                                        <div className="col">
                                        <h6>Search</h6>
                                    </div>
                                </div>
                                }

                                {!this.state.selectedOptionArtist  && 
                                    <div className="row pb-2">  
                                            <div className="col-7">
                                            <input 
                                                className="form-control" 
                                                type="text" 
                                                value={SearchString} 
                                                onChange={this.handleSearchChange} 
                                                placeholder="Search list name" 
                                            />
                                            </div>
                                            <div className="col-5 pl-0">
                                                <button type="button" className="btn btn-primary"  
                                                onClick={() => this.clearSearchField()} >Reset search</button>
                                            </div>
                                    </div>
                                }

                                <div className="form-check">
                                            <label className="form-check-label">
                                            <input type="checkbox"
                                                checked={this.state.GridOn}
                                                onChange={this.onChangeGrid}
                                                className="form-check-input"
                                            />
                                            Change to Grid
                                            </label>
                                        
                                </div>

                        </div>
                    </div>
        
                    <div className="row">
                        <div className='col-sm-4 pb-2' align="Left">
                            {this.state.selectedOptionRange === '' && 
                            <Button className="btn btn-primary btn-xs active focus" onClick={() => this.selectedOptionRange('All')}>All categories</Button>
                            }
                            {this.state.selectedOptionRange !== '' && 
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('All')}>All categories</Button>
                            }
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('People')}>People</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Albums')}>Albums</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Songs')}>Songs</Button>
                            &nbsp;&nbsp;
                        </div>
                        <div className="col-sm-3 pb-2" align="Left">
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Sessions')}>Rec. Sessions</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Concerts')}>Concerts</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Interviews')}>Interviews</Button>
                            &nbsp;&nbsp;
                            
                            
                        </div>
                        <div className="col-sm-3 pb-2" align="Left">
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Bootleg')}>Bootlegs</Button>
                            &nbsp;&nbsp;
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('Video')}>Videos</Button>
                            &nbsp;&nbsp;
                            {(window.location.hostname === "localhost") &&
                            <Button color="info" size="sm" onClick={() => this.selectedOptionRange('unfinished')}>Posts in progress</Button>
                            }
                        </div>


                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
        
                    {!this.state.GridOn && 
                        <div className="row">
                            <div className="col">

                                {
                                    (() => {
                                    if (allPosts.length < 1) {
                                        return <div><br /><br />
                                            <h3><i>Loading...</i></h3>
                                            </div>
                                    }
                                    return <div>{PostItems}</div>
                                    })()
                                }


                                    
                            </div>
                        </div>
                    }

                    {this.state.GridOn && 
                        <div className="row pr-2 pl-2 pt-3">
                            <div
                                id="myGrid"
                                style={{ height: '2000px', width: '1200px' }}
                                className="ag-theme-balham" 
                                >
                            
                                <AgGridReact
                                
                                onGridReady={ params => this.gridApi = params.api }
                                    rowSelection="multiple"
                                    pagination={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={filterArray}
                                    >
                                    
                                </AgGridReact>
                                
                            </div>
                        </div>
                    }
      
                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                        <b>Scroll to top</b>
                                    </button></p>
              
                </Container>
            </main>

        )
    }
}

export default Posts