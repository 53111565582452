import { useMediaQuery } from 'react-responsive'
import React, { useState } from 'react';

//Skal brukes steder hvord bilde linker lett blir BROKEN
export const ImageWithFallback = ({ src, alt }) => {
    const [isBroken, setIsBroken] = useState(false);
  
    // Handle the onError event to mark the image as broken
    const handleImageError = () => {
      setIsBroken(true);
    };
  
    return (
      // Conditionally render the img tag only if the image is not broken
      !isBroken ? (
        <img src={src} alt={alt} className="img-fluid rounded" onError={handleImageError} />
      ) : null // If broken, render nothing
    );
  };
  


export const Mobile = () => {

    // MTD tar vi tablets under paraplyen mobile
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

    return isMobile;
};

export const SelectProperty = (array, filter) => {

    const propName = filter;

    const OnlyProperty = array.map(item => item[propName]);
    const OnlyPropertySorted = OnlyProperty.sort();
    const uniqueProperty = OnlyPropertySorted.reduce((b,c) => (
        (b[b.findIndex(d => d[propName]===c)] ||
        // eslint-disable-next-line 
        b[b.push({[propName]: c, count: 0})-1]).count++,b), 
        []
    );
    const uniquePropertySorted = uniqueProperty.sort(function(a, b){
    return b.count-a.count
    })
    const PropertyOptions = uniquePropertySorted.map(value => { 
        return {value: value[propName], label: value[propName] + ' (' + value.count + ')'}; 
    //}
    });
    
    return PropertyOptions;
};

export const SearchLists = (array, field, searchText) => {

    let searchArray = [];
    let searchLists;
    
    for (let i = 0; i < array.length; i++) {

        if (field === 'name') {
            searchLists = array[i].name.toLowerCase();
        }
        if (field === 'title') {
            searchLists = array[i].title.toLowerCase();
        }
        if (field === 'artist') {
            searchLists = array[i].artist.toLowerCase();
        }
        if (field === 'searchtitle') {
            searchLists = array[i].searchtitle.toLowerCase();
        }
        if (field === 'searchlyrics') {
            searchLists = array[i].searchlyrics.toLowerCase();
        }
        if (field === 'searchtracks') {
            searchLists = array[i].searchtracks.toLowerCase();
        }
        if (field === 'search_setlist') {
            searchLists = array[i].search_setlist.toLowerCase();
        }
        if (field === 'searchdescription' && array[i].searchdescription !== null) {
            searchLists = array[i].searchdescription.toLowerCase();
        }

        if (searchLists.includes(searchText))
        
            {searchArray.push(array[i]);}
    } 
    return searchArray;

};

export const DecadeArrayChoice = (decade) => {

    let DecadeArray

    if (decade === 1960) {
        DecadeArray = ['1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969'];
    }

    if (decade === 1970) {
        DecadeArray = ['1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979'];
    }
    
    if (decade === 1980) {
        DecadeArray = ['1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989'];
    }

    return DecadeArray

};



