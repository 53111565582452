import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURLists, dbURLInterviewsAll } from '../Constants';
import '../lists.scss';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
//import Select from 'react-select';
//import { Link } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import SEO from "../../SEO";

const InterviewsAll= (props) => {
    const [interviews, setInterviews] = useState([])
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [searchString, setSearchString] = useState('')
    //const [searchStringDescription, setSearchStringDescription] = useState('')

    let filterInterviews, searchWords
    
    useEffect(() => {
        const fetchInterviews = () => {
          axios.get(dbURLInterviewsAll)
          .then(response => {
            setLoading2(false)
            setInterviews(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchList = () => {
            axios.get(dbURLists + 'all-interviews')
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }
        fetchList()
        fetchInterviews()
      }, [props.slug])
  
    if (error) return `Error: ${error.message|errorList.message}`;


     const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    const cleanInterviews = interviews.filter(e => e.transcript_full !== null)

    function SearchFullTranscript(searchText) { 
            let searchArray = [];
            let searchFullTranscript;
            
            for (let i = 0; i < cleanInterviews.length; i++) {

                //ta høyde for at transcript_full kan være NULL
    
                searchFullTranscript = cleanInterviews[i].transcript_full.toLowerCase();
    
                if (searchFullTranscript.includes(searchText))
                
                    {searchArray.push(cleanInterviews[i]);}
            } 
            return searchArray
        }

    /*
     const handleSearchChangeDescription = (e) => {
        setSearchStringDescription(e.target.value);
      };
    
    const clearSearchFieldDescription = (e) => {
        setSearchStringDescription('')
        }

    function SearchQuotesDescription(searchText) { 
        let searchArray = [];
        let searchQuoteDescriction;
        
        for (let i = 0; i < quotes.length; i++) {

            searchQuoteDescriction = quotes[i].comment.toLowerCase();

            if (searchQuoteDescriction.includes(searchText))
            
            //if (quotes[i].quote.includes(searchText))
            
                {searchArray.push(quotes[i]);}
        } 
        return searchArray
    } */


    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (searchString) {
        searchWords = searchString.split(/\s/).filter(word => word)
        filterInterviews = SearchFullTranscript(searchString.toLowerCase());
        } 
    
    if (!searchString) {
        filterInterviews = interviews;
    }


    /* 
    if (searchStringDescription) {
        searchWordsDescription = searchStringDescription.split(/\s/).filter(word => word)
        filterQuotes = SearchQuotesDescription(searchStringDescription.toLowerCase());
    }
     */

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, pic_link, page } = list[0];

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>
            
                <div className="row mb-3 gx-5">
                    <div className="col-sm-4">

                        <div className='mt-3'><img src={value.picture_link} alt="alt" className="img-fluid" /> </div>
                        <div className="mt-2 quote2">
                            <b>Sources/references:</b>
                                <ul>
                                    <li><b>Every Mind Polluting Word</b><br />
                                    assorted Bob Dylan utterances (by Artur Jarosinski?)
                                    </li>
                                    <li><a href="https://www.interferenza.net/bcs/index.html">
                                    Bread Crumb Sins</a></li>
                                    <li><a href="https://www.expectingrain.com/">
                                    Expecting Rain</a></li>
                                    <li><a href="http://www.bjorner.com/still.htm">
                                    Still On The Road</a></li>
                                    <li>
                                        <a href="https://www.amazon.com/Life-Stolen-Moments-Clinton-Heylin/dp/0711956693">
                                        A Life in Stolen Moments (Clinton Heylin)</a>
                                    </li>
                                </ul>
                        </div>
                        
                    </div>
                    <div className="col-sm-8 Description">
                            <div>
                                <h2 align='center'>{value.name}</h2>
                                <div className="SubTitle pl-3 py-1 mb-2">{value.sub_title}</div>
                            </div>
                            <div className="p-2">
                                {value.description} 
                            </div>

                            {
                                (() => {
                                if (value.comment1) {
                                    return <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="quote2">{value.comment1}</div>
                                                    <div className="blockquote-footer mt-1"><i>{value.source1}</i></div>
                                            </div>
                                }
                                return 
                                })()
                            }  
                            <div className="row">   
                                    <div className="col">
                                        <h4>Search</h4>
                                </div>
                            </div>
                            <div className="row mb-1">  
                                <div className="col-sm-5 mt-1">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={500}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all FULL transcripts" 
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-7 mt-1">
                                    <button type="button" className="btn btn-info"  
                                        onClick={() => clearSearchField()} > Reset </button>
                                </div>
                            </div>

                            <div className="d-flex p-2">
                                {((window.location.hostname === "localhost") || (value.in_progress)) &&
                                    <div className="text-center">
                                            <div className="btn btn-light btn-sm mt-2"><b>In progress</b></div>
                                        <div className="text-center">
                                            <div className="btn btn-info btn-sm mt-2">total count: {interviews.length}</div>
                                        </div> 
                                    </div>
                                } 
                                {searchString &&
                                    <div className="text-center">
                                        <div className="btn btn-info btn-sm mt-2">hits: {filterInterviews.length}</div>
                                    </div> 
                                }
                            </div>
                            
                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const InterviewItems = filterInterviews.map(value => {

        let DescCharLimit, TranscriptLimit;

        if (value.desc_char_limit) {
        DescCharLimit = value.desc_char_limit;
            }
        else {
                DescCharLimit = 500
            }
        
        if (value.interview_transcript) {
            TranscriptLimit = value.interview_transcript;
                }
        else {
            TranscriptLimit = 700
            }

        return (

            <React.Fragment key={value.id}>
                
                    <div className="row videoTitle2 mb-2">
                        <div className="col-sm-12 pt-2"> 

                            <h4>{value.title}</h4>
                            
                            {
                                (() => {
                                if (value.date_interview) {
                                return <div><b>{value.location}, {value.date_interview}</b>
                                        </div>
                                }
                                return <div><b>{value.location}, {value.date_txt}</b>
                                        </div>
                                })()
                            }

                            {
                                (() => {
                                if (value.source && !value.transcript) {
                                    return <div className="Font13 mt-1"><b>Source: {value.source}</b> 
                                            </div>
                                }
                                return 
                                })()
                            }  

                        </div>
                    </div>

                    {
                        (() => {
                        if (value.transcript) {
                            return <div className="row pt-3 pb-3">
                        
                                        {/* kolonne 1 */}
                                        <div className="col-sm-2">
                                            {
                                                (() => {
                                                if (value.picture) {
                                                    return <div className='mt-2'><img src={value.picture} 
                                                    alt="alt" className="img-fluid" /> </div>
                                                }
                                                return 
                                                })()
                                            }
                
                                            {
                                                (() => {
                                                if (value.interviewed_by) {
                                                    return <div className='mt-3'><b>Interviewer</b> <br />
                                                                <div className='factText p-1'>
                                                                    <div>{value.interviewed_by}</div>
                                                                </div>
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }           
                
                                            {
                                                (() => {
                                                if (value.source) {
                                                    return <div className='mt-3'><b>Source</b> <br />
                                                                <div className='factText p-2'>
                                                                    <div>Every Mind Polluting Word / {value.source}</div>
                                                                </div>
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }       
                
                                        </div>
                                        
                                        {/* kolonne 2 */}
                                        <div className="col-sm-6">
                
                                            {
                                                (() => {
                                                if (value.description) {
                                                    return <div className="Description p-2 border-bottom">
                                                                <ReadMoreAndLess
                                                                        charLimit={DescCharLimit}
                                                                        readMoreText={" more ▼"}
                                                                        readLessText={" less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.description}
                                                                </ReadMoreAndLess>
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                
                                            {
                                                (() => {
                                                if (value.transcript_full && !searchString) {
                                                    return <div className="bg-light mx-1 mt-3">
                                                                <div className="py-2 px-2 factText">
                                                                    <ReadMoreAndLess
                                                                            charLimit={TranscriptLimit}
                                                                            readMoreText={" more ▼"}
                                                                            readLessText={" less ▲"}
                                                                            readMoreClassName="read-more-less--more"
                                                                            readLessClassName="read-more-less--less"
                                                                        >
                                                                        {value.transcript_full}
                                                                    </ReadMoreAndLess>
                                                                </div>
                                                            </div>
                                                }
                                                if (value.transcript_full && searchString) {
                                                   return <div className="py-2 px-2 factText">
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={searchWords}
                                                                autoEscape={true}
                                                                textToHighlight={value.transcript_full}
                                                            />
                                                        </div> 
                                                }
                                                return 
                                                })()
                                            }
                
                                        </div>
                
                                        {/* kolonne 3 */}
                                        <div className="col-sm-4">
                
                                                {
                                                    (() => {
                                                    if (value.youtube1) {
                                                        return  <div>
                                                                    <div className="Header2 mb-1" align="center"><b>Audio/Video - click to play</b></div>
                                                                    <div>{value.youtube1_title}</div>
                                                                    <div className="border d-flex justify-content-center flex-nowrap">  
                                                                        <ReactPlayer
                                                                            url={ value.youtube + '?t=' + value.youtube1 }
                                                                            //className="react-player"
                                                                            //light='true'
                                                                            controls
                                                                            volume={0.9}
                                                                            width="380px"
                                                                            height="300px"
                                                                            /> 
                                                                    </div>
                                                                </div>
                                                    }
                                                    if (value.youtube2) {
                                                        return <div className="border d-flex justify-content-center flex-nowrap">  
                                                                    {/* <YoutubeEmbedAll embedId={value.youtube_2 + '?t=' + value.youtube2} /> */}
                                                                    <ReactPlayer
                                                                        url={ value.youtube_2 + '?t=' + value.youtube2 }
                                                                        //className="react-player"
                                                                        //light='true'
                                                                        controls
                                                                        volume={0.9}
                                                                        width="380px"
                                                                        height="300px"
                                                                    />
                                                            </div>
                                                    }
                                                    if (value.youtube3) {
                                                        return <div className="border my-2 d-flex justify-content-center flex-nowrap">  
                                                                    <ReactPlayer
                                                                        url={ value.youtube_3 + '?t=' + value.youtube3 }
                                                                        //className="react-player"
                                                                        //light='true'
                                                                        controls
                                                                        volume={0.9}
                                                                        width="380px"
                                                                        height="300px"
                                                                    /> 
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                                }
                
                                                {
                                                    (() => {
                                                    if (value.intro_quote) {
                                                        return <div className="card bg-light mt-2 pt-2 px-2">
                                                            <div className="quote2 mb-2">{value.intro_quote}</div>
                                                            <div className="blockquote-footer pb-2"><i>{value.intro_quote_source}</i></div>
                                                        </div>
                                                    }
                                                    return 
                                                    })()
                                                }
                                                
                                                        
                                        </div>
                
                                    </div>
                        }
                        return 
                        })()
                    } 

                    

            </React.Fragment>

        )

    })
    
    return (
        <main className="my-1 py-1">
            {interviews.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.musicthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {InterviewItems}
                        </div>
                    </div>

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default InterviewsAll