import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayAlbums } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import { Link } from "react-router-dom";

const AnyDayAlbums = (props) => {
  const [albums, setAlbums] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showAllAlbums, setShowAllAlbums] = useState(false);
  const [error, setError] = React.useState(null);
  let largeArray

const ShowAllAlbumsButton = () => {
    setShowAllAlbums(true);
    };
    
    useEffect(() => {
      const fetchAlbums = () => {
        axios.get(dbURLAnyDayAlbums + props.dateSelected)
        .then(response => {
          setIsLoading(false)
          setAlbums(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchAlbums()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  if (isLoading) {
    return <div>
            <main className="my-4 py-4"><div className="container"> 
            <div className="row pt-5"><div className="col">
            <h4>Loading...</h4></div></div></div></main>;
        </div>
}

  //fjerne duplikater
    const albumsUnique  = albums.filter(
        (obj, index) =>
          albums.findIndex((item) => item.id === obj.id) === index
      );
    //console.table(albumsUnique);
    
    //Fjerne objecter over 8 
    if (albumsUnique.length > 8 && !showAllAlbums) {
        largeArray = true
        albumsUnique.length = 8;
    }

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}

      {albumsUnique.length > 0 && (
        <div className="ml-1 mt-2 mt-md-0">
            <div className="row TodayTitle24nobg mx-5">
                <div className="col-sm-12"> 
                    <div className="text-center"><b>Albums</b>
                    </div>
                </div>
            </div>
          {albumsUnique.map(value => (

            <div key={value.id}>

                <div className="row my-2">
                    <div className="col-9">
                        {
                            (() => {
                            if (value.importance === 6) {
                            return <div className="Crown rounded"><b>&nbsp;{value.album}</b></div>    
                            }
                            if (value.importance === 5) {
                            return <div className="Great rounded"><b>&nbsp;{value.album}</b></div>  
                            }
                            return <div className="Good rounded"><b>&nbsp;{value.album}</b></div> 
                            })()
                        } 
                    </div>
                    <div className="col-3">
                        {
                            (() => {
                            if (value.spotify && value.youtube) {
                                return<div>
                                    
                                    <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                                    &nbsp;&nbsp;
                                    <a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=30,height=30" /></a>
                                    
                                    </div>
                            }
                            if (value.spotify) {
                                return <a href={value.spotify} target="_blank" rel="noopener noreferrer">
                                    <img border="0" alt="Spotify" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/f5d47e14-26ce-4521-16de-d135af1d6c00/width=30,height=30" /></a>
                            }
                            if (value.youtube) {
                                return <a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=30,height=30" /></a>
                            }
                            return 
                            })()
                        }
                    </div>
                </div>

                <div className="row ReducedText">

                    <div className="col-4">
                        <div align="center"><img src={value.picture} 
                        alt="" className="img-fluid rounded">
                        </img></div>
                            {
                                (() => {
                                if (value.aka) {
                                return <div align="center" className="SmallText">
                                <b>{value.aka}</b></div> 
                                }
                                return <div align="center" className="SmallText">
                                <b>{value.artist}</b></div> 
                                })()
                            }
                    </div>

                    <div className="col-8">
                        {
                            (() => {
                                if (props.dateSelected === value.released_slug) {
                                    return <div>
                                                <b>Released: </b><br />
                                                <div className="DateInline p-1 rounded"><b>{value.album_released}</b></div>
                                            </div>
                                }
                                if (value.released_slug && (props.dateSelected !== value.released_slug)) {
                                    return <div>
                                    <b>Released:</b><br />
                                    <div className="DateInline p-1 rounded">{value.album_released}</div>
                                    </div> 
                                }
                                return <div>
                                    <b>Released:</b> <br />
                                    <div className="DateInline p-1 rounded">{value.ar_text}</div>
                                    </div>
                            })()
                        }
                        {
                            (() => {
                            if (value.recorded_slug === props.dateSelected) {
                                return <div className="mt-1">
                                <b>Recorded: </b><br />
                                <div className="DateInline p-1 rounded"><b>{value.album_recorded}</b></div>
                                </div> 
                            }
                            if (value.recorded_slug && (value.recorded_slug !== props.dateSelected)) {
                                return <div className="mt-1">
                                <b>Recorded:</b> <br />
                                <div className="DateInline p-1 rounded">{value.album_recorded}</div>
                                </div> 
                            }
                            if (value.rd_text) {
                                return <div className="mt-1">
                                    <b>Recorded</b><br/> 
                                    <div className="factText">
                                        <ReadMoreAndLess
                                                charLimit={70}
                                                readMoreText={" more"}
                                                readLessText={" less"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.rd_text}
                                        </ReadMoreAndLess> 
                                    </div>
                                </div>
                            }
                            return 
                            })()
                        }

                        {
                            (() => {
                            if (value.producer) {
                                return <div><b>Producer</b> <br />
                                        <div className="factText">
                                            <ReadMoreAndLess
                                                charLimit={40}
                                                readMoreText={" more"}
                                                readLessText={" less"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.producer}
                                            </ReadMoreAndLess>
                                        </div>
                                </div>
                            }
                            return 
                            })()
                        }
                    </div>

                </div>

                <div className="row mt-2 ReducedText">

                    <div className="col">
                        {
                            (() => {
                            if (value.description) {
                            return <div className="Description mb-2">
                                        <ReadMoreAndLess
                                            charLimit={150}
                                            readMoreText={" more▼"}
                                            readLessText={" less▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                        <em> -> <a href={value.wikipedia}>wikipedia</a></em>
                                    </div>
                            }
                            return 
                        })()       
                        }

                        {
                            (() => {
                            if (value.listalbumid && value.list_album_unranked_id) {
                                return <div className="card p-2 mb-2 Description bg-light">
                                            <div>
                                            <b>Check out lists:</b><br /> 
                                            <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                                to={value.list_page}>{value.list_name}</Link> (#{value.list_place})</div>
                                            <div>
                                            <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                                to={value.list_unranked_page}>{value.list_unranked_name}</Link>
                                            </div>
                                        </div>
                            }
                            if (value.listalbumid && !value.list_album_unranked_id) {
                            return <div className="card p-2 mb-2 Description bg-light">
                                        <div>
                                        <b>Check out list:</b><br /> <Link style = {{'fontSize': '14px'}} 
                                        className="font-weight-bold text-wrap-2" 
                                            to={value.list_page}>{value.list_name}</Link> (#{value.list_place})
                                        </div>
                                        {
                                            (() => {
                                            if (value.artist === 'Van Morrison') {
                                            return <div>
                                                    <Link style = {{'fontSize': '14px'}} 
                                                    className="font-weight-bold text-wrap-2" 
                                                        to='/lists/ranked-songs/van-morrisons-50-greatest-songs'> Van Morrison’s 50 Greatest Songs</Link>
                                                    </div>    
                                            }
                                            return 
                                            })()    
                                        }
                                        {
                                            (() => {
                                            if (value.artist === 'David Bowie') {
                                            return <div>
                                                    <Link style = {{'fontSize': '14px'}} 
                                                    className="font-weight-bold text-wrap-2" 
                                                        to='/lists/ranked-songs/david-bowies-50-greatest-songs–ranked'> David Bowie's 50 greatest songs – ranked!</Link>
                                                    </div>    
                                            }
                                            return 
                                            })()    
                                        }
                                    </div>
                            }
                            if (value.list_album_unranked_id && !value.listalbumid && !value.list_album_unranked_id) {
                                return <div className="card p-2 mb-2 Description bg-light">
                                            <div>
                                            <b>Check out list:</b><br /> <Link style = {{'fontSize': '14px'}} 
                                            className="font-weight-bold text-wrap-2" 
                                                to={value.list_unranked_page}>{value.list_unranked_name}</Link>
                                            </div>
                                            {
                                            (() => {
                                            if (value.artist === 'Van Morrison') {
                                            return <div>
                                                    <Link style = {{'fontSize': '14px'}} 
                                                    className="font-weight-bold text-wrap-2" 
                                                        to='/lists/ranked-songs/van-morrisons-50-greatest-songs'> Van Morrison’s 50 Greatest Songs</Link>
                                                    </div>    
                                            }
                                            return 
                                            })()    
                                            }
                                            {
                                                (() => {
                                                if (value.artist === 'David Bowie') {
                                                return <div>
                                                        <Link style = {{'fontSize': '14px'}} 
                                                        className="font-weight-bold text-wrap-2" 
                                                            to='/lists/ranked-songs/david-bowies-50-greatest-songs–ranked'> David Bowie's 50 greatest songs – ranked!</Link>
                                                        </div>    
                                                }
                                                return 
                                                })()    
                                            }
                                        </div>
                            }
                            if (!value.listalbumid && value.artist === 'Bruce Springsteen') {
                                return <div className="card p-2 mb-2 Description bg-light">
                                        <div>
                                            <b>Check out list:</b><br /> 
                                                <Link style = {{'fontSize': '14px'}} 
                                                className="font-weight-bold text-wrap-2" 
                                                    to='/lists/ranked-songs/100-greatest-bruce-springsteen-songs'> 100 Greatest Bruce Springsteen Songs</Link>
                                        </div>  
                                        </div>
                            }
                            if (!value.listalbumid && value.artist === 'The Rolling Stones') {
                                return <div className="card p-2 mb-2 Description bg-light">
                                        <div>
                                            <b>Check out list:</b><br /> 
                                                <Link style = {{'fontSize': '14px'}} 
                                                className="font-weight-bold text-wrap-2" 
                                                    to='/lists/ranked-songs/100-greatest-rolling-stones-songs'> 100 Greatest Rolling Stones Songs</Link>
                                        </div>  
                                        </div>
                            }
                            if (!value.listalbumid && value.artist === 'Bob Dylan') {
                                return <div className="card p-2 mb-2 Description bg-light">
                                        <div>
                                            <b>Check out list:</b><br /> 
                                                <Link style = {{'fontSize': '14px'}} 
                                                className="font-weight-bold text-wrap-2" 
                                                    to='/lists/ranked-songs/the-100-best-bob-dylan-songs-recorded-in-the-60s'> The 100 Best Bob Dylan Songs Recorded in the 1960s</Link>
                                        </div>  
                                        </div>
                            }
                            if (!value.listalbumid && value.artist === 'Van Morrison') {
                                return <div className="card p-2 mb-2 Description bg-light">
                                        <div>
                                            <b>Check out list:</b><br /> 
                                                <Link style = {{'fontSize': '14px'}} 
                                                className="font-weight-bold text-wrap-2" 
                                                    to='/lists/ranked-songs/van-morrisons-50-greatest-songs'> Van Morrison’s 50 Greatest Songs</Link>
                                        </div>  
                                        </div>
                            }
                            if (!value.listalbumid && value.artist === 'David Bowie') {
                                return <div className="card p-2 mb-2 Description bg-light">
                                        <div>
                                            <b>Check out list:</b><br /> 
                                                <Link style = {{'fontSize': '14px'}} 
                                                className="font-weight-bold text-wrap-2" 
                                                    to='/lists/ranked-songs/david-bowies-50-greatest-songs–ranked'> David Bowie's 50 greatest songs – ranked!</Link>
                                        </div>  
                                        </div>
                            }
                            return 
                            })()    
                        }
                    </div>

                </div>
  
            </div>
          ))}
            {largeArray &&
            <div className='mt-2'>
            <button id = "btn_albums" className="btn-sm btn-info" onClick = {ShowAllAlbumsButton}>Show more albums</button>
            </div>
            }
            {/* {showAllAlbums &&
            <div className='mt-2'>
            <button id = "btn_albums" className="btn-sm btn-info" onClick = {ShowLessAlbumsButton}>Show less albums</button>
            </div>
            } */}
        </div>
      )}

    {albumsUnique.length === 0 &&
         <div className="ml-1 mt-2 mt-md-0">
            <div className="row TodayTitle24nobg mx-5">
                <div className="col-sm-12"> 
                    <div className="text-center"><b>Albums</b>
                    </div>
                    <h5>--No albums on this day--</h5>
                </div>
            </div>

        </div>
      }

    </div>
  )
}

export default AnyDayAlbums
