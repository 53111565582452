import React, { Component, useState } from 'react'
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import Select from 'react-select';
import { dbURL } from '../Constants';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { animateScroll as scroll } from "react-scroll";

class People extends Component {

// fetch
state = {
    columnDefs: [
      {headerName: "Month", field: "monthtext", filter: true, resizable: true, width: 100, cellStyle: {'background-color': 'ivory', 'font-size': '14px'}},
      {headerName: "Name", field: "name", width: 180, filter: true, resizable: true, cellStyle: { 'font-size': '14px' }},
      //{headerName: "Born", field: "born", filter: true, sortable: true, width: 130, cellStyle: { 'font-size': '16px' }},
      {headerName: "Born", field: "borntext", sortable: true, width: 162, cellStyle: { 'font-size': '14px' }},
      {headerName: "Genre", field: "genre", filter: true, sortable: true, width: 95, cellStyle: { 'font-size': '14px' }},
      {headerName: "Main band", field: "band", filter: true, sortable: true, width: 170, cellStyle: { 'font-size': '14px' }},
      {headerName: "Born/origin - city", field: "city", sortable: true, width: 160, cellStyle: { 'font-size': '14px' }},
      {headerName: "Region", field: "region", filter: true, sortable: true, width: 150, cellStyle: { 'font-size': '14px' }},
      {headerName: "Country", field: "country", filter: true, sortable: true, cellStyle: { 'font-size': '14px' }}
    ],
    columnDefsBirth: [
      {headerName: "Born", field: "borntext", sortable: true, width: 152, cellStyle: { 'font-size': '14px' }},
      {headerName: "Name", field: "name", width: 180, filter: true, resizable: true, cellStyle: { 'font-size': '14px' }},
      {headerName: "Genre", field: "genre", filter: true, sortable: true, width: 95, cellStyle: { 'font-size': '14px' }},
      {headerName: "Main band", field: "band", filter: true, sortable: true, width: 170, cellStyle: { 'font-size': '14px' }},
      {headerName: "Born/origin - city", field: "city", sortable: true, width: 180, cellStyle: { 'font-size': '14px' }},
      {headerName: "Region", field: "region", filter: true, sortable: true, width: 150, cellStyle: { 'font-size': '14px' }},
      {headerName: "Country", field: "country", filter: true, sortable: true, cellStyle: { 'font-size': '14px' }}
    ],
    rowAllData: [],
    rowAllDataSorted: [],
    rowCount: [],
    selectedOptionMonth: null,
    selectedOptionYear: null,
    selectedOptionGenre: null,
    GridOn: false,
    SortMonth: false
  }

  getPeopleItems(){
    axios.get(dbURL + 'allmemomusicmonths')
      .then(response => {
        this.setState({
          rowAllData: response.data,
          rowCount: response.data.length
        });
      });
  } 

  getPeopleItemsSorted(){
    axios.get(dbURL + 'allmemomusic')
      .then(response => {
        this.setState({
          rowAllDataSorted: response.data
        });
      });
  } 

  componentDidMount() {
    this.getPeopleItems()
    this.getPeopleItemsSorted()
  }

  selectedOptionMonth = (selectedMonth) => {
    this.setState({ selectedOptionMonth: selectedMonth });
  }

  selectedOptionYear = (selectedYear) => {
    this.setState({ selectedOptionYear: selectedYear });
  } 

  selectedOptionGenre = (selectedGenre) => {
    this.setState({ selectedOptionGenre: selectedGenre });
  } 

  clearFilterMonth(){
  this.setState({
    selectedOptionMonth: null, 
    })
  }

  clearFilterYear(){
  this.setState({
    selectedOptionYear: null, 
    })
  }

clearFilterGenre(){
    this.setState({
        selectedOptionGenre: null, 
        })
    }

onChangeGrid = () => {
      this.setState(initialState => ({
          GridOn: !initialState.GridOn,
      }));
   }

onChangeSortMonth = () => {
    this.setState(initialState => ({
        SortMonth: !initialState.SortMonth,
    }));
 }

 scrollToTop = () => {
  scroll.scrollToTop(); 
};

scrollToBottom = () => {
  scroll.scrollToBottom();
};


render() {

  const { selectedOptionMonth } = this.state;
    const { selectedOptionYear } = this.state;
    const { selectedOptionGenre } = this.state;

  //Filter logic

  let filterArray

  if (selectedOptionYear && !selectedOptionMonth && !selectedOptionGenre && !this.state.SortMonth) {
      filterArray = this.state.rowAllDataSorted.filter(e => e.year === selectedOptionYear.value)
      } 
  if (selectedOptionYear && !selectedOptionMonth && !selectedOptionGenre && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.year === selectedOptionYear.value)
       } 
  if (selectedOptionMonth && !selectedOptionYear && !selectedOptionGenre && !this.state.SortMonth) {
      filterArray = this.state.rowAllDataSorted.filter(e => e.monthtext === selectedOptionMonth.value);
      } 
   if (selectedOptionMonth && !selectedOptionYear && !selectedOptionGenre && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.monthtext === selectedOptionMonth.value);
       } 
  if (selectedOptionGenre && !selectedOptionYear && !selectedOptionMonth && !this.state.SortMonth) {
          filterArray = this.state.rowAllDataSorted.filter(e => e.genre === selectedOptionGenre.value);
      }
   if (selectedOptionGenre && !selectedOptionYear && !selectedOptionMonth && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.genre === selectedOptionGenre.value);
   }
  if (selectedOptionGenre && selectedOptionYear && !selectedOptionMonth && !this.state.SortMonth) {
          filterArray = this.state.rowAllDataSorted.filter(e => e.genre === selectedOptionGenre.value 
              && e.year === selectedOptionYear.value)
      }
   if (selectedOptionGenre && selectedOptionYear && !selectedOptionMonth && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.genre === selectedOptionGenre.value 
           && e.year === selectedOptionYear.value)
   }
  if (selectedOptionGenre && !selectedOptionYear && selectedOptionMonth && !this.state.SortMonth) {
          filterArray = this.state.rowAllDataSorted.filter(e => e.genre === selectedOptionGenre.value 
              && e.monthtext === selectedOptionMonth.value)
      }
   if (selectedOptionGenre && !selectedOptionYear && selectedOptionMonth && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.genre === selectedOptionGenre.value 
           && e.monthtext === selectedOptionMonth.value)
   }
  if (!selectedOptionGenre && selectedOptionYear && selectedOptionMonth && !this.state.SortMonth) {
          filterArray = this.state.rowAllDataSorted.filter(e => e.year === selectedOptionYear.value 
              && e.monthtext === selectedOptionMonth.value)
      }
 if (!selectedOptionGenre && selectedOptionYear && selectedOptionMonth && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.year === selectedOptionYear.value 
           && e.monthtext === selectedOptionMonth.value)
   }
  if (selectedOptionYear && selectedOptionMonth && selectedOptionGenre && !this.state.SortMonth) {
      filterArray = this.state.rowAllDataSorted.filter(e => e.year === selectedOptionYear.value 
          && e.monthtext === selectedOptionMonth.value && e.genre === selectedOptionGenre.value);
      }
  if (selectedOptionYear && selectedOptionMonth && selectedOptionGenre && this.state.SortMonth) {
       filterArray = this.state.rowAllData.filter(e => e.year === selectedOptionYear.value 
           && e.monthtext === selectedOptionMonth.value && e.genre === selectedOptionGenre.value);
       }
  if (!selectedOptionYear && !selectedOptionMonth && !selectedOptionGenre && !this.state.SortMonth) {
      filterArray = this.state.rowAllDataSorted
      } 
   if (!selectedOptionYear && !selectedOptionMonth && !selectedOptionGenre && this.state.SortMonth) {
       filterArray = this.state.rowAllData
       } 


   //get only Month
   const OnlyMonth = filterArray.map(item => item.monthtext)

   //unique Month + count
   const uniqueMonth = OnlyMonth.reduce((b,c) => (
       (b[b.findIndex(d => d.monthtext===c)] ||
       // eslint-disable-next-line 
       b[b.push({monthtext: c, count: 0})-1]).count++,b), 
       []
   );
   
   //map Month with count to MonthOptions
   const MonthOptions = uniqueMonth.map(value => { 
       return {value: value.monthtext, label: value.monthtext + ' (' + value.count + ')'}; 
   });

   //get only Year
   const OnlyYear = filterArray.map(item => item.year);
   //const OnlyYearSorted = OnlyYear.sort()

   //unique Year + count
   const uniqueYear = OnlyYear.reduce((b,c) => (
       (b[b.findIndex(d => d.year===c)] ||
       // eslint-disable-next-line 
       b[b.push({year: c, count: 0})-1]).count++,b), 
       []
   );
   const uniqueYearSorted = uniqueYear.sort(function(a, b){
    return b.count-a.count
})
   
   //map Year with count to YearOptions
   const YearOptions = uniqueYearSorted.map(value => { 
       return {value: value.year, label: value.year + ' (' + value.count + ')'}; 
   });

   //get only Genre
   const OnlyGenre = filterArray.map(item => item.genre);

   //unique genre + count
   
   const uniqueGenre = OnlyGenre.reduce((b,c) => (
       (b[b.findIndex(d => d.genre===c)] ||
       // eslint-disable-next-line 
       b[b.push({genre: c, count: 0})-1]).count++,b), 
       []
   );
   
   //map Genre with count to GenreOptions
   const GenreOptions = uniqueGenre.map(value => { 
       return {value: value.genre, label: value.genre + ' (' + value.count + ')'}; 
   });

   
  
    //dersom SortBorn -> endre kolonne rekekfølge

    let agColumns 

    if (this.state.SortMonth) {
      agColumns = this.state.columnDefs;
    } 
    if (!this.state.SortMonth) {
      agColumns = this.state.columnDefsBirth;
    }


    //Details visning
    //const AllPeople = this.state.rowAllData;
    

    const peopleItems = filterArray.map(value => {

      return (
          <React.Fragment key={value.no}>
  
          <div className="row pt-3 pb-3 border-bottom border-top border-info">
              
              <div className="col-sm-2 text-center">
                  {
                  (() => {
                      if (value.aka) {
                  return <p className='PeopleName'><b>{value.aka}</b></p>;
                  }
                  return <p className='PeopleName'><b>{value.name}</b></p>;
                  })()
                  }
                  <p align="center"><img src={value.picture} 
                  alt="" height="130" width="130" className="rounded mx-auto d-block">
                  </img></p>
              </div>
  
              <div className="col pb-3 border-right">
                      {value.description} <br /> <em> -> <a href={value.wiki}>wikipedia</a></em>
                  
              </div>
              
              {!this.state.DetailsOff && 
                  
              
                  <div className="col-sm-4 pb-4">
  
                      <div className="row">
                      
      
                          <div className="col pr-0 mr-2">
                                  <div>
                                  
                                  <b>Born/origin </b><br />
                                  <div className="Date">{value.born}</div>
                                      {
                                          (() => {
                                          if (value.dead) {
                                          return <div><b>Died</b><br />
                                          <div className="Date">{value.dead}</div></div>
                                          }
                                          return <br />
                                      })()
                                      }
                                  <b>Genre</b> <br /><div className="factText">{value.genre}</div>
                                      
                                  </div>
  
                          </div>
  
                          <div className="col pl-1">
                                  
                                  <b>City</b> <br /><div className="factText">{value.city}</div>
  
                                  {
                                      (() => {
                                      if (value.region) {
                                      return <div><b>Region</b> <br />
                                      <div className="factText">{value.region}</div></div>
                                      }
                                      return <br />
                                  })()
                                  }
  
                                  <b>Country</b> <br /><div className="factText">{value.country}</div>
  
                          
                          </div>
  
                      </div>
                      <div className="row">
                          <div className="col-7">
                                  {
                                          (() => {
                                          if (value.band) {
                                          return <div><b>Main band</b> <br />
                                          <div className="factText">{value.band}</div></div>
                                          }
                                          return <p></p>
                                      })()
                                  }
  
                          </div>
  
                      </div>
                  
                  </div>
              }
                  
  
          </div>
  
          </React.Fragment>          
      )
  
  })

  function RandomPerson() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function showBirthdate() {
      var x = document.getElementById("dateborn");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    }
    

    const randomItem = {};
    Object.assign(randomItem, filterArray[Math.floor(Math.random()*filterArray.length)]);
    return (
      <>
        <Button variant="info" onClick={handleShow}>
          Random person
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Random Person</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-4">
                  {
                    (() => {
                        if (randomItem.aka) {
                    return <p className='PeopleName'><b>{randomItem.aka}</b></p>;
                    }
                    return <p className='PeopleName'><b>{randomItem.name}</b></p>;
                    })()
                    }
                    <p align="center"><img src={randomItem.picture} 
                    alt="" height="130" width="130" className="rounded mx-auto d-block">
                    </img></p>
              </div>
              <div className="col-sm-8 pb-4">
  
                      <div className="row">
                      
      
                          <div className="col pr-0 mr-2">
                                  <div>
                                  <div id="dateborn" style={{display: 'none' }}>
                                  <b>Born </b><br />
                                  <div className="Date">{randomItem.born}</div></div>
                                      {
                                          (() => {
                                          if (randomItem.dead) {
                                          return <div><b>Died</b><br />
                                          <div className="Date">{randomItem.dead}</div></div>
                                          }
                                          return <br />
                                      })()
                                      }
                                  <b>Genre</b> <br /><div className="factText">{randomItem.genre}</div>
                                      
                                  </div>
  
                          </div>
  
                          <div className="col pl-1">
                                  
                                  <b>City</b> <br /><div className="factText">{randomItem.city}</div>
  
                                  {
                                      (() => {
                                      if (randomItem.region) {
                                      return <div><b>Region</b> <br />
                                      <div className="factText">{randomItem.region}</div></div>
                                      }
                                      return <br />
                                  })()
                                  }
  
                                  <b>Country</b> <br /><div className="factText">{randomItem.country}</div>
  
                          
                          </div>
  
                      </div>
                      <div className="row">
                          <div className="col-7">
                                  {
                                          (() => {
                                          if (randomItem.band) {
                                          return <div><b>Main band</b> <br />
                                          <div className="factText">{randomItem.band}</div></div>
                                          }
                                          return <p></p>
                                      })()
                                  }
  
                          </div>
  
                      </div>
                  
                  </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={showBirthdate}>
              Show Birthday
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

    return (

      //returnere, inkludere fra dynamisk
      <main className="my-1 py-2">
        
        <div className="container">
            <div className="row pb-2">
                <div className="col-sm-5">
                    <h3 >Important Music People</h3>
                </div>
                <div className="col-sm-7 ">
                    
                    <RandomPerson />

                </div>
            </div>
            
              <div className="row">
                    <div className="col-sm-2 pb-3">
                            <img src='https://s3.amazonaws.com/allaboutjazz/coverart/2008/bob_dylan_gallery08-2.jpg' 
                            alt="people" className="img-fluid rounded mx-auto d-block" />
                      </div>

                      <div className="col-sm-5">
                            <p>A highly subjective selection of important music people. 
                                </p>
                            <p>The reason for my "month-sorting" is an ongoing project og memorizing the selection. 
                              My goal is to stop when total count reaches 1000.

                            </p>
                            <div className="alert alert-success" role="alert">
                            In progress - count <span className="badge badge-light">{filterArray.length}</span>
                        
                                <div align="Right">
                                
                                    <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                    <b>Scroll down</b>
                                    </button>
                                </div>
                            </div>



                    </div>

                    <div className="col-sm-5">

                    
                          <h5>Filters</h5>
                        
                        <div className="row pb-2">
                        <div className="col-7">
                            <Select
                                value={selectedOptionMonth}
                                onChange={this.selectedOptionMonth}
                                options={MonthOptions}
                                placeholder='Select Month'
                            />
                            </div>
                            <div className="col-5">
                                <button className="btn btn-primary" onClick={() => this.clearFilterMonth()} >Reset filter</button>
                            </div>
                        </div>

                      <div className="row pb-2">
                          <div className="col-7">
                          <Select
                              value={selectedOptionYear}
                              onChange={this.selectedOptionYear}
                              options={YearOptions}
                              placeholder='Select Year'
                          />
                          </div>
                          <div className="col-5">
                                <button className="btn btn-primary" onClick={() => this.clearFilterYear()} >Reset filter</button>
                          </div>
                      </div>

                      <div className="row pb-3">
                          <div className="col-7">
                          <Select
                              value={selectedOptionGenre}
                              onChange={this.selectedOptionGenre}
                              options={GenreOptions}
                              placeholder='Select Genre'
                          />
                          </div>
                          <div className="col-5">
                                <button className="btn btn-primary" onClick={() => this.clearFilterGenre()} >Reset filter</button>
                          </div>
                      </div>
                    
                    
                      <div className="form-check">
                            <label className="form-check-label">
                            <input type="checkbox"
                                checked={this.state.GridOn}
                                onChange={this.onChangeGrid}
                                className="form-check-input"
                            />
                            Show more details
                            </label>
                        
                        </div>
                        
                        <div className="form-check">
                            <label className="form-check-label">
                            <input type="checkbox"
                                checked={this.state.SortMonth}
                                onChange={this.onChangeSortMonth}
                                className="form-check-input"
                            />
                            Sort on Month
                            </label>
                        
                        </div>
                        
                  </div>


              </div>
      
        {!this.state.GridOn &&
        <div className="row pr-2 pl-2 pt-3">
            <div
                id="myGrid"
                style={{ height: '2770px', width: '1200px' }}
                className="ag-theme-balham" 
                >
              
                  <AgGridReact
                  
                  onGridReady={ params => this.gridApi = params.api }
                      rowSelection="multiple"
                      pagination={true}
                      columnDefs={agColumns}
                      rowData={filterArray}
                      >
                      
                  </AgGridReact>
                
            </div>
        </div>
        }

        {this.state.GridOn && 
        <div className="row">
            <div className="col">
                    {peopleItems}
                    
            </div>
        </div>
        }
        
        <p align="Right"><br />
        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b>
                    </button></p>

        </div>
    </main>

    );

}

}

export default People