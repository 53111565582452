import React, { Component } from 'react';
import axios from 'axios';
import { dbURL, customStyles2 } from '../Constants';
import Select from 'react-select';

import DylanConcertslondon from '../concerts/DylanConcertsLondon';
import DylanLondonLocations from './DylanLondonLocations';

import { animateScroll as scroll } from "react-scroll";

import SEO from '../../SEO';

class BobDylanLondon extends Component {

  state = {
    concertsAllData: [],
    locationsAllData: [],
    //peopleAllData: [],
    selectedOptionRange: 'concerts',
    loading: true,
    selectedOptionYear: null,
    selectedOptionYearLocations: null,
    SearchString: ''
  }

  getConcertItems = () => axios.get(dbURL + 'dylanconcertslondon');
  getLocationItems = () => axios.get(dbURL + 'dylanlocationslondon');
  

  async componentDidMount() {
      try {
          const [concertsData, locationsData] = await axios.all([ this.getConcertItems(), this.getLocationItems() ]);
          this.setState(
                {
                    concertsAllData: concertsData.data,
                    locationsAllData: locationsData.data,
                    //peopleAllData: peopleData.data,
                    loading: false
              }
          );
      }
      catch (err) {
          console.log(err.message);
      }
      
  }

  selectedOptionRange = (selectedRange) => {
    this.setState({ selectedOptionRange: selectedRange });
  }

  scrollToTop = () => {
    scroll.scrollToTop(); 
  };

  scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  selectedOptionYear = (selectedYear) => {
    this.setState({ selectedOptionYear: selectedYear });
  } 

  clearFilterYear(){
    this.setState({
        selectedOptionYear: null, 
        })
    }
  
  selectedOptionYearLocations = (selectedYearLocation) => {
    this.setState({ selectedOptionYearLocations: selectedYearLocation });
  } 
  

  clearFilterLocationsYear(){
    this.setState({
        selectedOptionYearLocations: null, 
        })
    }
  
    handleSearchChange = this.handleSearchChange.bind(this);

    handleSearchChange(event) {
    this.setState({SearchString: event.target.value});
    } 

    clearSearchField(){
    this.setState({
        SearchString: '', 
        })
    }



 

  render() {

    const AllConcerts = this.state.concertsAllData
    const AllLocations = this.state.locationsAllData
    const { selectedOptionYear } = this.state
    const { selectedOptionYearLocations } = this.state
    const Range = this.state.selectedOptionRange
    const { SearchString } = this.state

    let filterArray

    //console.table (AllUniqueConcerts);


    //Remove if duplicate concert records
    const AllUniqueConcerts = AllConcerts.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    function SearchSongs(searchText) { 
      let searchArray = [];
      
      for (let i = 0; i < AllUniqueConcerts.length; i++) {

          if (AllUniqueConcerts[i].searchtitle.includes(searchText))
          
              {searchArray.push(AllUniqueConcerts[i]);}
              
      } 
      
      return searchArray
    }

    
    
    if (Range === 'concerts') {
        if (selectedOptionYear && !SearchString) {
          filterArray = AllUniqueConcerts.filter(e => e.gig_year === selectedOptionYear.value)
        }
        if (!selectedOptionYear && SearchString) {
          filterArray = SearchSongs(SearchString.toLowerCase())
          } 

        if (!selectedOptionYear && !SearchString) {
          filterArray = AllUniqueConcerts
          } 
    }

    if (Range === 'locations') {
        if (selectedOptionYearLocations) {
          filterArray = AllLocations.filter(e => e.year === selectedOptionYearLocations.value)
        }
        else filterArray = AllLocations
    }
    

    const OnlyYear = filterArray.map(item => item.gig_year);
    const OnlyYearSorted = OnlyYear.sort();
    const uniqueYear = OnlyYearSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.gig_year===c)] ||
             // eslint-disable-next-line 
             b[b.push({gig_year: c, count: 0})-1]).count++,b), 
             []
         );
    const uniqueYearSorted = uniqueYear.sort(function(a, b){
            return b.count-a.count
        })
    const YearOptions = uniqueYearSorted.map(value => { 
             //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
             return {value: value.gig_year, label: value.gig_year + ' (' + value.count + ')'}; 
            //}
         });

    const OnlyYearLocations = filterArray.map(item => item.year);
    const OnlyYearLocationsSorted = OnlyYearLocations.sort();
    const uniqueYearLocations = OnlyYearLocationsSorted.reduce((b,c) => (
            (b[b.findIndex(d => d.year===c)] ||
            // eslint-disable-next-line 
            b[b.push({year: c, count: 0})-1]).count++,b), 
            []
        );
    const uniqueYearLocationsSorted = uniqueYearLocations.sort(function(a, b){
            return b.count-a.count
        })
    const YearLocationsOptions = uniqueYearLocationsSorted.map(value => { 
            //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
            return {value: value.year, label: value.year + ' (' + value.count + ')'}; 
            //}
        });
    

    return (<main className="my-1 py-1">

                    <div className="container">  

                    <SEO
                      title={'Bob Dylan in London'}
                      description={'All concerts Dylan has played in London with embedded video/audio. Also many locations Dylan has visited/played in London'}
                      name={'Bob Dylan in London'}
                      picture_link={'https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/5a09f68c-9904-4d73-a10e-e6d35764b700/public'}
                      url={'https://musicthisday.com/dylanlondon'}
                      canonical={'https://musicthisday.com/dylanlondon'}
                      type='article' />


                      <div className="row mb-5">
                        <div className="col-sm-4">
                            <img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/5a09f68c-9904-4d73-a10e-e6d35764b700/public' 
                            alt="bobdylan" className="img-fluid rounded mx-auto d-block" />

                        </div>

                        <div className="col-sm-4">
                            <h1>Bob Dylan in London</h1>
                        
                            <div className="Description">
                            This page contains:
                              <ol>
                                <li>List of all <b>concerts</b> Dylan has played in London </li>
                                <li>List of many <b>locations</b> Dylan has visited/played in London </li>
                              
                              </ol> 
                                </div>
                            
                        
                             {
                                (() => {
                                if (this.state.selectedOptionRange==='concerts') {
                                return   <div>

                                      <div className="Header4 p-1 mb-4">
                                        <div className="factText"><b>70 Audio/video clips</b> are embedded in the concert list, 
                                          click the play button to start the video.
                                          PS! Some performances that cannot actually be categorized as "Concerts" are included. 
                                        </div>
                                      </div>
                                      <div>
                                        <b>References:</b>
                                        <li><b><a href="https://en.wikipedia.org/wiki/Bob_Dylan">
                                              Bob Dylan @ wikipedia
                                          </a></b>
                                          </li>
                                          <li><b><a href="http://www.bjorner.com/still.htm">
                                              Olof's Still on the road
                                          </a></b>
                                          </li>
                                          <li><b><a href="https://www.amazon.co.uk/Bob-Dylan-London-Troubadour-Tales/dp/0857162144">
                                          Bob Dylan in London: Troubadour Tales
                                          </a></b>
                                          </li>
                                          <li><b><a href="https://www.amazon.com/Bob-Dylan-Stolen-Moments-1941-1995/dp/0028646762">
                                          Bob Dylan: A Life in Stolen Moments Day by Day, 1941-1995
                                          </a></b>
                                          </li>
                                      </div>

                                  </div>
                                }
                                if (this.state.selectedOptionRange==='locations') {
                                return <div>
                                          {/* <h4><b>All locations</b>
                                          <div className="btn btn-info btn-xs float-right">count: {filterArray.length}</div></h4> */}
                                          <div className="Header4 py-1 px-1 mb-2">
                                            <p className="factText">
                                              Thanks to the new book <a href="https://www.amazon.co.uk/Bob-Dylan-London-Troubadour-Tales/dp/0857162144">
                                              Bob Dylan in London: Troubadour Tales</a> (Jackie Lees and K G Miles) for waking up my
                                              "sleeping" Bob Dylan in London project, and Clinton Heylin's book -  
                                              <a href="https://www.amazon.co.uk/Bob-Dylan-Stolen-Moments-1941-1995/dp/0711956693/ref=sr_1_1?dchild=1&keywords=clinton+heylin+life+in+stolen+moments&qid=1611862125&s=books&sr=1-1https://www.amazon.co.uk/Bob-Dylan-Stolen-Moments-1941-1995/dp/0711956693/ref=sr_1_1?dchild=1&keywords=clinton+heylin+life+in+stolen+moments&qid=1611862125&s=books&sr=1-1">
                                              Bob Dylan: A Life in Stolen Moments</a>.
                                              
                                              <hr />

                                              <b>--> <a href="https://goo.gl/maps/zs8WiuiXngAr3fMbA">
                                               Bob Dylan London Map @ google maps</a></b>
                                              
                                            </p>
                                          </div>

                                        </div>
                                
                                }
                                return <h4>People</h4>
                                
                              })()
                            } 
                        
                        </div>

                        <div className="col-sm-4">

                        
                          {((this.state.selectedOptionRange === 'concerts') && (!this.state.SearchString))&& 
                          <div><h4>Filters</h4>
                            <div className="row pb-4">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={selectedOptionYear}
                                            onChange={this.selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='Select year..'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterYear()} >Reset</button>
                                    </div>
                                </div>
                          
                           
                          </div>
                          }

                          {((!this.state.selectedOptionYear) && this.state.selectedOptionRange === 'concerts')  && 
                            <div className="row">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                            }

                            {((!this.state.selectedOptionYear) && this.state.selectedOptionRange === 'concerts')  && 
                                <div className="row pb-2">  
                                        <div className="col-7">
                                        <input 
                                            className="form-control" 
                                            type="text" 
                                            value={SearchString} 
                                            onChange={this.handleSearchChange} 
                                            placeholder="Search venue" 
                                        />
                                        </div>
                                        <div className="col-5 pl-0">
                                            <button type="button" className="btn btn-primary"  
                                            onClick={() => this.clearSearchField()} >Reset</button>
                                        </div>
                                </div>
                            }



                          {this.state.selectedOptionRange === 'locations' && 
                          <div className="row pb-2">  
                                <div className="col-6">
                                        <Select
                                            className="select"
                                            value={selectedOptionYearLocations}
                                            onChange={this.selectedOptionYearLocations}
                                            options={YearLocationsOptions}
                                            placeholder='year'
                                            //styles={customStyles}
                                        />
                                </div>
                                    <div className="col-6 pl-0"> 
                                        <button type="button" className="btn btn-primary btn-sm"  onClick={() => this.clearFilterLocationsYear()} >Reset filter</button>
                                </div>
                          </div>
                          }
                        
                        </div>

                        

                                      
                      </div> 

                      <div className="row">
                          
                          <div className="col-sm-3 pb-2 pl-1" align="Left">
                            {this.state.selectedOptionRange === 'concerts' && 
                            <div className="btn btn-primary btn-sm active focus" 
                            onClick={() => this.selectedOptionRange('concerts')}>Concerts</div>
                            }
                            {this.state.selectedOptionRange !== 'concerts' && 
                            <div className="btn btn-primary btn-sm" 
                            onClick={() => this.selectedOptionRange('concerts')}>Concerts</div>
                            }
                            &nbsp;&nbsp;
                            <div className="btn btn-primary btn-sm" 
                            onClick={() => this.selectedOptionRange('locations')}>Locations</div>

                          </div>

                         
                              {this.state.selectedOptionRange === 'concerts' && 
                                <>
                                      <div className="col-sm-3 pb-2 pl-1" align="Left">
                                        <div className="Header3"><b>All London concerts</b></div>
                                      </div>

                                      <div className="col-sm-3 pb-2 pl-1" align="Left">
                                        <div className="btn btn-info btn-xs">count: {filterArray.length}</div>
                                      </div>
                                </>
                              } 
                              {this.state.selectedOptionRange === 'locations' && 
                                <>
                                      <div className="col-sm-3 pb-2 pl-1" align="Left">
                                        <div className="Header3"><b>All London locations</b></div>
                                      </div>

                                      <div className="col-sm-3 pb-2 pl-1" align="Left">
                                        <div className="btn btn-info btn-xs">count: {filterArray.length}</div>
                                      </div>
                                </>
                              } 
                             


                              <div className="col-sm-3 pb-2" align="Right"> 
                                  <div className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                    <b>Scroll down</b>
                                  </div>
                              </div>

                        </div>

                        {
                          (() => {
                            if (this.state.loading) {
                              return <div><br /><h5><i>Loading...</i></h5></div>
                            }

                            if (this.state.selectedOptionRange==='concerts')
                              return <div> 
                              
                                  <div className="row">
                                      <div className="col">
                                        <DylanConcertslondon concertItems={filterArray} />
                                      </div>
                                  </div>
                              
                                </div>
                            return <div> 
                              
                                <div className="row">
                                    <div className="col">
                                      <DylanLondonLocations locationItems={filterArray} />
                                    </div>
                                </div>
                          
                              </div>
                          })()
                        }
                
                        {/* <Song songsItems={this.state.songsAllData} /> */}

                        <div align="Right"><br />
                        <div className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll top</b></div>
                        </div>
              
                                    
                    </div>
                    

                </main>

    )

  }

}

export default BobDylanLondon;