import React, { Component, useState } from 'react'
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Container } from 'reactstrap';
import Select from 'react-select';
import { dbURL, customStyles, customStyles2 } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import ReadMoreAndLess from "react-read-more-less";
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
import ReactPlayer from "react-player";
//import ReactGA from 'react-ga';



//import Select from 'react-select';

class BobDylanSongs extends Component {

    state = {
        columnDefs: [
            {headerName: "Song", field: "title", resizable: true, width: 400, cellStyle: { 'font-size': '14px' }},
            {headerName: "Recorded", field: "recorded", filter: true, sortable: true, width: 230, cellStyle: { 'font-size': '14px' }},
            {headerName: "Album", field: "album", sortable: true, width: 200, cellStyle: { 'font-size': '14px' }},
            {headerName: "Year released", field: "year_released", sortable: true, width: 110, cellStyle: { 'font-size': '14px' }},
            {headerName: "Songwriter", field: "songwriter", filter: true, width: 300, cellStyle: { 'font-size': '14px' }}
          ],
        rowAllData: [],
        row60sData: [],
        selectedOptionAlbum: null,
        selectedOptionYear: null,
        ShowOnlyOuttakes: false, 
        ShowOnlyDylan: false, 
        ShowOnlyLive: false, 
        GridOn: false,
        SearchString: '',
        SearchStringLyrics: '',
        selectedOptionRange: '',
        loading: true
      }
    
      getSongItems60s(){
        axios.get(dbURL + 'dylansongsall60s')
          .then(response => {
            this.setState({
              row60sData: response.data,
              loading: false
            });
          });
      } 

    getAllSongItems(){
        axios.get(dbURL + 'dylansongsall')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 
    
    componentDidMount() {
        this.getSongItems60s()
        this.getAllSongItems()
      }
    
      selectedOptionRange = (selectedRange) => {
        this.setState({ selectedOptionRange: selectedRange });
    }
      
      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

      handleSearchChangeLyrics = this.handleSearchChangeLyrics.bind(this);

      handleSearchChangeLyrics(event) {
      this.setState({SearchStringLyrics: event.target.value});
      } 
  
      clearSearchFieldLyrics(){
      this.setState({
          SearchStringLyrics: '', 
          })
      }
    
    selectedOptionAlbum = (selectedAlbum) => {
        this.setState({ selectedOptionAlbum: selectedAlbum });
    }

    selectedOptionYear = (selectedYear) => {
        this.setState({ selectedOptionYear: selectedYear });
    } 


    clearFilterAlbum(){
        this.setState({
            selectedOptionAlbum: null, 
            })
        }
    
    clearFilterYear(){
        this.setState({
            selectedOptionYear: null, 
            })
        }

    onChangeOuttakes = () => {
        this.setState(initialState => ({
            ShowOnlyOuttakes: !initialState.ShowOnlyOuttakes,
        }));
     }

     onChangeDylan = () => {
        this.setState(initialState => ({
            ShowOnlyDylan: !initialState.ShowOnlyDylan,
        }));
     }

     onChangeLive = () => {
        this.setState(initialState => ({
            ShowOnlyLive: !initialState.ShowOnlyLive,
        }));
     }

     onChangeGrid = () => {
        this.setState(initialState => ({
            GridOn: !initialState.GridOn,
        }));
     }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {
        
        const { selectedOptionAlbum } = this.state;
        const { selectedOptionYear } = this.state;
        const { ShowOnlyOuttakes } = this.state;
        const { ShowOnlyDylan } = this.state;
        const { ShowOnlyLive } = this.state;
        const { selectedOptionRange } = this.state;
        const { SearchString } = this.state;
        const { SearchStringLyrics } = this.state;
        const AllSongs = this.state.rowAllData;

        //Filter logic
        let rangeArray = this.state.row60sData;
        let filterArray, searchWords, AllSongsWithLyrics

        AllSongsWithLyrics = AllSongs.filter(e => e.searchlyrics !== null && e.searchlyrics !=='')
        //console.table(AllSongsWithLyrics);

        if (selectedOptionRange === '1959-1969') {
            rangeArray = AllSongs.filter(e => e.year_recorded < 1970);  
            } 
        if (selectedOptionRange === '1970-1979') {
            rangeArray = AllSongs.filter(e => e.year_recorded > 1969 && e.year_recorded < 1980);  
            } 
        if (selectedOptionRange === '1980-1989') {
            rangeArray = AllSongs.filter(e => e.year_recorded > 1979 && e.year_recorded < 1990);  
            } 
        if (selectedOptionRange === '1990-1999') {
            rangeArray = AllSongs.filter(e => e.year_recorded > 1989 && e.year_recorded < 2000);  
            } 
        if (selectedOptionRange === '2000-2020') {
            rangeArray = AllSongs.filter(e => e.year_recorded > 1999);  
            } 
        if (selectedOptionRange === 'All') {
            rangeArray = AllSongs;  
            }
        
        

        //console.table(rangeArray);
        

        function SearchSongs(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllSongs.length; i++) {

                if (AllSongs[i].searchtitle.includes(searchText))
                
                    {searchArray.push(AllSongs[i]);}
                    //console.table(filterArrayYear);
            } 
            //console.log(searchArray);
            return searchArray
        }

        function SearchSongsLyrics(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllSongsWithLyrics.length; i++) {

                if (AllSongsWithLyrics[i].searchlyrics.includes(searchText))
                
                    {searchArray.push(AllSongsWithLyrics[i]);}
                    //console.table(filterArrayYear);
            } 
            //her må eg legge inn HIGHLIGHT funksjon
            //bruk Mark.js 
            return searchArray
        }

        if (!selectedOptionYear && !selectedOptionAlbum && SearchString) {
            filterArray = SearchSongs(SearchString.toLowerCase());
            }  
        
        if (!selectedOptionYear && !selectedOptionAlbum && SearchStringLyrics) {
            
            searchWords = SearchStringLyrics.split(/\s/).filter(word => word)
            
            filterArray = SearchSongsLyrics(SearchStringLyrics.toLowerCase());
            } 

            

            if (selectedOptionYear && !selectedOptionAlbum && !ShowOnlyOuttakes) {
                filterArray = rangeArray.filter(e => e.year_recorded === selectedOptionYear.value);
                } 
            if (selectedOptionAlbum && !selectedOptionYear && !ShowOnlyOuttakes) {
                    filterArray = rangeArray.filter(e => e.album === selectedOptionAlbum.value);
                }
            if (ShowOnlyOuttakes && !selectedOptionYear && !selectedOptionAlbum) {
                    filterArray = rangeArray.filter(e => e.outtake === true);
                }
          

            if (selectedOptionYear && selectedOptionAlbum && !ShowOnlyOuttakes) {
                filterArray = rangeArray.filter(e => e.year_recorded === selectedOptionYear.value 
                    && e.album === selectedOptionAlbum.value);
                }

            if (selectedOptionYear && ShowOnlyOuttakes && !selectedOptionAlbum) {
                filterArray = rangeArray.filter(e => e.year_recorded === selectedOptionYear.value
                    && e.outtake === true);
                }

            if (selectedOptionAlbum && ShowOnlyOuttakes && !selectedOptionYear) {
                filterArray = rangeArray.filter(e => e.album === selectedOptionAlbum.value
                    && e.outtake === true);
                }

            if (!selectedOptionYear && !selectedOptionAlbum && !ShowOnlyOuttakes && !SearchString && !SearchStringLyrics) {
                filterArray = rangeArray;
                } 
            
            if (ShowOnlyDylan) {
                filterArray = filterArray.filter(e => e.songwriter_id === 43);
            } 

            if (ShowOnlyLive) {
                filterArray = filterArray.filter(e => e.live_version === true);
            } 

         //get only artists
         const OnlyAlbum = filterArray.map(item => item.album);
         //unique artist + count
         const uniqueAlbum = OnlyAlbum.reduce((b,c) => (
             (b[b.findIndex(d => d.album===c)] ||
             // eslint-disable-next-line 
             b[b.push({album: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueAlbumSorted = uniqueAlbum.sort(function(a, b){
            return b.count-a.count
        })
         //map Album with count to AlbumOptions
         const AlbumOptions = uniqueAlbumSorted.map(value => { 
             return {value: value.album, label: value.album + ' (' + value.count + ')'}; 
         });
 
 
         const OnlyYear = filterArray.map(item => item.year_recorded);
         const OnlyYearSorted = OnlyYear.sort();
         const uniqueYear = OnlyYearSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.year_recorded===c)] ||
             // eslint-disable-next-line 
             b[b.push({year_recorded: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueYearSorted = uniqueYear.sort(function(a, b){
            return b.count-a.count
        })
         const YearOptions = uniqueYearSorted.map(value => { 
             //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
             return {value: value.year_recorded, label: value.year_recorded + ' (' + value.count + ')'}; 
            //}
         });
        

        const songItems = filterArray.map(value => {

            return (
                <React.Fragment key={value.id}>

                <div className="row pt-3 pb-3 border-bottom border-top border-info">
                    
                    {/* Kolonne #1 */}
                    <div className="col-sm-2 text-center">
                        <p className='Song2'><b>{value.title}</b></p>
                        <div align="center">
                            {
                                (() => {
                                    if (value.picture) {
                                        return  <div>
                                        <img src={value.picture} alt="" height="130" width="130" 
                                        className="rounded mx-auto d-block"></img><br /><br />
                                        </div>
                                    }
                                    else if (value.album_picture) {
                                        return <div>
                                        <img src={value.album_picture} alt="" height="130" width="130"></img><br /><br />
                                        </div>
                                    }
                                        return <div align="center"><img src={value.pic_link} 
                                        alt="" height="100" width="100" className="rounded mx-auto d-block">
                                        </img></div>
                                })()
                            }
                            
                            {/* {
                                (() => {
                                if (value.youtube) {
                                    return <p><a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a></p>
                                }
                                return 
                                })()
                            } */}
                            {
                                (() => {
                                if (value.youtube) {
                                    return <div><b>Audio/Video</b>
                                        {/* <a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a> */}
                                    <ReactPlayer
                                                url={ value.youtube||value.vimeo }
                                                light='true'
                                                //light
                                                controls
                                                width="250"
                                                height="250"
                                            />
                                
                                </div>
                                }
                                return <p></p>;
                                })()
                            }
                            
                            
                            {
                                (() => {
                                if (value.cover_version) {
                                    return <div><button type="button" className="btn btn-info btn-xs">cover / trad</button>
                                    <br /><br />
                                    </div>
                                }
                                return 
                                })()
                            }

                            <b>Composer</b> <br />
                            <div className="factText">{value.songwriter}</div>
                            <br />
                            {
                                (() => {
                                if (value.main_composer) {
                                    return <div>
                                            <b>Main composer</b> <br />
                                            <div className="factText">{value.main_composer}</div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.original_performer) {
                                    return <div>
                                            <b>Original performer</b> <br />
                                            <div className="factText">{value.original_performer}</div>
                                        </div>
                                }
                                return 
                                })()
                            }
                        </div>


                    </div>

                    {/* Kolonne #2 */}
                    <div className="col-sm-5 pb-3 border-right Description">

                        <div className="row">
                            <div className="col py-2 border-right Description">

                                {
                                    (() => {
                                    if (value.description) {
                                    return  <div>
                                                <ReadMoreAndLess
                                                        charLimit={500}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.description}
                                                </ReadMoreAndLess>
                                                <p className="Description"> 
                                                    <br /> <em> -> <a href={value.wiki}>wikipedia</a></em>
                                                    </p>
                                        </div>
                                    
                                    }
                                    if (!value.description && value.comment) {
                                        return <div className="card bg-light py-2 px-2">
                                                <p className="quote3">{value.comment}</p> 
                                    
                                                {
                                                    (() => {
                                                        if (value.date_txt) {
                                                            return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                        }
                                                        return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                    })()

                                                }
                                        </div>
                                    }
                                    return
                                    })()
                                }

                            </div>

                        </div>

                        <div className="row pt-3">
                            
                            {
                                (() => {
                                    if (value.lyrics && !SearchStringLyrics) {
                                        return  <div className="bg-light ml-2 mr-2">
                                                    <div className="py-2 px-2">
                                                        <ReadMoreAndLess
                                                                charLimit={500}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.lyrics}
                                                        </ReadMoreAndLess>
                                                
                                                    </div>
                                                </div>
                                        }
                                    if (value.lyrics && SearchStringLyrics) {
                                      return  <div className="bg-light ml-2 mr-2">
                                                <div className="py-2 px-2">
                                      
                                                        <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={searchWords}
                                                            autoEscape={true}
                                                            textToHighlight={value.lyrics}
                                                        />
                                                </div>
                                            </div>

                                    }
                                    return <p>No lyrics..yet.</p>
                                    }
                                )()
                            }
                            
                            
                        </div>   

                        

                    </div>

                    {/* Kolonne #3 */}
                    <div className="col-sm-5">

                            <div className="row">
                            
            
                                <div className="col-sm-7">
                                        <div>
                                        
                                            <b>Recorded </b><br />
                                            {
                                                (() => {
                                                if (value.recorded) {
                                                return <div className="Date">{value.recorded}</div>
                                                }
                                                return <div className="Date">{value.rd_text}</div>
                                            })()
                                            }
                                           
                                            {
                                                (() => {
                                                if (value.studio_txt) {
                                                return <div>
                                                            <b>Location</b> <br />
                                                            <div className="factText">{value.studio_txt}</div>
                                                            
                                                        </div>
                                                } 
                                                return 
                                                })()
                                            }

                                            {
                                                (() => {
                                                if (value.producer) {
                                                return <div>
                                                            <b>Producer</b> <br />
                                                            <div className="factText">{value.producer}</div>
                                                            
                                                        </div>
                                                } 
                                                return 
                                                })()
                                            }
                                            
                                        
                                            {
                                                (() => {
                                                if (value.engineer) {
                                                return <div><b>Engineer</b> <br />
                                                <div className="factText">{value.engineer}</div>
                                                
                                                </div>
                                            
                                                } 
                                                return <p></p>
                                                })()
                                            }
                                            
                                            
                                            
                                        </div>

                                </div>

                                <div className="col-sm-5">
                                        
                                       
                                        
                                        {   
                                                (() => {
                                                if (value.single_released) {
                                                return <div>
                                                            <b>Single released</b> <br />
                                                            <div className="Date">{value.single_released}</div>
                                                        </div>
                                                } else if (value.sr_text) {
                                                return <div>
                                                            <b>Single released</b> <br />
                                                            <div className="Date">{value.sr_text}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                        }

                                        {
                                            (() => {
                                            if (value.album) {
                                            return <div><b>Album</b> <br />
                                            <div className="factText">{value.album}</div>
                                            <div className="Date"><i>{value.album_rd}</i></div>
                                            
                                            </div>
                                           
                                            } 
                                            return
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.song_label||value.album_label) {
                                            return <div>
                                                        <b>Label</b> <br />
                                                        <div className="factText"><i>{value.song_label||value.album_label}</i>
                                                        </div>
                                                    </div>
                                           
                                            } 
                                            return
                                            })()
                                        }

                                        

                                        {
                                            (() => {
                                            if (value.performed_onstage && value.times_live) {
                                            return <div><b>Performed onstage</b> <br />
                                            <div className="factText">{value.performed_onstage}</div>
                                            <div className="factText"><i>-> <b>{value.times_live}</b> times</i></div>
                                            <div className="factText"><b>First time:</b> {value.first_live} <br /> 
                                            <b>Last time:</b> {value.last_live}</div>
                                            <br />
                                            </div>
                                            } 
                                            if (value.performed_onstage && !value.times_live) {
                                                return <div><b>Performed onstage</b> <br />
                                                <div className="factText">{value.performed_onstage}</div>
                                                <br />
                                                </div>
                                            } 

                                            return <p></p>
                                            })()
                                        }

                                        {
                                            (() => {
                                            if (value.outtake) {
                                                return <div><Button className="btn btn-info btn-xs">Outtake/Demo</Button>
                                                <br /><br />
                                                </div>
                                            }
                                            if (value.live_version && value.alt_version) {
                                                return <div><Button className="btn btn-info btn-xs">Live</Button>  <Button className="btn btn-info btn-xs">Alt. version</Button>
                                                </div>
                                            }
                                            if (value.live_version) {
                                                return <Button className="btn btn-info btn-xs">Live</Button>
                                            }
                                            if (value.alt_version) {
                                                return <Button className="btn btn-info btn-xs">Alt. version</Button>
                                            }
                                            return 
                                            })()
                                        }

                                        
                                
                                </div>

                            </div>

                            
                            <div className="row pt-3">
                                <div className="col-12">
                                    {
                                        (() => {
                                        if (value.line_up) {
                                        return  <div><b>Line-up</b> <br />
                                                    <div className="factText">
                                                        {value.line_up}
                                                        <p></p>
                                                    </div>
                                                </div>
                                        } 
                                        return <p></p>
                                        })()
                                    }
                                </div>
                            </div>  

                            <div className="row pt-3">
                                <div className="col-12">
                                    {
                                        (() => {
                                        if (value.comment && value.description) {
                                            return <div className="card bg-light py-2 px-2">
                                                        <p className="quote3">{value.comment}</p> 
                                                        
                                                        {
                                                            (() => {
                                                                if (value.date_txt) {
                                                                    return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                }
                                                                return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                            })()

                                                        }

                                                    </div>
                                                                  
                                        }
                                        return <p></p>;
                                        })()
                                    }
                                </div>
                            </div>  
                        
                    </div>
                    
                        

                </div>

                </React.Fragment>          
            )

        })

       //MODAL
       function RandomSong() {
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
    
        function showRecordedDate() {
          var x = document.getElementById("daterecorded");
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
        }
        
    
        const randomItem = {};
        Object.assign(randomItem, filterArray[Math.floor(Math.random()*filterArray.length)]);
      
        return (
          <>
            
            <Button className="btn-info btn-sm" onClick={handleShow}>
                Random song
            </Button>
      
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Random Song</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-5">
                       <p className='Song'><b>{randomItem.title}</b></p>
                       <div align="center">
                        {
                        (() => {
                        if (randomItem.picture) {
                          return  <img src={randomItem.picture} alt="" height="130" width="130" 
                          className="rounded mx-auto d-block"></img>
                        }
                        return <img src={randomItem.album_picture} alt="" height="130" width="130"></img>;
                        })()
                        }
                        </div>
                        
                        
                  </div>
                  <div className="col-sm-7 pb-4">
      
                          <div className="row">
                          
          
                              <div className="col pr-0 mr-2">
                                      <div>
                                        <div id="daterecorded" style={{display: 'none' }}>
                                            <b>Recorded</b><br />
                                            {
                                                (() => {
                                                if (randomItem.recorded) {
                                                return <div>
                                                <div className="Date">{randomItem.recorded}</div></div>
                                                }
                                                return <div>
                                                <div className="Date">{randomItem.rd_text}</div></div>
                                                
                                            })()
                                            }
                                        </div>
                                      
                                          
                                      </div>
      
                              </div>
      
                              <div className="col pl-1">
                                      
                                      <b>Writer(s)</b> <br />
                                      <div className="factText">{randomItem.songwriter}</div>
      
                                       <div><b>Producer</b> <br />
                                        <div className="factText">{randomItem.producer}</div></div>
                                          
                                    
                              </div>
      
                          </div>
                          <div className="row">
                              <div className="col-12">
                                    <div><b>Album</b> <br />
                                        <div className="factText">{randomItem.album}</div>
                                        <div className="Date">{randomItem.album_rd}</div>
                                    </div>
                                              
      
                              </div>
      
                          </div>
                      
                      </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={showRecordedDate}>
                  Show Recorded date
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    
    
        return (

            <main className="my-1 py-1">
                <Container>
                    
                    <div className="row">
                        <div className="col-sm-2 mb-3">
                            
                            <img src='https://pyxis.nymag.com/v1/imgs/c6f/f4f/4b09e6339ac22e4148c34d4914ebe60006-24-bob-dylan.rvertical.w330.jpg' 
                            alt="bobdylan" className="img-fluid rounded mx-auto d-block" />
                            <br />
                            <div className="alert-sm alert-success text-center"><b>In progress</b></div> 
                            <br />
                            {/* <div className="text-center">
                                <div className="btn btn-info btn-xs">total count: {AllSongs.length}</div>
                            </div> */}
                            
                        </div>

                        <div className="col-sm-6">
                            <h3>Bob Dylan Songs - <div className="btn btn-secondary btn-xs">total count: {AllSongs.length}</div></h3>
                        
                            <p className="Description">
                            <b>Update - 25.05.2021</b><br />
                            I've decided to try to include all (unique) songs performed by Bob Dylan in this list. That 
                            includes covers both studio & live versions. <br />
                            So this will be a rather long journey, the final list should include around 2000 songs.

                                </p>
                        
                            <p className="factText">The default view is sorted by <b>Recorded date</b>.</p>
                            
                            <br />
                            <p className="Description"><i>All lyrics are the property and copyright of their owners, provided for educational purposes only.</i></p>
                            <br />
                        
                            {
                                (() => {
                                if (this.state.selectedOptionRange==='All') {
                                return   <h3><b>All songs</b> - <div className="btn btn-secondary btn-xs">count: {filterArray.length}</div></h3>
                                
                                }
                                if (this.state.selectedOptionRange) {
                                return <h3><b>Recorded {this.state.selectedOptionRange}</b> - <div className="btn btn-secondary btn-xs">count: {filterArray.length}</div></h3>
                            
                                }
                                return <h3><b>Recorded 1959-1969</b> - <div className="btn btn-secondary btn-xs">count: {filterArray.length}</div></h3>
                                
                            })()
                            }
                        
                        </div>
                        
                        <div className="col-sm-4 mb-4">
                            {(!this.state.SearchString && !this.state.SearchStringLyrics)  &&
                            <div className="row">   
                                <div className="col">
                                    <h4>Filters</h4>
                                </div>
                            </div>
                            }
                            {(!this.state.SearchString && !this.state.SearchStringLyrics)  &&
                            <div className="row pb-2">  
                                <div className="col-6">
                                        <Select
                                            className="select"
                                            value={selectedOptionYear}
                                            onChange={this.selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='Year recorded'
                                            styles={customStyles}
                                        />
                                </div>
                                    <div className="col-6 pl-0"> 
                                        <button type="button" className="btn btn-primary btn-sm"  onClick={() => this.clearFilterYear()} >Reset filter</button>
                                </div>
                            </div>
                            }
                            {(!this.state.SearchString && !this.state.SearchStringLyrics)  &&
                            <div className="row pb-2">  
                                <div className="col-9">
                                    <Select
                                        className="select"
                                        value={selectedOptionAlbum}
                                        onChange={this.selectedOptionAlbum}
                                        options={AlbumOptions}
                                        placeholder='Select Album'
                                        styles={customStyles2}
                                    />
                                </div>
                                <div className="col-3 pl-2">
                                    <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterAlbum()} >Reset</button>
                                </div>
                            </div>
                            }

                            {((!this.state.selectedOptionAlbum) && (!this.state.selectedOptionYear))  && 
                            <div className="row">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                            }

                            {((!this.state.selectedOptionAlbum) && (!this.state.selectedOptionYear) && !this.state.SearchStringLyrics)  && 
                                <div className="row pb-2">  
                                        <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="text" 
                                            value={SearchString} 
                                            onChange={this.handleSearchChange} 
                                            placeholder="Search song title" 
                                        />
                                        </div>
                                        <div className="col-3 pl-0">
                                            <button type="button" className="btn btn-primary"  
                                            onClick={() => this.clearSearchField()} >Reset</button>
                                        </div>
                                </div>
                            }

                            {((!this.state.selectedOptionAlbum) && (!this.state.selectedOptionYear) && !this.state.SearchString)  && 
                            <div className="row pb-2">  
                                    <div className="col-9">
                                        
                                        <div className="search">    
                                                
                                            <DebounceInput
                                                //type="number"
                                                minLength={3}
                                                debounceTimeout={1000}
                                                value={SearchStringLyrics}
                                                onChange={this.handleSearchChangeLyrics}
                                                placeholder="Search all lyrics" 
                                            />
                                        </div>

                                            

                                    </div>
                                    <div className="col-3 pl-0">
                                        <button type="button" className="btn btn-primary"  
                                        onClick={() => this.clearSearchFieldLyrics()} >Reset</button>
                                    </div>
                            </div>
                            }
                           
                            <div className="row align-items-start no-gutters">
                                    
                                <div className="col pt-2">

                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox"
                                            checked={this.state.GridOn}
                                            onChange={this.onChangeGrid}
                                            className="form-check-input"
                                        />
                                        Change to Grid
                                        </label>
                                    
                                    </div>

                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox"
                                            checked={this.state.ShowOnlyDylan}
                                            onChange={this.onChangeDylan}
                                            className="form-check-input"
                                        />
                                        Only written by Dylan
                                        </label>
                                    
                                    </div>

                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox"
                                            checked={this.state.ShowOnlyLive}
                                            onChange={this.onChangeLive}
                                            className="form-check-input"
                                        />
                                        Only live versions
                                        </label>
                                    
                                    </div>

                                    <div className="form-check">
                                        <label className="form-check-label">
                                        <input type="checkbox"
                                            checked={this.state.ShowOnlyOuttakes}
                                            onChange={this.onChangeOuttakes}
                                            className="form-check-input"
                                        />
                                        Only outtakes
                                        </label>
                                    
                                    </div>

                                    <span style={{float: "right"}}><RandomSong /></span>

                                </div>

                            </div>
                 
                        </div>

                    </div>

                    <div className="row">
                        
                        <div className="col-sm-10 pb-2 pl-1" align="Left">
                            {this.state.selectedOptionRange === '' && 
                            <Button className="btn btn-primary btn-xs active focus" onClick={() => this.selectedOptionRange('1959-1969')}>60s</Button>
                            }
                            {this.state.selectedOptionRange !== '' && 
                            <Button className="btn btn-primary btn-xs" onClick={() => this.selectedOptionRange('1959-1969')}>60s</Button>
                            }
                            &nbsp;&nbsp;
                            <Button className="btn btn-primary btn-xs" onClick={() => this.selectedOptionRange('1970-1979')}>70s</Button>
                            &nbsp;&nbsp;
                            <Button className="btn btn-primary btn-xs" onClick={() => this.selectedOptionRange('1980-1989')}>80s</Button>
                            &nbsp;&nbsp;
                            <Button className="btn btn-primary btn-xs" onClick={() => this.selectedOptionRange('1990-1999')}>90s</Button>
                            &nbsp;&nbsp;
                            <Button className="btn btn-primary btn-xs" onClick={() => this.selectedOptionRange('2000-2020')}>2000 -></Button>
                            &nbsp;&nbsp;
                            <Button className="btn btn-primary btn-xs" onClick={() => this.selectedOptionRange('All')} >All songs</Button>
                        </div>


                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>

                    </div>

                    {
                        (() => {
                        if (this.state.loading) {
                            return <div><br /><h5><i>Loading...</i></h5></div>
                        }
                        return <div> 
                            {!this.state.GridOn && 
                                <div className="row">
                                    <div className="col">
                                            {songItems}
                                    </div>
                                </div>
                            }
                            </div>
                        })()
                    }
        
        

                    {this.state.GridOn && 
                        <div className="row pr-2 pl-2 pt-3">
                            <div
                                id="myGrid"
                                style={{ height: '1150px', width: '1200px' }}
                                className="ag-theme-balham" 
                                >
                            
                                <AgGridReact
                                
                                onGridReady={ params => this.gridApi = params.api }
                                    rowSelection="multiple"
                                    pagination={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={filterArray}
                                    >
                                    
                                </AgGridReact>
                                
                            </div>
                        </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>
              
                </Container>
            </main>

        )
    }
}

export default BobDylanSongs