import React from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';

const NotFound = () => {

    return (

        
    <div>
        {/* REROUTING */}
        <Routes>
            <Route path="/dylananyday" element={<Navigate replace to="/bob-dylan-any-day" />} />
            <Route path="/merlehaggard" element={<Navigate replace to="/profiles/merle-haggard" />} />
            <Route path="/richardmanuel" element={<Navigate replace to="/profiles/richard-manuel" />} />
            <Route path="/emmylouharris" element={<Navigate replace to="/profiles/emmylou-harris" />} />
            <Route path="/jerryhamburg" element={<Navigate replace to="/concerts/great-concert-jerry-lee-lewis-star-club-hamburg" />} />
            <Route path="/bookends" element={<Navigate replace to="/albums/simon-and-garfunkel-bookends" />} />
            <Route path="/jailbreak" element={<Navigate replace to="/albums/thin-lizzy-jailbreak" />} />
            <Route path="/bandofgypsys" element={<Navigate replace to="/albums/jimi-hendrix-band-of-gypsys" />} />
            <Route path="/foryourpleasure" element={<Navigate replace to="/albums/roxy-music-for-your-pleasure" />} />
            <Route path="/pleasepleaseme" element={<Navigate replace to="/albums/the-beatles-please-please-me" />} />
            <Route path="/biabh" element={<Navigate replace to="/albums/bob-dylan-bringing-it-all-back-home" />} />
            <Route path="/surferrosa" element={<Navigate replace to="/albums/the-pixies-surfer-rosa" />} />
            <Route path="/bobdylanalbum" element={<Navigate replace to="/albums/bob-dylan-bob-dylan-album" />} />
            <Route path="/desolation" element={<Navigate replace to="/songs/bob-dylan-desolation-row" />} />
            <Route path="/bwmt" element={<Navigate replace to="/songs/bob-dylan-blind-willie-mctell" />} />
            <Route path="/tuib" element={<Navigate replace to="/songs/bob-dylan-tangled-up-in-blue" />} />
            <Route path="/likearollingstone" element={<Navigate replace to="/songs/bob-dylan-like-a-rolling-stone" />} />
            <Route path="/visionsofjohanna" element={<Navigate replace to="/songs/bob-dylan-visions-of-johanna" />} />
            <Route path="/100keyboard" element={<Navigate replace to="/lists/people/100-greatest-rock-keyboardists" />} />
            <Route path="/40artistslondon" element={<Navigate replace to="/lists/people/40-greatest-artists-from-london" />} />
            <Route path="/100artists" element={<Navigate replace to="/lists/people/100-greatest-artists" />} />
            <Route path="/100drums" element={<Navigate replace to="/lists/people/100-greatest-drummers-of-all-time" />} />
            <Route path="/100bass" element={<Navigate replace to="/lists/people/the-100-best-bass-players-of-all-time" />} />
            <Route path="/100singers" element={<Navigate replace to="/lists/people/100-greatest-singers-of-all-time" />} />
            <Route path="/100guitarists" element={<Navigate replace to="/lists/people/100-greatest-guitarists" />} />
            <Route path="/songwriters" element={<Navigate replace to="/lists/people/the-100-greatest-songwriters-of-all-time" />} />
            <Route path="/40dylan" element={<Navigate replace to="/lists/ranked-songs/bob-dylans-40-best-songs" />} />
            <Route path="/50dylan" element={<Navigate replace to="/lists/ranked-songs/bob-dylans-50-greatest-songs–ranked" />} />
            <Route path="/100dylan" element={<Navigate replace to="/lists/ranked-songs/100-greatest-bob-dylan-songs" />} />
            <Route path="/100dylanmojo" element={<Navigate replace to="/lists/ranked-songs/100-greatest-bob-dylan-songs-mojo" />} />
            <Route path="/dylanwar" element={<Navigate replace to="/lists/ranked-songs/a-bob-dylan-playlist-war" />} />
            <Route path="/20biblicaldylan" element={<Navigate replace to="/lists/ranked-songs/20-biblical-characters-who-appeared-in-bob-dylans-songs-before-he-became-a-christian" />} />
            <Route path="/dylanlove10" element={<Navigate replace to="/lists/ranked-songs/bob-dylans-10-greatest-love-songs" />} />
            <Route path="/25dylan21st" element={<Navigate replace to="/lists/ranked-songs/the-25-best-bob-dylan-songs-of-the-21st-century" />} />
            <Route path="/dylanlist00s" element={<Navigate replace to="lists/ranked-songs/the-35-best-bob-dylan-songs-recorded-in-the-21st-century" />} />
            <Route path="/dylanlist90s" element={<Navigate replace to="lists/ranked-songs/the-20-best-bob-dylan-songs-recorded-in-the-90s" />} />
            <Route path="/dylanlist60s" element={<Navigate replace to="lists/ranked-songs/the-100-best-bob-dylan-songs-recorded-in-the-60s" />} />
            <Route path="/dylanlist70s" element={<Navigate replace to="lists/ranked-songs/the-50-best-bob-dylan-songs-recorded-in-the-70s" />} />
            <Route path="/dylanlist80s" element={<Navigate replace to="lists/ranked-songs/the-40-best-bob-dylan-songs-recorded-in-the-80s" />} />
            <Route path="/duaneallman15" element={<Navigate replace to="lists/songs/duane-allman-15-essential-guitar-solos" />} />
            <Route path="/selectedsongs1970" element={<Navigate replace to="lists/songs/50-songs-released-in-1970-you-must-hear" />} />
            <Route path="/selectedsongs1969" element={<Navigate replace to="lists/songs/51-songs-released-in-1969-you-must-hear" />} />
            <Route path="/selectedsongs1971" element={<Navigate replace to="lists/songs/50-songs-released-in-1971-you-must-hear" />} />
            <Route path="/neil20hardcore" element={<Navigate replace to="lists/songs/20-insanely-great-neil-young-songs-only-hardcore-fans-Know" />} />
            <Route path="/25jazz" element={<Navigate replace to="lists/songs/25-essential-jazz-songs-everyone-should-know" />} />
            <Route path="/50bowie" element={<Navigate replace to="lists/ranked-songs/david-bowies-50-greatest-songs–ranked" />} />
            <Route path="/50van" element={<Navigate replace to="lists/ranked-songs/van-morrisons-50-greatest-songs" />} />
            <Route path="/10petty" element={<Navigate replace to="lists/ranked-songs/top-10-tom-petty-songs" />} />
            <Route path="/20george" element={<Navigate replace to="lists/ranked-songs/the-20-best-george-harrison-solo-songs" />} />
            <Route path="/20beatlessolo" element={<Navigate replace to="lists/ranked-songs/the-best-songs-from-the-beatles-solo-careers" />} />
            <Route path="/100beatles" element={<Navigate replace to="lists/ranked-songs/100-greatest-beatles-songs" />} />
            <Route path="/larswsongs" element={<Navigate replace to="lists/lars-winnerbacks-100-greatest-songs-ranked" />} />
            <Route path="/elvissongs100" element={<Navigate replace to="lists/ranked-songs/counting-down-elvis-his-100-finest-songs" />} />
            <Route path="/thewhosongs50" element={<Navigate replace to="lists/ranked-songs/the-who-50-greatest-songs" />} />
            <Route path="/lists/ranked-songs/the-songs-bob-dylan-has-played-live-the-most" element={<Navigate replace to="lists/songs/dylan/the-songs-bob-dylan-has-played-live-the-most" />} />
            <Route path="/bwmlive" element={<Navigate replace to="/lists/live/songs/dylan/bob-dylan-20-live-versions-of-blind-willie-mctell" />} />
            <Route path="/vojlive" element={<Navigate replace to="/lists/live/songs/dylan/bob-dylan-35-live-versions-of-visions-of-johanna" />} />
            <Route path="/drlive" element={<Navigate replace to="/lists/live/songs/dylan/bob-dylan-38-live-versions-of-desolation-row" />} />
            <Route path="/thclive" element={<Navigate replace to="/lists/live/songs/dylan/bob-dylan-44-live-versions-of-things-have-changed" />} />
            <Route path="/songs1989" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1989" />} />
            <Route path="/songs1988" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1988" />} />
            <Route path="/songs1987" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1987" />} />
            <Route path="/songs1986" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1986" />} />
            <Route path="/songs1985" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1985" />} />
            <Route path="/songs1984" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1984" />} />
            <Route path="/songs1984" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1984" />} />
            <Route path="/songs1980" element={<Navigate replace to="/lists/ranked-songs/the-30-best-songs-from-1980" />} />
            <Route path="/songs1981" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1981" />} />
            <Route path="/songs1982" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1982" />} />
            <Route path="/songs1983" element={<Navigate replace to="/lists/ranked-songs/the-20-best-songs-from-1983" />} />
            <Route path="/50cave" element={<Navigate replace to="/lists/ranked-songs/the-50-greatest-songs-of-nick-cave" />} />
            <Route path="/30cave" element={<Navigate replace to="/lists/ranked-songs/nick-caves-30-best-songs" />} />
            <Route path="/20cave" element={<Navigate replace to="/lists/ranked-songs/nick-caves-20-greatest-songs-of-all-time" />} />
            <Route path="/100dylan60s" element={<Navigate replace to="/lists/songs/dylan/the-100-best-bob-dylan-songs-recorded-in-the-60s" />} />
            <Route path="/50dylan70s" element={<Navigate replace to="/lists/songs/dylan/the-50-best-bob-dylan-songs-recorded-in-the-70s" />} />
            <Route path="/40stones" element={<Navigate replace to="/lists/ranked-songs/the-rolling-stones-40-best-songs" />} />
            <Route path="/50stones" element={<Navigate replace to="/lists/ranked-songs/the-50-greatest-rolling-stones-tracks" />} />
            <Route path="/100stones" element={<Navigate replace to="/lists/ranked-songs/100-greatest-rolling-stones-songs" />} />
            <Route path="/100bruce" element={<Navigate replace to="/lists/ranked-songs/100-greatest-bruce-springsteen-songs" />} />
            <Route path="/25allman" element={<Navigate replace to="/lists/ranked-songs/the-allman-brothers-bands-25-all-time-greatest-songs" />} />
            <Route path="/20allmanb" element={<Navigate replace to="/lists/songs/the-20-greatest-allman-brothers-band-songs-critics-picks" />} />
            <Route path="/20allman" element={<Navigate replace to="/lists/ranked-songs/the-allman-brothers-band-and-related-28-best-songs" />} />
            <Route path="/10allman" element={<Navigate replace to="/lists/ranked-songs/top-10-allman-brothers-band-songs" />} />
            <Route path="/25countryrock" element={<Navigate replace to="/lists/ranked-songs/the-25-best-country-rock-songs-of-all-time" />} />
            <Route path="/25neil2" element={<Navigate replace to="/lists/ranked-songs/the-25-greatest-neil-young-songs" />} />
            <Route path="/25lynyrd" element={<Navigate replace to="/lists/ranked-songs/the-25-greatest-lynyrd-skynyrd-songs-of-all-time" />} />
            <Route path="/25neil" element={<Navigate replace to="/lists/ranked-songs/the-25-best-neil-young-songs-staff-picks" />} />
            <Route path="/100country" element={<Navigate replace to="/lists/ranked-songs/100-greatest-country-songs-of-all-time" />} />
            <Route path="/10loureed" element={<Navigate replace to="/lists/ranked-songs/the-10-best-lou-reed-songs" />} />
            <Route path="/beatlessessions62" element={<Navigate replace to="/sessions/the-beatles-recording-sessions-1962" />} />
            <Route path="/dylansessions63" element={<Navigate replace to="/sessions/bob-dylan-recording-sessions-1963" />} />
            <Route path="/elvissun" element={<Navigate replace to="/sessions/elvis-presley-recording-sessions-at-sun-studio-from-1953-to-1955" />} />
            <Route path="/neil63-65" element={<Navigate replace to="/sessions/neil-young-early-recording-sessions-1963-65" />} />
            <Route path="/stonessessions62" element={<Navigate replace to="/sessions/the-rolling-stones-recording-sessions-1962-and-1963" />} />
            <Route path="/dylansessions62" element={<Navigate replace to="/sessions/bob-dylan-recording-sessions-1962" />} />
            <Route path="/neil66-68" element={<Navigate replace to="/sessions/neil-young-recording-sessions-1966-68" />} />
            <Route path="/dylansessions56-61" element={<Navigate replace to="/sessions/early-recording-sessions-by-bob-dylan-1956-61" />} />
            <Route path="/dylanconcerts1980" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1980" />} />
            <Route path="/dylanconcerts1981" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1981" />} />
            <Route path="/dylanconcerts1984" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1984" />} />
            <Route path="/dylanconcerts1986" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1986" />} />
            <Route path="/dylanconcerts1987" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1987" />} />
            <Route path="/dylanconcerts1988" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1988" />} />
            <Route path="/dylanconcerts1989" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1989" />} />
            <Route path="/dylanconcerts1990" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1990" />} />
            <Route path="/dylanconcerts1991" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1991" />} />
            <Route path="/dylanconcerts1992" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1992" />} />
            <Route path="/dylanconcerts1993" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1993" />} />
            <Route path="/dylanconcerts1994" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1994" />} />
            <Route path="/dylanconcerts1995" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1995" />} />
            <Route path="/dylanconcerts1996" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1996" />} />
            <Route path="/dylanconcerts1997" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1997" />} />
            <Route path="/dylanconcerts1998" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1998" />} />
            <Route path="/dylanconcerts1999" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-1999" />} />
            <Route path="/dylanconcerts2000" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2000" />} />
            <Route path="/dylanconcerts2001" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2001" />} />
            <Route path="/dylanconcerts2002" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2002" />} />
            <Route path="/dylanconcerts2003" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2003" />} />
            <Route path="/dylanconcerts2004" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2004" />} />
            <Route path="/dylanconcerts2005" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2005" />} />
            <Route path="/dylanconcerts2006" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2006" />} />
            <Route path="/dylanconcerts2007" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2007" />} />
            <Route path="/dylanconcerts2008" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2008" />} />
            <Route path="/dylanconcerts2009" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2009" />} />
            <Route path="/dylanconcerts2010" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2010" />} />
            <Route path="/dylanconcerts2011" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2011" />} />
            <Route path="/dylanconcerts2012" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2012" />} />
            <Route path="/dylanconcerts2013" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2013" />} />
            <Route path="/dylanconcerts2014" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2014" />} />
            <Route path="/dylanconcerts2015" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2015" />} />
            <Route path="/dylanconcerts2016" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2016" />} />
            <Route path="/dylanconcerts2017" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2017" />} />
            <Route path="/dylanconcerts2018" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2018" />} />
            <Route path="/dylanconcerts2019" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts-2019" />} />
            <Route path="/dylanconcerts100" element={<Navigate replace to="/lists/concerts/top-100-bob-dylan-concerts" />} />
            <Route path="/dylanconcerts" element={<Navigate replace to="/lists/concerts/recommended-bob-dylan-concerts" />} />
            <Route path="/bruceconcerts1975" element={<Navigate replace to="/lists/concerts/recommended-bruce-springsteen-concerts-1975" />} />
            <Route path="/bruceconcerts1976-77" element={<Navigate replace to="/lists/concerts/recommended-bruce-springsteen-concerts-1976-and-1977" />} />
            <Route path="/bruceconcerts1978" element={<Navigate replace to="/lists/concerts/recommended-bruce-springsteen-concerts-1978" />} />
            <Route path="/stonesconcerts1969" element={<Navigate replace to="/lists/concerts/recommended-rolling-stones-concerts-1969" />} />
            <Route path="/stonesconcerts1970-71" element={<Navigate replace to="/lists/concerts/recommended-rolling-stones-concerts-1970-1971" />} />
            <Route path="/stonesconcerts1972" element={<Navigate replace to="/lists/concerts/recommended-rolling-stones-concerts-1972" />} />
            <Route path="/beatlessolo" element={<Navigate replace to="/lists/albums/20-great-beatles-solo-albums" />} />
            <Route path="/stonesalbumsguardian" element={<Navigate replace to="/lists/ranked-albums/the-rolling-stones–every-album-ranked" />} />
            <Route path="/cohenalbums" element={<Navigate replace to="/lists/ranked-albums/leonard-cohen-albums-from-worst-to-best" />} />
            <Route path="/cashchaos" element={<Navigate replace to="/lists/ranked-albums/ranking-the-10-best-johnny-cash-albums" />} />
            <Route path="/50livealbums70s" element={<Navigate replace to="/lists/ranked-albums/the-50-best-live-albums-of-the-1970s" />} />
            <Route path="/20livealbums" element={<Navigate replace to="/lists/ranked-albums/the-20-greatest-live-albums" />} />
            <Route path="/50altcountry" element={<Navigate replace to="/lists/ranked-albums/the-50-best-alt-country-albums-of-all-time" />} />
            <Route path="/50progalbums" element={<Navigate replace to="/lists/ranked-albums/50-greatest-prog-rock-albums-of-all-time" />} />
            <Route path="/50livealbums" element={<Navigate replace to="/lists/ranked-albums/50-greatest-live-albums-of-all-time" />} />
            <Route path="/cos100albums" element={<Navigate replace to="/lists/ranked-albums/cos-100-greatest-albums-of-all-time" />} />
            <Route path="/cosdylanalbums" element={<Navigate replace to="/lists/ranked-albums/cos-ranking-every-bob-dylan-album-from-worst-to-best" />} />
            <Route path="/uncutalbums2021" element={<Navigate replace to="/lists/ranked-albums/uncut-75-best-albums-of-2021" />} />
            <Route path="/btl2020albums" element={<Navigate replace to="/lists/ranked-albums/the-25-best-albums-of-2020" />} />
            <Route path="/ultimatedylanalbums" element={<Navigate replace to="/lists/ranked-albums/ucr-bob-dylan-albums-ranked-from-worst-to-best" />} />
            <Route path="/100vh1albums" element={<Navigate replace to="/lists/ranked-albums/vh1-100-greatest-albums" />} />
            <Route path="/rs500a2020" element={<Navigate replace to="/lists/ranked-albums/rolling-stone-500-greatest-albums" />} />
            <Route path="/nme500a" element={<Navigate replace to="/lists/ranked-albums/nme-500-greatest-albums" />} />
            <Route path="/500songs" element={<Navigate replace to="/lists/ranked-songs/rolling-stone-500-greatest-songs" />} />
            <Route path="/lists/songs/dylan/bob-dylans-40-best-songs" element={<Navigate replace to="/lists/ranked-songs/bob-dylans-40-best-songs" />} />
            <Route path="/lists/songs/dylan/100-greatest-bob-dylan-songs" element={<Navigate replace to="/lists/ranked-songs/100-greatest-bob-dylan-songs" />} />
            <Route path="/lists/songs/dylan/100-greatest-bob-dylan-songs-mojo" element={<Navigate replace to="/lists/ranked-songs/100-greatest-bob-dylan-songs-mojo" />} />
            <Route path="/lists/songs/dylan/bob-dylans-50-greatest-songs–ranked" element={<Navigate replace to="/lists/ranked-songs/bob-dylans-50-greatest-songs–ranked" />} />
            <Route path="/lists/songs/dylan/the-100-best-bob-dylan-songs-recorded-in-the-60s" element={<Navigate replace to="/lists/ranked-songs/the-100-best-bob-dylan-songs-recorded-in-the-60s" />} />
            <Route path="/lists/songs/dylan/the-50-best-bob-dylan-songs-recorded-in-the-70s" element={<Navigate replace to="/lists/ranked-songs/the-50-best-bob-dylan-songs-recorded-in-the-70s" />} />
            <Route path="/lists/songs/dylan/the-40-best-bob-dylan-songs-recorded-in-the-80s" element={<Navigate replace to="/lists/ranked-songs/the-40-best-bob-dylan-songs-recorded-in-the-80s" />} />
            <Route path="/lists/songs/dylan/the-20-best-bob-dylan-songs-recorded-in-the-90s" element={<Navigate replace to="/lists/ranked-songs/the-20-best-bob-dylan-songs-recorded-in-the-90s" />} />
            <Route path="/lists/songs/dylan/10-essential-bob-dylan-performances-not-released" element={<Navigate replace to="/lists/ranked-songs/10-essential-bob-dylan-performances-not-released" />} />
            <Route path="/lists/songs/dylan/the-35-best-bob-dylan-songs-recorded-in-the-21st-century" element={<Navigate replace to="/lists/ranked-songs/the-35-best-bob-dylan-songs-recorded-in-the-21st-century" />} />
            <Route path="/lists/songs/dylan/20-biblical-characters-who-appeared-in-bob-dylans-songs-before-he-became-a-christian" element={<Navigate replace to="/lists/ranked-songs/20-biblical-characters-who-appeared-in-bob-dylans-songs-before-he-became-a-christian" />} />
            <Route path="/lists/songs/dylan/bob-dylans-10-greatest-love-songs" element={<Navigate replace to="/lists/ranked-songs/bob-dylans-10-greatest-love-songs" />} />
            <Route path="/lists/songs/dylan/a-bob-dylan-playlist-war" element={<Navigate replace to="/lists/ranked-songs/a-bob-dylan-playlist-war" />} />

            <Route path="/lists/songs/dylan/the-songs-bob-dylan-has-played-live-the-most" element={<Navigate replace to="/lists/ranked-songs/the-songs-bob-dylan-has-played-live-the-most" />} />
            <Route path="/dylantoday" element={<Navigate replace to="/dylananyday" />} />
            <Route path="/dylanbootlegtoday" element={<Navigate replace to="/bootleg/bob-dylan-masked-and-anonymous-various-songs-outtakes" />} />
            <Route path="/bootlegs" element={<Navigate replace to="/posts" />} />
            <Route path="/dylanvideotoday" element={<Navigate replace to="/posts" />} />
            <Route path="/videotoday" element={<Navigate replace to="/posts" />} />
            <Route path="/dylanlists" element={<Navigate replace to="/bobdylan" />} />

            {/* FLYTTING songs fra basic til EKTE songs */}
            <Route path="/lists/songs-basic/10-great-etta-james-songs" element={<Navigate replace to="/lists/songs/10-great-etta-james-songs" />} />
            <Route path="/lists/songs-basic/15-essential-cat-power-songs" element={<Navigate replace to="/lists/songs/15-essential-cat-power-songs" />} />
            <Route path="/lists/songs-basic/15-essential-sam-cooke-songs" element={<Navigate replace to="/lists/songs/15-essential-sam-cooke-songs" />} />
            <Route path="/lists/songs-basic/25-essential-lucinda-williams-songs" element={<Navigate replace to="/lists/songs/25-essential-lucinda-williams-songs" />} />
            <Route path="/lists/songs-basic/25-essential-jason-isbell-songs" element={<Navigate replace to="/lists/songs/25-essential-jason-isbell-songs" />} />
            <Route path="/lists/songs-basic/25-essential-blues-songs-from-1920s" element={<Navigate replace to="/lists/songs/25-essential-blues-songs-from-1920s" />} />
            <Route path="/lists/songs-basic/50-songs-released-in-1983-you-must-hear" element={<Navigate replace to="/lists/songs/50-songs-released-in-1983-you-must-hear" />} />
            <Route path="/lists/songs-basic/joachim-jokke-nielsen-50-greatest-songs" element={<Navigate replace to="/lists/songs/joachim-jokke-nielsen-50-greatest-songs" />} />
            <Route path="/lists/songs-basic/bjorn-eidsvag-30-selected-songs" element={<Navigate replace to="/lists/songs/bjorn-eidsvag-30-selected-songs" />} />
            <Route path="/lists/songs-basic/25-essential-grateful-dead-songs" element={<Navigate replace to="/lists/songs/25-essential-grateful-dead-songs" />} />
            <Route path="/lists/songs-basic/15-great-nick-lowe-songs" element={<Navigate replace to="/lists/songs/15-great-nick-lowe-songs" />} />
            <Route path="/lists/songs-basic/20-bob-dylan-songs-mentioning-fate-or-destiny" element={<Navigate replace to="/lists/songs/20-bob-dylan-songs-mentioning-fate-or-destiny" />} />
            <Route path="/lists/songs-basic/5-wonderful-chuck-leavell-performances" element={<Navigate replace to="/lists/songs/5-wonderful-chuck-leavell-performances" />} />
            <Route path="/lists/songs-basic/willie-nelson-sings-4-bob-dylan-songs" element={<Navigate replace to="/lists/songs/willie-nelson-sings-4-bob-dylan-songs" />} />
            <Route path="/lists/songs-basic/15-essential-small-faces-and-faces-songs" element={<Navigate replace to="/lists/songs/15-essential-small-faces-and-faces-songs" />} />
            
            <Route path="/lists/ranked-songs-simple/tom-waits-75-best-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/tom-waits-75-best-songs-ranked" />} />
            <Route path="/lists/ranked-songs-simple/pj-arvey-50-greatest-songs–ranked" element={<Navigate replace to="/lists/ranked-songs/pj-harvey-50-greatest-songs–ranked" />} />
            <Route path="/lists/ranked-songs-simple/johnny-cash-30-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/johnny-cash-30-greatest-songs-ranked" />} />
            <Route path="/lists/ranked-songs-simple/little-feat-15-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/little-feat-15-greatest-songs-ranked" />} />
            <Route path="/lists/ranked-songs-simple/loretta-lynn-15-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/loretta-lynn-15-greatest-songs-ranked" />} />   
            <Route path="/lists/ranked-songs-simple/chris-stapleton-20-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/chris-stapleton-20-greatest-songs-ranked" />} />         
            <Route path="/lists/ranked-songs-simple/50-greatest-britpop-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/50-greatest-britpop-songs-ranked" />} /> 
            <Route path="/lists/ranked-songs-simple/billy-joe-shaver-20-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/billy-joe-shaver-20-greatest-songs-ranked" />} />          
            <Route path="/lists/ranked-songs-simple/billy-joel-15-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/billy-joel-15-greatest-songs-ranked" />} /> 
            <Route path="/lists/ranked-songs-simple/cream-15-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/cream-15-greatest-songs-ranked" />} /> 
            <Route path="/lists/ranked-songs-simple/guy-clark-20-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/guy-clark-20-greatest-songs-ranked" />} /> 
            <Route path="/lists/ranked-songs-simple/the-doors-10-greatest-songs-ranked" element={<Navigate replace to="/lists/ranked-songs/the-doors-10-greatest-songs-ranked" />} /> 
            <Route path="/lists/ranked-songs-simple/25-great-americana-dylan-covers" element={<Navigate replace to="/lists/ranked-songs/25-great-americana-dylan-covers" />} /> 
            <Route path="/lists/ranked-songs-simple/americana-music–100-great-songs" element={<Navigate replace to="/lists/ranked-songs/americana-music–100-great-songs" />} /> 
            <Route path="/lists/ranked-songs-simple/the-500-greatest-songs-of-all-time-2021" element={<Navigate replace to="/lists/ranked-songs/the-500-greatest-songs-of-all-time-2021" />} /> 
            <Route path="/lists/ranked-songs-simple/the-1001-greatest-singles-ever-made" element={<Navigate replace to="/lists/ranked-songs/the-1001-greatest-singles-ever-made" />} /> 
            <Route path="/lists/ranked-songs-simple/country-musics-500-greatest-singles" element={<Navigate replace to="/lists/ranked-songs/country-musics-500-greatest-singles" />} /> 
            
        </Routes>
        

        <main className="my-1 py-1">
            <div className="container">  
                <div className="row pt-1">
                    <div className="col">
                        <h1>404 - Not Found!</h1>
                        <Link to="/lists">
                            Go to current Lists
                        </Link> <br />
                        <Link to="/posts">
                            Go to current Posts
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    </div>
    );
};

export default NotFound;

