import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import Select from 'react-select';
import { dbURL, customStyles, customStyles2 } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import {DebounceInput} from 'react-debounce-input';
//import Highlighter from "react-highlight-words";

class VideoAudioAll extends Component {

    state = {
        rowAllData: [],
        selectedOptionYear: null,
        selectedOptionArtist: null,
        SearchString: ''
      }

    getItems(){
        axios.get(dbURL + 'videoaudioall')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 
    
    componentDidMount() {
        this.getItems()
      }
    
      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
      this.setState({SearchString: event.target.value});
      } 
  
      clearSearchField(){
      this.setState({
          SearchString: '', 
          })
      }

    selectedOptionYear = (selectedYear) => {
        this.setState({ selectedOptionYear: selectedYear });
    } 

    clearFilterYear(){
        this.setState({
            selectedOptionYear: null, 
            })
        }
    
    selectedOptionArtist = (selectedArtist) => {
        this.setState({ selectedOptionArtist: selectedArtist });
    } 

    clearFilterArtist(){
        this.setState({
            selectedOptionArtist: null, 
            })
        }
    
    scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
        };

    render() {
        
        const AllItems = this.state.rowAllData;
        const { selectedOptionYear } = this.state;
        const { selectedOptionArtist } = this.state;
        const { SearchString } = this.state;
        let VideoItems

        function SearchSongs(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < AllItems.length; i++) {

                if (AllItems[i].search_text.includes(searchText))
                
                    {searchArray.push(AllItems[i]);}
                    //console.table(filterArrayYear);
            } 
            //console.log(searchArray);
            return searchArray
        }

        let filterArray, songTitle;

        if (!selectedOptionYear && !selectedOptionArtist && SearchString) {

            

            filterArray = SearchSongs(SearchString.toLowerCase());
            }  

            

            

        if (selectedOptionYear && !selectedOptionArtist) {
            filterArray = AllItems.filter(e => e.gig_year === selectedOptionYear.value);
        }

        if (!selectedOptionYear && selectedOptionArtist) {
            filterArray = AllItems.filter(e => e.artist === selectedOptionArtist.value);
        }

        if (selectedOptionYear && selectedOptionArtist) {
            filterArray = AllItems.filter(e => e.gig_year === selectedOptionYear.value 
                && e.artist === selectedOptionArtist.value);
            }
    
        if (!selectedOptionYear && !selectedOptionArtist && !SearchString) {
        filterArray = 0;
        }  

        //console.log(selectedOptionYear);
        //console.log(filterArray);
        
        const OnlyYear = AllItems.map(item => item.gig_year);
         const OnlyYearSorted = OnlyYear.sort();
         const uniqueYear = OnlyYearSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.gig_year===c)] ||
             // eslint-disable-next-line 
             b[b.push({gig_year: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueYearSorted = uniqueYear.sort(function(a, b){
            return b.count-a.count
        })
         const YearOptions = uniqueYearSorted.map(value => { 
             //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
             return {value: value.gig_year, label: value.gig_year + ' (' + value.count + ')'}; 
            //}
         });


         const OnlyArtist = AllItems.map(item => item.artist);
         const OnlyArtistSorted = OnlyArtist.sort();
         const uniqueArtist = OnlyArtistSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.artist===c)] ||
             // eslint-disable-next-line 
             b[b.push({artist: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueArtistSorted = uniqueArtist.sort(function(a, b){
            return b.count-a.count
        })
         const ArtistOptions = uniqueArtistSorted.map(value => { 
             //if uniqueYearSorted {
                // console.log (uniqueYearSorted);
             return {value: value.artist, label: value.artist + ' (' + value.count + ')'}; 
            //}
         });

         if (filterArray === 0) {
            VideoItems = <div><h3>Large list, select <i>year</i> or <i>artist</i> first... or <i>search for songs.</i></h3></div>
        } 
        if (filterArray !== 0) {

            VideoItems = filterArray.map(value => {

            if (value.alt_title) {
                songTitle = value.alt_title;
            }
            else songTitle = value.title;

            return (

                <React.Fragment key={value.id}>

                    <div className="row videoTitle">
                        <div className="col-sm-12"> 
                            {
                                (() => {
                                if (value.youtube_2) {
                                    return <div>&nbsp;<b>{value.artist} - {value.gig_date}</b></div>
                                }  
                                return <div>&nbsp;<b>{value.artist} - <i>{songTitle}</i> - {value.gig_date}</b></div>
                                })()
                            }

                        </div>
                    </div>
                    <div className="row pt-3 pb-3">
                        <div className="col-sm-6">
                            
                            <div className="Header2">
                                <b>{value.venue} <br /> {value.city}, {value.country}</b>
                            </div><br />
                            <b>Line-up:</b><br />
                            {
                                (() => {
                                if (value.musicians2) {
                                    return <div className="factText mb-3">{value.musicians2}</div>;
                                }  
                                return <div className="factText mb-3">{value.musicians}</div>;
                                })()
                            }

                            {
                                (() => {
                                    if (value.lyrics) {
                                    return <div className="bg-light mx-2 Description">
                                                <div className="py-2 px-2">
                                                    <ReadMoreAndLess
                                                            charLimit={350}
                                                            readMoreText={"   Read more ▼"}
                                                            readLessText={"   Read less ▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                        >
                                                        {value.lyrics}
                                                    </ReadMoreAndLess>
                                            
                                                </div>
                                            </div>
                               
                                } 
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.full_concert) {
                                    return <div className="Description pt-3"><b>Setlist:</b><br />
                                      {value.setlist}  
                                    {/* <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={searchWords}
                                        autoEscape={true}
                                        textToHighlight={value.setlist}
                                    />  */}
                                    
                                    
                                    </div>
                                }  
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.mult_songs1) {
                                    return <div><b>Songs in video/audio:</b><br />
                                                <div className="DescriptionBig">{value.mult_songs1}</div>
                                        </div>
                                }  
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (!value.full_concert && value.setlist) {
                                    return <div className="Description pt-3"><b>Setlist:</b><br />
                                      {value.setlist}  
                                    {/* <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={searchWords}
                                        autoEscape={true}
                                        textToHighlight={value.setlist}
                                    />  */}
                                    
                                    
                                    </div>
                                }  
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                                    <b>Audio/Video - click to play</b>

{
                                        (() => {
                                            if (value.youtube && value.youtube_2 && value.youtube_3 && value.youtube_4) {
                                                return <div>
                                                            
                                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                            <div className="border my-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                                
                                                                    <ReactPlayer
                                                                        url={ value.youtube||value.vimeo }
                                                                        //className="react-player"
                                                                        light='true'
                                                                        //light
                                                                        controls
                                                                        width="100%"
                                                                        height="100%"
                                                                        //width="300"
                                                                        //height="300"
                                                                    />
                                                            </div>
                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_2}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_2 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
    
                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_3}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_3 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>

                                                            <div className="border mb-2">
                                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.song_title_4}</b></div>
                                                                
                                                                <ReactPlayer
                                                                    url={ value.youtube_4 }
                                                                    light='true'
                                                                    //light
                                                                    controls
                                                                    width="300"
                                                                    height="300"
                                                                />
                                                            </div>
                                            
                                                        </div>
                                            }
                                        if (value.youtube && value.youtube_2 && value.youtube_3) {
                                            return <div>
                                                        
                                                        <div className="Header2">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                        
                                                            <ReactPlayer
                                                                url={ value.youtube||value.vimeo }
                                                                //className="react-player"
                                                                light='true'
                                                                //light
                                                                controls
                                                                width="350"
                                                                height="350"
                                                            /><br />
                                                        <div className="Header2">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                        
                                                        <ReactPlayer
                                                            url={ value.youtube_2 }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="350"
                                                            height="350"
                                                        /><br />
                                                        <div className="Header2">&nbsp;&nbsp;<b>{value.alt_title_3}</b></div>
                                                        
                                                        <ReactPlayer
                                                            url={ value.youtube_3 }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="350"
                                                            height="350"
                                                        /><br />
                                        
                                                    </div>
                                        }
                                        if (value.youtube && value.youtube_2) {
                                            return <div>
                                                        
                                                        <div className="Header2">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                        
                                                            <ReactPlayer
                                                                url={ value.youtube||value.vimeo }
                                                                light='true'
                                                                //light
                                                                controls
                                                                //width="500"
                                                                //height="500"
                                                            /><br />
                                                            <div className="Header2">&nbsp;&nbsp;<b>{value.alt_title_2}</b></div>
                                                        
                                                            <ReactPlayer
                                                                url={ value.youtube_2 }
                                                                light='true'
                                                                //light
                                                                controls
                                                                //width="500"
                                                                //height="500"
                                                            /><br />
                                        
                                                    </div>
                                        }
                                        if (value.youtube) {
                                            return <div>
                                                        
                                                        <div className="Header2">&nbsp;&nbsp;<b>{value.alt_title||value.title}</b></div>
                                                        
                                                            <ReactPlayer
                                                                url={ value.youtube||value.vimeo }
                                                                light='true'
                                                                //light
                                                                controls
                                                                width="350"
                                                                height="350"
                                                            /><br />
                                                            
                                        
                                                    </div>
                                        }

                                        return <div><i>Nothing found on YouTube.</i></div>
                                        })()
                                    }


                                   
                                    
                                    {
                                        (() => {
                                        if (value.description) {
                                            return <div className="factText2 px-2 py-2">
                                                    {value.description}
                                                     </div>
                                        }
                                        return <p></p>;
                                        })()
                                    }

                        </div>

                    </div>

                </React.Fragment>
            
            )

        })
        }

        
    
        return (
            //my-5 py-5
        <main className="my-1 py-1">
            <div className="container-sm">
                <div className="row pt-2">
                    <div className="col pb-2">
                        <div className="Header">
                            &nbsp;Selected Video & Audio
                            </div>
                            
                        </div>
                </div>
                <div className="row">
                        <div className="col-sm-7 border-right ReducedText">
                        <img src='https://s3-eu-west-1.amazonaws.com/iya-news-prod.inyourarea.co.uk/2019/02/JS76829793-1.jpg' 
                    alt="RS500songs" className="img-fluid rounded mx-auto d-block"  width="550" />
                            
                            
                        </div>
                        <div className="col-sm-5 ReducedText">
                            <p>Videos will when you press the play icon.</p>
                            <p className="factText">The default view is sorted by <b>Recorded date</b>.</p>
                            <p>Please use filters & "Search" to navigate. </p>
                            <p><b>Count:</b> <span className="badge badge-success"><b>{filterArray.length}</b></span></p>
                            <br />
                            <div className="row">   
                                <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>

                            <div className="row pb-2">  
                                <div className="col-7">
                                    {/* <input 
                                        className="form-control" 
                                        type="text" 
                                        value={SearchString} 
                                        onChange={this.handleSearchChange} 
                                        placeholder="Search for songs" 
                                    /> */}
                                    <div className="search">             
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={SearchString}
                                            onChange={this.handleSearchChange}
                                            placeholder="Search for songs" 
                                        />
                                    </div>
                                </div>
                                <div className="col-5 pl-0 d-none d-sm-block">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => this.clearSearchField()} >Reset</button>
                                </div>
                            </div>
                            <div className="row">   
                                <div className="col">
                                    <h4>Filters</h4>
                                </div>
                            </div>
                            <div className="row pb-2">  
                                <div className="col-sm-4 mr-3">
                                        <Select
                                            value={selectedOptionYear}
                                            onChange={this.selectedOptionYear}
                                            options={YearOptions}
                                            placeholder='Select Year'
                                            styles={customStyles}
                                        />
                                </div>
                                <div className="col-sm-6"> 
                                        <button type="button" className="btn btn-primary btn-sm"  onClick={() => this.clearFilterYear()} >Reset filter</button>
                                </div>
                            </div>
                            <div className="row pb-2">  
                                <div className="col-sm-7">
                                        <Select
                                            value={selectedOptionArtist}
                                            onChange={this.selectedOptionArtist}
                                            options={ArtistOptions}
                                            placeholder='Select Artist'
                                            styles={customStyles2}
                                        />
                                </div>
                                <div className="col-sm-5"> 
                                        <button type="button" className="btn btn-primary btn-sm"  onClick={() => this.clearFilterArtist()} >Reset filter</button>
                                </div>
                            </div>

                            
                        </div>
                </div>
                

                
                {/* <div className="row">
                    <div className="col pb-2" align="Right"> 
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                        </button>
                    </div>
                </div> */}
    
                <div className="row pr-2 pl-2 pt-2 mt-4">
                    <div className="col">
                        {VideoItems}
                    </div>
                </div>
                
                <p align="Right"><br />
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b>
                    </button></p>
       
        
            </div>
        </main>

        )
    }
}

export default VideoAudioAll