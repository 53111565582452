import React, { Component } from 'react'
import '../lists.scss';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Container, Button } from 'reactstrap';
import Select from 'react-select';
import { dbURL } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import dayjs from 'dayjs';

//import Select from 'react-select';

class DylanPeople extends Component {

    state = {
        columnDefs: [
            {headerName: "First time", field: "first_date", resizable: true, sortable: true, width: 115, cellStyle: {fontWeight: 'bold', 'background-color': 'ivory', 'font-size': '14px'}},
            {headerName: "Name", field: "name2", resizable: true, width: 200, cellStyle: { 'font-size': '14px' }},
            {headerName: "Instrument", field: "main_instrument", filter: true, sortable: true, width: 110, cellStyle: { 'font-size': '14px' }},
            {headerName: "Recording sessions", field: "rec_desc", sortable: true, width: 280, cellStyle: { 'font-size': '14px' }},
            {headerName: "Gigs", field: "band_desc", sortable: true, width: 280, cellStyle: { 'font-size': '14px' }},
            {headerName: "Guest", field: "sg_desc", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }}
        ],
        rowAllData: [],
        selectedOptionInstrument: null,
        selectedOptionYear: null,
        selectedOptionSGYear: null,
        GridOn: false,
        SortAscending: false,
        SearchString: ''
      }

    getpeopleItems(){
        axios.get(dbURL + 'dylanpeople')
          .then(response => {
            this.setState({
              rowAllData: response.data
            });
          });
      } 
    
    componentDidMount() {
        this.getpeopleItems()
      }
    
      handleSearchChange = this.handleSearchChange.bind(this);
    
      handleSearchChange(event) {
        this.setState({SearchString: event.target.value});
      } 

      clearSearchField(){
        this.setState({
            SearchString: '', 
            })
        }
    
    selectedOptionInstrument = (selectedInstrument) => {
        this.setState({ selectedOptionInstrument: selectedInstrument });
    }

    selectedOptionYear = (selectedYear) => {
        this.setState({ selectedOptionYear: selectedYear });
    } 

    selectedOptionSGYear = (selectedSGYear) => {
        this.setState({ selectedOptionSGYear: selectedSGYear });
    } 


    clearFilterInstrument(){
        this.setState({
            selectedOptionInstrument: null, 
            })
        }
    
    clearFilterYear(){
        this.setState({
            selectedOptionYear: null, 
            })
        }
    
    clearFilterSGYear(){
        this.setState({
            selectedOptionSGYear: null, 
            })
        }
    
    clearFilterRange(){
        this.setState({
            selectedOptionRange: null, 
            })
        }

     onChangeGrid = () => {
        this.setState(initialState => ({
            GridOn: !initialState.GridOn,
        }));
     }

     onChangeSort = () => {
        this.setState(initialState => ({
            SortAscending: !initialState.SortAscending,
        }));
     }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {

        const { selectedOptionInstrument } = this.state;
        const { selectedOptionYear } = this.state;
        const { selectedOptionSGYear } = this.state;
        const { SortAscending } = this.state;
        const { SearchString } = this.state;

        //Filter logic
        let filterArray, sortArray

        if (SortAscending) {
            sortArray = this.state.rowAllData.sort(function (a,b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.first_date) - new Date(a.first_date);
              });
        } else {
            sortArray = this.state.rowAllData.sort(function (a,b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.first_date) - new Date(b.first_date);
              });
        }

        function SearchNames(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < sortArray.length; i++) {

                if (sortArray[i].searchname.includes(searchText))
                
                    {searchArray.push(sortArray[i]);}
                    //console.table(filterArrayYear);
            } 
            console.log(searchArray);
            return searchArray
        }

        function GetAllByYear(year) { 
            let filterArrayYear = [];
            for (let i = 0; i < sortArray.length; i++) {

                if ((sortArray[i].band_desc && sortArray[i].band_desc.includes(year)) || 
                (sortArray[i].rec_desc && sortArray[i].rec_desc.includes(year))
                ) 
                {filterArrayYear.push(sortArray[i]);}
                //console.table(filterArrayYear);
            } 
            return filterArrayYear
        }

        function GetAllBySGYear(year) {
            let filterArraySGYear = [];
            for (let i = 0; i < sortArray.length; i++) {

                if ((sortArray[i].sg_desc && sortArray[i].sg_desc.includes(year))
                ) 
                {filterArraySGYear.push(sortArray[i]);}
                //console.table(filterArraySGYear);
            } 
            return filterArraySGYear
        }
            
        
        if (!selectedOptionYear && !selectedOptionSGYear && !selectedOptionInstrument && SearchString) {
            filterArray = SearchNames(SearchString.toLowerCase());
            } 

        if (selectedOptionYear && !selectedOptionSGYear && !selectedOptionInstrument) {
            filterArray = GetAllByYear(selectedOptionYear.value);
            } 
        if (!selectedOptionYear && selectedOptionSGYear && !selectedOptionInstrument) {
            filterArray = GetAllBySGYear(selectedOptionSGYear.value);
            }
        if (!selectedOptionYear && !selectedOptionSGYear && selectedOptionInstrument) {
                filterArray = sortArray.filter(e => e.main_instrument === selectedOptionInstrument.value);
            }

        if (selectedOptionYear && selectedOptionInstrument) {
            filterArray = sortArray.filter(e => e.first_date === selectedOptionYear.value 
                && e.main_instrument === selectedOptionInstrument.value);
            }
        if (!selectedOptionYear && !selectedOptionSGYear && !selectedOptionInstrument && !SearchString) {
            filterArray = sortArray;
            } 
        

         //get only instrument
         const OnlyInstrument = filterArray.map(item => item.main_instrument);
         
         //unique Instrument + count
         const uniqueInstrument = OnlyInstrument.reduce((b,c) => (
             (b[b.findIndex(d => d.main_instrument===c)] ||
             // eslint-disable-next-line 
             b[b.push({main_instrument: c, count: 0})-1]).count++,b), 
             []
         );

         const uniqueInstrumentSorted = uniqueInstrument.sort(function(a, b){
            return b.count-a.count
        })
       
         //map Instrument with count to InstrumentOptions
         const InstrumentOptions = uniqueInstrumentSorted.map(value => { 
             return {value: value.main_instrument, label: value.main_instrument + ' (' + value.count + ')'}; 
         });
      
         const YearOptions = [
            { value: '1962', label: '1962' },
            { value: '1963', label: '1963' },
            { value: '1965', label: '1965' },
            { value: '1966', label: '1966' },
            { value: '1967', label: '1967' },
            { value: '1968', label: '1968' },
            { value: '1969', label: '1969' },
            { value: '1970', label: '1970' },
            { value: '1973', label: '1973' },
            { value: '1974', label: '1974' },
            { value: '1975', label: '1975' },
            { value: '1976', label: '1976' },
            { value: '1977', label: '1977' }
          ]

          const YearOptionsSG = [
            { value: '1962', label: '1962' },
            { value: '1963', label: '1963' },
            { value: '1968', label: '1968' },
            { value: '1975', label: '1975' },
            { value: '1980', label: '1980' }
          ]
        

        const peopleItems = filterArray.map(value => {

            return (
                <React.Fragment key={value.id}>

                <div className="row pt-3 pb-3 border-bottom border-top border-info">
                    
                    <div className="col-sm-2 Description">
                        <p className='Song' align="center"><b>{value.name2}</b></p>
                        <div align="center">
                        {
                        (() => {
                        if (value.picture_link) {
                          return  <img src={value.picture_link} alt="" height="150" width="150" 
                          className="rounded mx-auto d-block"></img>
                        }
                        return <img src={value.picture} alt="" height="150" width="150"></img>;
                        })()
                        }
                        </div>
                        <div>
                            <b>Born </b><br />
                            <div className="factText">{value.born}</div>
                                
                                
                                {
                                    (() => {
                                    if (value.dead) {
                                    return <div><b>Died</b><br />
                                    <div className="factText">{value.dead}</div></div>
                                    }
                                    return <br />
                                })()
                                }
                                {
                                (() => {
                                if (value.city) {
                                return <div><b>Born/origin</b> <br />
                                <div className="factText">{value.city}, {value.country}</div></div>
                                }
                                return <p></p>
                                 })()
                                }
                                  
                                      
                        </div>

                    </div>
                    
                        <div className="col-sm-4 pb-4">

                            <div className="row">
                            
            
                                <div className="col">
                                        <div>
                                        
                                            <b>First performance with Bob Dylan </b><br />

                                                {
                                                    (() => {
                                                    if (value.first_date_text) {
                                                        return <div className="factText">{value.first_date_text}</div>
                                                    }
                                                    return <div className="factText">{dayjs(value.first_date).format('MMMM DD, YYYY')}</div>

                                                    })()
                                                }


                                            
                                            
                                            <br />

                                            <b>Main instrument</b><br />
                                            <div className="factText">{value.main_instrument}</div>

                                            <b>Recording Sessions</b>
                                                {
                                                    (() => {
                                                    if (value.rec_desc) {
                                                        return <div className="factText">{value.rec_desc}</div>
                                                    }
                                                    return <div className="factText"><em>None</em></div>
                                                    })()
                                                }

                                            <b>Concerts</b>
                                            {
                                            (() => {
                                            if (value.band_desc) {
                                                return <div className="factText">{value.band_desc}</div>
                                            }
                                            return <div className="factText"><em>None</em></div>
                                            })()
                                            }

                                            <b>Special guest</b>
                                            {
                                            (() => {
                                            if (value.sg_desc) {
                                                return <div className="factText">{value.sg_desc}</div>
                                            }
                                            return <div className="factText"><em>None</em></div>
                                            })()
                                            }


                                            
                                        
                                            {
                                                (() => {
                                                if (value.single_released) {
                                                return <div className="factText">
                                                <b>Single released</b> <br />
                                                    {value.single_released}</div>
                                                } else if (value.sr_text) {
                                                return <div className="factText">
                                                <b>Single released</b> <br />
                                                    {value.sr_text}</div>
                                                }
                                                return <p></p>
                                            })()
                                            }
                                            <br />
                                            
                                            {
                                            (() => {
                                            if (value.youtube) {
                                                return <p><a href={value.youtube} target="_blank" rel="noopener noreferrer">
                                            <img border="0" alt="youtube" src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=40,height=40" /></a></p>
                                            }
                                            return <p></p>;
                                            })()
                                            }
                                            
                                        </div>

                                </div>

                               

                            </div>

                            
                            
                        
                        </div>

                        <div className="col pb-3">
                            <div className="row pb-2 border-bottom Description">
                                    <ReadMoreAndLess
                                        charLimit={400}
                                        readMoreText={" More▼"}
                                        readLessText={" Less▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess>
                                <br /><em> -> <a href={value.wiki}>wikipedia</a></em>
                            </div>
                        

                        {
                                    (() => {
                                    if (value.mgray) {
                                        return <div className="row pt-2 Description">
                                            <b>Michael Gray - The Bob Dylan Encyclopedia</b>
                                    <ReadMoreAndLess
                                        charLimit={400}
                                        readMoreText={" More▼"}
                                        readLessText={" Less▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                        {value.mgray}
                                    </ReadMoreAndLess>
                                    
                                    </div>
                                    }
                                    return <p></p>;
                                    })()
                        }
                        </div>


                        
                    
                        

                </div>

                </React.Fragment>          
            )

        })

       //console.log(this.state.DetailsOff);
    
        return (

            <main className="my-1 py-2">
            <Container>
                   
            <div className="row">
                <div className="col-sm-2 mb-3">
                    <img src='https://static01.nyt.com/images/2012/06/03/books/review/03FINN/03FINN-jumbo.jpg?quality=75&auto=webp&disable=upscale' 
                    alt="RS500songs" className="img-fluid rounded mx-auto d-block" />
                    
                    
                </div>

            <div className="col-sm-6">
                        <h3>Musicians who has performed with Bob Dylan</h3>
                    
                        <p>Starting in Studio A - Columbia Recording Studios, New York City on October 26, 1962 when he first recorded with a band, I will do this chronologically.
                            </p>
                        <p>Home recordings, hotel rooms performances, etc.. are excluded. Same goes for other artists recording sessions.</p>
                        
                        <p>The list is default sorted by "First performance with Bob Dylan"</p>
                        
                        <b>Check out:</b>
                        <ul>
                        <li><a href="https://www.amazon.com/Bob-Dylan-Encyclopedia-Michael-Gray/dp/0826469337/ref=sr_1_3?dchild=1&keywords=michael+gray+bob+dylan&qid=1587617035&s=books&sr=1-3">
                        The Bob Dylan Encyclopedia - Michael Gray</a></li>
                        <li>
                            <a href="http://www.bjorner.com/still.htm">
                            Still On The Road - Olof Bjørner</a>
                        </li>
                        </ul>
                        <div className="alert alert-success" role="alert">
                            Work in <b><u>early</u></b> progress - count <span className="badge badge-light">{filterArray.length}</span>
                        
                            <div align="Right">
                            
                                <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                <b>Scroll down</b>
                                </button>
                            </div>
                        </div>
                        
            </div>
                        
            <div className="col-sm-4 mb-4">
                {(!this.state.SearchString) &&
                    <div className="row">   
                        <div className="col">
                            <h4>Filters</h4>
                        </div>
                    </div>
                }
                
                {(!this.state.SearchString) &&
                <div className="row pb-2">  
                        <div className="col-7">
                            <Select
                                value={selectedOptionInstrument}
                                onChange={this.selectedOptionInstrument}
                                options={InstrumentOptions}
                                placeholder='Select Instrument'
                            />
                        </div>
                        <div className="col-5">
                            <Button color="primary"  onClick={() => this.clearFilterInstrument()} >Reset filter</Button>
                        </div>
                    </div>
                }

                {(!this.state.SearchString) &&
                     <div className="row pb-2">  
                        <div className="col-7">
                            Rec sessions or concerts
                                <Select
                                    value={selectedOptionYear}
                                    onChange={this.selectedOptionYear}
                                    options={YearOptions}
                                    placeholder='Select Year'
                                />
                        </div>
                            <div className="col-5"> <br />
                                <Button color="primary"  onClick={() => this.clearFilterYear()} >Reset filter</Button>
                        </div>
                    </div> 
                }

                {(!this.state.SearchString) &&
                    <div className="row pb-2">  
                        <div className="col-7">
                            Special guest
                                <Select
                                    value={selectedOptionSGYear}
                                    onChange={this.selectedOptionSGYear}
                                    options={YearOptionsSG}
                                    placeholder='Select Year'
                                />
                        </div>
                            <div className="col-5"> <br />
                                <Button color="primary"  onClick={() => this.clearFilterSGYear()} >Reset filter</Button>
                        </div>
                    </div> 
                }

                    <div className="row">   
                        <div className="col">
                            <h4>Search</h4>
                        </div>
                    </div>

                    {((!this.state.selectedOptionInstrument) && (!this.state.selectedOptionYear) && (!this.state.selectedOptionSGYear)) && 
                        <div className="row pb-2">  
                                <div className="col-7">
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    value={SearchString} 
                                    onChange={this.handleSearchChange} 
                                    placeholder="Search name" 
                                />
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => this.clearSearchField()} >Reset search</button>
                                </div>
                        </div>
                        }
             
                           
                <div className="row align-items-start no-gutters">
                                    
                    <div className="col pt-2">
                                        
                    

                        <div className="form-check">
                            <label className="form-check-label">
                            <input type="checkbox"
                                checked={this.state.GridOn}
                                onChange={this.onChangeGrid}
                                className="form-check-input"
                            />
                            Change to Grid
                            </label>
                        
                        </div>
                        {!this.state.GridOn && 
                                <div className="form-check">
                                    <label className="form-check-label">
                                    <input type="checkbox"
                                        checked={this.state.SortAscending}
                                        onChange={this.onChangeSort}
                                        className="form-check-input"
                                    />
                                    Sort Ascending
                                    </label>
                                </div>
                            }
    
                    </div>
                </div>
                <br />
                
            </div>
        </div>
        
        {!this.state.GridOn && 
        <div className="row">
            <div className="col">
                    {peopleItems}
            </div>
        </div>
        }

        {this.state.GridOn && 
        <div className="row pr-2 pl-2 pt-3">
        <div
            id="myGrid"
            style={{ height: '2000px', width: '1200px' }}
            className="ag-theme-balham" 
            >
          
              <AgGridReact
              
              onGridReady={ params => this.gridApi = params.api }
                  rowSelection="multiple"
                  pagination={true}
                  columnDefs={this.state.columnDefs}
                  rowData={filterArray}
                  >
                  
              </AgGridReact>
            
        </div>
        </div>
        }
       <p align="Right"><br />
            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                <b>Scroll to top</b>
            </button>
            
        </p>
                
              
      </Container>
    </main>

        )
    }
}

export default DylanPeople