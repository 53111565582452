import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import { dbURLPostSessions, dbURLPostHeading } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import ReactPlayer from "react-player";
import Highlighter from "react-highlight-words";
import {DebounceInput} from 'react-debounce-input';
//import ReactGA from "react-ga";
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import SEO from '../../SEO';
import withRouter from '../withRouter';

class RecordingSessions extends Component {

    state = {
        SessionHedingData: [],
        SessionsData: [],
        SearchString: '',
        loading: true,
        loading2: true
      }

    getSessionHeadingItem(){
        axios.get(dbURLPostHeading + this.props.params.slug)
          .then(response => {
            this.setState({
                SessionHedingData: response.data,
                loading2: false
            });
          });
      } 

    getSessionsItems(){
        axios.get(dbURLPostSessions + this.props.params.slug)
          .then(response => {
            this.setState({
                SessionsData: response.data,
                loading: false
            });
          });
      } 
    
    componentDidMount() {
        this.getSessionHeadingItem()
        this.getSessionsItems()
      }

      handleSearchChange = this.handleSearchChange.bind(this);

      handleSearchChange(event) {
        this.setState({SearchString: event.target.value});
        } 
    
      clearSearchField(){
        this.setState({
          SearchString: '', 
            })
        }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    render() {

        if (this.state.loading || this.state.loading2) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }

        const { SearchString } = this.state;
        const SessionHeading = this.state.SessionHedingData;
        const SessionItemsAll = this.state.SessionsData;
        const Filter = this.props.Selected;
        //const Artist = this.props.Artist;
        let filterArray, SelectedSessionsRemoveNull, SelectedSessions, searchWords, Sources


        if (SessionHeading.length < 1) {
            return <div>
                        <main className="my-1 py-1">
                            <div className="container">  
                                <div className="row pt-1">
                                    <div className="col">
                                        <h1>404 - Not Found!</h1>
                                        <Link to="/posts">
                                            Go to current Posts
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                </div> 
        }

        const {name, description, picture_link, page} = SessionHeading[0];

        if (Filter) {
            SelectedSessionsRemoveNull = SessionItemsAll.filter(e => e.selected != null);
            SelectedSessions = SelectedSessionsRemoveNull.filter(e => e.selected.includes(Filter));
        }
        if (!Filter) {
            SelectedSessions = SessionItemsAll;
        }


        function SearchRecSheets(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < SelectedSessions.length; i++) {
                //console.table(AllItems);
                if (SelectedSessions[i].search_rec_sheets.includes(searchText))
                
                    {searchArray.push(SelectedSessions[i]);}
                    
            } 
            //console.log(searchArray);
            return searchArray
        }

        if (SearchString) {
            searchWords = SearchString.split(/\s/).filter(word => word)

            filterArray = SearchRecSheets(SearchString.toLowerCase());
            } 
        if (!SearchString) {
            filterArray = SelectedSessions;
        }

        const DylanSources = <ul><li><b><a href='http://www.bjorner.com/still.htm'> Olof's Still On The Road </a></b></li>
        <li><b><a href='https://www.amazon.com/Double-Life-Bob-Dylan-1941-1966-ebook/dp/B08LDPF8M6/ref=sr_1_1?keywords=clinton+heylin&qid=1639381998&sr=8-1'> The Double Life of Bob Dylan: A Restless, Hungry Feeling, 1941-1966 (Clinton Heylin) </a></b></li>
        <li><b><a href='http://www.punkhart.com/dylan/tapes/index.html'> Bob Dylan's Unreleased Tapes </a></b></li>
        <li><b><a href='https://en.wikipedia.org'> Wikipedia </a></b></li>
        <li><b><a href='https://www.youtube.com/'> YouTube </a></b></li></ul>;

        const StonesSources = <ul><li><b><a href='http://aeppli.ch/tug.htm'> The Ultimate Guide To The Rolling Stones </a></b></li>
        <li><b><a href='https://www.amazon.com/Rolling-Stones-All-Songs-Behind/dp/0316317748'> The Rolling Stones All the Songs: The Story Behind Every Track (book) </a></b></li>
        <li><b><a href='https://www.timeisonourside.com'> Time Is On Our Side </a></b></li>
        <li><b><a href='https://www.dbboots.com'> dboots - The Rolling Stones Bootlegs database </a></b></li>
        <li><b><a href='https://en.wikipedia.org'> Wikipedia </a></b></li>
        <li><b><a href='https://www.youtube.com/'> YouTube </a></b></li></ul>;

        const NeilSources = <ul><li><b><a href='http://www.sugarmtn.org'> Sugar Mountain (wonderful website) </a></b></li>
        <li><b><a href='https://www.amazon.com/Rolling-Stones-All-Songs-Behind/dp/0316317748'> Waging Heavy Peace (book by Neil Young) </a></b></li></ul>;
       
        const ElvisSources = <ul><li><b><a href='https://www.keithflynn.com/recording-sessions/index.html'> Keith Flynn's Elvis Presley Pages (website) </a></b></li>
        <li><b><a href='https://www.amazon.com/Elvis-Presley-Complete-Recording-Sessions/dp/0312185723/ref=sr_1_1?keywords=Elvis+Presley%3A+A+Life+in+Music%3A+The+Complete+Recording+Sessions&qid=1639908571&s=books&sr=1-1'> Elvis Presley: A Life in Music: The Complete Recording Sessions (book by Ernst Jørgensen) </a></b></li>
        <li><b><a href='https://www.amazon.com/Elvis-Music-FAQ-Thats-Recorded/dp/1617130494'> Elvis Music FAQ (book by Mike Eder) </a></b></li>
        <li><b><a href='https://www.amazon.com/Reconsider-Baby-Elvis-Presley-Listeners/dp/1976053013'> Reconsider Baby. Elvis Presley: A Listener's Guide (book by Shane Brown)</a></b></li>
        <li><b><a href='https://www.amazon.com/Blue-Moon-Boys-Story-Presleys/dp/1556526148'> The Blue Moon Boys - The Story of Elvis Presley's Band (book) </a></b></li></ul>;

        const BeatlesSources = <ul><li><b><a href='https://en.wikipedia.org/wiki/The_Beatles%27_recording_sessions'> The Beatles' recording sessions (wikipedia) </a></b></li>
        <li><b><a href='https://www.amazon.com/All-Songs-Behind-Beatles-Release/dp/1579129528'> All the Songs: The Story Behind Every Beatles Release (book) </a></b></li>
        <li><b><a href='https://www.beatlesbible.com/'> The Beatles Bible (website)</a></b></li>
        <li><b><a href='https://www.amazon.com/Tune-Beatles-All-These-Years/dp/1101903295'> Tune In: The Beatles: All These Years (book)</a></b></li></ul>;

        const {artist} = SessionHeading[0];
        console.table(SessionHeading);
        console.log(artist);

        if (artist === 'Bob Dylan') {
            Sources = DylanSources
        }
        if (artist === 'The Rolling Stones') {
            Sources = StonesSources
        }
        if (artist === 'Neil Young') {
            Sources = NeilSources
        }
        if (artist === 'Elvis Presley') {
            Sources = ElvisSources
        }
        if (artist === 'The Beatles') {
            Sources = BeatlesSources
        }

        const SelectedSessionHeader = SessionHeading.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row mb-3">
                        {/* SJEKKE PÅ MOBIL */}

                        <div className="col-sm-4">
                            <img src={value.picture_link}
                            alt="alt" className="img-fluid rounded mx-auto d-block" />
                            <div className="btn btn-secondary btn-xs mt-3 mb-2 ml-5">Number of sessions: {SelectedSessions.length}</div>
                            {
                                (() => {
                                if (value.no_audio) {
                                    return <div className="btn btn-secondary btn-xs mt-2 mb-2 ml-5">Number of audio clips: {value.no_audio}</div>
                                }
                                return <p></p>;
                                })()
                            }
                        </div>

                        <div className="col-sm-8">
                            <h3>{value.name}</h3>
                            
                            <div className="Description">
                                <ReactMarkdown>
                                    {value.description}                       
                                </ReactMarkdown>
                                
                            </div>
                            
                             {
                                (() => {
                                if (value.comment1) {
                                    return <div className="card bg-light py-2 px-2 my-2">
                                                <p className="quote3">{value.comment1}
                                                
                                                </p> 
                                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }


                             <div className="row">
                                 <div className="col-sm-7">
                                    <b>Sources</b>
                                    
                                    <div className="Description">{Sources}</div>
                                    
                                    <b>Also check out:</b>
                                    <div className="Description">

                                        <ul>
                                            {
                                                (() => {
                                                if (value.link1) {
                                                    return <div><li><b><a href={value.url1}>
                                                                    {value.link1}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link2) {
                                                    return <div><li><b><a href={value.url2}>
                                                                    {value.link2}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link3) {
                                                    return <div><li><b><a href={value.url3}>
                                                                    {value.link3}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link4) {
                                                    return <div><li><b><a href={value.url4}>
                                                                    {value.link4}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.link5) {
                                                    return <div><li><b><a href={value.url5}>
                                                                    {value.link5}
                                                                    </a></b>
                                                                </li> 
                                                            </div>
                                                }
                                                return 
                                                })()
                                            }

                                            
                                           
                                        </ul>
                                
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div>
                                        
                                        <div className="row">   
                                                <div className="col">
                                                <h5>Search</h5>
                                            </div>
                                        </div>
                                
                                        <div className="row pb-2">  
                                                <div className="col-8">
                                                <DebounceInput
                                                        //type="number"
                                                        minLength={3}
                                                        debounceTimeout={500}
                                                        value={SearchString}
                                                        onChange={this.handleSearchChange}
                                                        placeholder=" Search all Songs" 
                                                    />
                                                </div>
                                                <div className="col-4 pl-0">
                                                    <button type="button" className="btn btn-primary btn-sm"  
                                                    onClick={() => this.clearSearchField()} >Reset</button>
                                                </div>
                                        </div>
                                        {this.state.SearchString && 
                                                    <div className="Description">    
                                                    <b>Rec Session hits: {filterArray.length}</b>
                                                    </div>
                                                }
                                    </div>
                                        

                                
                                </div>
                            
                            </div>

                                


                        </div>

                    </div>

                </React.Fragment>
            )
            }
        )

        const SessionItems = filterArray.map(value => {
            
            return (

                <React.Fragment key={value.id}>

                    <div className="row ConcertTitleLight2 mt-3">
                        <div className="col-sm-12"> 
                            {
                                (() => {
                                    if (value.mult_days) {
                                        return <div>&nbsp;{value.mult_days}, {value.sessions_year} - {value.studio} - {value.location}   
                                        </div>
                                        }
                                    if (value.rec_date) {
                                        return <div>&nbsp;{value.rec_date} - {value.studio} - {value.location}   
                                        </div>
                                    }
                                    return <div>&nbsp;{value.date_txt} - {value.studio} - {value.location}   
                                    </div>
                                })()
                            }
                        </div>
                    
                    </div>

                    <div className="row py-1 mb-3">

                        {/* 1 kolonne */}
                        <div className="col-sm-3">
                            <div>
                                <div className="Song2 mb-1" align="center"><b>{value.title}</b></div>
                            </div>

                            {
                                (() => {
                                    if (value.picture) {
                                        return <div className="text-center mb-1"><img src={value.picture} alt="" height="220" width="220"></img></div>
                                        }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.album_cover) {
                                        return <div className="text-center"><img src={value.album_cover} alt="" height="220" width="220"></img></div>
                                        }
                                    return 
                                })()
                            }

                            
                            {
                                (() => {
                                if (value.producer) {
                                return <div>
                                            <b>Producer</b> <br />
                                            <div className="factText p-1">{value.producer}</div>
                                            
                                        </div>
                                } 
                                return 
                                })()
                            }
                        
                            {
                                (() => {
                                if (value.engineer) {
                                return <div><b>Engineer</b> <br />
                                <div className="factText mb-2 p-1">{value.engineer}</div>
                                
                                </div>
                            
                                } 
                                return
                                })()
                            }

                            {
                                (() => {
                                if (value.musicians) {
                                return <div><b>Musicians</b> <br />
                                <div className="factText p-1">{value.musicians}</div>
                                </div>
                            
                                } 
                                return
                                })()
                            }

                        </div>

                        {/* 2 kolonne */}
                        <div className="col-sm-5 Description">

                            {
                                (() => {
                                if (SearchString) {
                                    return  <div className="bg-light ml-2 mr-2">
                                                <div className="py-2 px-2">
                                                <b>Recording sheet:</b><br />
                                                        <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={searchWords}
                                                            autoEscape={true}
                                                            textToHighlight={value.rec_sheet}
                                                        />
                                                </div>
                                            </div>
                                }
                                return <div className="bg-light mx-2 py-1 px-2">
                                            <b>Recording sheet:</b><br />
                                            <ReadMoreAndLess
                                                charLimit={800}
                                                readMoreText={" more ▼"}
                                                readLessText={" less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                                    >
                                                    {value.rec_sheet}
                                            </ReadMoreAndLess>
                                        </div>
                                })()
                            }

                            {
                                (() => {
                                if (value.notes) {
                                    return  <div className="Header4 mt-2 p-1">
                                                    <div className="factText">

                                                        <ReadMoreAndLess
                                                                charLimit={600}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.notes}
                                                        </ReadMoreAndLess>
                                                    
                                                    </div>
                                            </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.releases) {
                                    return  <div className="Header4 my-2 p-1">
                                                <b>Releases:</b><br />
                                                    <div className="factText">
                                                        {value.releases}
                                                    </div>
                                            </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.description_col2) {
                                    return <div className="Description mb-2">
                                            <ReadMoreAndLess
                                                    charLimit={500}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description_col2}
                                            </ReadMoreAndLess>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment2) {
                                    return <div className="card bg-light py-2 px-2 mt-3">
                                                <p className="quote3">{value.comment2}
                                                
                                                </p> 
                                                <div className="blockquote-footer"><i>{value.source2}</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }

                            {
                                (() => {
                                if (value.comment3) {
                                    return <div className="card bg-light py-2 px-2 mt-3">
                                                <p className="quote3">{value.comment3}
                                                
                                                </p> 
                                                <div className="blockquote-footer"><i>{value.source3}</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }

                            {
                                (() => {
                                if (value.session_picture && !value.session_pic_high) {
                                    return <div className="text-center"><img src={value.session_picture} alt="" height="400" width="450"></img></div>
                                }
                                if (value.session_picture && value.session_pic_high) {
                                    return <div className="text-center"><img src={value.session_picture} alt="" height="470" width="400"></img></div>
                                }
                                return 
                                })()
                            }

                        </div>
                        
                        {/* 3 kolonne */}
                        <div className="col-sm-4">

                            {
                                (() => {
                                    if (value.youtube && value.youtube2 && value.youtube3 && value.youtube4 && value.youtube5) {
                                        return <div>
                                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border my-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                                    <ReactPlayer
                                                        url={ value.youtube }
                                                        //className="react-player"
                                                        light='true'
                                                        //light
                                                        controls
                                                        width="100%"
                                                        height="100%"
                                                        //width="300"
                                                        //height="300"
                                                    />
                                            </div>
                                            <div className="border mb-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                                
                                                <ReactPlayer
                                                    url={ value.youtube2 }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="300"
                                                    height="300"
                                                />
                                            </div>
    
                                            <div className="border mb-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title3}</b></div>
                                                
                                                <ReactPlayer
                                                    url={ value.youtube3 }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="300"
                                                    height="300"
                                                />
                                            </div>
    
                                            <div className="border mb-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title4}</b></div>
                                                
                                                <ReactPlayer
                                                    url={ value.youtube4 }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="300"
                                                    height="300"
                                                />
                                            </div>
                                            <div className="border mb-2">
                                                <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title5}</b></div>
                                                
                                                <ReactPlayer
                                                    url={ value.youtube5 }
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="300"
                                                    height="300"
                                                />
                                            </div>
    
                                        </div>
                                    }
                                if (value.youtube && value.youtube2 && value.youtube3 && value.youtube4) {
                                    return <div>
                                        <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                        <div className="border my-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                                <ReactPlayer
                                                    url={ value.youtube }
                                                    //className="react-player"
                                                    light='true'
                                                    //light
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                    //width="300"
                                                    //height="300"
                                                />
                                        </div>
                                        <div className="border mb-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                            
                                            <ReactPlayer
                                                url={ value.youtube2 }
                                                light='true'
                                                //light
                                                controls
                                                width="300"
                                                height="300"
                                            />
                                        </div>

                                        <div className="border mb-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title3}</b></div>
                                            
                                            <ReactPlayer
                                                url={ value.youtube3 }
                                                light='true'
                                                //light
                                                controls
                                                width="300"
                                                height="300"
                                            />
                                        </div>

                                        <div className="border mb-2">
                                            <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title4}</b></div>
                                            
                                            <ReactPlayer
                                                url={ value.youtube4 }
                                                light='true'
                                                //light
                                                controls
                                                width="300"
                                                height="300"
                                            />
                                        </div>

                                    </div>
                                }
                                
                                if (value.youtube && value.youtube2 && value.youtube3) {
                                    return <div>
                                
                                    <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                    <div className="border my-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                        
                                            <ReactPlayer
                                                url={ value.youtube }
                                                //className="react-player"
                                                light='true'
                                                //light
                                                controls
                                                width="100%"
                                                height="100%"
                                                //width="300"
                                                //height="300"
                                            />
                                    </div>
                                    <div className="border mb-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                        
                                        <ReactPlayer
                                            url={ value.youtube2 }
                                            light='true'
                                            //light
                                            controls
                                            width="300"
                                            height="300"
                                        />
                                    </div>

                                    <div className="border mb-2">
                                        <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title3}</b></div>
                                        
                                        <ReactPlayer
                                            url={ value.youtube3 }
                                            light='true'
                                            //light
                                            controls
                                            width="300"
                                            height="300"
                                        />
                                    </div>
                    
                                </div>
                                }
                                if (value.youtube && value.youtube2) {
                                    return <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>
                                                <div className="border my-2">
                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                                    
                                                        <ReactPlayer
                                                            url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="300"
                                                            height="300"
                                                        />
                                                    </div>
                                                    <div className="border mb-2">
                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.title2}</b></div>
                                                
                                                    <ReactPlayer
                                                        url={ value.youtube2 }
                                                        light='true'
                                                        //light
                                                        controls
                                                        width="300"
                                                        height="300"
                                                    />
                                                </div>
                                                
                                            </div>
                                }
                                if (value.youtube) {
                                    return <div>
                                                <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                                                <div className="border my-2">

                                                    <div className="DateTxt" align="center">&nbsp;&nbsp;<b>{value.video_title}</b></div>
                                                    
                                                        <ReactPlayer
                                                            url={ value.youtube }
                                                            light='true'
                                                            //light
                                                            controls
                                                            width="300"
                                                            height="300"
                                                        /><br />
                                                    

                                                </div>
                                                    
                                
                                            </div>
                                }

                                return
                                })()
                            }


                            {
                                (() => {
                                if (value.description) {
                                return <div className="Description mb-2">
                                        <ReadMoreAndLess
                                                charLimit={500}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                    </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <p className="quote3">{value.comment}
                                                
                                                </p> 
                                                <div className="blockquote-footer"><i>{value.source}</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }

                        </div>


                    </div>


                </React.Fragment>
            )

        })


        return (

            <main className="my-1 py-1">
                <div className="container">
                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={picture_link}
                    url={'https://musicthisday.com/'+page}
                    type='article' />
                            
                    {SelectedSessionHeader}

                    <div className="row">
                        <div className="col pb-2 mr-4" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {
                        (() => {
                        if (this.state.loading) {
                            return <div><br /><h5><i>Loading session...</i></h5></div>
                        }
                        return <div> 
                            
                                <div className="row">
                                    <div className="col">
                                            {SessionItems}
                                    </div>
                                </div>
                            
                            </div>
                        })()
                    }

                    <div className="mr-3">
                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>
                    </div>
              
                </div>
            </main>




        )
    }
}

export default withRouter(RecordingSessions);