import React, { Component } from "react";
import {
    Routes,
    Route
  } from "react-router-dom";
import NotFound from "./NotFound";
import Header from './components/Header';
import MusicAnyDay from "./components/MusicAnyDay";
import DylanAnyDay from "./components/today/DylanAnyDay";
import PeopleProfile from "./components/people/Profiles";
import VideoAudioAll from "./components/concerts/VideoAudioAll";
import Video from "./components/Video/Video";

import Lists from "./components/Lists";
import DylanListsPosts from "./components/DylanListsPosts";

// POSTS
import Posts from "./components/Posts";
import Post from "./components/posts/post";
import RecordingSessions from "./components/sessions/RecSessions";
import TheBestSongs from "./components/songs/BestSongs";
import TheBestAlbums from "./components/albums/BestAlbums";
import GreatConcert from "./components/concerts/GreatConcert";
// ***

import Covers from "./components/songs/Covers";
import SongsCoversDylan from "./components/songs/SongsCoversDylan";

import BobDylanSongs from "./components/songs/BobDylan";
import BobDylanLondon from "./components/travel/BobDylanLondon";
import ConcertsTours from "./components/concerts/ConcertsTour";
import GreatestConcertsByDecade from "./components/concerts/GreatestConcerts";

import Bootleg from "./components/Video/Bootleg";

import PeopleLists from "./components/people/PeopleLists";
import SongLists from "./components/songs/SongLists";
import AlbumLists from "./components/albums/AlbumLists";

//import BobDylanSongsLiveLists from "./components/songs/BobDylanSongsLiveLists";
import SongsLiveLists from "./components/songs/SongsLiveLists";

import ConcertsLists from "./components/concerts/ConcertLists";
import Concert from "./components/concerts/Concert";

import QuoteLists from "./components/words/QuoteLists";
import InterviewLists from "./components/words/InterviewLists";
import InterviewsAll from "./components/words/InterviewsAll";

//større prosjekt med TIMELINE
import BobDylanTimelineYear from "./components/history/BobDylanTimeline";
import MusicTimelineYear from "./components/history/MusicTimeline";


// FJERNE ? SKJULE ?
import People from "./components/people/People";
import ConcertLog from "./components/concerts/ConcertLog";

import DylanPeople from "./components/people/DylanPeople";

  class App extends Component {
    
    render() {

      return (
        
        <div className="App">
            
            <Header />
                    
            <Routes>

                <Route path="/" element={<MusicAnyDay />} />

                {/* <Route path="/dylanlists" element={<DylanLists />} /> */}

                <Route path="/bobdylan" element={<DylanListsPosts />} />


                <Route path="/bob-dylan-any-day" element={<DylanAnyDay />} />

                <Route path="/videoaudioall" element={<VideoAudioAll />} />

                <Route path="/video/:slug" element={<Video />} />



                <Route path="/bobdylansongs" element={<BobDylanSongs />} />
                <Route path="/dylanlondon" element={<BobDylanLondon />} />

                <Route path="/lists/people/:slug" element={<PeopleLists />} />
                {/* <Route path="/lists/people/:slug" element={<PeopleLists />} /> */}


                {/* SONGS */}
                <Route path="/lists/songs/:slug" 
                element={<SongLists SongsAPI={'songsunrankedlist/'} />} />

                <Route path="/lists/ranked-songs/:slug" 
                element={<SongLists SongsAPI={'songsrankedlist/'} />} />

                {/* 500 låter 1970s */}
                <Route path="/lists/songs-decade/:slug" 
                element={<SongLists SongsAPI={'500songs1970s/'} />} />

                
                <Route path="/covers/:slug" element={<Covers />} />

                <Route path="/lists/covers/songs-covered-by-bob-dylan" element={<SongsCoversDylan />} />

                <Route path="/lists/albums/:slug" 
                element={<AlbumLists AlbumsAPI = {'albumsunrankedlist/'} />} />

                <Route path="/lists/ranked-albums/:slug" 
                element={<AlbumLists AlbumsAPI={'albumsrankedlist/'} />} />

                <Route path="/lists/live/songs/dylan/:slug" element={<SongsLiveLists />} />
                <Route path="/lists/live/songs/:slug" element={<SongsLiveLists />} />

                <Route path="/lists/concerts/:slug" element={<ConcertsLists />} />

                <Route path="/concert/:slug" element={<Concert />} />


                <Route path="/lists/quotes/:slug" element={<QuoteLists />} />
                <Route path="/lists/interviews/:slug" element={<InterviewLists />} />
                <Route path="/lists/all-interviews" element={<InterviewsAll />} />



                <Route path="/timelines/bob-dylan/:slug" element={<BobDylanTimelineYear />} />
                <Route path="/timelines/music/:slug" element={<MusicTimelineYear />} />

                

               

                {/* MÅ FIKSES */}
                <Route path="/dylan2021tour" element={<ConcertsTours api={'concertwtour/40'} api2={'tour/40'} />} />
                
                <Route path="/greatestconcerts70s" element={<GreatestConcertsByDecade HeaderAPI={'list/160'} ConcertsAPI={'greatestconcerts/greatest70s'} />} />
                <Route path="/greatestconcerts60s" element={<GreatestConcertsByDecade HeaderAPI={'list/157'} ConcertsAPI={'greatestconcerts/greatest60s'} />} />

                <Route path="/bootleg/:slug" element={<Bootleg />} />
                           


                <Route path="/lists" element={<Lists />} />

                <Route path="/posts" element={<Posts />} />

                <Route path="/profiles/:slug" element={<PeopleProfile />} /> 

                <Route path="/post/:slug" element={<Post />} />

                <Route path="/sessions/:slug" element={<RecordingSessions />} />

                <Route path="/songs/:slug" element={<TheBestSongs />} />

                <Route path="/albums/:slug" element={<TheBestAlbums />} />

                <Route path="/concert/:slug" element={<GreatConcert />} />
                
               
                {/* FJERNE eller skjule ?? */}

                <Route path="/people" element={<People />} />
                <Route path="/concertlog" element={<ConcertLog />} />

                <Route path="/dylanpeople" element={<DylanPeople />} />

                

                {/* TIL SLUTT */}
                    
                <Route path="*" element={<NotFound />} />
                
            </Routes> 
                    
        </div> 
        
   );
  }
}

export default App;