import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURLists, dbURLCoverVersions } from '../Constants';
import '../lists.scss';
import SEO from '../../SEO';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import { AgGridReact } from 'ag-grid-react';
import ReactMarkdown from 'react-markdown';
import { useParams, Link } from 'react-router-dom';
import ReactPlayer from "react-player";
import ReadMoreAndLess from "react-read-more-less";
import { SearchLists, Mobile } from "../misc/utils";



const Covers = () => { 
    const [covers, setCovers] = useState([])
    const [list, setList] = useState([])
    const [loadingCovers, setLoadingCovers] = useState(true)
    const [loadingList, setLoadingList] = useState(true)
    const [errorCovers, setErrorCovers] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
        {headerName: "#", field: "rank", resizable: true, width: 60, cellStyle: { 'font-size': '14px' }}, 
        {headerName: "Song", field: "title", resizable: true, width: 400, cellStyle: { 'font-size': '14px' }},
        {headerName: "Artist", field: "artist", resizable: true, width: 300, cellStyle: { 'font-size': '14px' }},
        ])
    
    const params = useParams();
    
    let filterArray

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setLoadingList(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchCovers = () => {
            axios.get(dbURLCoverVersions + params.slug)
            .then(response => {
                setLoadingCovers(false)
                setCovers(response.data)
            }).catch(errorCovers => {
                setErrorCovers(errorCovers);
            });
        }

        fetchList()
        fetchCovers()
      }, [params.slug])
  
    if (errorList) return `Error List: ${errorList.message}`;
    if (errorCovers) return `Error ListInProgress: ${errorCovers.message}`;

    const isMobile = Mobile();
    
    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (loadingList || loadingCovers) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }
    
    if (searchString) {
        filterArray = SearchLists(covers, 'title', searchString.toLowerCase());
        } 
    if (!searchString) {
        filterArray = covers;
    }
    

    const {name, description, pic_link, page, many_versions_same_song, ranked_list } = list[0];

    const listHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <h1>{value.name}</h1>

                <div className="row my-3">

                     {/* kolonne 1 */}
                     <div className="col-sm-3">
                                            
                            <img src={value.picture_link}
                        alt="alt" className="img-fluid" /> 

                        {
                            (() => {
                            if (value.ListPic2) {
                            return  <div className='mt-2'><img src={value.ListPic2} alt="alt" className="img-fluid" /> </div>
                            }
                            return 
                            })()
                        }
                        
                         {value.facebook_left &&
                                    <div className="mt-3">
                                        Please check out our <b>Facebook page</b> for daily updates:
                                        -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                                    </div>
                                }

                        
                        {((window.location.hostname === "localhost") || (value.in_progress)) &&
                            <div>
                            <br />
                            <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                            
                            <br />
                            <div className="text-center">
                                <div className="btn btn-info btn-xs">total count: {covers.length}</div>
                            </div> 
                        </div>
                        }
                        
                    </div>
                                    
                    {/* kolonne 2 */}
                    <div className="col-sm-5">
                            
                            {/* <div className="DescriptionBig my-2 mx-2 py-1 px-1">{value.description}</div> */}
                                <div className="factTextNoWrap rounded p-2">
                                    <ReactMarkdown>
                                        {value.description}                     
                                    </ReactMarkdown>
                                    
                                </div>
                            <br />
                            
                            {
                                (() => {
                                if (value.spotify) {
                                return  <div className='mb-2 ml-4'><b>--> <a href={ value.spotify }>
                                Spotify playlist</a></b></div>
                                }
                                return 
                                })()
                            }
                            
                            <div>

                                {
                                    (() => {
                                    if (value.url) {
                                    return  <div>
                                            <b>Source</b>
                                            <div className="Description">
                                                <ul>
                                                    <li>
                                                        <b><a href={value.url}>
                                                            {value.name} ({value.source})
                                                        </a></b>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                    </div>
                                    }
                                    return 
                                    })()
                                }

                                <b>Check out</b>
                                <div className="Description">
                                    <ul>
                                        {
                                            (() => {
                                            if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                            return 
                                            })()
                                        }


                                        <li><b><a href='/lists'>
                                            More Lists @ MusicThisDay.com
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            <p className="Description"><i>All lyrics are the property and copyright of 
                                their owners, provided for educational purposes only.</i></p>

                            {!value.facebook_left &&
                            <div>
                                Please check out our <b>Facebook page</b> for daily updates:
                                -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                            </div>
                            }
                        
                    </div>

                    {/* kolonne 3 */}
                    <div className="col-sm-4 mb-1">

                        {
                            (() => {
                            if (many_versions_same_song) {
                            return  <div>
                                        <div className="px-2"><b>Lyrics</b> <br /></div>
                                        <div className="bg-light ml-2 mr-2">
                                            <div className="py-2 px-2 Description">
                                                <ReadMoreAndLess
                                                        charLimit={700}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.lyrics}
                                                </ReadMoreAndLess>
                                            </div>
                                        </div>
                                    </div>
                            }
                            return <div>

                                        <div className="row">   
                                                <div className="col">
                                                <h4>Search</h4>
                                            </div>
                                        </div>
                                                
                                        <div className="row mb-1">  
                                            <div className="col-sm-6 mt-1">
                                                <div className="searchMedium">    
                                                    <DebounceInput
                                                        //type="number"
                                                        minLength={3}
                                                        debounceTimeout={500}
                                                        value={searchString}
                                                        onChange={handleSearchChange}
                                                        placeholder="Search all covers" 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mt-1">
                                                <button type="button" className="btn btn-primary"   
                                                    onClick={() => clearSearchField()} > Reset search</button>
                                            </div>
                                        </div>

                                        <div className="form-check mt-4">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                checked={AgGrid}
                                                onChange={checkHandler}
                                                className="form-check-input"
                                            />
                                            <label htmlFor="checkbox">Change to Grid </label>
                                        </div>

                                </div>
                            })()
                        }

                        
                        
                
                    </div>

                </div>        
            </React.Fragment>
            )
    })

    const coverItems = filterArray.map(value => {

        return (

            <React.Fragment key={value.id}>

                <div className="row videoTitle p-1 m-1">
                    <div className="col-sm-12"> 
                        {
                            (() => {
                            if (many_versions_same_song) {
                            return  <div>&nbsp;<b>#{value.rank}&nbsp; {value.artist}</b></div>
                            }
                            if (ranked_list) {
                            return <div>&nbsp;<b>#{value.rank}&nbsp; {value.artist} - {value.title}</b></div>
                            }
                            return <div>&nbsp;<b>{value.artist} - {value.title}</b></div>
                            })()
                        }

                    </div>
                </div>

                <div className="row py-3 m-1">

                    {/* kolonne1 */}
                    <div className="col-sm-6">

                        {
                            (() => {
                            if (value.description) {
                            return  <div>
                                <div className='row'>
                                    <div className='col mt-2'>
                                        <div className="factText15 rounded p-2">
                                        <ReadMoreAndLess
                                                charLimit={700}
                                                readMoreText={" more ▼"}
                                                readLessText={" less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            return 
                            })()
                        }


                        {
                            (() => {
                            if (value.quote) {
                            return  <div>
                                <div className='row'>
                                    <div className='col mt-4'>
                                        <div className="card bg-light py-2 px-2 mx-4">
                                            <div className="quote mb-3">{value.quote}</div> 
                                            <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            return 
                            })()
                        }

                        {
                            (() => {
                            if (value.lyrics) {
                            return  <div>
                                <div className='row'>
                                    <div className='col Description mt-4'>
                                        <ReadMoreAndLess
                                                charLimit={350}
                                                readMoreText={" more ▼"}
                                                readLessText={" less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.lyrics}
                                        </ReadMoreAndLess>
                                    </div>
                                </div>
                            </div>
                            }
                            return 
                            })()
                        }

                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-6">
                        {
                            (() => {
                            if (value.rank < 4) {
                            return  <div>
                                        
                                        {isMobile && 
                                        <div className="mx-1 mt-2">
                                            <div className="Header2 mt-2" align="center"><b>Audio/Video - click to play</b></div>
                                            <ReactPlayer
                                                url={ value.youtube }
                                                controls={true}
                                                light='true'
                                                volume={0.9}
                                                width="426"
                                                height="240"
                                                />
                                        </div>
                                        }
                                        {!isMobile && 
                                        <div className="mt-2"> 
                                        <div className="Header2 mt-2" align="center"><b>Audio/Video - click to play</b></div> 
                                        <ReactPlayer
                                            url={ value.youtube }
                                            volume={0.9}
                                            light='true'
                                            controls={true}
                                            />
                                        </div>
                                        }

                                    </div>
                            }
                            return <div>
                                        {isMobile && 
                                         <div>
                                            <div className="Header2 mt-2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border bg-light">
                                                    <ReactPlayer
                                                    url={ value.youtube }
                                                    light='true'
                                                    volume={0.9}
                                                    controls={true}
                                                    width="426"
                                                    height="240"
                                                    />
                                            </div>
                                        </div> 
                                        }
                                        {!isMobile && 
                                        <div>
                                            <div className="Header2 mt-2" align="center"><b>Audio/Video - click to play</b></div>
                                            <div className="border d-flex justify-content-center flex-nowrap bg-light">  
                                                <ReactPlayer
                                                url={ value.youtube }
                                                volume={0.9}
                                                light='true'
                                                controls={true}
                                                />
                                            </div>
                                        </div>
                                        }
                                </div>
                            })()
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    })

    
    return (
        <main className="my-1 py-1">
            {list.length > 0 && (
                <div className="container">

                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.musicthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {listHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {!AgGrid && 
                    <div className="row">
                        <div className="col">

                            {
                                (() => {
                                if (coverItems.length > 0) {
                                return <div> {coverItems} </div>
                                }
                                return <div>
                                        <h3>No songs found</h3>

                                    </div>
                                })()
                            }

                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={filterArray}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )


}

export default Covers