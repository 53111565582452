import React from 'react';
//import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

const Header = () => (

    <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                    <img
                    alt=""
                    src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/883ffb4f-5f34-4cc9-d19b-2cec8e95e400/width=70,height=30"
                    className="d-inline-block align-top"
                    />{' '}
                MusicThisDay
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/lists">Lists</Nav.Link>

                    <Nav.Link href="/posts">Posts</Nav.Link>

                    <Nav.Link href="/bobdylan">Bob Dylan</Nav.Link>

                    <NavDropdown title="People" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/lists/people/the-100-greatest-songwriters-of-all-time">The 100 Greatest Songwriters of All Time</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/people/100-greatest-guitarists">100 Greatest Guitarists</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/people/100-greatest-singers-of-all-time">100 Greatest Singers</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/people/the-100-best-bass-players-of-all-time">100 Best Bass Players</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/people/100-greatest-drummers-of-all-time">100 Greatest Drummers</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/people/the-best-jazz-musicians-of-all-time%E2%80%9340-legendary-jazz-artists">The Best Jazz Musicians of All Time</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/people/100-greatest-rock-keyboardists">100 Greatest Rock Keyboardists</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/people">Significant Music People</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Albums" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/lists/ranked-albums/rolling-stone-500-greatest-albums">The 500 Greatest Albums of All Time</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-albums/nme-500-greatest-albums">NME: 500 Greatest Albums</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-albums/cos-100-greatest-albums-of-all-time">COS: The 100 Greatest Albums of All Time</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-albums/vh1-100-greatest-albums">VH1: 100 Greatest Albums</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/lists/ranked-albums/my-personal-200-best-albums">Egil's Top 200 Albums</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Songs" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/500songs">The 500 Greatest Songs of All Time</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/lists/ranked-songs/100-greatest-bob-dylan-songs">100 Greatest Bob Dylan Songs</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-songs/bob-dylans-50-greatest-songs–ranked">Guardian: Bob Dylan's 50 greatest songs – ranked!</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-songs/van-morrisons-50-greatest-songs">Van Morrison’s 50 Greatest Songs</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-songs/david-bowies-50-greatest-songs–ranked">David Bowie's 50 greatest songs – ranked!</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-songs/100-greatest-rolling-stones-songs">100 Greatest Rolling Stones Songs</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/ranked-songs/100-greatest-bruce-springsteen-songs">100 Greatest Bruce Springsteen Songs</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Concerts" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/lists/concerts/top-100-bob-dylan-concerts">Top 100 Bob Dylan Concerts</NavDropdown.Item>
                        <NavDropdown.Item href="/greatestconcerts60s">15 Insanely Great Concerts From the 1960s</NavDropdown.Item>
                        <NavDropdown.Item href="/greatestconcerts70s">15 Insanely Great Concerts From the 1970s</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/concerts/recommended-bruce-springsteen-concerts-1978">Recommended Bruce Springsteen Concerts - 1978</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/concerts/recommended-rolling-stones-concerts-1972">Recommended Rolling Stones Concerts - 1972</NavDropdown.Item>
                        <NavDropdown.Item href="/lists/concerts/recommended-bob-dylan-concerts">Recommended Bob Dylan Concerts</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/videoaudioall">All video/audio (large list)</NavDropdown.Item>
                        <NavDropdown.Item href="/concertlog">My concert log</NavDropdown.Item>
                    </NavDropdown>
                    
                </Nav>   
            </Navbar.Collapse>

            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="me-auto">
                    <Nav.Link href="https://borntolisten.com/">borntolisten.com</Nav.Link>

                    <Navbar.Brand href="https://www.facebook.com/JohannasVisions/">
                        <img
                        alt=""
                        src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/2fcb22db-34d1-4212-ccac-c8e12c33f600/width=30,height=30"
                        className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>

                    <Navbar.Brand href="mailto:egil@musicthisday.com">
                        <img
                        alt=""
                        src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/63ef7666-06c3-4703-68c4-83d8c6a09200/width=30,height=30"
                        className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>
                </Nav>

            </Navbar.Collapse>
        </Container>
    </Navbar>
    
)

export default Header;