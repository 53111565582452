import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import { dbURLpeople } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
//import ReactGA from "react-ga";
import YoutubeEmbed from "../misc/YoutubeEmbed";
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import withRouter from '../withRouter';


class PeopleProfile extends Component {

    state = {
        ProfileAllData: [],
        loading: true
      }

      getAllProfileData(){
        axios.get(dbURLpeople + this.props.params.slug)
          .then(response => {
            this.setState({
                ProfileAllData: response.data,
                loading: false
            });
          });
      }
    
    componentDidMount() {
        this.getAllProfileData()
      }

    scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    render() {
        
        const AllProfileDetails = this.state.ProfileAllData;
        
        const today = dayjs().format("MM-DD").toString();

        const ProfileHeader = AllProfileDetails.map(value => {
            
            let Header;
                if (dayjs(value.birth_date).format("MM-DD") === today) {
                    Header = 'Happy Birhday ' + value.artist;
                } else {
                    Header = value.artist;
                }

            return (
                <React.Fragment key={value.id}>
                    <div className="text-center text-light bg-secondary rounded"><b>{Header}</b></div>
                </React.Fragment>
            )

        })
        

        const ProfileDetails = AllProfileDetails.map(value => {

            return (
                <React.Fragment key={value.id}>

                    <div className="row">

                        <div className="col-sm-5 mb-3 text-center">
                            
                            <img src={value.selected_pic} className="img-fluid" alt=""></img>

                        </div>
                        <div className="col-sm-7 mb-3">

                            {
                                (() => {
                                if (value.dead) {
                                    return <div className='BestSong text-center p-1 mb-3 rounded'>
                                                <b>{value.artist} ({value.born} - {value.dead})</b>
                                            </div>
                                }
                                return <div className='BestSong text-center p-1 mb-3 rounded'>
                                        <b>{value.artist} ({value.born})</b>
                                    </div>
                                })()
                            }

                            
                            
                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.main_comment}</div> 
                                <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                            </div>
                            {
                                (() => {
                                if (value.comment) {
                                    return <div className="card bg-light mt-3 py-2 px-2">
                                                <p className="quote3">{value.comment}
                                                
                                                </p> 
                                                <div className="blockquote-footer"><i>{value.source}</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }
                            {
                                (() => {
                                if (value.comment_2) {
                                    return <div className="card bg-light mt-3 py-2 px-2">
                                                <p className="quote3">{value.comment_2}
                                                
                                                </p> 
                                                <div className="blockquote-footer"><i>{value.source_2}</i></div>
                                            
                                            </div>
                                }
                                return <p></p>;
                                })()
                            }

                        </div>

                    </div>

                    <div className="row pt-3 pb-2 border-bottom border-top border-info">

                        {/* kolonne 1 */}
                        <div className="col-sm-8 pb-2 border-right">

                            <div className="row">
                                <div className="col py-2 border-right Description">

                                    <div>
                                        <div className='factText p-1'>
                                            <ReadMoreAndLess
                                                    charLimit={1600}
                                                    readMoreText={" Read more ▼"}
                                                    readLessText={" Read less ▲"}
                                                    readMoreClassName="read-more-less--more"
                                                    readLessClassName="read-more-less--less"
                                                >
                                                {value.description}
                                            </ReadMoreAndLess>
                                        </div>
                                        <p className="Description"> 
                                            <br /> <em> -> <a href={value.wikipedia_link}>more info..</a></em>
                                            </p>
                                    </div>
                                    
                                </div>

                            </div>

                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-4">

                                <div className="row">
                                
                                    <div className="col">
                                        <div>
                                            {
                                                (() => {
                                                if (value.birth_name) {
                                                    return <div>
                                                            <b>Birth name</b> <br />
                                                             <div className="factText px-1">{value.birth_name}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                                
                                            {
                                                (() => {
                                                if (value.dead) {
                                                    return <div>
                                                            <div>
                                                            <b>Born</b> <br />
                                                            <div className="Date">{value.born} - {value.born_place}</div>
                                                            </div>
                                                            <div>
                                                            <b>Died</b> <br />
                                                            <div className="Date">{value.dead} - {value.dead_place}</div>
                                                            </div>
                                                        </div>
                                                }
                                                return <div>
                                                        <b>Born</b> <br />
                                                        <div className="Date">{value.born} - {value.born_place}</div>
                                                    </div>
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.sub_genres) {
                                                    return <div>
                                                            <b>Genres</b> <br />
                                                             <div className="factText px-1">{value.sub_genres}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.occupation) {
                                                    return <div>
                                                            <b>Occupation(s)</b> <br />
                                                             <div className="factText px-1">{value.occupation}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.instrument) {
                                                    return <div>
                                                            <b>Instruments</b> <br />
                                                             <div className="factText px-1">{value.instrument}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.years_active) {
                                                    return <div>
                                                            <b>Years active</b> <br />
                                                             <div className="factText px-1">{value.years_active}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.associated_acts) {
                                                    return <div>
                                                            <b>Associated acts</b> <br />
                                                             <div className="factText px-1">{value.associated_acts}</div>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
      
                                        </div>

                                    </div>

                                </div>

                                {/* <div className="row pt-3">
                                    <div className="col-12">
                                        
                                        {
                                            (() => {
                                            if (value.line_up) {
                                            return  <div><b>Probable line-up</b> <br />
                                                        <div className="factText px-1">
                                                            {value.line_up}
                                                        </div>
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                    </div>
                                </div> */}
                            
                            </div>
                                
                    </div>

                    {/* Selected songs */}
                        <div className='row mt-3'>
                            <div className='col'>
                                <div className='BestSong text-center p-1 mb-1 rounded'><b>Selected songs</b>
                                </div>
                            </div>
                        </div>
                                        <div className="row pt-3 pb-3 border-bottom border-info">

                                            <div className="col-sm-6">

                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube1} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song1_txt}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube3} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song3_txt}</b></h5>
                                                    </div>
                                                </div>

                                                

                                            </div>

                                            <div className="col-sm-6">
                                                
                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube2} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song2_txt}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube4} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song4_txt}</b></h5>
                                                    </div>
                                                </div>
                                            

                                            </div>

                    </div>

                    {/* Selected live songs */}
                    {
                        (() => {
                        if (value.song_live1) {
                            return <div>
                                    <div className='row mt-3'>
                                        <div className='col'>
                                            <div className='BestSong text-center p-1 mb-1 rounded'><b>Selected live songs</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        return 
                        })()
                    }

                    <div className="row pt-3 pb-3">

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.song_live1 && value.song_live3) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.youtube_live1} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_live1}
                                            </div>
                                            
                                        </div>
                                        <div className="card bg-light mt-3 py-2 px-2">

                                        <div className="mx-4">  
                                                <YoutubeEmbed embedId={value.youtube_live3} />
                                        </div>

                                    <div className='DescriptionBig2 pl-4'>
                                        {value.song_live3}
                                    </div>
                                    
                                </div></div>
                                }
                                if (value.song_live1 && !value.song_live3) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.youtube_live1} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_live1}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.youtube_live2 && value.youtube_live4) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.youtube_live2} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_live2}
                                            </div>
                                            
                                        </div>

                                        <div className="card bg-light mt-3 py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.youtube_live4} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_live4}
                                            </div>
                                            
                                        </div>


                                    </div>
                                }
                                if (value.youtube_live2 && !value.youtube_live4) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.youtube_live2} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_live2}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>

                    {/* Selected cover songs */}
                    {
                        (() => {
                        if (value.song_cover1) {
                            return <div>
                                    <div className='row mt-3'>
                                        <div className='col'>
                                            <div className='BestSong text-center p-1 mb-1 rounded'><b>Selected cover songs</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        return 
                        })()
                    }

                    <div className="row pt-3 pb-3">

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.song_cover1 && value.song_cover3) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video_cover1} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_cover1}
                                            </div>
                                            
                                        </div>
                                        <div className="card bg-light mt-3 py-2 px-2">

                                        <div className="mx-4">  
                                                <YoutubeEmbed embedId={value.video_cover3} />
                                        </div>

                                    <div className='DescriptionBig2 pl-4'>
                                        {value.song_cover3}
                                    </div>
                                    
                                </div></div>
                                }
                                if (value.song_cover1 && !value.song_cover3) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video_cover1} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_cover}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                            {
                                (() => {
                                if (value.video_cover2 && value.video_cover4) {
                                    return <div><div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video_cover2} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_cover2}
                                            </div>
                                            
                                        </div>

                                        <div className="card bg-light mt-3 py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video_cover4} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_cover4}
                                            </div>
                                            
                                        </div>


                                    </div>
                                }
                                if (value.video_cover2 && !value.video_cover4) {
                                    return <div className="card bg-light py-2 px-2">

                                                <div className="mx-4">  
                                                        <YoutubeEmbed embedId={value.video_cover2} />
                                                </div>

                                            <div className='DescriptionBig2 pl-4'>
                                                {value.song_cover2}
                                            </div>
                                            
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>


                    


                </React.Fragment>
            )


        })

        return (
            <div>
                {
                    (() => {
                    if (this.state.loading) {
                        return <div>
                                <main className="my-4 py-4"><div className="container"> 
                                <div className="row pt-5"><div className="col">
                                <h4>Loading...</h4></div></div></div></main>;
                            </div>
                    }
                    if (AllProfileDetails.length < 1) {
                        return <div>
                                    <main className="my-1 py-1">
                                        <div className="container">  
                                            <div className="row pt-1">
                                                <div className="col">
                                                    <h1>404 - Not Found!</h1>
                                                    <Link to="/lists">
                                                        Go to current Posts
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                            </div>
                    }
                    return <main className="my-1 py-1">
                                <div className="container">

                                    <div className="row bestSongsTitle mb-1">
                                        <div className="col-sm-12 mb-2"> 
                                            {ProfileHeader}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                                {ProfileDetails}
                                        </div>
                                    </div>
                        
                                    <p align="Right"><br />
                                        <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                        <b>Scroll to top</b></button>
                                    </p>
                                    
                            
                                </div>
                            </main>
                    })()
                }
                
            </div>

        )

    }

}
export default withRouter(PeopleProfile);