import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayPeople } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import { Link } from "react-router-dom";
import { ImageWithFallback } from "../misc/utils";

const AnyDayPeople = (props) => {
const [people, setPeople] = useState([]);
const [showAllPeople, setShowAllPeople] = useState(false);
//const [isLoading, setIsLoading] = useState(true)
const [error, setError] = React.useState(null);
let largeArray

const ShowAllPeopleButton = () => {
    setShowAllPeople(true);
    };
/* const ShowLessPeopleButton = () => {
    setShowAllPeople(false);
    }; */

    
    useEffect(() => {
      const fetchPeople = () => {
        axios.get(dbURLAnyDayPeople + props.dateSelected)
        .then(response => {
          //setIsLoading(false)
          setPeople(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchPeople()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  //fjerne duplikater
  const peopleUnique  = people.filter(
    (obj, index) =>
      people.findIndex((item) => item.id === obj.id) === index
  );

  //Fjerne objecter over 8 
  if (peopleUnique.length > 8  && !showAllPeople) {
    largeArray = true
    peopleUnique.length = 8;
    }

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {peopleUnique.length > 0 && (
        <div>
            <div className="row TodayTitle24nobg mx-5">
                <div className="col-sm-12"> 
                    <div className="text-center"><b>People</b>
                    </div>
                </div>
            </div>
          {peopleUnique.map(value => (
            <div key={value.id}>
                <div className="row my-2">
                    <div className="col">
                        {
                            (() => {
                            if ((value.importance === 6) && value.aka) {
                            return <div className="Crown rounded"><b>&nbsp;{value.aka}</b></div>    
                            }
                            if ((value.importance === 5) && value.aka) {
                                return <div className="Great rounded"><b>&nbsp;{value.aka}</b></div>    
                                }
                            if ((value.importance === 4) && value.aka) {
                                return <div className="Good rounded"><b>&nbsp;{value.aka}</b></div>    
                                }
                            if ((value.importance === 6) && !value.aka) {
                            return <div className="Crown rounded"><b>&nbsp;{value.name}</b></div>  
                            }
                            if ((value.importance === 5) && !value.aka) {
                                return <div className="Great rounded"><b>&nbsp;{value.name}</b></div>  
                                }
                            return <div className="Good rounded"><b>&nbsp;{value.name}</b></div> 
                            })()
                        } 
                    </div>
                </div>
                {value.picture &&
                <div className="row ReducedText">
                    <div className="col-4">

                        {value.picture &&
                            <div align="center">
                                <ImageWithFallback src={value.picture}  
                                alt="description" />
                               {/*  <img src={value.picture} 
                            alt="" className="img-fluid rounded">
                            </img> */}
                            </div>
                        }
                        
                        
                    </div>

                    <div className="col-8">
                        

                        {
                            (() => {
                            if (props.dateSelected === value.born_slug) {
                            return <div>
                                    <b>Born: </b><br />
                                    <div className="DateInline p-1 rounded"><b>{value.born}</b></div>
                                </div>
                            }
                            return <div>
                                    <b>Born:</b><br />
                                    <div className="DateInline p-1 rounded">{value.born}</div>
                                </div>
                            })()
                        }
                        
                        {
                            (() => {
                            if (props.dateSelected === value.dead_slug) {
                            return <div>
                                    <b>Died: </b><br />
                                    <div className="DateInline p-1 rounded"><b>{value.dead}</b></div>
                                </div>
                            }
                            if (value.dead_slug && (props.dateSelected !== value.dead_slug)) {
                                return <div>
                                        <b>Died:</b><br />
                                        <div className="DateInline p-1 rounded">{value.dead}</div>
                                    </div>
                            }
                            return 
                            })()
                        }

                        {value.genre && !value.band &&
                            <div>
                                <b>Genre: </b><br />
                                <div className="DateInline p-1 rounded"><b>{value.genre}</b></div>
                            </div>
                        }

                        {
                            (() => {
                                if (value.band) {
                                return <div className="mt-1"><b>Main band</b><br />
                                <div className="DateInline p-1 rounded">{value.band}</div></div>
                                }
                                return
                            })()
                        }
                        {
                            (() => {
                                if (value.city && !value.born_place) {
                                return <div className="mt-1"><b>Born/origin</b><br />
                                <div className="DateInline p-1 rounded">{value.city}, {value.country}</div></div>
                                }
                                if (value.born_place) {
                                    return <div className="mt-1"><b>Born/origin</b><br />
                                <div className="DateInline p-1 rounded">{value.born_place}</div></div>
                                }
                                return 
                            })()
                        }


                    </div>
                </div>
                }
                {!value.picture &&
                <div>
                    <div className="row ReducedText">
                        <div className="col-5">
                            {
                                (() => {
                                if (props.dateSelected === value.born_slug) {
                                return <div>
                                        <b>Born: </b><br />
                                        <div className="DateInline p-1 rounded"><b>{value.born}</b></div>
                                    </div>
                                }
                                return <div>
                                        <b>Born:</b><br />
                                        <div className="DateInline p-1 rounded">{value.born}</div>
                                    </div>
                                })()
                            }
                            
                            {
                                (() => {
                                if (props.dateSelected === value.dead_slug) {
                                return <div>
                                        <b>Died: </b><br />
                                        <div className="DateInline p-1 rounded"><b>{value.dead}</b></div>
                                    </div>
                                }
                                if (value.dead_slug && (props.dateSelected !== value.dead_slug)) {
                                    return <div>
                                            <b>Died:</b><br />
                                            <div className="DateInline p-1 rounded">{value.dead}</div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {value.genre && !value.band &&
                                <div>
                                    <b>Genre: </b><br />
                                    <div className="DateInline p-1 rounded"><b>{value.genre}</b></div>
                                </div>
                            }

                            {
                                (() => {
                                    if (value.band) {
                                    return <div className="mt-1"><b>Main band</b><br />
                                    <div className="DateInline p-1 rounded">{value.band}</div></div>
                                    }
                                    return
                                })()
                            }
                            {
                                (() => {
                                    if (value.city && !value.born_place) {
                                    return <div className="mt-1"><b>Born/origin</b><br />
                                    <div className="DateInline p-1 rounded">{value.city}, {value.country}</div></div>
                                    }
                                    if (value.born_place) {
                                        return <div className="mt-1"><b>Born/origin</b><br />
                                    <div className="DateInline p-1 rounded">{value.born_place}</div></div>
                                    }
                                    return 
                                })()
                            }
                            
                        </div>

                        <div className="col-7">
                            
                            <div className="Description mb-2">
                                <ReadMoreAndLess
                                    charLimit={220}
                                    readMoreText={" more▼"}
                                    readLessText={" less▲"}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"
                                    >
                                    {value.description}
                                </ReadMoreAndLess>
                                <em> -> <a href={value.wiki}>wikipedia</a></em>
                            </div>
                            
                        </div>
                    </div>
                    {value.listpeopleid &&
                    <div className="card p-2 my-2 Description bg-light">
                        <b>Check out list:</b>
                            {value.list_place &&
                            <div>
                                <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                to={value.list_page}>{value.list_name}</Link> (#{value.list_place})
                            </div>
                            } 
                            
                            {!value.list_place &&
                            <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                            to={value.list_page}>{value.list_name}</Link> 
                            } 
                    </div>
                    }

                </div>
                }

                {value.picture &&
                <div className="row mt-2 ReducedText">
                    <div className="col">
                        {
                            (() => {
                            if (value.description) {
                            return <div className="Description mb-2">
                                        <ReadMoreAndLess
                                            charLimit={150}
                                            readMoreText={" more▼"}
                                            readLessText={" less▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                        <em> -> <a href={value.wiki}>wikipedia</a></em>
                                    </div>
                            }
                            return 
                        })()       
                        }

                        {
                            (() => {
                            if (value.listpeopleid) {
                            return <div className="card p-2 mb-2 Description bg-light">
                                        <b>Check out list:</b>
                                            {value.list_place &&
                                            <div>
                                                <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                                to={value.list_page}>{value.list_name}</Link> (#{value.list_place})
                                            </div>
                                            } 
                                            
                                            {!value.list_place &&
                                            <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                            to={value.list_page}>{value.list_name}</Link> 
                                            } 
                                    </div>
                            }
                            return 
                            })()    
                        }
                    </div>
                </div>
                }
                
            </div>
          ))}
        {largeArray &&
        <div className='mt-2'>
          <button id = "btn" className="btn-sm btn-info" onClick = {ShowAllPeopleButton}>Show more people</button>
          </div>
        }
        {/* {showAllPeople &&
        <div className='mt-2'>
          <button id = "btn" className="btn-sm btn-info" onClick = {ShowLessPeopleButton}>Show less people</button>
          </div>
        } */}
        </div>
      )}
    </div>
  )
}

export default AnyDayPeople
